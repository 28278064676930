import { FC, memo } from 'react'

import Icon from '@components/Icon/Icon'
import { Tooltip, TooltipProps } from '@components/NewDesign/Tooltip'
import Typography from '@components/NewDesign/Typography'
import env from '@config'
import InfoIcon from '@icons/info_outline.svg'
import cn from 'classnames'

import styles from './DeadlineProjects.module.scss'

const ECONOMY_SUPPORT_EMAIL = env.REACT_APP_ECONOMY_SUPPORT_EMAIL

type DeadlineProjectsTooltipProps = Pick<TooltipProps, 'targetClassName'>

const DeadlineProjectsTooltip: FC<DeadlineProjectsTooltipProps> = ({ targetClassName }) => {
  const tooltipContent = (
    <>
      <Typography.Body variant="bodyMRegular">
        Указан рекомендованный срок рассмотрения документов.
      </Typography.Body>

      <Typography.Body className={styles.deadlineProjects__row} variant={'bodyMRegular'}>
        Для получения более точной информации напишите в Минэкономразвития России
        <a href={`mailto:${ECONOMY_SUPPORT_EMAIL}`}>{ECONOMY_SUPPORT_EMAIL}</a>
      </Typography.Body>
    </>
  )

  return (
    <Tooltip
      offset={[0, 10]}
      closeDelay={100}
      popoverClassName={styles.deadlineProjects__popover}
      trigger={'click'}
      position={'bottom'}
      content={tooltipContent}
    >
      <Icon
        noCurrentColorSvgFill
        className={cn(styles.deadlineProjects__icon, targetClassName)}
        src={InfoIcon}
        size="xs"
      />
    </Tooltip>
  )
}

export default memo(DeadlineProjectsTooltip)
