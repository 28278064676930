import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import FormBlockTitleIndicator from '@components/DocumentFormComponents/FormIndicator/FormBlockTitleIndicator'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import NoDataFieldFallback from '@components/DocumentFormComponents/NoDataFieldFallback'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import {
  useFactInvestorCapitalIndicator,
  useInvestorCapitalMadeOnTimeIndicatorValue,
} from '@components/Forms/IndicatorsOfUnfulfilledAgreementReport/Forms/1/hooks'
import Container from '@components/ReactBootstrap/Container'
import Stack from '@components/ReactBootstrap/Stack'

import { indicatorsOfUnfulfilledAgreementReportBlockValues } from '../../const'
import { useIndicatorsOfUnfulfilledAgreementReportManager } from '../../Manager'
import { IndicatorsOfUnfulfilledAgreementReportFormValues } from '../../types'
import { IndicatorsOfUnfulfilledAgreementReportFieldsControlUpdateWatcher } from '../../watcher'

const First = () => {
  const formInstance = useFormContext<IndicatorsOfUnfulfilledAgreementReportFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    preparedProps: { subscribableControl },
  } = useIndicatorsOfUnfulfilledAgreementReportManager()

  const { getControllerProps, getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: IndicatorsOfUnfulfilledAgreementReportFieldsControlUpdateWatcher,
  })

  const { factInvestorCapitalIndicatorFormName, factInvestorCapitalIndicatorTitle } =
    useFactInvestorCapitalIndicator(formInstance)

  const investorCapitalMadeOnTimeIndicatorValue =
    useInvestorCapitalMadeOnTimeIndicatorValue(formInstance)

  if (!formInstance) return null

  return (
    <>
      <FormBlockTitleIndicator activeValue={investorCapitalMadeOnTimeIndicatorValue} />
      <Container className={'p-0'}>
        <Stack direction={'vertical'} gap={2}>
          <RowWithBorder
            disableTitleTopPadding
            disableBottomDefaultStyles
            title={'Объем капиталовложений (с НДС) (план)'}
            firstColumnSize={6}
            secondColumnSize={6}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: indicatorsOfUnfulfilledAgreementReportBlockValues['1'].investorCapitalVAT,
              })}
            >
              <ControlledDocumentDataView
                hideSuptitle
                disableContainerPaddingTop
                disableContainerPaddingBottom
                contentPosition="right"
                fallbackValue={<NoDataFieldFallback />}
                controllerProps={getControllerProps({
                  name: indicatorsOfUnfulfilledAgreementReportBlockValues['1'].investorCapitalVAT,
                })}
                formIconTooltipProps={{
                  tooltipContent: 'по данным СЗПК',
                  iconPosition: 'right',
                }}
              />
            </SubscribableControl>
          </RowWithBorder>
          <RowWithBorder
            disableTitleTopPadding
            disableBottomDefaultStyles
            title={'Объем капиталовложений (без НДС) (план)'}
            firstColumnSize={6}
            secondColumnSize={6}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: indicatorsOfUnfulfilledAgreementReportBlockValues['1'].investorCapital,
              })}
            >
              <ControlledDocumentDataView
                hideSuptitle
                disableContainerPaddingTop
                disableContainerPaddingBottom
                contentPosition="right"
                fallbackValue={<NoDataFieldFallback />}
                controllerProps={getControllerProps({
                  name: indicatorsOfUnfulfilledAgreementReportBlockValues['1'].investorCapital,
                })}
                formIconTooltipProps={{
                  tooltipContent: 'по данным СЗПК',
                  iconPosition: 'right',
                }}
              />
            </SubscribableControl>
          </RowWithBorder>
          <RowWithBorder
            disableTitleTopPadding
            disableBottomDefaultStyles
            title={factInvestorCapitalIndicatorTitle}
            firstColumnSize={6}
            secondColumnSize={6}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: factInvestorCapitalIndicatorFormName,
              })}
            >
              <ControlledDocumentDataView
                hideSuptitle
                disableContainerPaddingTop
                disableContainerPaddingBottom
                contentPosition="right"
                fallbackValue={<NoDataFieldFallback />}
                controllerProps={getControllerProps({
                  name: factInvestorCapitalIndicatorFormName,
                })}
                formIconTooltipProps={{
                  tooltipContent: 'по данным отчета об исполнении',
                  iconPosition: 'right',
                }}
              />
            </SubscribableControl>
          </RowWithBorder>
          <RowWithBorder
            disableTitleTopPadding
            disableBottomDefaultStyles
            title={'Срок осуществления капиталовложений в полном объеме (план)'}
            firstColumnSize={6}
            secondColumnSize={6}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: indicatorsOfUnfulfilledAgreementReportBlockValues['1']
                  .madeInvestorCapitalDate,
              })}
            >
              <ControlledDocumentDataView
                hideSuptitle
                disableContainerPaddingTop
                disableContainerPaddingBottom
                contentPosition="right"
                controllerProps={getControllerProps({
                  name: indicatorsOfUnfulfilledAgreementReportBlockValues['1']
                    .madeInvestorCapitalDate,
                })}
                formIconTooltipProps={{
                  tooltipContent: 'по данным СЗПК',
                  iconPosition: 'right',
                }}
              />
            </SubscribableControl>
          </RowWithBorder>
        </Stack>
      </Container>
    </>
  )
}

export default First
