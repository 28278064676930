import { FieldArrayControlUpdateWatcher } from '@components/DocumentFormComponents/fieldArrayWatcher'
import { FieldCollapseControlUpdateWatcher } from '@components/DocumentFormComponents/fieldCollapseWatcher'
import { FieldsControlUpdateWatcher } from '@components/DocumentFormComponents/watcher'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'

export const AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher =
  new FieldsControlUpdateWatcher()
export const AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher =
  new FieldArrayControlUpdateWatcher<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>()
export const AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldCollapseControlUpdateWatcher =
  new FieldCollapseControlUpdateWatcher()
