import React, { FC } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { SeventhRidObjectsArrayPathName } from '@components/Forms/ProjectSZPKForm/Forms/7/types'
import { useProjectSZPKManager } from '@components/Forms/ProjectSZPKForm/Manager'
import { ProjectSZPKFormValues } from '@components/Forms/ProjectSZPKForm/types'
import { ProjectSZPKFieldsControlUpdateWatcher } from '@components/Forms/ProjectSZPKForm/watcher'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { ridProjectSZPKValidationMap } from './validation'

interface RidFormItemProps {
  isShowRidFormItemContent: boolean
  blockViewIsValidating: boolean
  editMode: boolean
  formInstance: UseFormReturn<ProjectSZPKFormValues>
  formName: SeventhRidObjectsArrayPathName
}

const RidFormItem: FC<RidFormItemProps> = ({
  isShowRidFormItemContent,
  blockViewIsValidating,
  editMode,
  formInstance,
  formName,
}) => {
  const {
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useProjectSZPKManager()

  const { getSubscribableControlProps, getControllerProps, getInputProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: ProjectSZPKFieldsControlUpdateWatcher,
    })

  const ridKind = useWatch({
    name: `${formName}.ridKind`,
  }) as string | undefined

  return (
    <div>
      <Col xs={12}>
        {!isShowRidFormItemContent && (
          <Row>
            <Col xs={12}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.ridName`,
                })}
              >
                <ControlledDocumentDataView
                  suptitle={ridKind || ''}
                  controllerProps={getControllerProps({
                    name: `${formName}.ridName`,
                  })}
                />
              </SubscribableControl>
            </Col>
          </Row>
        )}
        <CollapseWrapper
          defaultExpanded={isShowRidFormItemContent}
          isExpanded={isShowRidFormItemContent}
        >
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.ridKind`,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${formName}.ridKind`,
                      rules: ridProjectSZPKValidationMap.ridKind,
                      inputProps: {
                        label: 'Вид',
                      },
                      onBlur: () => setTimeout(() => handleChangeValue?.(`${formName}.ridKind`), 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.ridKind`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.ridName`,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${formName}.ridName`,
                      rules: ridProjectSZPKValidationMap.ridName,

                      inputProps: {
                        label: 'Наименование',
                        leftAddons: (
                          <FormIconWithTooltip tooltipContent="Заполняется в соответствии с ГК РФ и бизнес-планом инвестиционного проекта" />
                        ),
                      },
                      onBlur: () => setTimeout(() => handleChangeValue?.(`${formName}.ridName`), 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.ridName`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.ridAddress`,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${formName}.ridAddress`,
                      rules: ridProjectSZPKValidationMap.ridAddress,
                      inputProps: {
                        label: 'Адрес места выполнения работ по созданию РИД',
                      },
                      onBlur: () =>
                        setTimeout(() => handleChangeValue?.(`${formName}.ridAddress`), 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.ridAddress`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.ridOwner`,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${formName}.ridOwner`,
                      rules: ridProjectSZPKValidationMap.ridOwner,
                      inputProps: {
                        label: 'Обладатель исключительного права',
                      },
                      onBlur: () =>
                        setTimeout(() => handleChangeValue?.(`${formName}.ridOwner`), 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.ridOwner`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: `${formName}.ridDetails`,
                  })}
                >
                  <ControlledInput
                    {...getInputProps({
                      name: `${formName}.ridDetails`,
                      rules: ridProjectSZPKValidationMap.ridDetails,
                      inputProps: {
                        label: 'Иные характеристики',
                        caption: 'если применимо',
                      },
                      onBlur: () =>
                        setTimeout(() => handleChangeValue?.(`${formName}.ridDetails`), 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${formName}.ridDetails`)
                        }, 0),
                    })}
                  />
                </SubscribableControl>
              </Col>
            </Row>
          </Stack>
        </CollapseWrapper>
      </Col>
    </div>
  )
}

export default RidFormItem
