import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import NoDataFieldFallback from '@components/DocumentFormComponents/NoDataFieldFallback'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import Container from '@components/ReactBootstrap/Container'
import Stack from '@components/ReactBootstrap/Stack'

import { indicatorsOfUnfulfilledAgreementReportBlockValues } from '../../const'
import { useIndicatorsOfUnfulfilledAgreementReportManager } from '../../Manager'
import { IndicatorsOfUnfulfilledAgreementReportFormValues } from '../../types'
import { IndicatorsOfUnfulfilledAgreementReportFieldsControlUpdateWatcher } from '../../watcher'

const Third = () => {
  const formInstance = useFormContext<IndicatorsOfUnfulfilledAgreementReportFormValues>()

  const {
    state: { editMode, blockViewIsValidating },
    preparedProps: { subscribableControl },
  } = useIndicatorsOfUnfulfilledAgreementReportManager()

  const { getControllerProps, getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: IndicatorsOfUnfulfilledAgreementReportFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={2}>
        <RowWithBorder
          disableTitleTopPadding
          disableBottomDefaultStyles
          title={'Количество новых рабочих мест (план)'}
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: indicatorsOfUnfulfilledAgreementReportBlockValues['3'].newWorkPlaces,
            })}
          >
            <ControlledDocumentDataView
              hideSuptitle
              disableContainerPaddingTop
              disableContainerPaddingBottom
              contentPosition="right"
              controllerProps={getControllerProps({
                name: indicatorsOfUnfulfilledAgreementReportBlockValues['3'].newWorkPlaces,
              })}
              formIconTooltipProps={{
                tooltipContent: 'по данным СЗПК',
                iconPosition: 'right',
              }}
            />
          </SubscribableControl>
        </RowWithBorder>
        <RowWithBorder
          disableTitleTopPadding
          disableBottomDefaultStyles
          titleHeightFallback={'20px'}
          title={'Количество новых рабочих мест (факт)'}
          firstColumnSize={6}
          secondColumnSize={6}
        >
          <SubscribableControl
            {...getSubscribableControlProps({
              path: indicatorsOfUnfulfilledAgreementReportBlockValues['3'].factNewWorkPlace,
            })}
          >
            <ControlledDocumentDataView
              hideSuptitle
              disableContainerPaddingTop
              disableContainerPaddingBottom
              contentPosition="right"
              fallbackValue={<NoDataFieldFallback />}
              controllerProps={getControllerProps({
                name: indicatorsOfUnfulfilledAgreementReportBlockValues['3'].factNewWorkPlace,
              })}
              formIconTooltipProps={{
                tooltipContent: 'по данным отчета об исполнении',
                iconPosition: 'right',
              }}
            />
          </SubscribableControl>
        </RowWithBorder>
      </Stack>
    </Container>
  )
}

export default Third
