import React, { cloneElement, isValidElement, ReactNode, useEffect } from 'react'
import { FieldValues, Path, useWatch } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types'

import { formObjectTooltipControlTypes } from '@components/DocumentFormComponents/FormControls/FormObjectTooltipControl/const'
import FormActionIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormActionIconWithTooltip'
import { isUndefined } from '@helpers/checkTypes'
import { ValueOf } from '@helpers/ValueOf'

const formObjectTooltipControlTexts = {
  isNew: 'Новый элемент',
}

interface FormObjectTooltipControlProps<T extends FieldValues> {
  name: Path<T>
  control: Control<T>
  children: ReactNode
  type?: ValueOf<typeof formObjectTooltipControlTypes>
  onDifference?: (path: Path<T> | string, isChanged: boolean) => void
}

const FormObjectTooltipControl = <T extends FieldValues>({
  type = formObjectTooltipControlTypes.isNew,
  control,
  name,
  children,
  onDifference,
}: FormObjectTooltipControlProps<T>) => {
  const conditionToCloneChildren = useWatch({
    control,
    name: `${name}.${type}` as Path<T>,
  })

  useEffect(() => {
    if (isUndefined(onDifference) || !conditionToCloneChildren) return

    onDifference(name, true)

    return () => {
      onDifference(name, false)
    }
  }, [])

  if (!isValidElement(children)) return null

  const renderChildren = () => {
    if (!conditionToCloneChildren) return children

    return cloneElement(children, {
      ...children.props,
      leftAddons: (
        <FormActionIconWithTooltip
          tooltipContent={formObjectTooltipControlTexts[type]}
          tooltipProps={{
            offset: [40, 10],
          }}
        />
      ),
    })
  }

  return (
    <div id={name} key={name}>
      {renderChildren()}
    </div>
  )
}

export default FormObjectTooltipControl
