import React, { FC, memo } from 'react'
import { useFormContext } from 'react-hook-form'

import DocumentForm from '@components/DocumentFormComponents/Form'
import { FormItemProps } from '@components/DocumentFormComponents/Form/types'
import { FormModalStatementProps } from '@components/DocumentFormComponents/types'
import { useRegistrationCertificateManager } from '@components/Forms/RegistrationCertificateForm/Manager'
import { RegistrationCertificateFormValues } from '@components/Forms/RegistrationCertificateForm/types'

import { mapOfRegistrationCertificateMenu } from '../const'
import RegistrationCertificateForms from '../Forms'

const mapOfRegistrationCertificateForms: FormItemProps = {
  '1': {
    node: <RegistrationCertificateForms.First />,
  },
  '2': {
    node: <RegistrationCertificateForms.Second />,
  },
  '3': {
    node: <RegistrationCertificateForms.Third />,
  },
  '4': {
    node: <RegistrationCertificateForms.Fourth />,
  },
  '5': {
    node: <RegistrationCertificateForms.Fifth />,
  },
  '6': {
    node: <RegistrationCertificateForms.Sixth />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '7': {
    node: <RegistrationCertificateForms.Seventh />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '8': {
    node: <RegistrationCertificateForms.Eighth />,
    overrideBlockProps: {
      isNestedBlock: true,
    },
  },
  '9': {
    node: <RegistrationCertificateForms.Ninth />,
  },
  '10': {
    node: <RegistrationCertificateForms.Tenth />,
  },
  '11': {
    node: <RegistrationCertificateForms.Eleventh />,
  },
} as const

const RegistrationCertificateForm: FC<FormModalStatementProps> = ({ ...rest }) => {
  const formInstance = useFormContext<RegistrationCertificateFormValues>()

  const {
    state: { formIsLoading },
  } = useRegistrationCertificateManager()

  if (!formInstance) return null

  return (
    <DocumentForm
      {...rest}
      mapOfMenu={mapOfRegistrationCertificateMenu}
      mapOfForms={mapOfRegistrationCertificateForms}
      formIsLoading={formIsLoading}
    />
  )
}

export default memo(RegistrationCertificateForm)
