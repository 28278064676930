import React, { FC, ReactNode } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import FieldArraySwapper from '@components/DocumentFormComponents/FieldArraySwapper'
import FlippedWithCollapse from '@components/DocumentFormComponents/FlippedWithCollapse'
import styles from '@components/DocumentFormComponents/FormStyles/ObjectItem.module.scss'
import { useFormCollapseControl } from '@components/DocumentFormComponents/hooks/useFormCollapseControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import ObjectStepWrapperHeaderNode from '@components/DocumentFormComponents/ObjectStepWrapper/ObjectStepWrapperHeaderNode'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import StepAnotherObjectForm from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/8/AdditionalForms/AnotherObjectForm/StepAnotherObjectForm'
import { EighthMeasureObjectsArrayPathName } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/8/types'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldCollapseControlUpdateWatcher,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import Button from '@components/NewDesign/Button'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import DeleteIcon from '@icons/DeleteIcon.svg'
import cn from 'classnames'

interface StepAnotherObjectProps {
  id: string
  anchorId: string
  name: EighthMeasureObjectsArrayPathName
  indexOfObject: number
  lastIndexOfObject: number
  onRemoveObject: VoidFunction
  onMoveTop: () => Promise<void>
  onMoveBottom: () => Promise<void>
  leftAddons?: ReactNode
}

const StepAnotherObject: FC<StepAnotherObjectProps> = ({
  id,
  anchorId,
  indexOfObject,
  lastIndexOfObject,
  name,
  onRemoveObject,
  onMoveTop,
  onMoveBottom,
  leftAddons,
}) => {
  const formInstance =
    useFormContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>()

  const {
    state: { editMode },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const title = useWatch({
    name: `${name}.title`,
    control: formInstance.control,
  })

  const { getSubscribableControlProps, getControllerProps } = useFormComponentPresets({
    editMode,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  const { isExpanded: isShowObjectItemContent, onToggleCollapse: toggleShowObjectItemContent } =
    useFormCollapseControl({
      initialExpanded: true,
      name,
      watcher:
        AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldCollapseControlUpdateWatcher,
    })

  const handleRemoveObject = async () => {
    await onRemoveObject()
  }

  return (
    <FlippedWithCollapse flipId={id}>
      <Row id={anchorId}>
        <Col xs={12}>
          <div className={styles.objectItem}>
            <div className={styles.objectItem__header}>
              <ObjectStepWrapperHeaderNode
                leftAddons={leftAddons}
                buttonProps={{
                  onClick: toggleShowObjectItemContent,
                  trailingIcon: {
                    className: cn({
                      [styles['objectItem__header-button__icon--active']]: isShowObjectItemContent,
                    }),
                  },
                }}
              >
                {title}
              </ObjectStepWrapperHeaderNode>

              {editMode && (
                <Stack direction={'horizontal'} gap={2}>
                  <FieldArraySwapper
                    index={indexOfObject}
                    lastIndex={lastIndexOfObject}
                    onMoveTop={onMoveTop}
                    onMoveBottom={onMoveBottom}
                  />
                  <AsyncWrapper promise={handleRemoveObject}>
                    {({ isLoading, wrappedPromise }) => (
                      <Button
                        disabled={isLoading}
                        variant={'buttonSMedium'}
                        size={'2xs'}
                        color={'negative'}
                        view={'plain'}
                        loaderProps={{
                          loading: isLoading,
                          placement: 'trailing',
                          variant: 'lite',
                        }}
                        leadingIcon={{
                          noCurrentColorSvgFill: true,
                          src: DeleteIcon,
                        }}
                        onClick={wrappedPromise}
                      >
                        Удалить
                      </Button>
                    )}
                  </AsyncWrapper>
                </Stack>
              )}
            </div>
            <div className={styles.objectItem__content}>
              {!isShowObjectItemContent && (
                <Row>
                  <Col xs={12}>
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: `${name}.objectName`,
                      })}
                    >
                      <ControlledDocumentDataView
                        suptitle={'Наименование'}
                        controllerProps={getControllerProps({
                          name: `${name}.objectName`,
                        })}
                      />
                    </SubscribableControl>
                  </Col>
                </Row>
              )}
              <CollapseWrapper isExpanded={isShowObjectItemContent}>
                <StepAnotherObjectForm formName={name} />
              </CollapseWrapper>
            </div>
          </div>
        </Col>
      </Row>
    </FlippedWithCollapse>
  )
}

export default StepAnotherObject
