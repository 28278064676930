import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import { DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST } from '@components/DocumentFormComponents/const'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormListTooltipControl from '@components/DocumentFormComponents/FormControls/FormListTooltipControl'
import FormObjectTooltipControl from '@components/DocumentFormComponents/FormControls/FormObjectTooltipControl'
import FormError from '@components/DocumentFormComponents/FormError'
import Group from '@components/DocumentFormComponents/Group'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import ObjectRegionItem from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/AdditionalForms/MainTemplate/ConstructionObject/Forms/CObjectsForm/ObjectFormItem/ObjectRegionsList/ObjectRegionItem'
import {
  SeventhConstructionObjectRegionsPathName,
  SeventhConstructionObjectsArrayPathName,
  SeventhConstructionSubObjectsArrayPathName,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/types'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import Button from '@components/NewDesign/Button'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import CircleAddIcon from '@icons/CircleAddIcon.svg'

const { isFormFieldError } = DocumentFormHelpers

interface ObjectRegionsListProps {
  editMode: boolean
  blockViewIsValidating: boolean
  formInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>
  formModifierInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>
  formName: SeventhConstructionObjectsArrayPathName | SeventhConstructionSubObjectsArrayPathName
}

const ObjectRegionsList: FC<ObjectRegionsListProps> = ({
  editMode,
  blockViewIsValidating,
  formInstance,
  formModifierInstance,
  formName,
}) => {
  const {
    handlers: { handleAddItemToListWithOutValue, handleRemoveItemFromList },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const { fields: objectRegions } = useFieldArraySubscribableControl<
    AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
    SeventhConstructionObjectRegionsPathName,
    'keyNameId'
  >({
    control: formInstance.control,
    name: `${formName}.objectRegions`,
    keyName: 'keyNameId',
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  })

  const handleAddNewObjectNamePermission = async () => {
    await handleAddItemToListWithOutValue?.(`${formName}.objectRegions`)

    if (!objectRegions.length) {
      formInstance.clearErrors(`${formName}.objectRegions`)
    }
  }

  const handleRemoveObjectNamePermission = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${formName}.objectRegions.${index}`,
      `${formName}.objectRegions`,
    )
  }

  const objectRegionsError = formInstance.getFieldState(`${formName}.objectRegions`)?.error

  return (
    <FormListTooltipControl
      disablePaddingRight
      enablePaddingLeft
      name={`${formName}.objectRegions`}
      control={formModifierInstance.control}
      onDifference={handleUpdateChanges}
    >
      {({ iconWithTooltip }) => (
        <Group disableBottomBorder title="Местоположение объекта" leftAddons={iconWithTooltip}>
          <Stack direction={'vertical'} gap={!!objectRegions.length ? 3 : 0}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.objectRegions`,
              })}
            >
              <FlipperList list={objectRegions}>
                {objectRegions.map((objectRegion, index) => {
                  const objectRegionFormName = `${formName}.objectRegions.${index}` as const

                  return (
                    <FormObjectTooltipControl
                      key={objectRegion.id}
                      name={objectRegionFormName}
                      control={formModifierInstance.control}
                      onDifference={handleUpdateChanges}
                    >
                      <ObjectRegionItem
                        editMode={editMode}
                        blockViewIsValidating={blockViewIsValidating}
                        id={objectRegion.id}
                        name={objectRegionFormName}
                        index={index}
                        formInstance={formInstance}
                        formModifierInstance={formModifierInstance}
                        onRemove={editMode ? handleRemoveObjectNamePermission(index) : undefined}
                      />
                    </FormObjectTooltipControl>
                  )
                })}
              </FlipperList>
            </SubscribableControl>
            {isFormFieldError(objectRegionsError) && !objectRegions.length && (
              <FormError>{DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}</FormError>
            )}
            {editMode && (
              <Row className={'px-1'}>
                <Col xs={12}>
                  <AsyncWrapper promise={handleAddNewObjectNamePermission}>
                    {({ isLoading, wrappedPromise }) => {
                      return (
                        <Button
                          disabled={isLoading}
                          leadingIcon={{ src: CircleAddIcon }}
                          variant={'buttonSMedium'}
                          size={'2xs'}
                          view={'plain'}
                          loaderProps={{
                            loading: isLoading,
                            placement: 'trailing',
                            variant: 'lite',
                          }}
                          onClick={wrappedPromise}
                        >
                          Добавить местоположение
                        </Button>
                      )
                    }}
                  </AsyncWrapper>
                </Col>
              </Row>
            )}
          </Stack>
        </Group>
      )}
    </FormListTooltipControl>
  )
}

export default ObjectRegionsList
