import { FormModifierAdapters } from '@components/DocumentFormComponents/adapters/Modifier.adapter'
import { FormObjectStates } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnChangingCharacteristicsOfObjectsConstructionObjectModifier,
  AAgreementOnChangingCharacteristicsOfObjectsConstructionSubObjectModifier,
  AAgreementOnChangingCharacteristicsOfObjectsFormModifierValues,
  AAgreementOnChangingCharacteristicsOfObjectsPermissionModifier,
  AAgreementOnChangingCharacteristicsOfObjectsStageModifier,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/types'
import { isArray } from '@helpers/checkTypes'
import { PropertyTypeEnum } from '@services/Properties/Properties.entity'

const { getModifierStatesFormPropertyState } = FormModifierAdapters

const generateFormModifierValuesForAAgreementOnChangingCharacteristicsOfObjectsForm = (
  objectFromPropertyState: Record<string, any>,
): AAgreementOnChangingCharacteristicsOfObjectsFormModifierValues => {
  const preparedConstructionStages =
    ((): AAgreementOnChangingCharacteristicsOfObjectsStageModifier[] => {
      if (
        !isArray(objectFromPropertyState.steps?.value) ||
        !objectFromPropertyState.steps?.value?.length
      )
        return []

      return objectFromPropertyState.steps?.value.map(
        (step): AAgreementOnChangingCharacteristicsOfObjectsStageModifier => {
          const preparedStepCompensations = ((): FormObjectStates[] => {
            if (
              !isArray(step.value.stepCompensations?.value) ||
              !step.value.stepCompensations?.value?.length
            )
              return []

            return step.value.stepCompensations?.value.map(
              (stepCompensation): FormObjectStates =>
                getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(stepCompensation),
            )
          })()

          const preparedConstructionObjects =
            ((): AAgreementOnChangingCharacteristicsOfObjectsConstructionObjectModifier[] => {
              const objects = step.value.objects?.value.filter((object) => {
                return (
                  object.value.isMainObject?.value &&
                  !object.value.isSubObject?.value &&
                  !object.value.objectOtherActivityName?.value?.id
                )
              })

              const subObjects = step.value.objects?.value.filter((object) => {
                return (
                  object.value.isSubObject?.value &&
                  !object.value.objectOtherActivityName?.value?.id &&
                  object.value.parentSubObject?.value
                )
              })

              return objects.map(
                (
                  object,
                ): AAgreementOnChangingCharacteristicsOfObjectsConstructionObjectModifier => {
                  const preparedSubObjects: AAgreementOnChangingCharacteristicsOfObjectsConstructionSubObjectModifier[] =
                    subObjects
                      .filter(
                        (subObject) =>
                          object.permanentId === subObject.value.parentSubObject?.value?.id,
                      )
                      .map(
                        (
                          subObject,
                        ): AAgreementOnChangingCharacteristicsOfObjectsConstructionSubObjectModifier => {
                          const preparedObjectRegions =
                            isArray(subObject.value?.objectRegions?.value) &&
                            subObject.value?.objectRegions?.value?.length
                              ? subObject.value?.objectRegions?.value.map(
                                  (objectRegion): FormObjectStates =>
                                    getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(
                                      objectRegion,
                                    ),
                                )
                              : []

                          return {
                            ...getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(
                              subObject,
                            ),
                            objectRegions: preparedObjectRegions,
                            objectRegionsState: getModifierStatesFormPropertyState<
                              typeof PropertyTypeEnum.LIST
                            >(subObject.value?.objectRegions),
                          }
                        },
                      )

                  const preparedObjectAreas =
                    isArray(object.value?.objectAreas?.value) &&
                    object.value?.objectAreas?.value?.length
                      ? object.value?.objectAreas?.value.map(
                          (objectArea): FormObjectStates =>
                            getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(
                              objectArea,
                            ),
                        )
                      : []

                  const preparedObjectRegions =
                    isArray(object.value?.objectRegions?.value) &&
                    object.value?.objectRegions?.value?.length
                      ? object.value?.objectRegions?.value.map(
                          (objectRegion): FormObjectStates =>
                            getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(
                              objectRegion,
                            ),
                        )
                      : []

                  return {
                    ...getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(object),
                    objectAreas: preparedObjectAreas,
                    objectAreasState: getModifierStatesFormPropertyState<
                      typeof PropertyTypeEnum.LIST
                    >(object.value?.objectAreas),
                    objectRegions: preparedObjectRegions,
                    objectRegionsState: getModifierStatesFormPropertyState<
                      typeof PropertyTypeEnum.LIST
                    >(object.value?.objectRegions),
                    subObjects: preparedSubObjects,
                    subObjectsState: getModifierStatesFormPropertyState<
                      typeof PropertyTypeEnum.LIST
                    >(step.value?.objects),
                  }
                },
              )
            })()

          const preparedPermissions =
            ((): AAgreementOnChangingCharacteristicsOfObjectsPermissionModifier[] => {
              if (!isArray(step.value.permissions?.value) || !step.value.permissions?.value.length)
                return []

              return step.value.permissions?.value.map(
                (permission): AAgreementOnChangingCharacteristicsOfObjectsPermissionModifier => {
                  const preparedObjectNamePermissions = (() => {
                    if (
                      !isArray(permission.value.permissionObjects?.value) ||
                      !permission.value.permissionObjects?.value.length
                    )
                      return []

                    return permission.value.permissionObjects?.value.map(
                      (permissionObject): FormObjectStates => ({
                        id: permissionObject.propertyId,
                        isNew: !!permissionObject?.new,
                      }),
                    )
                  })()

                  return {
                    ...getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(permission),
                    objectNamePermissions: preparedObjectNamePermissions,
                    objectNamePermissionsState: getModifierStatesFormPropertyState<
                      typeof PropertyTypeEnum.LIST
                    >(permission.value.permissionObjects),
                  }
                },
              )
            })()

          const preparedExploitationObjects = (() => {
            if (!step.value.commissionObjects?.value || !step.value.commissionObjects?.value.length)
              return []

            return step.value.commissionObjects?.value.map(
              (commissionObject): FormObjectStates =>
                getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(commissionObject),
            )
          })()

          const preparedRegistrationObjects = (() => {
            if (
              !step.value.registrationObjects?.value ||
              !step.value.registrationObjects?.value.length
            )
              return []

            return step.value.registrationObjects?.value.map(
              (registrationObject): FormObjectStates =>
                getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(registrationObject),
            )
          })()

          return {
            ...getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(step),
            stepActivityNames: [],
            stepActivityNamesStates: getModifierStatesFormPropertyState<
              typeof PropertyTypeEnum.LIST
            >(step.value.stepActivityNames),
            stepCompensations: preparedStepCompensations,
            stepCompensationsState: getModifierStatesFormPropertyState<
              typeof PropertyTypeEnum.LIST
            >(step.value.stepCompensations),
            constructionObjects: preparedConstructionObjects,
            constructionObjectsState: getModifierStatesFormPropertyState<
              typeof PropertyTypeEnum.LIST
            >(step.value.objects),
            permissionsObjects: preparedPermissions,
            permissionsObjectsState: getModifierStatesFormPropertyState<
              typeof PropertyTypeEnum.LIST
            >(step.value.permissions),

            exploitation: {
              exploitationObjects: preparedExploitationObjects,
              exploitationObjectsState: getModifierStatesFormPropertyState<
                typeof PropertyTypeEnum.LIST
              >(step.value.commissionObjects),
            },

            registration: {
              registrationObjects: preparedRegistrationObjects,
              registrationObjectsState: getModifierStatesFormPropertyState<
                typeof PropertyTypeEnum.LIST
              >(step.value.registrationObjects),
            },
          }
        },
      )
    })()

  return {
    '5': {
      constructionStages: preparedConstructionStages,
    },
  }
}

export { generateFormModifierValuesForAAgreementOnChangingCharacteristicsOfObjectsForm }
