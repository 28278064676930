import React, { FC } from 'react'
import { useWatch } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import SummaryReportOfMonitoringResultsRegionReportInfoCollapse from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/3/RegionReportInfoCollapse'
import { BaseSummaryReportOfMonitoringResultsRegionReportItemProps } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/3/RegionReportInfoItem/types'
import { SummaryReportOfMonitoringResultsFieldsControlUpdateWatcher } from '@components/Forms/SummaryReportOfMonitoringResultsForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const SummaryReportOfMonitoringResultsRegionReportInfoFederalTotalCapitalItem: FC<
  BaseSummaryReportOfMonitoringResultsRegionReportItemProps
> = ({ editMode, blockViewIsValidating, formName, index, formInstance, subscribableControl }) => {
  const federalTotalCapitalFactDeviation = useWatch({
    name: `${formName}.federalTotalCapitalFactDeviation`,
    control: formInstance.control,
  })

  const federalTotalCapitalPlanDeviation = useWatch({
    name: `${formName}.federalTotalCapitalPlanDeviation`,
    control: formInstance.control,
  })

  const { getAmountInputProps, getSubscribableControlProps, getControllerProps } =
    useFormComponentPresets({
      formInstance,
      editMode,
      blockViewIsValidating,
      subscribableControl,
      watcher: SummaryReportOfMonitoringResultsFieldsControlUpdateWatcher,
    })

  return (
    <SummaryReportOfMonitoringResultsRegionReportInfoCollapse indexOfReport={index}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.regionName`,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Наименование региона'}
                controllerProps={getControllerProps({
                  name: `${formName}.regionName`,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Group title={'Объем капитальных вложений'}>
          <Stack direction={'vertical'} gap={3}>
            <RowWithBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Объем из справок проверяющей организации. Фактическое значение'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.federalTotalCapitalFactChecker`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.federalTotalCapitalFactChecker`,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Объем из регионального отчета. Фактическое значение'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.federalTotalCapitalFact`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.federalTotalCapitalFact`,
                    inputProps: {
                      disabled: true,
                      leftAddons: (
                        <FormIconWithTooltip tooltipContent={federalTotalCapitalFactDeviation} />
                      ),
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Объем из справок проверяющей организации. Плановое значение'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.federalTotalCapitalPlanChecker`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.federalTotalCapitalPlanChecker`,
                    inputProps: {
                      disabled: true,
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Объем из регионального отчета. Плановое значение'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.federalTotalCapitalPlan`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.federalTotalCapitalPlan`,
                    inputProps: {
                      disabled: true,
                      leftAddons: (
                        <FormIconWithTooltip tooltipContent={federalTotalCapitalPlanDeviation} />
                      ),
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Stack>
        </Group>
      </Stack>
    </SummaryReportOfMonitoringResultsRegionReportInfoCollapse>
  )
}

export default SummaryReportOfMonitoringResultsRegionReportInfoFederalTotalCapitalItem
