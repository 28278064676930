import React, { FC, memo, useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'

import ControlledCheckbox from '@components/Checkbox/Controlled'
import IconButton from '@components/NewDesign/IconButton'
import { useManualTooltipControl } from '@components/NewDesign/Tooltip/utils'
import Typography from '@components/NewDesign/Typography'
import FilterTooltip from '@components/PreparedTooltips/Filter'
import ControlledRadioGroup from '@components/Radio/ControlledRadioGroup'
import TagItem from '@components/TagNew/Item'
import SortSmallIcon from '@icons/SortSmallIcon.svg'
import {
  mapOfActionAllowed,
  mapOfFilterTooltipNames,
  mapOfStageStatementFilters,
  mapOfStatementLvl,
  statementFiltersDefaultValues,
} from '@routes/NewStatements/const'
import { StatementFilterFormValues } from '@routes/NewStatements/types'
import cn from 'classnames'

import styles from './FilterTooltip.module.scss'

interface StatementFiltersControlProps {
  getInitialFilters: () => StatementFilterFormValues
  countOfActiveFilters: number
  isRegisteredStatementsRender: boolean
  onApplyFilters: (filtersValues: StatementFilterFormValues) => void
  onResetFilters: VoidFunction
}

type StatementFilterControlFormValues = Omit<StatementFilterFormValues, 'dfoStageExclude'> & {
  dfoStageExclude: boolean
}

const StatementFiltersControl: FC<StatementFiltersControlProps> = ({
  getInitialFilters,
  isRegisteredStatementsRender = true,
  countOfActiveFilters,
  onApplyFilters,
  onResetFilters,
}) => {
  const tooltipControl = useManualTooltipControl()

  const { control, getValues, reset } = useForm<StatementFilterControlFormValues>({
    defaultValues: {
      ...getInitialFilters(),
      dfoStageExclude: !getInitialFilters().dfoStageExclude?.length,
    },
  })

  const handleApplyLocalFilters = useCallback(() => {
    const filtersValues = getValues()

    onApplyFilters({
      ...filtersValues,
      dfoStageExclude: !filtersValues.dfoStageExclude
        ? statementFiltersDefaultValues.dfoStageExclude
        : null,
    })
  }, [getValues, onApplyFilters])

  const handleResetLocalFilters = useCallback(() => {
    reset({
      ...statementFiltersDefaultValues,
      dfoStageExclude: false,
    })
    onResetFilters()
  }, [onResetFilters, reset])

  //Стоит delay в 50 на закрытие
  const handleManualCloseTooltip = () => {
    setTimeout(() => {
      reset({
        ...getInitialFilters,
        dfoStageExclude: !getInitialFilters().dfoStageExclude?.length,
      })
    }, 55)
  }

  const filterTooltipContent = useMemo(
    () => (
      <>
        <div className={styles.filters__row}>
          <Typography.Body
            className={styles['filters__checkboxes-container']}
            variant={'bodyMMedium'}
            color={'text-base-tertiary'}
          >
            Документы
          </Typography.Body>
          <div className={styles['filters__row-content']}>
            <ControlledRadioGroup
              name={mapOfFilterTooltipNames.actionAllowed}
              control={control}
              radioGroupProps={{ type: 'tag', direction: 'horizontal' }}
            >
              <TagItem className={styles.filters__tag} value={mapOfActionAllowed.all}>
                Все
              </TagItem>
              <TagItem className={styles.filters__tag} value={mapOfActionAllowed.needAttention}>
                Требуют моего внимания
              </TagItem>
            </ControlledRadioGroup>
          </div>
        </div>
        <div className={styles.filters__row}>
          <Typography.Body
            className={styles['filters__checkboxes-container']}
            variant={'bodyMMedium'}
            color={'text-base-tertiary'}
          >
            Уровень соглашения
          </Typography.Body>
          <div className={styles['filters__row-content']}>
            <ControlledRadioGroup
              name={mapOfFilterTooltipNames.statementLvl}
              control={control}
              radioGroupProps={{ type: 'tag', direction: 'horizontal' }}
            >
              <TagItem className={styles.filters__tag} value={mapOfStatementLvl.all}>
                Все
              </TagItem>
              <TagItem className={styles.filters__tag} value={mapOfStatementLvl.federal}>
                Федеральный
              </TagItem>
              <TagItem className={styles.filters__tag} value={mapOfStatementLvl.regional}>
                Региональный
              </TagItem>
            </ControlledRadioGroup>
          </div>
        </div>
        <div className={styles.filters__row}>
          <Typography.Body
            className={styles['filters__checkboxes-container']}
            variant={'bodyMMedium'}
            color={'text-base-tertiary'}
          >
            Статус
          </Typography.Body>
          <div className={styles['filters__row-content']}>
            <ControlledRadioGroup
              name={mapOfFilterTooltipNames.stage}
              control={control}
              radioGroupProps={{ type: 'tag', direction: 'horizontal' }}
            >
              <TagItem className={styles.filters__tag} value={mapOfStageStatementFilters.all}>
                Все
              </TagItem>
              <TagItem className={styles.filters__tag} value={mapOfStageStatementFilters.active}>
                Актуальные
              </TagItem>
              <TagItem className={styles.filters__tag} value={mapOfStageStatementFilters.finished}>
                Завершенные
              </TagItem>
            </ControlledRadioGroup>
          </div>
        </div>
        <div className={styles.filters__checkboxes}>
          {isRegisteredStatementsRender && (
            <div className={styles['filters__checkboxes-item']}>
              <ControlledCheckbox
                name={mapOfFilterTooltipNames.isRegistered}
                control={control}
                checkBoxProps={{
                  label: 'Показать только заключенные соглашения',
                  contentClassName: styles['filters__checkboxes-item__content'],
                }}
              />
            </div>
          )}
          <div
            className={cn(styles['filters__checkboxes-item'], {
              [styles['filters__checkboxes-item--only']]: !isRegisteredStatementsRender,
            })}
          >
            <ControlledCheckbox
              name={mapOfFilterTooltipNames.dfoStageExclude}
              control={control}
              checkBoxProps={{
                label: 'Показать черновики, отозванные и отказанные комплекты',
                contentClassName: styles['filters__checkboxes-item__content'],
              }}
            />
          </div>
        </div>
      </>
    ),
    [control, isRegisteredStatementsRender],
  )

  const handleOpenTooltip = () => {
    reset({
      ...getInitialFilters(),
      dfoStageExclude: !getInitialFilters().dfoStageExclude?.length,
    })
    tooltipControl.handlers.handleOpenTooltip()
  }

  return (
    <FilterTooltip
      filterContent={filterTooltipContent}
      manualControl={{
        tooltipIsOpen: tooltipControl.state.tooltipIsOpen,
        handleCloseTooltip: tooltipControl.handlers.handleCloseTooltip,
      }}
      tooltipProps={{
        popoverClassName: styles['filters__tooltip__popover'],
      }}
      tooltipContentProps={{
        containerClassName: styles['filters__tooltip-content__container'],
        headlineClassName: styles['filters__tooltip-content__headline'],
        filtersContentClassName: styles['filters__tooltip-content__content'],
        actionsClassName: cn(styles['filters__tooltip-content__actions'], {
          [styles['filters__tooltip-content__actions--only']]: !isRegisteredStatementsRender,
        }),
      }}
      onApplyFilters={handleApplyLocalFilters}
      onResetFilters={handleResetLocalFilters}
      onClose={handleManualCloseTooltip}
    >
      <div className={styles.filters__sortButton}>
        <IconButton
          geometry={'round'}
          view={'gray'}
          icon={{
            src: SortSmallIcon,
            className: styles.filters__sortIcon,
          }}
          onClick={handleOpenTooltip}
        />
        {!!countOfActiveFilters && (
          <div className={styles.filters__count}>
            <Typography.Caption
              className={styles['filters__count-text']}
              variant={'captionSRegular'}
              as={'span'}
              color={'on-accent-on-surface-primary'}
            >
              {countOfActiveFilters}
            </Typography.Caption>
          </div>
        )}
      </div>
    </FilterTooltip>
  )
}

export default memo(StatementFiltersControl)
