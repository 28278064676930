import { Path } from 'react-hook-form'

import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'

import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from './types'

const {
  getDefaultRfFieldState,
  getDefaultSubjectsFieldState,
  getDefaultMunicipalitiesFieldState,
  getDefaultInvestorFieldState,
} = FieldStateHelpers

const mapOfAAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenu = [
  { id: '1', title: 'Уполномоченный федеральный орган исполнительной власти' },
  { id: '2', title: 'Уполномоченный орган субъекта Российской Федерации' },
  { id: '3', title: 'Муниципальное образование' },
  { id: '4', title: 'Организация, реализующая проект' },
  { id: '5', title: 'Концессионное соглашение' },
  { id: '6', title: 'О проекте' },
  { id: '7', title: 'Этапы' },
  { id: '8', title: 'Иные мероприятия' },
  { id: '9', title: 'Результаты реализации проекта' },
  { id: '10', title: 'Технологические и экологические требования' },
  { id: '11', title: 'Стабилизационная оговорка' },
  { id: '12', title: 'Права и обязанности публично-правовых образований' },
]

const defaultAAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues =
  {
    '1': getDefaultRfFieldState(),
    '2': {
      subjects: getDefaultSubjectsFieldState(),
    },
    '3': {
      municipalities: getDefaultMunicipalitiesFieldState(),
    },
    '4': getDefaultInvestorFieldState(),
    '5': {
      concessionFail: null,
      concessionFailDocument: '',
    },
    '6': {
      projectPurpose: '',
      projectPurposeCommited: '',
      totalCapital: '',
      totalCapitalCommited: '',
      investorCapital: '',
      investorCapitalCommited: '',
      remainsInvestorCapital: '',
      remainsInvestorCapitalCommited: '',
      projectSupportCompensation: '',
      projectSupportCompensationCommited: '',
      projectRelatedCompensation: '',
      projectRelatedCompensationCommited: '',
    },
    '7': {
      stages: [],
    },
    '8': {
      stepActivityOthers: [],
    },
    '9': {
      projectResultEcoActivity: {
        ecoActivityName: null,
        ecoActivityNameCommited: null,
        ecoActivityProgramNameInstrumental: '',
        ecoActivityProgramNameInstrumentalCommited: '',
        ecoActivityEndDate: '',
        ecoActivityEndDateCommited: '',
      },
      otherProjectResultActivity: '',
      otherProjectResultActivityCommited: '',
      projectResultActivity: [],
      projectResults: [],
    },
    '10': {
      technologicalRequirements: [],
    },
    '11': {
      extraMunicipalAccessionLinkCompensation: '',
      extraMunicipalAccessionLinkCompensationCommited: '',
      extraMunicipalAccessionLinkStabilization: '',
      extraMunicipalAccessionLinkStabilizationCommited: '',
      stabilizationPeriod: '',
      stabilizationPeriodCommited: '',
      oldStabilizationPeriodTaxDuty: '',
      oldStabilizationPeriodTaxDutyCommited: '',
      oldStabilizationPeriodFederalStateSupport: '',
      oldStabilizationPeriodFederalStateSupportCommited: '',
      oldStabilizationPeriodRegionalStateSupport: '',
      oldStabilizationPeriodRegionalStateSupportCommited: '',
    },
    '12': {
      rightDutySubjectForestResource: false,
      rightDutySubjectForestResourceCommited: false,
      rightDutySubjectWaterResource: false,
      rightDutySubjectWaterResourceCommited: false,
      rightDutyRfForestResource: false,
      rightDutyRfForestResourceCommited: false,
      rightDutyRfWaterResource: false,
      rightDutyRfWaterResourceCommited: false,
    },
    additionalFields: {
      isFederal: true,
      isPaper: false,
      isMunicipalityParticipant: true,
      projectStartDate: '',
      projectEndDate: '',
      anotherStageOptions: [],
      allProjectObjectsLength: 0,
    },
  }

const defaultAAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues =
  {
    '7': {
      stages: [],
    },
    '8': {
      stepActivityOthers: [],
    },
    '9': {
      projectResults: [],
      projectResultsState: {
        id: '1',
        addedElementInList: false,
        deletedElementFromList: false,
      },
    },
    '10': {
      technologicalRequirements: [],
    },
  }

const aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues = {
  '1': {
    rfFullName: '1.rfFullName',
    rfHeaderName: '1.rfHeaderName',
    rfHeaderPosition: '1.rfHeaderPosition',
    rfHeaderNameGenitive: '1.rfHeaderNameGenitive',
    rfHeaderPositionGenitive: '1.rfHeaderPositionGenitive',
    rfHeaderReasonGenitive: '1.rfHeaderReasonGenitive',
  },
  '2': {
    subjects: '2.subjects',
  },
  '3': {
    municipalities: '3.municipalities',
  },
  '4': {
    investorFullName: '4.investorFullName',
    investorHeaderName: '4.investorHeaderName',
    investorHeaderPosition: '4.investorHeaderPosition',
    investorHeaderNameGenitive: '4.investorHeaderNameGenitive',
    investorHeaderPositionGenitive: '4.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '4.investorHeaderReasonGenitive',
  },
  '5': {
    concessionFail: '5.concessionFail',
    concessionFailDocument: '5.concessionFailDocument',
  },
  '6': {
    projectPurpose: '6.projectPurpose',
    totalCapital: '6.totalCapital',
    investorCapital: '6.investorCapital',
    remainsInvestorCapital: '6.remainsInvestorCapital',
    projectSupportCompensation: '6.projectSupportCompensation',
    projectRelatedCompensation: '6.projectRelatedCompensation',
  },
  '7': {
    stages: '7.stages',
  },
  '8': {
    stepActivityOthers: '8.stepActivityOthers',
  },
  '9': {
    projectResultActivity: '9.projectResultActivity',
    otherProjectResultActivity: '9.otherProjectResultActivity',
    projectResults: '9.projectResults',
    projectResultEcoActivity: '9.projectResultEcoActivity',
    ecoActivityName: '9.projectResultEcoActivity.ecoActivityName',
    ecoActivityEndDate: '9.projectResultEcoActivity.ecoActivityEndDate',
    ecoActivityProgramNameInstrumental:
      '9.projectResultEcoActivity.ecoActivityProgramNameInstrumental',
  },
  '10': {
    technologicalRequirements: '10.technologicalRequirements',
  },
  '11': {
    extraMunicipalAccessionLinkStabilization: '11.extraMunicipalAccessionLinkStabilization',
    extraMunicipalAccessionLinkCompensation: '11.extraMunicipalAccessionLinkCompensation',
    stabilizationPeriod: '11.stabilizationPeriod',
    oldStabilizationPeriodTaxDuty: '11.oldStabilizationPeriodTaxDuty',
    oldStabilizationPeriodFederalStateSupport: '11.oldStabilizationPeriodFederalStateSupport',
    oldStabilizationPeriodRegionalStateSupport: '11.oldStabilizationPeriodRegionalStateSupport',
  },
  '12': {
    rightDutySubjectWaterResource: '12.rightDutySubjectWaterResource',
    rightDutySubjectForestResource: '12.rightDutySubjectForestResource',
    rightDutyRfWaterResource: '12.rightDutyRfWaterResource',
    rightDutyRfForestResource: '12.rightDutyRfForestResource',
  },
  additionalFields: {
    isFederal: 'additionalFields.isFederal',
    isPaper: 'additionalFields.isPaper',
    isMunicipalityParticipant: 'additionalFields.isMunicipalityParticipant',
    projectStartDate: 'additionalFields.projectStartDate',
    projectEndDate: 'additionalFields.projectEndDate',
    anotherStageOptions: 'additionalFields.anotherStageOptions',
  },
} as const

const rewriteAAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsAfterApplyDiffs: Path<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>[] =
  [aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9'].projectResultActivity]

export {
  aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues,
  defaultAAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  defaultAAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
  mapOfAAgreementOnNonConclusionOrNonPerformanceOfConcessionAMenu,
  rewriteAAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsAfterApplyDiffs,
}
