import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'
import { isArray } from '@helpers/checkTypes'

const generatePropertiesObjectForRegistrationCertificateForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  const preparedExtraRegisteredDocumentValues = (() => {
    if (
      !isArray(objectFromPropertyState.extraRegisteredDocumentValues?.value) ||
      !objectFromPropertyState.extraRegisteredDocumentValues?.value?.length
    )
      return []

    return objectFromPropertyState.extraRegisteredDocumentValues?.value.map(
      (extraRegisteredDocument) => ({
        propertyId: extraRegisteredDocument.propertyId,
        lastUpdateDt: extraRegisteredDocument.lastUpdateDt,
        type: extraRegisteredDocument.type,
        value: {
          extraTypeName: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
            extraRegisteredDocument?.value?.extraTypeName,
          ),
          extraRegistrationDate: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
            extraRegisteredDocument?.value?.extraRegistrationDate,
          ),
          extraLastSignDate: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
            extraRegisteredDocument?.value?.extraLastSignDate,
          ),
          extraDescription: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
            extraRegisteredDocument?.value?.extraDescription,
          ),
        },
      }),
    )
  })()

  const preparedRelatedContracts = (() => {
    if (
      !isArray(objectFromPropertyState.relatedContracts?.value) ||
      !objectFromPropertyState.relatedContracts?.value?.length
    )
      return []

    return objectFromPropertyState.relatedContracts?.value.map((relatedContract) => ({
      propertyId: relatedContract.propertyId,
      lastUpdateDt: relatedContract.lastUpdateDt,
      type: relatedContract.type,
      value: {
        relatedContractNumber: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
          relatedContract?.value?.relatedContractNumber,
        ),
        relatedContractDate: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
          relatedContract?.value?.relatedContractDate,
        ),
        relatedContractPublicLawMember:
          PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
            relatedContract?.value?.relatedContractPublicLawMember,
          ),
        relatedContractKind: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
          relatedContract?.value?.relatedContractKind,
        ),
        relatedContractTerms: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
          relatedContract?.value?.relatedContractTerms,
        ),
      },
    }))
  })()

  const preparedGeneralStepRegisteredDateValues = (() => {
    if (
      !isArray(objectFromPropertyState.stepRegisteredDateValues?.value) ||
      !objectFromPropertyState.stepRegisteredDateValues?.value?.length
    )
      return []

    return objectFromPropertyState.stepRegisteredDateValues?.value.map((step) => {
      const currentStep = objectFromPropertyState.steps?.value?.find(
        (item) => item.permanentId === step?.value?.stepId?.value?.id,
      )

      return {
        propertyId: step.propertyId,
        lastUpdateDt: step.lastUpdateDt,
        type: step.type,
        value: {
          id: step.propertyId,
          stepStartDate: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
            currentStep?.value?.stepStartDate,
          ),
          stepEndDate: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
            currentStep?.value?.stepEndDate,
          ),
          stepInvestorCapitalValue: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
            currentStep?.value?.stepInvestorCapitalValue,
          ),
          factStepInvestorCapital: {
            propertyId: step.value?.factStepInvestorCapital?.propertyId,
            type: step.value?.factStepInvestorCapital?.type,
            lastUpdateDt: step.value?.factStepInvestorCapital?.lastUpdateDt,
          },
          stepRemainsCapital: {
            propertyId: step.value?.stepRemainsCapital?.propertyId,
            type: step.value?.stepRemainsCapital?.type,
            lastUpdateDt: step.value?.stepRemainsCapital?.lastUpdateDt,
          },
        },
      }
    })
  })()

  const preparedRelatedContractListRegisteredDocumentValues = (() => {
    if (
      !isArray(objectFromPropertyState?.relatedContractListRegisteredDocumentValues?.value) ||
      !objectFromPropertyState?.relatedContractListRegisteredDocumentValues?.value?.length
    )
      return []

    return objectFromPropertyState?.relatedContractListRegisteredDocumentValues?.value.map(
      (documentValue) => ({
        propertyId: documentValue.propertyId,
        lastUpdateDt: documentValue.lastUpdateDt,
        type: documentValue.type,
        value: {
          relatedContractNumberRegisteredDocument: {
            propertyId: documentValue?.value?.relatedContractNumberRegisteredDocument?.propertyId,
            type: documentValue?.value?.relatedContractNumberRegisteredDocument?.type,
            lastUpdateDt:
              documentValue?.value?.relatedContractNumberRegisteredDocument?.lastUpdateDt,
          },
          relatedContractDateRegisteredDocument: {
            propertyId: documentValue?.value?.relatedContractDateRegisteredDocument?.propertyId,
            type: documentValue?.value?.relatedContractDateRegisteredDocument?.type,
            lastUpdateDt: documentValue?.value?.relatedContractDateRegisteredDocument?.lastUpdateDt,
          },
          relatedContractDescriptionRegisteredDocument: {
            propertyId:
              documentValue?.value?.relatedContractDescriptionRegisteredDocument?.propertyId,
            type: documentValue?.value?.relatedContractDescriptionRegisteredDocument?.type,
            lastUpdateDt:
              documentValue?.value?.relatedContractDescriptionRegisteredDocument?.lastUpdateDt,
          },
          relatedContractOrganizationRegisteredDocument: {
            propertyId:
              documentValue?.value?.relatedContractOrganizationRegisteredDocument?.propertyId,
            type: documentValue?.value?.relatedContractOrganizationRegisteredDocument?.type,
            lastUpdateDt:
              documentValue?.value?.relatedContractOrganizationRegisteredDocument?.lastUpdateDt,
          },
        },
      }),
    )
  })()

  return {
    '1': {
      ...PropertiesPropsBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
    },
    '2': {
      ...PropertiesPropsBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
    },
    '3': {
      investorName: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.investor?.value?.memberOrganizationId,
      ),
      investorInn: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.investor?.value?.memberOrganizationId,
      ),
      investorOgrn: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.investor?.value?.memberOrganizationId,
      ),
      investorKpp: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.investor?.value?.memberOrganizationId,
      ),
      investorOktmo: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.investor?.value?.memberOrganizationId,
      ),
      investorOkpo: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.investor?.value?.memberOrganizationId,
      ),
      investorCapital: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.investor?.value?.memberOrganizationId,
      ),
      investorOkved: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.investor?.value?.memberOrganizationId,
      ),
      investorPaymentDetails: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.investor?.value?.memberOrganizationId,
      ),
      investorDetails: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.investor?.value?.memberOrganizationId,
      ),
      investorLocation: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.investor?.value?.memberOrganizationId,
      ),
      investorAddress: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.investor?.value?.memberOrganizationId,
      ),
      investorAddressFullEgrulFormat:
        PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
          objectFromPropertyState.investor?.value?.memberOrganizationId,
        ),
      investorPhone: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.investor?.value?.memberOrganizationId,
      ),
      investorEmail: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.investor?.value?.memberOrganizationId,
      ),
      isProjectCompany: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.investor?.value.isProjectCompany,
      ),
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    '4': {
      rfFullName: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.rf?.value?.memberOrganizationId,
      ),
      subjects: PropertiesPropsBasicAdapters.getSubjectsAdapter(objectFromPropertyState),
      municipalities:
        PropertiesPropsBasicAdapters.getMunicipalitiesAdapter(objectFromPropertyState),
    },
    '5': {
      projectName: {
        propertyId: objectFromPropertyState.projectName?.propertyId,
        type: objectFromPropertyState.projectName?.type,
        lastUpdateDt: objectFromPropertyState.projectName?.lastUpdateDt,
      },
      szpkSignDate: {
        propertyId: objectFromPropertyState.szpkSignDate?.propertyId,
        type: objectFromPropertyState.szpkSignDate?.type,
        lastUpdateDt: objectFromPropertyState.szpkSignDate?.lastUpdateDt,
      },
      investorCapital: {
        propertyId: objectFromPropertyState.investorCapital?.propertyId,
        type: objectFromPropertyState.investorCapital?.type,
        lastUpdateDt: objectFromPropertyState.investorCapital?.lastUpdateDt,
      },
      factMadeInvestorCapital: !!objectFromPropertyState.factMadeInvestorCapital?.value
        ? {
            propertyId: objectFromPropertyState.factMadeInvestorCapital?.propertyId,
            type: objectFromPropertyState.factMadeInvestorCapital?.type,
            lastUpdateDt: objectFromPropertyState.factMadeInvestorCapital?.lastUpdateDt,
          }
        : {
            propertyId: objectFromPropertyState.madeInvestorCapital?.propertyId,
            type: objectFromPropertyState.madeInvestorCapital?.type,
            lastUpdateDt: objectFromPropertyState.madeInvestorCapital?.lastUpdateDt,
          },
      factRemainsInvestorCapital: !!objectFromPropertyState.factMadeInvestorCapital?.value
        ? {
            propertyId: objectFromPropertyState.factRemainsInvestorCapital?.propertyId,
            type: objectFromPropertyState.factRemainsInvestorCapital?.type,
            lastUpdateDt: objectFromPropertyState.factRemainsInvestorCapital?.lastUpdateDt,
          }
        : {
            propertyId: objectFromPropertyState.remainsInvestorCapital?.propertyId,
            type: objectFromPropertyState.remainsInvestorCapital?.type,
            lastUpdateDt: objectFromPropertyState.remainsInvestorCapital?.lastUpdateDt,
          },
      plannedTax: PropertiesPropsBasicAdapters.getPropertyPropsFromPropertyState(
        objectFromPropertyState.plannedTax,
      ),
      stabilizationPeriod: {
        propertyId: objectFromPropertyState.stabilizationPeriod?.propertyId,
        type: objectFromPropertyState.stabilizationPeriod?.type,
        lastUpdateDt: objectFromPropertyState.stabilizationPeriod?.lastUpdateDt,
      },
      stepRegisteredDateValues: {
        propertyId: objectFromPropertyState.stepRegisteredDateValues?.propertyId,
        type: objectFromPropertyState.stepRegisteredDateValues?.type,
        lastUpdateDt: objectFromPropertyState.stepRegisteredDateValues?.lastUpdateDt,
        value: preparedGeneralStepRegisteredDateValues,
      },
    },
    '6': {
      extraRegisteredDocuments: {
        propertyId: objectFromPropertyState.extraRegisteredDocumentValues?.propertyId,
        type: objectFromPropertyState.extraRegisteredDocumentValues?.type,
        lastUpdateDt: objectFromPropertyState.extraRegisteredDocumentValues?.lastUpdateDt,
        value: preparedExtraRegisteredDocumentValues,
      },
    },
    '7': {
      relatedContractListRegisteredDocumentValues: {
        propertyId: objectFromPropertyState.relatedContractListRegisteredDocumentValues?.propertyId,
        type: objectFromPropertyState.relatedContractListRegisteredDocumentValues?.type,
        lastUpdateDt:
          objectFromPropertyState.relatedContractListRegisteredDocumentValues?.lastUpdateDt,
        value: preparedRelatedContractListRegisteredDocumentValues,
      },
    },
    '8': {
      relatedContracts: {
        propertyId: objectFromPropertyState.relatedContracts?.propertyId,
        type: objectFromPropertyState.relatedContracts?.type,
        lastUpdateDt: objectFromPropertyState.relatedContracts?.lastUpdateDt,
        value: preparedRelatedContracts,
      },
    },
    '9': {
      infoMonitoringReport: {
        propertyId: objectFromPropertyState.infoMonitoringReport?.propertyId,
        type: objectFromPropertyState.infoMonitoringReport?.type,
        lastUpdateDt: objectFromPropertyState.infoMonitoringReport?.lastUpdateDt,
      },
      infoMonitoringResult: {
        propertyId: objectFromPropertyState.infoMonitoringResult?.propertyId,
        type: objectFromPropertyState.infoMonitoringResult?.type,
        lastUpdateDt: objectFromPropertyState.infoMonitoringResult?.lastUpdateDt,
      },
    },
    '10': {
      infoBudgetRF: {
        propertyId: objectFromPropertyState.infoBudgetRF?.propertyId,
        type: objectFromPropertyState.infoBudgetRF?.type,
        lastUpdateDt: objectFromPropertyState.infoBudgetRF?.lastUpdateDt,
      },
      infoDamageFormatCompensation: {
        propertyId: objectFromPropertyState.infoDamageFormatCompensation?.propertyId,
        type: objectFromPropertyState.infoDamageFormatCompensation?.type,
        lastUpdateDt: objectFromPropertyState.infoDamageFormatCompensation?.lastUpdateDt,
      },
      infoViolationStabilizationPeriod: {
        propertyId: objectFromPropertyState.infoViolationStabilizationPeriod?.propertyId,
        type: objectFromPropertyState.infoViolationStabilizationPeriod?.type,
        lastUpdateDt: objectFromPropertyState.infoViolationStabilizationPeriod?.lastUpdateDt,
      },
      infoPartner: {
        propertyId: objectFromPropertyState.infoPartner?.propertyId,
        type: objectFromPropertyState.infoPartner?.type,
        lastUpdateDt: objectFromPropertyState.infoPartner?.lastUpdateDt,
      },
      infoDeposit: {
        propertyId: objectFromPropertyState.infoDeposit?.propertyId,
        type: objectFromPropertyState.infoDeposit?.type,
        lastUpdateDt: objectFromPropertyState.infoDeposit?.lastUpdateDt,
      },
      infoCession: {
        propertyId: objectFromPropertyState.infoCession?.propertyId,
        type: objectFromPropertyState.infoCession?.type,
        lastUpdateDt: objectFromPropertyState.infoCession?.lastUpdateDt,
      },
    },
    '11': {
      agreementTerminationReason: {
        propertyId: objectFromPropertyState.agreementTerminationReason?.propertyId,
        type: objectFromPropertyState.agreementTerminationReason?.type,
        lastUpdateDt: objectFromPropertyState.agreementTerminationReason?.lastUpdateDt,
      },
      agreementTerminationReasonDescription: {
        propertyId: objectFromPropertyState.agreementTerminationReasonDescription?.propertyId,
        type: objectFromPropertyState.agreementTerminationReasonDescription?.type,
        lastUpdateDt: objectFromPropertyState.agreementTerminationReasonDescription?.lastUpdateDt,
      },
      agreementTerminationReasonInvalidPart: {
        propertyId: objectFromPropertyState.agreementTerminationReasonInvalidPart?.propertyId,
        type: objectFromPropertyState.agreementTerminationReasonInvalidPart?.type,
        lastUpdateDt: objectFromPropertyState.agreementTerminationReasonInvalidPart?.lastUpdateDt,
      },
    },
  }
}

export { generatePropertiesObjectForRegistrationCertificateForm }
