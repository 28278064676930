import { mapOfStageStatementFilters } from '@routes/NewStatements/const'
import {
  BasketFilterValue,
  FilterFactory,
  StatementFilterFormValues,
} from '@routes/NewStatements/types'

class UOFilterController implements FilterFactory {
  readonly mapOfFiltersByType: Partial<
    Record<BasketFilterValue, Partial<StatementFilterFormValues>>
  > = {
    all: {
      stage: 'all',
      statementLvl: 'all',
      actionAllowed: 'all',
    },
    concluded: {
      stage: mapOfStageStatementFilters.active,
      isRegistered: true,
    },
    consideration: {
      stage: mapOfStageStatementFilters.checking,
    },
    archive: {
      stage: mapOfStageStatementFilters.archive,
    },
  }

  getAllMapOfFilters() {
    return this.mapOfFiltersByType
  }

  getFilters(tabValue: BasketFilterValue): StatementFilterFormValues | Record<string, unknown> {
    if (!this.mapOfFiltersByType[tabValue]) return {}
    return this.mapOfFiltersByType[tabValue] as StatementFilterFormValues
  }
}

export { UOFilterController }
