import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import CapitalValueGroup from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Forms/3/AdditionalForms/CapitalValueGroup'
import NewWorkPlacesGroup from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Forms/3/AdditionalForms/NewWorkPlacesGroup'
import SzpkReportInfoCardItemHeader from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Forms/3/AdditionalForms/SzpkReportInfo/SzpkReportInfoCardItemHeader'
import {
  GroupWithPathNameProps,
  InnerGroupCardProps,
} from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Forms/3/types'
import CollapsedCard from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Forms/AdditionalForms/CollapsedCard/index'
import { DataOnFulfillmentOfTermsSZPKFormValues } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/types'
import Stack from '@components/ReactBootstrap/Stack'

export type CapitalValueGroupCardProps = InnerGroupCardProps

export type NewWorkplacesGroupCardProps = InnerGroupCardProps

interface SzpkReportInfoCardItemProps extends GroupWithPathNameProps {
  cardIndex: number
  capitalValueGroupCardProps?: CapitalValueGroupCardProps
  newWorkPlacesGroupCardProps?: NewWorkplacesGroupCardProps
}

const SzpkReportInfoCardItem: FC<SzpkReportInfoCardItemProps> = ({
  formName,
  cardIndex,
  capitalValueGroupCardProps,
  newWorkPlacesGroupCardProps,
}) => {
  const formInstance = useFormContext<DataOnFulfillmentOfTermsSZPKFormValues>()

  if (!formInstance) return null

  return (
    <CollapsedCard cardNumber={cardIndex + 1}>
      <Stack gap={3}>
        <SzpkReportInfoCardItemHeader formName={formName} />

        {capitalValueGroupCardProps && (
          <CapitalValueGroup
            groupTitle={capitalValueGroupCardProps.groupTitle}
            planFormName={`${formName}.${capitalValueGroupCardProps.planAppendix}`}
            planCheckerFormName={`${formName}.${capitalValueGroupCardProps.planCheckerAppendix}`}
            factFormName={`${formName}.${capitalValueGroupCardProps.factAppendix}`}
            factCheckerFormName={`${formName}.${capitalValueGroupCardProps.factCheckerAppendix}`}
          />
        )}

        {newWorkPlacesGroupCardProps && (
          <NewWorkPlacesGroup
            groupTitle={newWorkPlacesGroupCardProps.groupTitle}
            planFormName={`${formName}.${newWorkPlacesGroupCardProps.planAppendix}`}
            planCheckerFormName={`${formName}.${newWorkPlacesGroupCardProps.planCheckerAppendix}`}
            factFormName={`${formName}.${newWorkPlacesGroupCardProps.factAppendix}`}
            factCheckerFormName={`${formName}.${newWorkPlacesGroupCardProps.factCheckerAppendix}`}
          />
        )}
      </Stack>
    </CollapsedCard>
  )
}

export default SzpkReportInfoCardItem
