import { FormModifierAdapters } from '@components/DocumentFormComponents/adapters/Modifier.adapter'
import {
  AAgreementOnCreationOtherEstateObjectsFormModifierValues,
  AAgreementOnCreationOtherEstateObjectsStageModifier,
} from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'
import { isArray } from '@helpers/checkTypes'
import { PropertyTypeEnum } from '@services/Properties/Properties.entity'

const { getModifierStatesFormPropertyState } = FormModifierAdapters

const generateFormModifierValuesForAAgreementOnCreationOtherEstateObjectsForm = (
  objectFromPropertyState: Record<string, any>,
): AAgreementOnCreationOtherEstateObjectsFormModifierValues => {
  const preparedConstructionStages = ((): AAgreementOnCreationOtherEstateObjectsStageModifier[] => {
    if (
      !isArray(objectFromPropertyState.steps?.value) ||
      !objectFromPropertyState.steps?.value?.length
    )
      return []

    return objectFromPropertyState.steps?.value.map(
      (step): AAgreementOnCreationOtherEstateObjectsStageModifier => {
        return {
          ...getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(step),
          constructionObjects: [],
          constructionObjectsState: getModifierStatesFormPropertyState<
            typeof PropertyTypeEnum.LIST
          >(step.value.objects),
          permissionsObjects: [],
          permissionsObjectsState: getModifierStatesFormPropertyState<typeof PropertyTypeEnum.LIST>(
            step.value.permissions,
          ),

          exploitation: {
            exploitationObjects: [],
            exploitationObjectsState: getModifierStatesFormPropertyState<
              typeof PropertyTypeEnum.LIST
            >(step.value.commissionObjects),
          },

          registration: {
            registrationObjects: [],
            registrationObjectsState: getModifierStatesFormPropertyState<
              typeof PropertyTypeEnum.LIST
            >(step.value.registrationObjects),
          },
        }
      },
    )
  })()

  return {
    '5': {
      constructionStages: preparedConstructionStages,
    },
  }
}

export { generateFormModifierValuesForAAgreementOnCreationOtherEstateObjectsForm }
