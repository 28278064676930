import React, { useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import { DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST } from '@components/DocumentFormComponents/const'
import FieldView from '@components/DocumentFormComponents/FieldView'
import Border from '@components/DocumentFormComponents/FieldView/Border'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import FormObjectTooltipControl from '@components/DocumentFormComponents/FormControls/FormObjectTooltipControl'
import FormError from '@components/DocumentFormComponents/FormError'
import { useFormModifierContext } from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import ControlledFormMultipleSelect from '@components/DocumentFormComponents/FormSelect/Multi/Controlled'
import Group from '@components/DocumentFormComponents/Group'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/const'
import ManufactoringOfGoods from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/9/Forms/ManufactoringOfGoods'
import MeasuresNegativeImpactForm from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/9/Forms/MeasuresNegativeImpact'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import { OptionProps } from '@components/NewDesign/Select/model'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { getObjectValue } from '@helpers/object/getObjectValue'

import { NinthManufactoringOfGoodsPathName } from './types'
import { ninthSectionValidationMap } from './validation'

const { transformRHFPathInProperties } = DocumentFormHelpers

const OTHER_PROJECT_RESULT_ACTIVITY_OPTION_NAME = 'Иное'

const MEASURES_NEGATIVE_IMPACT_OPTION_NAME =
  'Выполнение мероприятий по снижению негативного воздействия на окружающую среду'

const MANUFACTORING_OF_GOODS_OPTION_NAMES = [
  'Производство товаров',
  'Выполнение работ',
  'Создание РИД',
  'Оказание услуг',
]

const { isFormFieldError } = DocumentFormHelpers

const Ninth = () => {
  const formInstance =
    useFormContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>()

  const formModifierInstance =
    useFormModifierContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: {
      getPropertiesProps,
      handleRemoveCustomValue,
      handleAddItemToListWithValue,
      handleAddItemToListWithOutValue,
      handleRemoveItemFromList,
      handleChangeValue,
      debouncedHandleChangeValue,
      handleUpElementInList,
      handleDownElementInList,
    },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const {
    getSubscribableControlProps,
    getFormFieldControlProps,
    getTextareaProps,
    getMultipleSelectProps,
  } = useFormComponentPresets({
    isNew: false,
    editMode,
    blockViewIsValidating,
    formInstance,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
    subscribableControl,
  })

  const { fields: projectResults } = useFieldArraySubscribableControl<
    AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
    NinthManufactoringOfGoodsPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9'].projectResults,
    keyName: 'keyNameId',
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  })

  const projectResultActivities = useWatch({
    name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9']
      .projectResultActivity,
    control: formInstance?.control,
  })

  const projectResultError = formInstance.getFieldState(
    aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9'].projectResults,
  )?.error

  const manufactofingOfGoodsRenderCondition = useMemo(() => {
    if (!projectResultActivities || !projectResultActivities.length) return false

    return projectResultActivities.some(
      ({ displayValue }) =>
        MANUFACTORING_OF_GOODS_OPTION_NAMES.includes(displayValue) ||
        displayValue === OTHER_PROJECT_RESULT_ACTIVITY_OPTION_NAME,
    )
  }, [projectResultActivities])

  const measuresNegativeImpactRenderCondition = useMemo(() => {
    if (!projectResultActivities || !projectResultActivities.length) return false

    return projectResultActivities.some(
      ({ displayValue }) => displayValue === MEASURES_NEGATIVE_IMPACT_OPTION_NAME,
    )
  }, [projectResultActivities])

  const otherProjectActivityRenderCondition = useMemo(() => {
    if (!projectResultActivities || !projectResultActivities.length) return false

    return projectResultActivities.some(
      ({ displayValue }) => displayValue === OTHER_PROJECT_RESULT_ACTIVITY_OPTION_NAME,
    )
  }, [projectResultActivities])

  const currentGapValue =
    manufactofingOfGoodsRenderCondition && measuresNegativeImpactRenderCondition
      ? 3
      : manufactofingOfGoodsRenderCondition || measuresNegativeImpactRenderCondition
      ? 2
      : 0

  const clearManufactoringGoodErrors = () => {
    formInstance.clearErrors(
      aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9'].projectResults,
    )
  }

  const handleAddManufactoringGood = async () => {
    await handleAddItemToListWithOutValue?.(
      aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9'].projectResults,
    )

    if (!projectResults.length) {
      clearManufactoringGoodErrors()
    }
  }

  const handleRemoveRequirementsForm = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9'].projectResults}.${index}`,
      aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9'].projectResults,
    )

    clearManufactoringGoodErrors()
  }

  const onChangeProjectResultActivityValue = async (
    _currentList: OptionProps[],
    currentValue: OptionProps,
    prevList: OptionProps[],
  ) => {
    const foundCurrentValueIndex = prevList?.findIndex((val) => val.value === currentValue.value)

    if (foundCurrentValueIndex === -1) {
      await handleAddItemToListWithValue?.(
        aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9']
          .projectResultActivity,
        {
          id: currentValue.value,
        },
      )

      return
    }

    const propertyProjectResultActivities = getObjectValue(
      getPropertiesProps?.(),
      transformRHFPathInProperties(
        aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9']
          .projectResultActivity,
      ),
    )

    if (!propertyProjectResultActivities || !propertyProjectResultActivities?.value) return

    const propertyProjectResultActivity =
      propertyProjectResultActivities.value?.[foundCurrentValueIndex]

    if (!propertyProjectResultActivity) return

    const propertyParentToRemove = {
      id: propertyProjectResultActivities.propertyId,
      lastUpdateDt: propertyProjectResultActivities.lastUpdateDt,
    }

    handleRemoveCustomValue?.(propertyParentToRemove, propertyProjectResultActivity.propertyId)

    if (currentValue.displayValue === OTHER_PROJECT_RESULT_ACTIVITY_OPTION_NAME) {
      formInstance.clearErrors(
        aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9']
          .otherProjectResultActivity,
      )
    }
  }

  const handleChangeElementOrderToUp = (index: number) => async () => {
    await handleUpElementInList?.(
      `${aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9'].projectResults}.${index}`,
    )
  }

  const handleChangeElementOrderToDown = (index: number) => async () => {
    await handleDownElementInList?.(
      `${aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9'].projectResults}.${index}`,
    )
  }

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={currentGapValue}>
        <Row>
          <Stack direction={'vertical'} gap={3}>
            <Row>
              <Col xs={12}>
                <SubscribableControl
                  {...getSubscribableControlProps({
                    path: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9']
                      .projectResultActivity,
                  })}
                >
                  {({ overrideProps }) => {
                    const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                      ? overrideProps.fetcherOptions
                      : undefined

                    return (
                      <ControlledFormMultipleSelect
                        {...getMultipleSelectProps({
                          fetcherProps,
                          optionsAdapter: (item) => ({
                            displayValue: item.name,
                            value: item.id,
                          }),
                          selectProps: {
                            withContextSearch: true,
                            chipNameLengthToShowTooltip: 75,
                            popoverProps: {
                              zIndex: 51,
                            },
                            inputProps: {
                              view: 'secondary',
                              label: 'Планируемый вид деятельности',
                              dataTestId: 'projectResultActivity',
                            },
                            onChangeForm: onChangeProjectResultActivityValue,
                          },
                          controllerProps: {
                            name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues[
                              '9'
                            ].projectResultActivity,
                            rules: ninthSectionValidationMap.projectResultActivity,
                          },
                        })}
                      />
                    )
                  }}
                </SubscribableControl>
              </Col>
            </Row>
            <CollapseWrapper
              defaultExpanded={otherProjectActivityRenderCondition}
              isExpanded={otherProjectActivityRenderCondition}
            >
              <Row>
                <Col xs={12}>
                  <FormFieldControl
                    {...getFormFieldControlProps({
                      path: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9']
                        .otherProjectResultActivity,
                      formFieldTooltipProps: {
                        onChange: () =>
                          setTimeout(() => {
                            handleChangeValue?.(
                              aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9']
                                .otherProjectResultActivity,
                            )
                          }, 0),
                        onDifference: handleUpdateChanges,
                      },
                    })}
                  >
                    <ControlledTextarea
                      {...getTextareaProps({
                        name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9']
                          .otherProjectResultActivity,
                        rules: ninthSectionValidationMap.otherProjectResultActivity,

                        textareaProps: {
                          label: 'Описание иного результата',
                        },
                        onBlur: () =>
                          setTimeout(() => {
                            handleChangeValue?.(
                              aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9']
                                .otherProjectResultActivity,
                            )
                          }, 0),
                        onChange: () =>
                          setTimeout(() => {
                            debouncedHandleChangeValue?.(
                              aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9']
                                .otherProjectResultActivity,
                            )
                          }, 0),
                      })}
                    />
                  </FormFieldControl>
                </Col>
              </Row>
            </CollapseWrapper>
          </Stack>
        </Row>
        <CollapseWrapper
          defaultExpanded={manufactofingOfGoodsRenderCondition}
          isExpanded={manufactofingOfGoodsRenderCondition}
        >
          <Border />
          <Group disableBottomBorder title={' '}>
            <Stack direction={'vertical'} gap={3}>
              <Row>
                <Col xs={12}>
                  <Stack direction={'vertical'} gap={3}>
                    <SubscribableControl
                      {...getSubscribableControlProps({
                        path: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9']
                          .projectResults,
                      })}
                    >
                      <FlipperList list={projectResults}>
                        {projectResults.map((field, index) => {
                          const formName =
                            `${aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['9'].projectResults}.${index}` as const

                          return (
                            <FormObjectTooltipControl
                              key={field.id}
                              name={formName}
                              control={formModifierInstance.control}
                              onDifference={handleUpdateChanges}
                            >
                              <ManufactoringOfGoods
                                id={field.id}
                                title={`Результат №${index + 1}`}
                                formInstance={formInstance}
                                formModifierInstance={formModifierInstance}
                                indexOfGood={index}
                                lastIndexOfGood={projectResults.length - 1}
                                name={formName}
                                onRemoveStep={handleRemoveRequirementsForm(index)}
                                onMoveTop={handleChangeElementOrderToUp(index)}
                                onMoveBottom={handleChangeElementOrderToDown(index)}
                              />
                            </FormObjectTooltipControl>
                          )
                        })}
                      </FlipperList>
                    </SubscribableControl>
                  </Stack>
                </Col>
              </Row>
              {isFormFieldError(projectResultError) && !projectResults.length && (
                <Row>
                  <FormError>{DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}</FormError>
                </Row>
              )}
              {editMode && (
                <Row className={'px-0.5'}>
                  <AsyncWrapper promise={handleAddManufactoringGood}>
                    {({ isLoading, wrappedPromise }) => {
                      return (
                        <FieldView.ActionButton
                          disableWrapperPaddingLeft
                          disabled={isLoading}
                          loaderProps={{
                            loading: isLoading,
                            placement: 'trailing',
                            variant: 'lite',
                          }}
                          onClick={wrappedPromise}
                        >
                          Указать товар/работу/услугу/РИД
                        </FieldView.ActionButton>
                      )
                    }}
                  </AsyncWrapper>
                </Row>
              )}
            </Stack>
          </Group>
        </CollapseWrapper>
        <CollapseWrapper
          defaultExpanded={measuresNegativeImpactRenderCondition}
          isExpanded={measuresNegativeImpactRenderCondition}
        >
          <Border />
          <Group disableBottomBorder title={'Мероприятия по снижению негативного воздействия'}>
            <Row>
              <Col xs={12}>
                <MeasuresNegativeImpactForm />
              </Col>
            </Row>
          </Group>
        </CollapseWrapper>
      </Stack>
    </Container>
  )
}

export default Ninth
