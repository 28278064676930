import React, { FC, ReactNode } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import GroupWithRemoveButton from '@components/DocumentFormComponents/GroupWithRemoveButton'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import { EighthMeasureObjectsRegionsArrayPathName } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/8/types'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import { isArray, isObject } from '@helpers/checkTypes'
import { IRegionsSetupPropsObject } from '@services/Properties/Properties.entity'

import { objectRegionsValidationMap } from './validation'

interface StepAnotherObjectRegionItemProps {
  editMode: boolean
  blockViewIsValidating: boolean
  index: number
  name: EighthMeasureObjectsRegionsArrayPathName
  id: string
  formInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>
  formModifierInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>
  leftAddons?: ReactNode
  onRemove?: () => Promise<void>
}

const StepAnotherObjectRegionItem: FC<StepAnotherObjectRegionItemProps> = ({
  editMode,
  blockViewIsValidating,
  index,
  id,
  name,
  formInstance,
  formModifierInstance,
  leftAddons,
  onRemove,
}) => {
  const {
    handlers: { handleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const isNew = useWatch({
    name: `${name}.isNew`,
    control: formModifierInstance.control,
  })

  const { getFormFieldControlProps, getSingleSelectProps } = useFormComponentPresets({
    isNew,
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  return (
    <GroupWithRemoveButton
      disableBottomBorder
      id={id}
      title={`Местоположение №${index + 1}`}
      leftAddons={leftAddons}
      onRemove={onRemove}
    >
      <FormFieldControl
        {...getFormFieldControlProps({
          path: `${name}.objectRegion`,
          formFieldTooltipProps: {
            isNew,
            onChange: () => setTimeout(() => handleChangeValue?.(`${name}.objectRegion`), 0),
            onDifference: handleUpdateChanges,
          },
        })}
      >
        {({ overrideProps }) => {
          const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
            ? overrideProps.fetcherOptions
            : undefined

          return (
            <ControlledFormSingleSelect
              {...getSingleSelectProps({
                fetcherProps,
                transformOptionsData: (data) =>
                  isObject<IRegionsSetupPropsObject>(data) && isObject(data.filteredRegions)
                    ? Object.entries(data.filteredRegions)
                    : data,
                optionsAdapter: (item) => {
                  if (!isArray(item)) return null

                  const [itemKey, itemValue] = item

                  return {
                    displayValue: itemValue || '',
                    value: itemKey,
                  }
                },
                controllerProps: {
                  name: `${name}.objectRegion`,
                  rules: objectRegionsValidationMap.objectRegion,
                },
                selectProps: {
                  withContextSearch: true,
                  inputProps: {
                    label: 'Адрес (местоположение) объекта',
                  },
                  onChangeFormValue: () =>
                    setTimeout(() => handleChangeValue?.(`${name}.objectRegion`), 0),
                },
              })}
            />
          )
        }}
      </FormFieldControl>
    </GroupWithRemoveButton>
  )
}

export default StepAnotherObjectRegionItem
