import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { ParametersApplicationReimbursementExpensesFormValues } from '@components/Forms/ParametersApplicationReimbursementExpensesForm/types'

const { getDefaultInvestorFieldState } = FieldStateHelpers

const mapOfParametersApplicationReimbursementExpensesMenu = [
  { id: '1', title: 'Организация, реализующая проект' },
  { id: '2', title: 'О проекте' },
  { id: '3', title: 'Данные о предоставленном возмещении' },
]

const defaultParametersApplicationReimbursementExpensesFormValues: ParametersApplicationReimbursementExpensesFormValues =
  {
    '1': {
      ...getDefaultInvestorFieldState(),
      investorInn: '',
      investorKpp: '',
    },
    '2': { szpkNumber: '', szpkRegistrationDate: '', projectName: '' },
    '3': {
      compensationsFacts: [],
    },
  }

const parametersApplicationReimbursementExpensesBlockValues = {
  '1': {
    investorFullName: '1.investorFullName',
    investorInn: '1.investorInn',
    investorKpp: '1.investorKpp',
  },
  '2': {
    szpkNumber: '2.szpkNumber',
    szpkRegistrationDate: '2.szpkRegistrationDate',
    projectName: '2.projectName',
  },
  '3': {
    compensationsFacts: '3.compensationsFacts',
  },
} as const

export {
  defaultParametersApplicationReimbursementExpensesFormValues,
  mapOfParametersApplicationReimbursementExpensesMenu,
  parametersApplicationReimbursementExpensesBlockValues,
}
