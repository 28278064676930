import React, { FC } from 'react'
import { Path, UseFormReturn } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl, {
  SubscribableFormControlProps,
} from '@components/DocumentFormComponents/SubscribableControl'
import { registrationCertificateBlockValues } from '@components/Forms/RegistrationCertificateForm/const'
import { municipalityRegistrationCertificateValidationMap } from '@components/Forms/RegistrationCertificateForm/Forms/4/MunicipalitiesList/validation'
import {
  RegistrationCertificateFormModifierValues,
  RegistrationCertificateFormValues,
} from '@components/Forms/RegistrationCertificateForm/types'
import {
  RegistrationCertificateFieldArrayControlUpdateWatcher,
  RegistrationCertificateFieldsControlUpdateWatcher,
} from '@components/Forms/RegistrationCertificateForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface RegistrationCertificateMunicipalitiesListProps {
  editMode: boolean
  blockViewIsValidating: boolean
  formInstance: UseFormReturn<RegistrationCertificateFormValues>
  formModifierInstance: UseFormReturn<RegistrationCertificateFormModifierValues>
  subscribableControl: SubscribableFormControlProps<RegistrationCertificateFormValues>
  onChangeValue?: (
    pathName: Path<RegistrationCertificateFormValues>,
    batchBlockId?: string,
  ) => Promise<void>
  handleChangePublicValue?: (
    pathName: Path<RegistrationCertificateFormValues>,
    newIsPublic: boolean,
  ) => void
}

const RegistrationCertificateMunicipalitiesList: FC<
  RegistrationCertificateMunicipalitiesListProps
> = ({
  editMode,
  blockViewIsValidating,
  formInstance,
  formModifierInstance,
  subscribableControl,
  onChangeValue,
  handleChangePublicValue,
}) => {
  const { fields: municipalities } = useFieldArraySubscribableControl<
    RegistrationCertificateFormValues,
    typeof registrationCertificateBlockValues['4']['municipalities']
  >({
    control: formInstance.control,
    name: registrationCertificateBlockValues['4'].municipalities,
    watcher: RegistrationCertificateFieldArrayControlUpdateWatcher,
  })

  const { getSubscribableControlProps, getFormFieldControlProps, getSingleSelectProps } =
    useFormComponentPresets({
      isNew: true,
      editMode,
      blockViewIsValidating,
      formInstance,
      watcher: RegistrationCertificateFieldsControlUpdateWatcher,
      subscribableControl,
    })

  if (!municipalities.length) return null

  return (
    <Row>
      <Col xs={12}>
        <Stack direction={'vertical'} gap={3}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: registrationCertificateBlockValues['4'].municipalities,
            })}
          >
            {municipalities.map((municipality, index) => {
              const formName =
                `${registrationCertificateBlockValues['4'].municipalities}.${index}` as const

              return (
                <FieldView.RowWithBottomBorder
                  disableBottomDefaultStyles
                  key={municipality.id}
                  firstColumnSize={6}
                  secondColumnSize={6}
                  title={municipality.municipalityFullName}
                >
                  <FormFieldControl
                    {...getFormFieldControlProps({
                      path: `${formName}.municipalityHeaderName`,
                      formFieldPublicProps: {
                        control: formModifierInstance.control,
                        onPublic: (value) =>
                          handleChangePublicValue?.(`${formName}.municipalityHeaderName`, value),
                      },
                    })}
                  >
                    {({ overrideProps }) => {
                      const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                        ? overrideProps.fetcherOptions
                        : undefined

                      return (
                        <ControlledFormSingleSelect
                          {...getSingleSelectProps({
                            fetcherProps,
                            optionsAdapter: (item) => ({
                              displayValue: item.headerName || '',
                              value: item.id,
                            }),
                            selectProps: {
                              inputProps: {
                                label: 'Глава муниципального образования',
                              },
                              onChangeFormValue: () =>
                                onChangeValue?.(`${formName}.municipalityHeaderName`),
                            },
                            controllerProps: {
                              name: `${formName}.municipalityHeaderName`,
                              rules:
                                municipalityRegistrationCertificateValidationMap.municipalityHeaderName,
                            },
                          })}
                        />
                      )
                    }}
                  </FormFieldControl>
                </FieldView.RowWithBottomBorder>
              )
            })}
          </SubscribableControl>
        </Stack>
      </Col>
    </Row>
  )
}

export default RegistrationCertificateMunicipalitiesList
