import { FormModifierAdapters } from '@components/DocumentFormComponents/adapters/Modifier.adapter'
import { FormObjectStates } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAAnotherStageModifier,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionObjectModifier,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionPermissionModifier,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionSubObjectModifier,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAMeasureObjectModifier,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAProjectResultModifier,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStageModifier,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStepAnotherStageModifier,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { isArray } from '@helpers/checkTypes'
import { PropertyTypeEnum } from '@services/Properties/Properties.entity'

const { getModifierStatesFormPropertyState } = FormModifierAdapters

const generateFormModifierValuesForAAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm = (
  objectFromPropertyState: Record<string, any>,
): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues => {
  const preparedStages =
    ((): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStageModifier[] => {
      if (
        !isArray(objectFromPropertyState.steps?.value) ||
        !objectFromPropertyState.steps?.value?.length
      )
        return []

      return objectFromPropertyState.steps?.value.map(
        (step): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStageModifier => {
          const preparedStepCompensations = ((): FormObjectStates[] => {
            if (
              !isArray(step.value.stepCompensations?.value) ||
              !step.value.stepCompensations?.value?.length
            )
              return []

            return step.value.stepCompensations?.value.map(
              (stepCompensation): FormObjectStates =>
                getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(stepCompensation),
            )
          })()

          const preparedOldStepExpenses = ((): FormObjectStates[] => {
            if (
              !isArray(objectFromPropertyState.oldStepExpenses?.value) ||
              !objectFromPropertyState.oldStepExpenses?.value?.length
            )
              return []

            return objectFromPropertyState.oldStepExpenses?.value
              .filter(
                (oldStepExpense) =>
                  oldStepExpense.value?.oldStepExpenses?.value?.id === step.permanentId,
              )
              .map(
                (oldStepExpense): FormObjectStates =>
                  getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(oldStepExpense),
              )
          })()

          const preparedConstructionObjects =
            ((): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionObjectModifier[] => {
              const objects =
                step.value.objects?.value.filter((object) => {
                  return (
                    object.value.isMainObject?.value &&
                    !object.value.isSubObject?.value &&
                    !object.value.objectOtherActivityName?.value?.id
                  )
                }) || []

              const subObjects =
                step.value.objects?.value.filter((object) => {
                  return (
                    object.value.isSubObject?.value &&
                    !object.value.objectOtherActivityName?.value?.id &&
                    object.value.parentSubObject?.value
                  )
                }) || []

              return (
                objects.map(
                  (
                    object,
                  ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionObjectModifier => {
                    const preparedSubObjects: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionSubObjectModifier[] =
                      subObjects
                        .filter(
                          (subObject) =>
                            object.permanentId === subObject.value.parentSubObject?.value?.id,
                        )
                        .map(
                          (
                            subObject,
                          ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionSubObjectModifier => {
                            const preparedObjectRegions =
                              isArray(subObject.value?.objectRegions?.value) &&
                              subObject.value?.objectRegions?.value?.length
                                ? subObject.value?.objectRegions?.value.map(
                                    (objectRegion): FormObjectStates =>
                                      getModifierStatesFormPropertyState<
                                        typeof PropertyTypeEnum.MAP
                                      >(objectRegion),
                                  )
                                : []

                            return {
                              ...getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(
                                subObject,
                              ),
                              objectRegions: preparedObjectRegions,
                              objectRegionsState: getModifierStatesFormPropertyState<
                                typeof PropertyTypeEnum.LIST
                              >(subObject.value?.objectRegions),
                            }
                          },
                        )

                    const preparedObjectAreas =
                      isArray(object.value?.objectAreas?.value) &&
                      object.value?.objectAreas?.value?.length
                        ? object.value?.objectAreas?.value.map(
                            (objectArea): FormObjectStates =>
                              getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(
                                objectArea,
                              ),
                          )
                        : []

                    const preparedObjectRegions =
                      isArray(object.value?.objectRegions?.value) &&
                      object.value?.objectRegions?.value?.length
                        ? object.value?.objectRegions?.value.map(
                            (objectRegion): FormObjectStates =>
                              getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(
                                objectRegion,
                              ),
                          )
                        : []

                    return {
                      ...getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(object),
                      objectAreas: preparedObjectAreas,
                      objectAreasState: getModifierStatesFormPropertyState<
                        typeof PropertyTypeEnum.LIST
                      >(object.value?.objectAreas),
                      objectRegions: preparedObjectRegions,
                      objectRegionsState: getModifierStatesFormPropertyState<
                        typeof PropertyTypeEnum.LIST
                      >(object.value?.objectRegions),
                      subObjects: preparedSubObjects,
                      subObjectsState: getModifierStatesFormPropertyState<
                        typeof PropertyTypeEnum.LIST
                      >(step.value?.objects),
                    }
                  },
                ) || []
              )
            })()

          const preparedPermissions =
            ((): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionPermissionModifier[] => {
              if (!isArray(step.value.permissions?.value) || !step.value.permissions?.value.length)
                return []

              return step.value.permissions?.value.map(
                (
                  permission,
                ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionPermissionModifier => {
                  const preparedObjectNamePermissions = (() => {
                    if (
                      !isArray(permission.value.permissionObjects?.value) ||
                      !permission.value.permissionObjects?.value.length
                    )
                      return []

                    return permission.value.permissionObjects?.value.map(
                      (permissionObject): FormObjectStates => ({
                        id: permissionObject.propertyId,
                        isNew: !!permissionObject?.new,
                      }),
                    )
                  })()

                  return {
                    ...getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(permission),
                    objectNamePermissions: preparedObjectNamePermissions,
                    objectNamePermissionsState: getModifierStatesFormPropertyState<
                      typeof PropertyTypeEnum.LIST
                    >(permission.value.permissionObjects),
                  }
                },
              )
            })()

          const preparedExploitationObjects = (() => {
            if (!step.value.commissionObjects?.value || !step.value.commissionObjects?.value.length)
              return []

            return step.value.commissionObjects?.value.map(
              (commissionObject): FormObjectStates =>
                getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(commissionObject),
            )
          })()

          const preparedRegistrationObjects = (() => {
            if (
              !step.value.registrationObjects?.value ||
              !step.value.registrationObjects?.value.length
            )
              return []

            return step.value.registrationObjects?.value.map(
              (registrationObject): FormObjectStates =>
                getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(registrationObject),
            )
          })()

          const preparedRids = (() => {
            if (!isArray(step.value.rids?.value) || !step.value.rids?.value.length) return []

            return step.value.rids?.value.map(
              (rid): FormObjectStates =>
                getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(rid),
            )
          })()

          const preparedExploitationRids = (() => {
            if (!step.value.commissionRids?.value || !step.value.commissionRids?.value.length)
              return []

            return step.value.commissionRids?.value.map((exploitation) =>
              getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(exploitation),
            )
          })()

          const preparedRidNames = (() => {
            if (
              !step.value?.ridStateRegistrationIds?.value ||
              !step.value.ridStateRegistrationIds?.value.length
            )
              return []

            return step.value.ridStateRegistrationIds?.value.map((rid) => ({
              id: rid.propertyId,
              isNew: !!rid?.new,
            }))
          })()

          const preparedRegistrationRids = (() => {
            if (!step.value.registrationRids?.value || !step.value.registrationRids?.value.length)
              return []

            return step.value.registrationRids?.value.map((registration) =>
              getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(registration),
            )
          })()

          return {
            ...getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(step),
            stepCompensations: preparedStepCompensations,
            stepCompensationsState: getModifierStatesFormPropertyState<
              typeof PropertyTypeEnum.LIST
            >(step.value.stepCompensations),
            oldStepExpenses: preparedOldStepExpenses,
            stepActivityNames: [],
            stepActivityNamesStates: getModifierStatesFormPropertyState<
              typeof PropertyTypeEnum.LIST
            >(step.value.stepActivityNames),
            constructionStage: {
              constructionObjects: preparedConstructionObjects,
              constructionObjectsState: getModifierStatesFormPropertyState<
                typeof PropertyTypeEnum.LIST
              >(step.value.objects),

              permissionsObjects: preparedPermissions,
              permissionsObjectsState: getModifierStatesFormPropertyState<
                typeof PropertyTypeEnum.LIST
              >(step.value.permissions),

              exploitation: {
                exploitationObjects: preparedExploitationObjects,
                exploitationObjectsState: getModifierStatesFormPropertyState<
                  typeof PropertyTypeEnum.LIST
                >(step.value.commissionObjects),
              },

              registration: {
                registrationObjects: preparedRegistrationObjects,
                registrationObjectsState: getModifierStatesFormPropertyState<
                  typeof PropertyTypeEnum.LIST
                >(step.value.registrationObjects),
              },
            },
            ridStage: {
              ridObjects: preparedRids,
              ridObjectsState: getModifierStatesFormPropertyState<typeof PropertyTypeEnum.LIST>(
                step.value.rids,
              ),

              exploitation: {
                ridExploitationObjects: preparedExploitationRids,
                ridExploitationObjectsState: getModifierStatesFormPropertyState<
                  typeof PropertyTypeEnum.LIST
                >(step.value.commissionRids),
              },

              registration: {
                ridNames: preparedRidNames,
                ridNamesState: getModifierStatesFormPropertyState<typeof PropertyTypeEnum.LIST>(
                  step.value?.ridStateRegistrationIds,
                ),
                ridRegistrationsObjects: preparedRegistrationRids,
                ridRegistrationsObjectsState: getModifierStatesFormPropertyState<
                  typeof PropertyTypeEnum.LIST
                >(step.value.registrationRids),
              },
            },
          }
        },
      )
    })()

  const preparedStepActivityOthers =
    ((): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStepAnotherStageModifier[] => {
      if (
        !isArray(objectFromPropertyState.steps?.value) ||
        !objectFromPropertyState.steps?.value.length
      )
        return []

      return objectFromPropertyState.steps?.value.map(
        (step): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStepAnotherStageModifier => {
          return {
            id: step.propertyId,
            activityOthers: step.value.stepActivityOthers?.value.map(
              (
                stepActivity,
              ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAAnotherStageModifier => {
                const preparedMeasureObjects: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAMeasureObjectModifier[] =
                  step.value.objects?.value
                    .filter((object) => {
                      return (
                        object.value.objectOtherActivityName?.value.id === stepActivity.permanentId
                      )
                    })
                    .map(
                      (
                        object,
                      ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAMeasureObjectModifier => {
                        const preparedObjectAreas =
                          isArray(object.value?.objectAreas?.value) &&
                          object.value?.objectAreas?.value?.length
                            ? object.value?.objectAreas?.value.map(
                                (objectArea): FormObjectStates =>
                                  getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(
                                    objectArea,
                                  ),
                              )
                            : []

                        const preparedObjectRegions =
                          isArray(object.value?.objectRegions?.value) &&
                          object.value?.objectRegions?.value?.length
                            ? object.value?.objectRegions?.value.map(
                                (objectRegion): FormObjectStates =>
                                  getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(
                                    objectRegion,
                                  ),
                              )
                            : []

                        return {
                          ...getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(
                            object,
                          ),
                          objectRegions: preparedObjectRegions,
                          objectRegionsState: getModifierStatesFormPropertyState<
                            typeof PropertyTypeEnum.LIST
                          >(object.value?.objectRegions),
                          objectAreas: preparedObjectAreas,
                          objectAreasState: getModifierStatesFormPropertyState<
                            typeof PropertyTypeEnum.LIST
                          >(object.value?.objectAreas),
                        }
                      },
                    )

                return {
                  ...getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(stepActivity),
                  measureObjects: preparedMeasureObjects,
                  measureObjectsState: getModifierStatesFormPropertyState<
                    typeof PropertyTypeEnum.LIST
                  >(step.value.objects),
                }
              },
            ),
          }
        },
      )
    })()

  const preparedProjectResults =
    ((): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAProjectResultModifier[] => {
      if (
        !isArray(objectFromPropertyState.projectResults?.value) ||
        !objectFromPropertyState.projectResults?.value.length
      )
        return []

      return objectFromPropertyState.projectResults?.value.map(
        (item): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAProjectResultModifier => {
          const preparedVolumeOfProduction = (() => {
            if (
              !isArray(item.value.projectResultValues?.value) ||
              !item.value.projectResultValues?.value
            )
              return []

            return item.value.projectResultValues?.value
              .sort((projectResultA, projectResultB) => projectResultA.order - projectResultB.order)
              .map((projectResult) =>
                getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(projectResult),
              )
          })()

          return {
            ...getModifierStatesFormPropertyState<typeof PropertyTypeEnum.MAP>(item),
            volumeOfProduction: preparedVolumeOfProduction,
            volumeOfProductionState: getModifierStatesFormPropertyState<
              typeof PropertyTypeEnum.LIST
            >(item.value.projectResultValues),
          }
        },
      )
    })()

  const preparedTechnologicalRequirements = (() => {
    if (
      !isArray(objectFromPropertyState.projectNpas?.value) ||
      !objectFromPropertyState.projectNpas?.value.length
    )
      return []

    return objectFromPropertyState.projectNpas.value.map((npa) => ({
      id: npa?.propertyId,
      isNew: !!npa?.new,
    }))
  })()

  return {
    '7': {
      stages: preparedStages,
    },
    '8': {
      stepActivityOthers: preparedStepActivityOthers,
    },
    '9': {
      projectResults: preparedProjectResults,
      projectResultsState: getModifierStatesFormPropertyState<typeof PropertyTypeEnum.LIST>(
        objectFromPropertyState.projectResults,
      ),
    },
    '10': {
      technologicalRequirements: preparedTechnologicalRequirements,
    },
  }
}

export { generateFormModifierValuesForAAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm }
