import React, { memo } from 'react'
import { useParams } from 'react-router'

import Icon from '@components/Icon'
import Typography from '@components/NewDesign/Typography'
import DeadlineProjectsTooltip from '@components/PreparedTooltips/DeadlineProjects'
import Projects from '@components/Projects'
import UiActionButton from '@components/Projects/[id]/ActionBanner/Button'
import { getTextColor } from '@components/Projects/[id]/ActionBanner/helpers'
import { useActionBannerStatus } from '@components/Projects/[id]/ActionBanner/hooks/useActionBannerStatus'
import { useUiActionsManager } from '@components/Projects/[id]/ActionBanner/Manager'
import { useDfosManager } from '@components/Projects/[id]/DfosManager'
import { Skeleton } from '@components/Skeleton/Skeleton'
import { iconByName } from '@constants/config'
import { useAvailableConfigsByRole } from '@hooks/new/configs/useAvailableDfoConfigsByRole'
import { useProject } from '@hooks/new/swr/useProject'
import DeadlineService from '@services/Deadline/Deadline.service'
import { useDocumentActions } from '@services/DocumentActions/DocumentAction.hook'
import DocumentActionsService from '@services/DocumentActions/DocumentActions.helpers'
import cn from 'classnames'

import styles from './ActionBanner.module.scss'

const { getDefaultFormattedDate, checkRenderTooltipByViewType } = DeadlineService
const { fromActionToActionsStack } = DocumentActionsService
const ActionBanner = (): JSX.Element => {
  const { projectId } = useParams()

  const {
    state: { projectPanelActions, isLoadingProjectActions },
  } = useUiActionsManager()

  const {
    state: { currentDfoInfo },
  } = useDfosManager()

  const { isLoadingRoleConfig, getConfigForDfo } = useAvailableConfigsByRole()
  const { project, isLoadingProject } = useProject({
    key: { projectId, _key: 'project' },
    config: {
      isPaused: () => !projectId,
    },
  })

  const { createActionsStack } = useDocumentActions()

  const params =
    currentDfoInfo &&
    getConfigForDfo({
      dfo: {
        stage: currentDfoInfo.stage,
        actionAllowed: currentDfoInfo.actionAllowed,
        type: currentDfoInfo.type,
        version: currentDfoInfo.version,
      },
      isFederal: !!project?.isFederal,
    })

  const { stageLineInfoProcessing, processedStageLineHeader, processedStageLineDescription } =
    useActionBannerStatus({ params, projectId })

  const textColorClassName = params?.stageStyle && {
    [styles[`${getTextColor(params.stageStyle)}`]]: getTextColor(params.stageStyle),
  }

  const actionBannerDynamicClassName =
    styles[`actionBanner_${params?.stageStyle === 'grey' ? 'blue' : params?.stageStyle || 'blue'}`]
  const logoStageLineCondition = params?.stageLineIcon === 'logo'

  const currentDeadline =
    !stageLineInfoProcessing &&
    currentDfoInfo?.deadline?.overdueDate &&
    getDefaultFormattedDate(currentDfoInfo.deadline.overdueDate)

  const deadlineTooltipIsRender =
    currentDfoInfo?.deadline &&
    currentDeadline &&
    checkRenderTooltipByViewType(currentDfoInfo?.deadline.viewType)

  return (
    <div className={cn(styles.actionBanner, actionBannerDynamicClassName)}>
      <Projects.ProjectContainer className={styles.actionBanner__wrapper}>
        <Skeleton
          animate
          visible={isLoadingRoleConfig || stageLineInfoProcessing}
          className={styles.actionBanner__skeleton}
        >
          <div className={styles['actionBanner__info-wrapper']}
          data-testid='dfoState'>
            {params?.stageStyle && params?.stageLineIcon && (
              <div
                className={cn(styles.icon, {
                  [styles[`icon_${params.stageStyle}`]]: !logoStageLineCondition,
                  [styles[`icon-logo--${params.stageStyle}`]]: logoStageLineCondition,
                })}
              >
                <Icon noCurrentColorSvgFill src={iconByName[params.stageLineIcon]} />
              </div>
            )}
            <div className={styles.actionBanner__info}>
              <div className={styles['actionBanner__info-header']}>
                <Typography.Body
                  as={'span'}
                  variant={'bodyLMedium'}
                  className={cn({
                    ...textColorClassName,
                  })}
                >
                  {processedStageLineHeader}
                </Typography.Body>
                {currentDeadline && (
                  <Typography.Body as={'span'} variant={'bodyLMedium'} color={'text-base-tertiary'}>
                    &nbsp;· {currentDeadline} включительно
                  </Typography.Body>
                )}
                {deadlineTooltipIsRender && (
                  <DeadlineProjectsTooltip targetClassName={styles.actionBanner__tooltip} />
                )}
              </div>

              <Typography.Body
                color={'text-base-secondary'}
                variant={'bodyMRegular'}
                className={cn(styles['actionBanner__info-subtitle'], {
                  ...textColorClassName,
                })}
              >
                {processedStageLineDescription}
              </Typography.Body>
            </div>
          </div>
        </Skeleton>
        <Skeleton
          animate
          visible={isLoadingProject || isLoadingProjectActions}
          className={styles.actionBanner__skeleton}
        >
          {!!projectPanelActions.length && (
            <div className={styles.actionBanner__actions}>
              {projectPanelActions.map((action, index) => {
                const actionsForStack = fromActionToActionsStack(action)

                const handleUiActionClick = async () => {
                  try {
                    await createActionsStack(actionsForStack).runStack()
                  } catch {}
                }

                return (
                  <UiActionButton
                    key={index}
                    className={styles['actionBanner__actions-item']}
                    uiAction={action}
                    buttonProps={{
                      dataTestId: `ActionBanner-action-${action.actionId}-${action.displayName}-button`,
                      onClick: handleUiActionClick,
                    }}
                  />
                )
              })}
            </div>
          )}
        </Skeleton>
      </Projects.ProjectContainer>
    </div>
  )
}

export default memo(ActionBanner)
