import React, { FC } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import ControlledCheckbox from '@components/Checkbox/Controlled'
import { CheckboxDefaultPayload } from '@components/Checkbox/types'
import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import FormListTooltipControl from '@components/DocumentFormComponents/FormControls/FormListTooltipControl'
import { useFormModifierContext } from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import styles from '@components/DocumentFormComponents/FormStyles/MainTemplate.module.scss'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import {
  getFetcherProps,
  isFetcherProps,
} from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementOnChangingCharacteristicsOfObjectsBlockValues } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/const'
import FormController from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Forms/5/AdditionalForms/MainTemplate/FormController'
import StepCompensations from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Forms/5/AdditionalForms/MainTemplate/StepCompensations'
import { fifthSectionValidationMap } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Forms/5/AdditionalForms/MainTemplate/validation'
import { ConstructionStagesArrayPathName } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Forms/5/types'
import { useAAgreementOnChangingCharacteristicsOfObjectsManager } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Manager'
import {
  AAgreementOnChangingCharacteristicsOfObjectsFormModifierValues,
  AAgreementOnChangingCharacteristicsOfObjectsFormValues,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/types'
import { AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { objOfDateFormats } from '@constants/dateFormats'
import { isNotEmptyString } from '@helpers/checkTypes'
import { getObjectValue } from '@helpers/object/getObjectValue'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import dayjs from 'dayjs'

interface MainTemplateProps {
  isConstructionRender: boolean
  parentId: string
  name: ConstructionStagesArrayPathName
  indexOfMainBlock: number
}

const { transformRHFPathInProperties } = DocumentFormHelpers

const MainTemplate: FC<MainTemplateProps> = ({
  isConstructionRender,
  parentId,
  name,
  indexOfMainBlock,
}) => {
  const formInstance = useFormContext<AAgreementOnChangingCharacteristicsOfObjectsFormValues>()

  const formModifierInstance =
    useFormModifierContext<AAgreementOnChangingCharacteristicsOfObjectsFormModifierValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: {
      handleChangeValue,
      handleAddCustomValue,
      handleRemoveCustomValue,
      debouncedHandleChangeValue,
      getPropertiesProps,
      getOverrideProps,
    },
    preparedProps: { subscribableControl },
  } = useAAgreementOnChangingCharacteristicsOfObjectsManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const isNew = useWatch({
    name: `${name}.isNew`,
    control: formModifierInstance.control,
  })

  const {
    getSubscribableControlProps,
    getFormFieldControlProps,
    getCheckboxProps,
    getTextareaProps,
    getCalendarInputProps,
    getSingleSelectProps,
    getAmountInputProps,
  } = useFormComponentPresets({
    isNew,
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher,
  })

  const stepCompensationForm = useWatch({
    name: `${name}.stepCompensationValue`,
    control: formInstance.control,
  })

  const stepActivityNames = useWatch({
    name: `${name}.stepActivityNames`,
    control: formInstance.control,
  })

  const stepStartDate = useWatch({
    name: `${name}.stepStartDate`,
    control: formInstance.control,
  })

  const projectEndDate = useWatch({
    name: aAgreementOnChangingCharacteristicsOfObjectsBlockValues.additionalFields.projectEndDate,
    control: formInstance.control,
  })

  const stepCompensationBlockRenderCondition = isNotEmptyString(stepCompensationForm)

  const handleCheckStepActivity = async (
    stepActivityPath: string,
    stepActivityName: string,
    payload?: CheckboxDefaultPayload,
  ) => {
    const propertyStepActivityNames = getObjectValue(
      getPropertiesProps?.(),
      transformRHFPathInProperties(stepActivityPath),
    )

    const allControlProps = getOverrideProps?.()[propertyStepActivityNames?.propertyId]

    const overrideProps = getFetcherProps(allControlProps)
    if (!overrideProps) return null

    if (!propertyStepActivityNames || !overrideProps.length) return

    const idOfAddProperty = overrideProps.find((item) => item.name === stepActivityName)?.id

    const propertyInfo = {
      id: propertyStepActivityNames.propertyId,
      lastUpdateDt: propertyStepActivityNames.lastUpdateDt,
    }

    if (payload?.checked) {
      return await handleAddCustomValue?.(propertyInfo, {
        id: idOfAddProperty,
      })
    }

    const idToRemove = stepActivityNames?.find(
      (item) => item.value.name === stepActivityName,
    ).propertyId

    if (!idToRemove) return
    return await handleRemoveCustomValue?.(propertyInfo, idToRemove)
  }

  return (
    <Container className={styles.mainTemplate}>
      <Col xs={12}>
        <Stack direction={'vertical'} gap={3}>
          <FieldView.RowWithBottomBorder
            disableBottomDefaultStyles
            firstColumnSize={6}
            secondColumnSize={6}
            title={'Номер этапа'}
          >
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${name}.stepNumber`,
              })}
            >
              <ControlledAmountInput
                {...getAmountInputProps({
                  name: `${name}.stepNumber`,
                  inputProps: {
                    disabled: true,
                    integersOnly: true,
                    hideSuffix: true,
                    hasNegativeValue: false,
                  },
                })}
              />
            </SubscribableControl>
          </FieldView.RowWithBottomBorder>
          <Row>
            <Col xs={12}>
              <FormFieldControl
                {...getFormFieldControlProps({
                  path: `${name}.stepDescription`,
                  formFieldTooltipProps: {
                    onChange: () =>
                      setTimeout(() => handleChangeValue?.(`${name}.stepDescription`), 0),
                    onDifference: handleUpdateChanges,
                  },
                })}
              >
                <ControlledTextarea
                  {...getTextareaProps({
                    name: `${name}.stepDescription`,
                    rules: fifthSectionValidationMap.stepDescription,
                    textareaProps: {
                      maxRows: Infinity,
                      label: 'Описание этапа',
                    },
                    onBlur: () =>
                      setTimeout(() => handleChangeValue?.(`${name}.stepDescription`), 0),
                    onChange: () =>
                      setTimeout(() => {
                        debouncedHandleChangeValue?.(`${name}.stepDescription`)
                      }, 0),
                  })}
                />
              </FormFieldControl>
            </Col>
          </Row>
          <Col xs={12} className={styles.mainTemplate__container}>
            <Stack direction={'vertical'} gap={3} className={styles.mainTemplate__stack}>
              <Row>
                <Col xs={12}>
                  <FormListTooltipControl
                    name={`${name}.stepActivityNames`}
                    control={formModifierInstance.control}
                    onDifference={handleUpdateChanges}
                  >
                    <Typography.Body variant={'bodyMMedium'} color={'text-base-secondary'}>
                      Тип мероприятия
                    </Typography.Body>
                  </FormListTooltipControl>
                </Col>
              </Row>
              <AsyncWrapper promise={handleCheckStepActivity}>
                {({
                  isLoading: isStepActivityChecking,
                  wrappedPromise: wrappedHandleCheckStepActivity,
                }) => (
                  <Row>
                    <Col xs={4}>
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${name}.isConstruction`,
                        })}
                        watcher={
                          AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher
                        }
                      >
                        <ControlledCheckbox
                          {...getCheckboxProps({
                            name: `${name}.isConstruction`,
                            checkBoxProps: {
                              disabled: isStepActivityChecking || !editMode,
                              label: 'Строительство',
                            },
                            onChange: async (e, payload) =>
                              wrappedHandleCheckStepActivity(
                                `${name}.isConstruction`,
                                'Строительство (создание)',
                                payload,
                              ),
                          })}
                        />
                      </SubscribableControl>
                    </Col>
                    <Col xs={4}>
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${name}.isReconstruction`,
                        })}
                        watcher={
                          AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher
                        }
                      >
                        <ControlledCheckbox
                          {...getCheckboxProps({
                            name: `${name}.isReconstruction`,
                            checkBoxProps: {
                              disabled: isStepActivityChecking || !editMode,
                              label: 'Реконструкция',
                            },
                            onChange: async (e, payload) =>
                              wrappedHandleCheckStepActivity(
                                `${name}.isReconstruction`,
                                'Реконструкция',
                                payload,
                              ),
                          })}
                        />
                      </SubscribableControl>
                    </Col>
                    <Col xs={4}>
                      <SubscribableControl
                        {...getSubscribableControlProps({
                          path: `${name}.isModernization`,
                        })}
                        watcher={
                          AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher
                        }
                      >
                        <ControlledCheckbox
                          {...getCheckboxProps({
                            name: `${name}.isModernization`,
                            checkBoxProps: {
                              disabled: isStepActivityChecking || !editMode,
                              label: 'Модернизация',
                            },
                            onChange: async (e, payload) =>
                              wrappedHandleCheckStepActivity(
                                `${name}.isModernization`,
                                'Модернизация',
                                payload,
                              ),
                          })}
                        />
                      </SubscribableControl>
                    </Col>
                  </Row>
                )}
              </AsyncWrapper>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={6}
                secondColumnSize={6}
                title={'Дата окончания этапа'}
              >
                <FormFieldControl
                  {...getFormFieldControlProps({
                    path: `${name}.stepEndDate`,
                    formFieldTooltipProps: {
                      onChange: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${name}.stepEndDate`)
                        }, 0),
                      onDifference: handleUpdateChanges,
                    },
                  })}
                >
                  <ControlledCalendarInput
                    {...getCalendarInputProps({
                      name: `${name}.stepEndDate`,
                      rules: {
                        ...fifthSectionValidationMap.stepEndDate,
                        validate: {
                          positiveProjectStartDate: (value) => {
                            if (
                              isDateValidForDayjs(value) &&
                              dayjs(value, objOfDateFormats.defaultFormat) >
                                dayjs(projectEndDate, objOfDateFormats.defaultFormat)
                            )
                              return 'дата окончания этапа должна быть меньше или равна дате окончания проекта'
                          },
                          positiveStepStartDate: (value) => {
                            return (
                              (isDateValidForDayjs(value) &&
                                dayjs(value, objOfDateFormats.defaultFormat) >
                                  dayjs(stepStartDate, objOfDateFormats.defaultFormat)) ||
                              'дата окончания этапа должна быть больше даты начала этапа'
                            )
                          },
                        },
                      },
                      calendarInputProps: {
                        label: 'Дата окончания этапа',
                      },
                      onBlur: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${name}.stepEndDate`)
                        }, 0),
                      onCalendarChange: () =>
                        setTimeout(() => {
                          handleChangeValue?.(`${name}.stepEndDate`)
                        }, 0),
                      onInputChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${name}.stepEndDate`)
                        }, 0),
                    })}
                  />
                </FormFieldControl>
              </FieldView.RowWithBottomBorder>
              <FieldView.RowWithBottomBorder
                disableBottomDefaultStyles
                firstColumnSize={8}
                secondColumnSize={4}
                title={'Объем планируемых к возмещению затрат по этапу'}
              >
                <FormFieldControl
                  {...getFormFieldControlProps({
                    path: `${name}.stepCompensationValue`,
                    formFieldTooltipProps: {
                      onChange: () =>
                        setTimeout(() => handleChangeValue?.(`${name}.stepCompensationValue`), 0),
                      onDifference: handleUpdateChanges,
                    },
                  })}
                >
                  <ControlledAmountInput
                    {...getAmountInputProps({
                      name: `${name}.stepCompensationValue`,
                      rules: fifthSectionValidationMap.stepCompensationValue,
                      onBlur: () =>
                        setTimeout(() => handleChangeValue?.(`${name}.stepCompensationValue`), 0),
                      onChange: () =>
                        setTimeout(() => {
                          debouncedHandleChangeValue?.(`${name}.stepCompensationValue`)
                        }, 0),
                    })}
                  />
                </FormFieldControl>
              </FieldView.RowWithBottomBorder>
              <CollapseWrapper
                defaultExpanded={stepCompensationBlockRenderCondition}
                isExpanded={stepCompensationBlockRenderCondition}
              >
                <Row>
                  <Col xs={12}>
                    <FormFieldControl
                      {...getFormFieldControlProps({
                        path: `${name}.stepCompensationForm`,
                        formFieldTooltipProps: {
                          onChange: () =>
                            setTimeout(
                              () => handleChangeValue?.(`${name}.stepCompensationForm`),
                              0,
                            ),
                          onDifference: handleUpdateChanges,
                        },
                      })}
                    >
                      {({ overrideProps }) => {
                        const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                          ? overrideProps.fetcherOptions
                          : undefined

                        return (
                          <ControlledFormSingleSelect
                            {...getSingleSelectProps({
                              fetcherProps,
                              optionsAdapter: (item) => ({
                                displayValue: item.name || '',
                                value: item.id,
                              }),
                              controllerProps: {
                                name: `${name}.stepCompensationForm`,
                              },
                              selectProps: {
                                withContextSearch: true,
                                inputProps: {
                                  label: 'Форма возмещения затрат по этапу',
                                },
                                onChangeFormValue: () =>
                                  setTimeout(
                                    () => handleChangeValue?.(`${name}.stepCompensationForm`),
                                    0,
                                  ),
                              },
                            })}
                          />
                        )
                      }}
                    </FormFieldControl>
                  </Col>
                </Row>
              </CollapseWrapper>
            </Stack>
          </Col>
          <CollapseWrapper
            defaultExpanded={stepCompensationBlockRenderCondition}
            isExpanded={stepCompensationBlockRenderCondition}
          >
            <Row>
              <Col xs={12}>
                <StepCompensations
                  formName={`${name}.stepCompensations`}
                  editMode={editMode}
                  formInstance={formInstance}
                  formModifierInstance={formModifierInstance}
                  blockViewIsValidating={blockViewIsValidating}
                />
              </Col>
            </Row>
          </CollapseWrapper>
          <Row>
            <Col xs={12}>
              <FormController
                isNew={isNew}
                parentId={parentId}
                indexOfMainBlock={indexOfMainBlock}
                name={name}
                isConstructionRender={isConstructionRender}
              />
            </Col>
          </Row>
        </Stack>
      </Col>
    </Container>
  )
}

export default MainTemplate
