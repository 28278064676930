import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import { DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST } from '@components/DocumentFormComponents/const'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormObjectTooltipControl from '@components/DocumentFormComponents/FormControls/FormObjectTooltipControl'
import FormError from '@components/DocumentFormComponents/FormError'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import RFormItem from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/AdditionalForms/MainTemplate/RidObject/Forms/RForm/RFormItem'
import { SeventhRidObjectsPathName } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/types'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import Button from '@components/NewDesign/Button'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import CircleAddIcon from '@icons/CircleAddIcon.svg'

const { isFormFieldError } = DocumentFormHelpers

interface RFormProps {
  name: SeventhRidObjectsPathName
  formInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>
  formModifierInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>
}

const RForm: FC<RFormProps> = ({ name, formInstance, formModifierInstance }) => {
  const {
    state: { blockViewIsValidating, editMode },
    handlers: {
      handleAddItemToListWithOutValue,
      handleRemoveItemFromList,
      handleUpElementInList,
      handleDownElementInList,
    },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const { fields: ridForms } = useFieldArraySubscribableControl<
    AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
    SeventhRidObjectsPathName,
    'keyNameId'
  >({
    name,
    control: formInstance.control,
    keyName: 'keyNameId',
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  })

  const { getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  const ridFormsError = formInstance.getFieldState(name)?.error

  const handleAddRidForm = async () => {
    await handleAddItemToListWithOutValue?.(name)

    if (!ridForms.length) {
      formInstance.clearErrors(name)
    }
  }

  const handleRemoveRidForm = (index: number) => async () => {
    await handleRemoveItemFromList?.(`${name}.${index}`, name)
  }

  const handleChangeElementOrderToUp = (index: number) => async () => {
    await handleUpElementInList?.(`${name}.${index}`)
  }

  const handleChangeElementOrderToDown = (index: number) => async () => {
    await handleDownElementInList?.(`${name}.${index}`)
  }

  return (
    <Container className="p-0">
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <Stack direction={'vertical'} gap={!!ridForms.length ? 3 : 0}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: name,
                })}
              >
                <FlipperList list={ridForms}>
                  {ridForms.map((ridForm, index) => {
                    const formName = `${name}.${index}` as const

                    return (
                      <SubscribableControl
                        key={ridForm.id}
                        {...getSubscribableControlProps({
                          path: formName,
                        })}
                      >
                        <FormObjectTooltipControl
                          name={formName}
                          control={formModifierInstance.control}
                          onDifference={handleUpdateChanges}
                        >
                          <RFormItem
                            id={ridForm.id}
                            anchorId={ridForm.anchorId}
                            formName={formName}
                            blockViewIsValidating={blockViewIsValidating}
                            editMode={editMode}
                            formInstance={formInstance}
                            formModifierInstance={formModifierInstance}
                            indexOfRidForm={index}
                            lastIndexOfRidForm={ridForms.length - 1}
                            onDeleteRidForm={handleRemoveRidForm(index)}
                            onMoveTop={handleChangeElementOrderToUp(index)}
                            onMoveBottom={handleChangeElementOrderToDown(index)}
                          />
                        </FormObjectTooltipControl>
                      </SubscribableControl>
                    )
                  })}
                </FlipperList>
              </SubscribableControl>
              {isFormFieldError(ridFormsError) && !ridForms.length && (
                <FormError>{DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}</FormError>
              )}
              {editMode && (
                <Row className={'px-1'}>
                  <Col xs={12}>
                    <AsyncWrapper promise={handleAddRidForm}>
                      {({ isLoading, wrappedPromise }) => {
                        return (
                          <Button
                            disabled={isLoading}
                            variant={'buttonSMedium'}
                            leadingIcon={{ src: CircleAddIcon }}
                            size={'2xs'}
                            view={'plain'}
                            loaderProps={{
                              loading: isLoading,
                              placement: 'trailing',
                              variant: 'lite',
                            }}
                            onClick={wrappedPromise}
                          >
                            Добавить РИД
                          </Button>
                        )
                      }}
                    </AsyncWrapper>
                  </Col>
                </Row>
              )}
            </Stack>
          </Col>
        </Row>
      </Stack>
    </Container>
  )
}

export default RForm
