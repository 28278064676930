import { FieldErrors, UnpackNestedValue } from 'react-hook-form'

import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'

import { szpksChangeTerminateMenuItemId } from '../Menu/const'
import { DataOnFulfillmentOfTermsSZPKFormValues } from '../types'

const menuAnchorConverterMap = {
  '1': '1',
  '2': '2',
  '3.1': '3',
  '3.2': '4',
  '3.3': '5',
  '3.4': '6',
  '3.5': '7',
  '3.6': '8',
  '4': '9',
  '5.1': '10',
  '5.2': '11',
  '5.3': '12',
}

const generateMenuListForDataOnFulfillmentOfTermsSZPKMenuState = (
  formValues:
    | DataOnFulfillmentOfTermsSZPKFormValues
    | UnpackNestedValue<DataOnFulfillmentOfTermsSZPKFormValues>,
  initialHashMap: NestedMenuHashMap,
  formErrors: FieldErrors<DataOnFulfillmentOfTermsSZPKFormValues>,
): NestedMapOfMenu[] => {
  const getMenuSectionWithError = (menuSection: NestedMapOfMenu) => {
    const preparedChildrenSections = menuSection.children.map((item) => ({
      ...item,
      hasError: !!formErrors?.[menuAnchorConverterMap[item.id]],
    }))

    return {
      ...menuSection,
      hasError:
        preparedChildrenSections.some((item) => item.hasError) ||
        !!formErrors?.[menuAnchorConverterMap[menuSection.id]],
      children: preparedChildrenSections,
    }
  }

  const getPreparedEconomicSpheresRegionalReportMenu = (item: NestedMapOfMenu): NestedMapOfMenu => {
    const economicSpheresRegionalReportItemsError = formErrors?.['9']?.economicSpheresRegionalReport

    if (
      !formValues['9'].economicSpheresRegionalReport ||
      !formValues['9'].economicSpheresRegionalReport.length
    )
      return {
        ...item,
        hasError: !!economicSpheresRegionalReportItemsError,
      }

    return {
      ...item,
      hasError: !!economicSpheresRegionalReportItemsError,
      children: formValues?.['9']?.economicSpheresRegionalReport.map(
        (economicSphereRegional, indexOfEconomicSphereRegional): NestedMapOfMenu => {
          const economicSpheresRegionalReportItemError =
            formErrors?.['9']?.economicSpheresRegionalReport?.[indexOfEconomicSphereRegional]

          return {
            id: `4.${indexOfEconomicSphereRegional + 1}`,
            path: `4.${indexOfEconomicSphereRegional + 1}`,
            title: economicSphereRegional.economicSphereNumberTitleRegionalReport,
            isCollapsed: false,
            hasError: !!economicSpheresRegionalReportItemError,
            parentBaseId: '4',
            children:
              economicSphereRegional.economicSphereOkvedsRegionalReport?.map(
                (economicSphereOkved, indexOfEconomicSphereOkved): NestedMapOfMenu => ({
                  id: `4.${indexOfEconomicSphereRegional + 1}.${indexOfEconomicSphereOkved + 1}`,
                  path: `4.${indexOfEconomicSphereRegional + 1}.${indexOfEconomicSphereOkved + 1}`,
                  title: economicSphereOkved.economicSphereOkvedNumberTitleRegionalReport,
                  isCollapsed: false,
                  hasError:
                    !!formErrors?.['9']?.economicSpheresRegionalReport?.[
                      indexOfEconomicSphereRegional
                    ]?.economicSphereOkvedsRegionalReport?.[indexOfEconomicSphereOkved],
                  parentBaseId: `4.${indexOfEconomicSphereRegional + 1}`,
                  children: [],
                }),
              ) || [],
          }
        },
      ),
    }
  }

  const getPreparedSzpksChangeTerminateMenu = (item: NestedMapOfMenu): NestedMapOfMenu => {
    const szpksChangeTerminateItemsError = formErrors?.['10']?.szpksChangeTerminate

    if (
      !formValues?.['10']?.szpksChangeTerminate ||
      !formValues?.['10']?.szpksChangeTerminate.length
    )
      return {
        ...item,
        hasError: !!szpksChangeTerminateItemsError,
      }

    return {
      ...item,
      hasError: !!szpksChangeTerminateItemsError,
      children:
        formValues?.['10']?.szpksChangeTerminate.map(
          (szpkChange, indexOfSzpkChange): NestedMapOfMenu => {
            const szpkChangeItemError =
              formErrors?.['10']?.szpksChangeTerminate?.[indexOfSzpkChange]

            return {
              id: `5.1.${indexOfSzpkChange + 1}`,
              path: `5.1.${indexOfSzpkChange + 1}`,
              title: szpkChange.projectName || 'без наименования',
              isCollapsed: false,
              hasError: !!szpkChangeItemError,
              parentBaseId: '5.1',
              children: [],
            }
          },
        ) || [],
    }
  }

  const getPreparedChildrenOfFifthSection = (hashMapItem) =>
    hashMapItem.children.map((item) => {
      if (item.path === szpksChangeTerminateMenuItemId)
        return getPreparedSzpksChangeTerminateMenu(item)

      return {
        ...item,
        hasError: !!formErrors?.[menuAnchorConverterMap[item.path]],
      }
    })

  const preparedHashMap = {
    '1': getMenuSectionWithError(initialHashMap['1']),
    '2': getMenuSectionWithError(initialHashMap['2']),
    '3': getMenuSectionWithError(initialHashMap['3']),
    '4': getPreparedEconomicSpheresRegionalReportMenu(initialHashMap['4']),
    '5': {
      ...initialHashMap['5'],
      hasError: getPreparedChildrenOfFifthSection(initialHashMap['5']).some(
        ({ hasError }) => hasError,
      ),
      children: getPreparedChildrenOfFifthSection(initialHashMap['5']),
    },
  }

  return Object.values(preparedHashMap)
}

export { generateMenuListForDataOnFulfillmentOfTermsSZPKMenuState }
