import React from 'react'
import { useFormContext } from 'react-hook-form'

import FlipperList from '@components/DocumentFormComponents/FlipperList'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { useInitialCollapseStateList } from '@components/DocumentFormComponents/hooks/useInitialCollapseStateList'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementOnChangingCharacteristicsOfObjectsBlockValues } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/const'
import { ConstructionStagesPathName } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Forms/5/types'
import ConstructionStage from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Forms/5/СonstructionStage'
import { useAAgreementOnChangingCharacteristicsOfObjectsManager } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Manager'
import { AAgreementOnChangingCharacteristicsOfObjectsFormValues } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/types'
import {
  AAgreementOnChangingCharacteristicsOfObjectsFieldArrayControlUpdateWatcher,
  AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { isUndefined } from '@helpers/checkTypes'

const Fifth = () => {
  const formInstance = useFormContext<AAgreementOnChangingCharacteristicsOfObjectsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    preparedProps: { subscribableControl },
  } = useAAgreementOnChangingCharacteristicsOfObjectsManager()

  const { fields: constructionStages } = useFieldArraySubscribableControl<
    AAgreementOnChangingCharacteristicsOfObjectsFormValues,
    ConstructionStagesPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: aAgreementOnChangingCharacteristicsOfObjectsBlockValues['5'].constructionStages,
    keyName: 'keyNameId',
    watcher: AAgreementOnChangingCharacteristicsOfObjectsFieldArrayControlUpdateWatcher,
  })

  const { initialCollapseState } = useInitialCollapseStateList(constructionStages)

  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher,
  })

  if (!formInstance) return null

  return (
    <Container className={'p-0'}>
      <Row>
        <Col xs={12}>
          <Stack direction={'vertical'} gap={!constructionStages.length ? 0 : 3}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: aAgreementOnChangingCharacteristicsOfObjectsBlockValues['5']
                  .constructionStages,
              })}
            >
              <FlipperList list={constructionStages} gap={1} listIdName={'id'}>
                {constructionStages.map((stage, index) => {
                  const currentInitState = !isUndefined(initialCollapseState[stage.id])
                    ? initialCollapseState[stage.id]
                    : true

                  return (
                    <ConstructionStage
                      id={stage.id}
                      anchorId={stage.anchorId}
                      indexOfStage={index}
                      initialAccordionState={currentInitState}
                      stepDescription={stage.stepDescription}
                      key={stage.id}
                    />
                  )
                })}
              </FlipperList>
            </SubscribableControl>
          </Stack>
        </Col>
      </Row>
    </Container>
  )
}

export default Fifth
