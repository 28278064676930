import React from 'react'
import { useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import { DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST } from '@components/DocumentFormComponents/const'
import EmptyDataText from '@components/DocumentFormComponents/EmptyDataText'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormObjectTooltipControl from '@components/DocumentFormComponents/FormControls/FormObjectTooltipControl'
import FormError from '@components/DocumentFormComponents/FormError'
import { useFormModifierContext } from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/const'
import TechnologicalRequirementsForm from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/10/AdditionalForms/TechnologicalRequirementsForm'
import { TechnologicalRequirementsPathName } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/10/types'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const { isFormFieldError } = DocumentFormHelpers

const Tenth = () => {
  const formInstance =
    useFormContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>()

  const formModifierInstance =
    useFormModifierContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>()

  const {
    state: { editMode },
    handlers: {
      handleAddItemToListWithOutValue,
      handleRemoveItemFromList,
      getPropertiesProps,
      handleUpElementInList,
      handleDownElementInList,
    },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  const { fields: technologicalRequirements } = useFieldArraySubscribableControl<
    AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
    TechnologicalRequirementsPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['10']
      .technologicalRequirements,
    keyName: 'keyNameId',
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  })

  if (!formInstance) return null

  const technologicalRequirementsError = formInstance.getFieldState(
    aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['10']
      .technologicalRequirements,
  )?.error

  const handleAddTechnologicalRequirementsForm = async () => {
    await handleAddItemToListWithOutValue?.(
      aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['10']
        .technologicalRequirements,
    )

    if (!technologicalRequirements.length) {
      formInstance.clearErrors(
        aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['10']
          .technologicalRequirements,
      )
    }
  }

  const handleRemoveForm = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['10'].technologicalRequirements}.${index}`,
      aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['10']
        .technologicalRequirements,
    )
  }

  const handleChangeElementOrderToUp = (index: number) => async () => {
    await handleUpElementInList?.(
      `${aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['10'].technologicalRequirements}.${index}`,
    )
  }

  const handleChangeElementOrderToDown = (index: number) => async () => {
    await handleDownElementInList?.(
      `${aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['10'].technologicalRequirements}.${index}`,
    )
  }

  return (
    <Container className={'p-0'}>
      {!editMode && !technologicalRequirements.length && <EmptyDataText />}
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['10']
              .technologicalRequirements,
          })}
        >
          <FlipperList list={technologicalRequirements}>
            {technologicalRequirements.map((field, index) => {
              const formName =
                `${aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['10'].technologicalRequirements}.${index}` as const

              return (
                <FormObjectTooltipControl
                  key={field.id}
                  name={formName}
                  control={formModifierInstance.control}
                  onDifference={handleUpdateChanges}
                >
                  <TechnologicalRequirementsForm
                    id={field.id}
                    formInstance={formInstance}
                    formModifierInstance={formModifierInstance}
                    title={`Акт №${index + 1}`}
                    name={formName}
                    indexOfRequirement={index}
                    lastIndexOfRequirement={technologicalRequirements.length - 1}
                    onMoveTop={handleChangeElementOrderToUp(index)}
                    onMoveBottom={handleChangeElementOrderToDown(index)}
                    onRemoveObjectStep={
                      index === 0 || !editMode ? undefined : handleRemoveForm(index)
                    }
                  />
                </FormObjectTooltipControl>
              )
            })}
          </FlipperList>
        </SubscribableControl>
        {isFormFieldError(technologicalRequirementsError) && !technologicalRequirements.length && (
          <Row className={'px-1'}>
            <FormError rowClassName={'px-4'} colClassName={'px-2'}>
              {DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}
            </FormError>
          </Row>
        )}
        {editMode && (
          <Row>
            <AsyncWrapper promise={handleAddTechnologicalRequirementsForm}>
              {({ isLoading, wrappedPromise }) => {
                return (
                  <FieldView.ActionButton
                    disabled={isLoading}
                    loaderProps={{
                      loading: isLoading,
                      placement: 'trailing',
                      variant: 'lite',
                    }}
                    onClick={wrappedPromise}
                  >
                    Добавить
                  </FieldView.ActionButton>
                )
              }}
            </AsyncWrapper>
          </Row>
        )}
      </Stack>
    </Container>
  )
}

export default Tenth
