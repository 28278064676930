import { Control, FieldError, FieldErrors, FieldValues, Path } from 'react-hook-form'
import type { RegisterOptions } from 'react-hook-form/dist/types/validator'

import {
  formatAmount,
  getAmountValueFromStr,
  getAmountValueFromStrWithoutRound,
  getStrValueFromNumber,
} from '@components/NewDesign/AmountInput/utils'
import { isSelectOption } from '@components/NewDesign/Select/helpers'
import { objOfDateFormats } from '@constants/dateFormats'
import {
  isEmptyString,
  isFunction,
  isNull,
  isNullOrUndefined,
  isNumber,
  isObject,
  isString,
  isUndefined,
} from '@helpers/checkTypes'
import { getErrorFromControl } from '@helpers/eventHelpers'
import { getObjectValue } from '@helpers/object/getObjectValue'
import dayjsService from '@services/Dayjs/Dayjs.service'
import DayjsService from '@services/Dayjs/Dayjs.service'
import { PropertyTypeEnum } from '@services/Properties/Properties.entity'

import {
  AdditionalValueParams,
  FormModalContextProps,
  FormRules,
  GetDefaultFormModalContextProps,
  GetFormattedValueProps,
  IViolation,
  RulesFormFunctionProps,
} from './types'

const BILLION_NUMBER_WITHOUT_KOPECK = 1000000000

class DocumentFormHelpers {
  static getPreparedBillionValue = (value?: string) => {
    if (!isString(value) || !value.length) return ''

    const billionValue = DocumentFormHelpers.getBillionsFromRubles(value)

    return DocumentFormHelpers.formatSeparatedValue(getStrValueFromNumber(billionValue))
  }

  static formatSeparatedValue(value: string, extended?: boolean) {
    if (extended)
      return value
        .replace(/\s/g, '')
        .replace(/[^0-9]/g, '')
        .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')

    return value.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
  }

  static getFormattedValue({ minority = 100, value, ...restProps }: GetFormattedValueProps) {
    const formattedValue = getAmountValueFromStr(value || '', 100) || 0

    return formatAmount({
      ...restProps,
      value: formattedValue,
      minority,
    })
  }

  static formatBackendMoneyToString(valueInKopecks: number, extended?: boolean) {
    const valueInRubles = valueInKopecks / 100
    return DocumentFormHelpers.formatSeparatedValue(getStrValueFromNumber(valueInRubles), extended)
  }

  static transformValueFromRHF(value) {
    const preparedValue = isString(value) ? value.trim() : value

    if (
      isUndefined(value) ||
      isEmptyString(preparedValue) ||
      (Array.isArray(preparedValue) && !preparedValue.length) ||
      (isObject(preparedValue) && !Object.keys(preparedValue).length)
    )
      return null

    return preparedValue
  }

  static validateValueBeforeSend<T extends FieldValues>(
    control: Control<T>,
    value: string | number | null,
    fieldName: string,
  ) {
    const getJsonVisibility = fieldName.split('.')

    return getErrorFromControl<T>(control, getJsonVisibility) ? null : value
  }

  static parseErrorsFromViolations(violations: IViolation[]): IViolation[] {
    return violations.map((violation) => {
      if (violation.field.includes('[')) {
        const fieldName = violation.field.replace('[', '.').replace(']', '')

        return {
          ...violation,
          field: fieldName,
        }
      }
      return violation
    })
  }

  static validationAdapter<T extends FieldValues>(
    rules: FormRules<T>,
    rulesProps: RulesFormFunctionProps<T>,
  ) {
    if (isFunction(rules)) {
      const rulesWithOnChange = rules(rulesProps)

      return {
        ...rulesWithOnChange,
        onChange: (e) => {
          rulesWithOnChange?.onChange?.(e)

          if (rulesProps.needTrigger) {
            rulesProps.form.trigger(rulesProps.name as Path<T>)
          }
        },
      }
    }

    const objectOfRules = rules as Omit<
      RegisterOptions<T, Path<T>>,
      'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
    >

    return {
      ...objectOfRules,
      onChange: (e) => {
        objectOfRules?.onChange?.(e)

        if (rulesProps.needTrigger) {
          rulesProps.form.trigger(rulesProps.name as Path<T>)
        }
      },
    }
  }

  static transformRHFPathInProperties(originalPath: string): string {
    const parts = originalPath.split('.')
    let transformedPath = ''

    for (let i = 0; i < parts.length; i++) {
      // Добавляем текущую часть пути к результату
      if (i > 0) {
        transformedPath += '.'
      }
      transformedPath += parts[i]

      // Добавляем .value после каждого элемента кроме чисел, первых двух элементов и последнего
      if (i >= 1 && i < parts.length - 1) {
        transformedPath += '.value'
      }
    }

    return transformedPath
  }

  static incrementLastNumberInPath(path: string): string {
    // Регулярное выражение для поиска всех чисел
    const regex = /\d+(?!.*\d)/

    // Находим последнее число в строке
    const lastNumberMatch = path.match(regex)
    if (lastNumberMatch) {
      // Получаем число как строку, затем конвертируем в число и увеличиваем на один
      const lastNumber = lastNumberMatch[0]
      const incrementedNumber = parseInt(lastNumber, 10) + 1

      // Заменяем последнее число на увеличенное значение
      return path.replace(regex, incrementedNumber.toString())
    }

    return path // Возвращаем исходную строку, если числа не найдены
  }

  static transformValueByTypeForSendToApi(
    type: string,
    value: unknown,
    additionalValueParams?: AdditionalValueParams,
  ) {
    if (isEmptyString(value) || isNull(value)) return null

    const preparedValue = isSelectOption(value) ? value.value : value

    if (!type) return preparedValue

    if (type.startsWith(PropertyTypeEnum.CATALOG)) {
      return {
        id: isObject(value) && 'id' in value ? value['id'] : preparedValue,
      }
    }

    if (!isString(preparedValue) || !preparedValue.length) return preparedValue

    const transformedValueByTypeMap = {
      INT: getAmountValueFromStrWithoutRound(
        preparedValue,
        additionalValueParams?.hasNegativeValue,
      ),
      MONEY: (() => {
        if (isNumber(preparedValue)) return preparedValue

        const isValueMayBeNegative = isUndefined(additionalValueParams?.hasNegativeValue)
          ? true
          : additionalValueParams?.hasNegativeValue
        const transformedFromString = getAmountValueFromStrWithoutRound(
          preparedValue,
          isValueMayBeNegative,
        )

        if (!isNumber(transformedFromString)) return transformedFromString

        return Math.round(transformedFromString * 100)
      })(),
      DOUBLE: getAmountValueFromStrWithoutRound(
        preparedValue,
        additionalValueParams?.hasNegativeValue,
      ),
      STRING: preparedValue.trim(),
      DATE: (() => {
        const isYear = preparedValue.trim().length === 4

        const currentDayjsFormat = isYear
          ? objOfDateFormats.yearFormat.yearOnly
          : objOfDateFormats.defaultFormat

        return dayjsService
          .dayjs(preparedValue, currentDayjsFormat)
          .format(objOfDateFormats.defaultNativeDateFormat)
      })(),
    }

    return isNullOrUndefined(transformedValueByTypeMap[type])
      ? preparedValue
      : transformedValueByTypeMap[type]
  }

  static fromYearBackendToYEARCalendar(date: string) {
    return DayjsService.dayjs(date, objOfDateFormats.defaultFormat).startOf('y').format('YYYY')
  }

  static isBlockHaveError<T extends FieldValues>(
    idOfBlock: string,
    blockValues: Record<string, any>,
    errorsFromRHFInstance: FieldErrors<T>,
  ): boolean {
    if (!blockValues[idOfBlock]) {
      console.error(`value by ${idOfBlock} not found in blockValues`)

      return true
    }

    const values: string[] = Object.values(blockValues[idOfBlock])

    return values.some((item) => {
      return isString(item)
        ? idOfBlock in errorsFromRHFInstance
        : !!getObjectValue(errorsFromRHFInstance, item)
    })
  }

  static fromViolationsMessagesToSingleMessage(violations: string[]) {
    if (violations.length === 1) return violations[0]

    return violations.reduce((previousValue, currentValue, currentIndex) => {
      const nextValue = `${currentIndex + 1}. ${currentValue}`

      return `${previousValue}\n${nextValue}`.trim()
    }, '')
  }

  static getDictionaryNameByCatalogType(catalogType: string) {
    const regex = /CATALOG_VALUE_(.*)/
    const match = catalogType.match(regex)

    if (!match?.[1]) return null

    return match[1].toLowerCase()
  }

  static fromRubToBillion(rubles: number) {
    return rubles / BILLION_NUMBER_WITHOUT_KOPECK
  }

  static fromBillionToRub(billions: number) {
    return billions * BILLION_NUMBER_WITHOUT_KOPECK
  }

  static getRublesFromBillion(billions: number | string) {
    if (!billions || (isString(billions) && !billions.length)) return 0

    if (isNumber(billions)) return DocumentFormHelpers.fromBillionToRub(billions)

    const transformedFromString = getAmountValueFromStrWithoutRound(billions, true)

    if (!isNumber(transformedFromString)) return 0

    return DocumentFormHelpers.fromBillionToRub(transformedFromString)
  }

  static getBillionsFromRubles(rubles: number | string) {
    if (!rubles || (isString(rubles) && !rubles.length)) return 0

    if (isNumber(rubles)) return DocumentFormHelpers.fromRubToBillion(rubles)

    const transformedFromString = getAmountValueFromStrWithoutRound(rubles, true)

    if (!isNumber(transformedFromString)) return 0

    return DocumentFormHelpers.fromRubToBillion(transformedFromString)
  }

  static isFormFieldError(value: unknown): value is FieldError {
    return isObject(value) && 'ref' in value && 'message' in value
  }

  static getDefaultFormModalContextValue<T extends FieldValues>(
    props?: GetDefaultFormModalContextProps,
  ) {
    const {
      blockViewIsValidating = false,
      editMode = true,
      formIsLoading = false,
      lastUpdateDraftTime = '',
    } = props || {}

    return {
      state: {
        formIsLoading,
        lastUpdateDraftTime,
        blockViewIsValidating,
        editMode,
      },
      handlers: {},
      actions: {},
      preparedProps: {} as FormModalContextProps<T>['preparedProps'],
    }
  }

  static getYearOfDateFromPropertyDateValue(value?: string | null, defaultValue = '') {
    if (!value) return defaultValue

    return DocumentFormHelpers.fromYearBackendToYEARCalendar(value) || defaultValue
  }

  static getDeviationText(deviationValue?: string, totalDeviationValue?: string) {
    const defaultDeviationText = 'Расхождение: 0 (0%)'

    if (!deviationValue || !totalDeviationValue) return defaultDeviationText

    const preparedDeviationValue = getAmountValueFromStrWithoutRound(deviationValue) || 0
    const preparedTotalDeviationValue = getAmountValueFromStrWithoutRound(totalDeviationValue) || 0

    if (!preparedDeviationValue || !preparedTotalDeviationValue) return defaultDeviationText

    const deviationResult = preparedDeviationValue - preparedTotalDeviationValue
    const deviationPercentResult =
      (preparedDeviationValue / preparedTotalDeviationValue) * 100 - 100

    const deviation = Math.round(deviationResult * 100) / 100
    const deviationPercent = `${Math.round(deviationPercentResult)}%`

    return `Расхождение: ${deviation} (${deviationPercent})`
  }
}

export { DocumentFormHelpers }
