import { useCallback } from 'react'
import { FieldErrors, UnpackNestedValue } from 'react-hook-form'

import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import { generateMenuListForAAgreementOnChangingCharacteristicsOfObjects } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/adapters/Menu.adapter'
import { generateFormModifierValuesForAAgreementOnChangingCharacteristicsOfObjectsForm } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/adapters/Modifier.adapter'
import { generateOverridePropsForAAgreementOnChangingCharacteristicsOfObjects } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/adapters/OverrideProps.adapter'
import { generatePrefetchedPropsForAAgreementOnChangingCharacteristicsOfObjects } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/adapters/PrefetchedProps.adapter'
import { generatePropertiesObjectForAAgreementOnChangingCharacteristicsOfObjectsForm } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForAAgreementOnChangingCharacteristicsOfObjectsForm } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/adapters/RHF.adapter'
import {
  AAgreementOnChangingCharacteristicsOfObjectsFormModifierValues,
  AAgreementOnChangingCharacteristicsOfObjectsFormValues,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/types'
import { IOverridePropsFromClass } from '@services/Properties/Properties.entity'

const useAAgreementOnChangingCharacteristicsOfObjectsAdapters = () => {
  const generateRHFObject = useCallback(
    (
      objectFromPropertyState: Record<string, any>,
    ): AAgreementOnChangingCharacteristicsOfObjectsFormValues =>
      generateRHFObjectForAAgreementOnChangingCharacteristicsOfObjectsForm(objectFromPropertyState),
    [],
  )

  const generatePropertiesObject = useCallback(
    (objectFromPropertyState: Record<string, any>): Record<string, unknown> =>
      generatePropertiesObjectForAAgreementOnChangingCharacteristicsOfObjectsForm(
        objectFromPropertyState,
      ),
    [],
  )

  const generateMenuListByFormValues = useCallback(
    (
      formValues:
        | AAgreementOnChangingCharacteristicsOfObjectsFormValues
        | UnpackNestedValue<AAgreementOnChangingCharacteristicsOfObjectsFormValues>,
      initialHashMap: NestedMenuHashMap,
      formErrors: FieldErrors<AAgreementOnChangingCharacteristicsOfObjectsFormValues>,
    ): NestedMapOfMenu[] =>
      generateMenuListForAAgreementOnChangingCharacteristicsOfObjects(
        formValues,
        initialHashMap,
        formErrors,
      ),
    [],
  )

  const generateOverrideProps = useCallback(
    (objectFromPropertyState: Record<string, any>): IOverridePropsFromClass[] =>
      generateOverridePropsForAAgreementOnChangingCharacteristicsOfObjects(objectFromPropertyState),
    [],
  )

  const generatePrefetchedPropsArray = useCallback(
    (objectFromPropertyState: Record<string, any>): string[] =>
      generatePrefetchedPropsForAAgreementOnChangingCharacteristicsOfObjects(
        objectFromPropertyState,
      ),
    [],
  )

  const generateFormModifierValues = useCallback(
    (
      objectFromPropertyState: Record<string, any>,
    ): AAgreementOnChangingCharacteristicsOfObjectsFormModifierValues =>
      generateFormModifierValuesForAAgreementOnChangingCharacteristicsOfObjectsForm(
        objectFromPropertyState,
      ),
    [],
  )

  return {
    generateRHFObject,
    generatePropertiesObject,
    generateOverrideProps,
    generateMenuListByFormValues,
    generatePrefetchedPropsArray,
    generateFormModifierValues,
  }
}

export { useAAgreementOnChangingCharacteristicsOfObjectsAdapters }
