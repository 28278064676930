import { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { GroupWithPathNameProps } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Forms/3/types'
import { useDataOnFulfillmentOfTermsSZPKManager } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Manager'
import { DataOnFulfillmentOfTermsSZPKFormValues } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/types'
import { DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/watcher'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'

const SzpkReportInfoCardItemHeader: FC<GroupWithPathNameProps> = ({ formName }) => {
  const formInstance = useFormContext<DataOnFulfillmentOfTermsSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    preparedProps: { subscribableControl },
  } = useDataOnFulfillmentOfTermsSZPKManager()

  const { getControllerProps, getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    editMode,
    blockViewIsValidating,
    subscribableControl,
    watcher: DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher,
  })

  return (
    <>
      <Row>
        <Col xs={12}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.investorFullName`,
            })}
          >
            <ControlledDocumentDataView
              suptitle={'Наименование организации, реализующей проект'}
              controllerProps={getControllerProps({
                name: `${formName}.investorFullName`,
              })}
            />
          </SubscribableControl>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${formName}.projectName`,
            })}
          >
            <ControlledDocumentDataView
              suptitle={'Наименование проекта'}
              controllerProps={getControllerProps({
                name: `${formName}.projectName`,
              })}
            />
          </SubscribableControl>
        </Col>
      </Row>
    </>
  )
}

export default SzpkReportInfoCardItemHeader
