import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValues } from '@components/Forms/NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForm/types'

const { getDefaultUOFieldState, getDefaultInfoAboutDocument } = FieldStateHelpers

const mapOfNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAMenu = [
  { id: '1', title: 'Сведения о документе' },
  { id: '2', title: 'Уполномоченная организация' },
  { id: '3', title: 'Организация, реализующая проект' },
  { id: '4', title: 'Выявленные нарушения' },
]

const defaultNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValues: NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValues =
  {
    '1': getDefaultInfoAboutDocument(),
    '2': getDefaultUOFieldState(),
    '3': {
      investorFullName: '',
      investorFullNameInstrumental: '',
      investorInn: '',
      investorOgrn: '',
      investorAddress: '',
    },
    '4': {
      ruleLinkSolicitationRelatedContractChecking: '',
      ruleLinkSolicitationRelatedContractReturn: '',
      solicitationRelatedContractTechError: '',
      solicitationRelatedContractDocumentError: '',
      solicitationRelatedContractRequirementError: '',
      solicitationRelatedContractRequirementLinkError: '',
      solicitationRelatedContractContractKindError: '',
      solicitationRelatedContractRuleError: '',
      solicitationRelatedContractRuleLinkError: '',
      solicitationRelatedContractRelatedContractError: '',
      solicitationRelatedContractCreditContractError: '',
      solicitationRelatedContractOtherError: '',
      solicitationDaysRevision: '',
    },
  }

const notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues = {
  '1': {
    documentDateSign: '1.documentDateSign',
    documentNumber: '1.documentNumber',
    documentReceivers: '1.documentReceivers',
  },
  '2': {
    uoFullName: '2.uoFullName',
    uoHeaderName: '2.uoHeaderName',
    uoHeaderNameGenitive: '2.uoHeaderNameGenitive',
    uoHeaderPosition: '2.uoHeaderPosition',
    uoHeaderPositionGenitive: '2.uoHeaderPositionGenitive',
    uoHeaderReasonGenitive: '2.uoHeaderReasonGenitive',
  },
  '3': {
    investorFullName: '3.investorFullName',
    investorFullNameInstrumental: '3.investorFullNameInstrumental',
    investorInn: '3.investorInn',
    investorOgrn: '3.investorOgrn',
    investorAddress: '3.investorAddress',
  },
  '4': {
    ruleLinkSolicitationRelatedContractChecking: '4.ruleLinkSolicitationRelatedContractChecking',
    ruleLinkSolicitationRelatedContractReturn: '4.ruleLinkSolicitationRelatedContractReturn',
    solicitationRelatedContractTechError: '4.solicitationRelatedContractTechError',
    solicitationRelatedContractDocumentError: '4.solicitationRelatedContractDocumentError',
    solicitationRelatedContractRequirementError: '4.solicitationRelatedContractRequirementError',
    solicitationRelatedContractRequirementLinkError:
      '4.solicitationRelatedContractRequirementLinkError',
    solicitationRelatedContractContractKindError: '4.solicitationRelatedContractContractKindError',
    solicitationRelatedContractRuleError: '4.solicitationRelatedContractRuleError',
    solicitationRelatedContractRuleLinkError: '4.solicitationRelatedContractRuleLinkError',
    solicitationRelatedContractRelatedContractError:
      '4.solicitationRelatedContractRelatedContractError',
    solicitationRelatedContractCreditContractError:
      '4.solicitationRelatedContractCreditContractError',
    solicitationRelatedContractOtherError: '4.solicitationRelatedContractOtherError',
    solicitationDaysRevision: '4.solicitationDaysRevision',
  },
} as const

export {
  defaultNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValues,
  mapOfNotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAMenu,
  notificationOfIdentifiedViolationsDuringContractRecognizeConcludedABlockValues,
}
