import React, { FC, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'

import { DocumentDataViewProps } from '@components/DocumentFormComponents/DocumentDataView/model'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import Typography from '@components/NewDesign/Typography'
import { isNumber, isUndefined } from '@helpers/checkTypes'
import { useHover } from '@hooks/new/events/useHover'
import cn from 'classnames'

import styles from './DocumentDataView.module.scss'

const TRANSITION_TIMEOUT = 100

const transitionLabelAddonsClassNames = {
  enter: styles['transition__fade-enter'],
  exit: styles['transition__fade-exit'],
  enterActive: styles['transition__fade-enter--active'],
  exitActive: styles['transition__fade-exit--active'],
}

const DocumentDataView: FC<DocumentDataViewProps> = ({
  disableContainerPaddingLeft = false,
  disableContainerPaddingRight = false,
  disableContainerPaddingTop = false,
  disableContainerPaddingBottom = false,
  hideSuptitle = false,
  suptitle,
  contentTypographyProps,
  contentPosition = 'left',
  suptitleTypographyProps,
  formIconTooltipProps,
  fallbackValue = '-',
  children,
}) => {
  const { className, leftAddons, rightAddons, ...restSuptitleTypographyProps } =
    suptitleTypographyProps || {}
  const {
    isShowTooltipForFallbackValue = true,
    iconProps,
    iconPosition = 'left',
    tooltipProps,
    tooltipContent,
  } = formIconTooltipProps || {}
  const { iconClassName, ...restIconProps } = iconProps || {}
  const { targetClassName, ...restTooltipProps } = tooltipProps || {}

  const suptitleLeftAddonNodeRef = useRef<HTMLDivElement | null>(null)
  const suptitleRightAddonNodeRef = useRef<HTMLDivElement | null>(null)

  const [hoverRef, isHovered] = useHover<HTMLDivElement>()

  const isShowFormIconTooltip =
    !isUndefined(formIconTooltipProps) && (!!children || isShowTooltipForFallbackValue)
  const isIconRight = iconPosition === 'right'

  const suptitleLeftAddonsRenderCondition = !isUndefined(leftAddons) && isHovered
  const suptitleRightAddonsRenderCondition = !isUndefined(rightAddons) && isHovered

  return (
    <div
      ref={hoverRef}
      className={cn(styles.documentDataView__container, {
        [styles['documentDataView__container--disablePaddingLeft']]: disableContainerPaddingLeft,
        [styles['documentDataView__container--disablePaddingRight']]: disableContainerPaddingRight,
        [styles['documentDataView__container--disablePaddingTop']]: disableContainerPaddingTop,
        [styles['documentDataView__container--disablePaddingBottom']]:
          disableContainerPaddingBottom,
        [styles['documentDataView__container--withSuptitleAddons']]:
          suptitleLeftAddonsRenderCondition || suptitleRightAddonsRenderCondition,
      })}
    >
      {!hideSuptitle && (
        <div className={styles['documentDataView__suptitle-container']}>
          <CSSTransition
            mountOnEnter
            unmountOnExit
            in={suptitleLeftAddonsRenderCondition}
            timeout={TRANSITION_TIMEOUT}
            nodeRef={suptitleLeftAddonNodeRef}
            classNames={transitionLabelAddonsClassNames}
          >
            <div
              ref={suptitleLeftAddonNodeRef}
              className={styles['documentDataView__suptitle-addon']}
            >
              {leftAddons}
            </div>
          </CSSTransition>
          <div className={styles['documentDataView__suptitle-wrapper']}>
            <Typography.Body
              {...restSuptitleTypographyProps}
              align={contentPosition === 'left' ? 'left' : 'right'}
              className={cn(styles['documentDataView__suptitle'], className)}
              variant={'bodySMedium'}
              color={'text-base-tertiary'}
            >
              {suptitle}
            </Typography.Body>
          </div>
          <CSSTransition
            mountOnEnter
            unmountOnExit
            in={suptitleRightAddonsRenderCondition}
            timeout={TRANSITION_TIMEOUT}
            nodeRef={suptitleRightAddonNodeRef}
            classNames={transitionLabelAddonsClassNames}
          >
            <div
              ref={suptitleRightAddonNodeRef}
              className={styles['documentDataView__suptitle-addon']}
            >
              {rightAddons}
            </div>
          </CSSTransition>
        </div>
      )}
      <div
        className={cn(styles.documentDataView__wrapper, {
          [styles['documentDataView__wrapper--contentRight']]: contentPosition === 'right',
          [styles['documentDataView__wrapper--iconRight']]: isIconRight,
          [styles['documentDataView__wrapper--withHidedSuptitle']]: hideSuptitle,
        })}
      >
        {isShowFormIconTooltip && (
          <FormIconWithTooltip
            tooltipContent={tooltipContent}
            tooltipProps={{
              ...restTooltipProps,
              targetClassName: cn(styles['documentDataView__icon-wrapper'], targetClassName),
            }}
            iconProps={{
              ...restIconProps,
              className: cn(styles.documentDataView__icon, iconClassName as string | undefined, {
                [styles['documentDataView__icon--right']]: isIconRight,
              }),
            }}
          />
        )}
        <Typography.Body
          {...contentTypographyProps}
          className={cn(styles.documentDataView, contentTypographyProps)}
          variant={'bodyMMedium'}
        >
          {!!children || isNumber(children) ? children : fallbackValue}
        </Typography.Body>
      </div>
    </div>
  )
}

export default DocumentDataView
