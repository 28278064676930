import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { NotificationFillingAppConcludingFormValues } from '@components/Forms/NotificationFillingAppConcludingForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isNotEmptyString, isString } from '@helpers/checkTypes'

const EXTRA_DAYS_REVISION_LIMIT = 100

const fourthSectionValidationMap: FormValuesValidationSection<
  keyof NotificationFillingAppConcludingFormValues['4'],
  NotificationFillingAppConcludingFormValues
> = {
  ruleLinkExtraError: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  extraTechError: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 500) : undefined),
  },
  extraSetDocumentError: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 500) : undefined),
  },
  extraRuleLinkError: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 500) : undefined),
  },
  extraDaysRevision: {
    validate: (value) => {
      if (!isString(value)) return

      return (
        Number(value) <= EXTRA_DAYS_REVISION_LIMIT ||
        `значение не должно превышать ${EXTRA_DAYS_REVISION_LIMIT}`
      )
    },
  },
  extraOtherError: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 500) : undefined),
  },
}

export { fourthSectionValidationMap }
