import React, { FC } from 'react'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import Border from '@components/DocumentFormComponents/FieldView/Border'
import styles from '@components/DocumentFormComponents/FormStyles/FormController.module.scss'
import mainTemplateStyles from '@components/DocumentFormComponents/FormStyles/MainTemplate.module.scss'
import ConstructionObject from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Forms/5/AdditionalForms/MainTemplate/ConstructionObject'
import { ConstructionStagesArrayPathName } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Forms/5/types'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'

interface FormControllerProps {
  isNew: boolean
  isConstructionRender: boolean
  parentId: string
  indexOfMainBlock: number
  name: ConstructionStagesArrayPathName
}

const FormController: FC<FormControllerProps> = ({
  isNew,
  parentId,
  indexOfMainBlock,
  name,
  isConstructionRender,
}) => {
  return (
    <div className={styles.templateController}>
      <Col xs={12}>
        <CollapseWrapper
          defaultExpanded={isConstructionRender}
          isExpanded={isConstructionRender}
        >
          <Row className={styles.templateController__construction}>
            <Row>
              <Border className={mainTemplateStyles.mainTemplate__border} />
            </Row>
            <Row className={mainTemplateStyles.mainTemplate__container}>
              <ConstructionObject
                isNew={isNew}
                parentId={parentId}
                indexOfMainBlock={indexOfMainBlock}
                name={name}
              />
            </Row>
          </Row>
        </CollapseWrapper>
      </Col>
    </div>
  )
}

export default FormController
