import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { NotificationFillingAppConcludingFormValues } from '@components/Forms/NotificationFillingAppConcludingForm/types'

const generateRHFObjectForNotificationFillingAppConcludingForm = (
  objectFromPropertyState: Record<string, any>,
): NotificationFillingAppConcludingFormValues => {
  return {
    '1': {
      ...RHFBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
    },
    '2': {
      ...RHFBasicAdapters.getUOAdapter(objectFromPropertyState),
    },
    '3': {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      investorFullNameGenitive: objectFromPropertyState.investorFullNameGenitive?.value || '',
    },
    '4': {
      projectName: objectFromPropertyState.projectName.value || '',
      extraTechError: objectFromPropertyState.extraTechError.value || '',
      extraSetDocumentError: objectFromPropertyState.extraSetDocumentError.value || '',
      extraRuleLinkError: objectFromPropertyState.extraRuleLinkError.value || '',
      ruleLinkExtraError: objectFromPropertyState.ruleLinkExtraError.value || '',
      extraOtherError: objectFromPropertyState.extraOtherError.value || '',
      extraDaysRevision: objectFromPropertyState.extraDaysRevision.value || '',
    },
  } as NotificationFillingAppConcludingFormValues
}

export { generateRHFObjectForNotificationFillingAppConcludingForm }
