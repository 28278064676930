import React, { FC, ReactNode } from 'react'

import CollapseWrapper from '@components/DocumentFormComponents/CollapseWrapper'
import Button from '@components/NewDesign/Button'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import { useBooleanState } from '@hooks/useBooleanState'
import chevronRightIcon from '@icons/navigation/chevron_right.svg'
import cn from 'classnames'

import styles from './RegionReportInfoCollapse.module.scss'

interface SummaryReportOfMonitoringResultsRegionReportInfoCollapseProps {
  indexOfReport: number
  children: ReactNode
}

const SummaryReportOfMonitoringResultsRegionReportInfoCollapse: FC<
  SummaryReportOfMonitoringResultsRegionReportInfoCollapseProps
> = ({ children, indexOfReport }) => {
  const { booleanState: isExpanded, reverseBooleanState: toggleShowForm } = useBooleanState()

  return (
    <>
      <Row>
        <Col>
          <Button
            fixWidth
            view="plain"
            size="s"
            variant="buttonSMedium"
            color="default"
            className={styles['regionReportInfo-button']}
            trailingIcon={{
              src: chevronRightIcon,
              className: cn(styles['regionReportInfo-button__icon'], {
                [styles['regionReportInfo-button__icon--active']]: isExpanded,
              }),
            }}
            onClick={toggleShowForm}
          >
            Региональный отчет №{indexOfReport + 1}
          </Button>
        </Col>
      </Row>
      <CollapseWrapper isExpanded={isExpanded}>
        {children}
      </CollapseWrapper>
    </>
  )
}

export default SummaryReportOfMonitoringResultsRegionReportInfoCollapse
