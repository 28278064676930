import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { DataOnFulfillmentOfTermsSZPKFormValues } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/types'

const { getDefaultInitiatorFieldState } = FieldStateHelpers

const defaultDataOnFulfillmentOfTermsSZPKFormValues: DataOnFulfillmentOfTermsSZPKFormValues = {
  '1': {
    documentDateSign: '',
    documentNumber: '',
    documentReceivers: '',
  },
  '2': {
    ...getDefaultInitiatorFieldState(),
  },
  '3': {
    signedSzpkReportsSubtitle: '',

    szpkCountRegionalReportPlan: '',
    szpkCountRegionalReportFact: '',

    federalSzpkCountRegionalReportPlan: '',
    federalSzpkCountRegionalReportPlanCorrect: '',
    federalSzpkCountRegionalReportFact: '',
    federalSzpkCountRegionalReportFactCorrect: '',

    regionalSzpkCountRegionalReportPlan: '',
    regionalSzpkCountRegionalReportPlanCorrect: '',
    regionalSzpkCountRegionalReportFact: '',
    regionalSzpkCountRegionalReportFactCorrect: '',
  },
  '4': {
    szpkPeriodCountRegionalReportPlan: '',
    szpkPeriodCountRegionalReportFact: '',

    federalSzpkPeriodCountRegionalReportPlan: '',
    federalSzpkPeriodCountRegionalReportPlanCorrect: '',
    federalSzpkPeriodCountRegionalReportFact: '',
    federalSzpkPeriodCountRegionalReportFactCorrect: '',

    regionalSzpkPeriodCountRegionalReportPlan: '',
    regionalSzpkPeriodCountRegionalReportPlanCorrect: '',
    regionalSzpkPeriodCountRegionalReportFact: '',
    regionalSzpkPeriodCountRegionalReportFactCorrect: '',
  },
  '5': {
    totalCapitalRegionalReportPlan: '',
    totalCapitalRegionalReportFact: '',

    federalTotalCapitalRegionalReportPlan: '',
    federalTotalCapitalRegionalReportPlanChecker: '',
    federalTotalCapitalRegionalReportPlanCorrect: '',
    federalTotalCapitalRegionalReportFact: '',
    federalTotalCapitalRegionalReportFactChecker: '',
    federalTotalCapitalRegionalReportFactCorrect: '',

    regionalTotalCapitalRegionalReportPlan: '',
    regionalTotalCapitalRegionalReportPlanChecker: '',
    regionalTotalCapitalRegionalReportPlanCorrect: '',
    regionalTotalCapitalRegionalReportFact: '',
    regionalTotalCapitalRegionalReportFactChecker: '',
    regionalTotalCapitalRegionalReportFactCorrect: '',
  },
  '6': {
    investorCapitalRegionalReportPlan: '',
    investorCapitalRegionalReportFact: '',

    federalInvestorCapitalRegionalReportPlan: '',
    federalInvestorCapitalRegionalReportPlanChecker: '',
    federalInvestorCapitalRegionalReportPlanCorrect: '',
    federalInvestorCapitalRegionalReportFact: '',
    federalInvestorCapitalRegionalReportFactChecker: '',
    federalInvestorCapitalRegionalReportFactCorrect: '',

    regionalInvestorCapitalRegionalReportPlan: '',
    regionalInvestorCapitalRegionalReportPlanChecker: '',
    regionalInvestorCapitalRegionalReportPlanCorrect: '',
    regionalInvestorCapitalRegionalReportFact: '',
    regionalInvestorCapitalRegionalReportFactChecker: '',
    regionalInvestorCapitalRegionalReportFactCorrect: '',
  },
  '7': {
    newWorkPlacesRegionalReportPlan: '',
    newWorkPlacesRegionalReportFact: '',

    federalNewWorkPlacesRegionalReportPlan: '',
    federalNewWorkPlacesRegionalReportPlanChecker: '',
    federalNewWorkPlacesRegionalReportPlanCorrect: '',
    federalNewWorkPlacesRegionalReportFact: '',
    federalNewWorkPlacesRegionalReportFactChecker: '',
    federalNewWorkPlacesRegionalReportFactCorrect: '',

    regionalNewWorkPlacesRegionalReportPlan: '',
    regionalNewWorkPlacesRegionalReportPlanChecker: '',
    regionalNewWorkPlacesRegionalReportPlanCorrect: '',
    regionalNewWorkPlacesRegionalReportFact: '',
    regionalNewWorkPlacesRegionalReportFactChecker: '',
    regionalNewWorkPlacesRegionalReportFactCorrect: '',
  },
  '8': {
    compensationValueRegionalReportPlan: '',
    compensationValueRegionalReportFact: '',

    federalCompensationValueRegionalReportPlan: '',
    federalCompensationValueRegionalReportPlanChecker: '',
    federalCompensationValueRegionalReportPlanCorrect: '',
    federalCompensationValueRegionalReportFact: '',
    federalCompensationValueRegionalReportFactChecker: '',
    federalCompensationValueRegionalReportFactCorrect: '',

    regionalCompensationValueRegionalReportPlan: '',
    regionalCompensationValueRegionalReportPlanChecker: '',
    regionalCompensationValueRegionalReportPlanCorrect: '',
    regionalCompensationValueRegionalReportFact: '',
    regionalCompensationValueRegionalReportFactChecker: '',
    regionalCompensationValueRegionalReportFactCorrect: '',
  },
  '9': {
    economicSpheresRegionalReport: [],
  },
  '10': {
    szpksChangeTerminate: [],
  },
  '11': {
    federalChangeTerminationSzpkCountRegionalReport: '',
    federalChangeTerminationSzpkTotalCapitalRegionalReport: '',
    federalChangeTerminationSzpkInvestorCapitalRegionalReport: '',
    federalChangeTerminationReasonCountRegionalReport: '',

    federalChangeSzpkCountRegionalReport: '',
    federalChangeSzpkTotalCapitalRegionalReport: '',
    federalChangeSzpkInvestorCapitalRegionalReport: '',
    federalChangeReasonCountRegionalReport: '',

    federalTerminationSzpkCountRegionalReport: '',
    federalTerminationSzpkTotalCapitalRegionalReport: '',
    federalTerminationSzpkInvestorCapitalRegionalReport: '',
    federalTerminationReasonCountRegionalReport: '',
  },
  '12': {
    regionalChangeTerminationSzpkCountRegionalReport: '',
    regionalChangeTerminationSzpkTotalCapitalRegionalReport: '',
    regionalChangeTerminationSzpkInvestorCapitalRegionalReport: '',
    regionalChangeTerminationReasonCountRegionalReport: '',

    regionalChangeSzpkCountRegionalReport: '',
    regionalChangeSzpkTotalCapitalRegionalReport: '',
    regionalChangeSzpkInvestorCapitalRegionalReport: '',
    regionalChangeReasonCountRegionalReport: '',

    regionalTerminationSzpkCountRegionalReport: '',
    regionalTerminationSzpkTotalCapitalRegionalReport: '',
    regionalTerminationSzpkInvestorCapitalRegionalReport: '',
    regionalTerminationReasonCountRegionalReport: '',
  },
  additionalForms: {
    federalSzpkReportsInfo: [],
    notFederalSzpkReportsInfo: [],
    federalSzpkReportsInfoWithinMonitoringDates: [],
    notFederalSzpkReportsInfoWithinMonitoringDates: [],
  },
}

const dataOnFulfillmentOfTermsSZPKBlockValues = {
  '1': {
    documentDateSign: '1.documentDateSign',
    documentNumber: '1.documentNumber',
    documentReceivers: '1.documentReceivers',
  },
  '2': {
    initiatorFullName: '2.initiatorFullName',
    initiatorHeaderName: '2.initiatorHeaderName',
    initiatorHeaderPosition: '2.initiatorHeaderPosition',
  },
  '3': {
    signedSzpkReportsSubtitle: '3.signedSzpkReportsSubtitle',

    federalSzpkReportsInfo: '3.federalSzpkReportsInfo',
    notFederalSzpkReportsInfo: '3.notFederalSzpkReportsInfo',

    szpkCountRegionalReportPlan: '3.szpkCountRegionalReportPlan',
    szpkCountRegionalReportFact: '3.szpkCountRegionalReportFact',

    federalSzpkCountRegionalReportPlan: '3.federalSzpkCountRegionalReportPlan',
    federalSzpkCountRegionalReportPlanCorrect: '3.federalSzpkCountRegionalReportPlanCorrect',
    federalSzpkCountRegionalReportFact: '3.federalSzpkCountRegionalReportFact',
    federalSzpkCountRegionalReportFactCorrect: '3.federalSzpkCountRegionalReportFactCorrect',

    regionalSzpkCountRegionalReportPlan: '3.regionalSzpkCountRegionalReportPlan',
    regionalSzpkCountRegionalReportPlanCorrect: '3.regionalSzpkCountRegionalReportPlanCorrect',
    regionalSzpkCountRegionalReportFact: '3.regionalSzpkCountRegionalReportFact',
    regionalSzpkCountRegionalReportFactCorrect: '3.regionalSzpkCountRegionalReportFactCorrect',
  },
  '4': {
    federalSzpkReportsInfoWithinMonitoringDates: '4.federalSzpkReportsInfoWithinMonitoringDates',
    notFederalSzpkReportsInfoWithinMonitoringDates:
      '4.notFederalSzpkReportsInfoWithinMonitoringDates',

    szpkPeriodCountRegionalReportPlan: '4.szpkPeriodCountRegionalReportPlan',
    szpkPeriodCountRegionalReportFact: '4.szpkPeriodCountRegionalReportFact',

    federalSzpkPeriodCountRegionalReportPlan: '4.federalSzpkPeriodCountRegionalReportPlan',
    federalSzpkPeriodCountRegionalReportPlanCorrect:
      '4.federalSzpkPeriodCountRegionalReportPlanCorrect',
    federalSzpkPeriodCountRegionalReportFact: '4.federalSzpkPeriodCountRegionalReportFact',
    federalSzpkPeriodCountRegionalReportFactCorrect:
      '4.federalSzpkPeriodCountRegionalReportFactCorrect',

    regionalSzpkPeriodCountRegionalReportPlan: '4.regionalSzpkPeriodCountRegionalReportPlan',
    regionalSzpkPeriodCountRegionalReportPlanCorrect:
      '4.regionalSzpkPeriodCountRegionalReportPlanCorrect',
    regionalSzpkPeriodCountRegionalReportFact: '4.regionalSzpkPeriodCountRegionalReportFact',
    regionalSzpkPeriodCountRegionalReportFactCorrect:
      '4.regionalSzpkPeriodCountRegionalReportFactCorrect',
  },
  '5': {
    federalSzpkReportsInfo: '5.federalSzpkReportsInfo',
    notFederalSzpkReportsInfo: '5.notFederalSzpkReportsInfo',

    totalCapitalRegionalReportPlan: '5.totalCapitalRegionalReportPlan',
    totalCapitalRegionalReportFact: '5.totalCapitalRegionalReportFact',

    federalTotalCapitalRegionalReportPlan: '5.federalTotalCapitalRegionalReportPlan',
    federalTotalCapitalRegionalReportPlanChecker: '5.federalTotalCapitalRegionalReportPlanChecker',
    federalTotalCapitalRegionalReportPlanCorrect: '5.federalTotalCapitalRegionalReportPlanCorrect',
    federalTotalCapitalRegionalReportFact: '5.federalTotalCapitalRegionalReportFact',
    federalTotalCapitalRegionalReportFactChecker: '5.federalTotalCapitalRegionalReportFactChecker',
    federalTotalCapitalRegionalReportFactCorrect: '5.federalTotalCapitalRegionalReportFactCorrect',

    regionalTotalCapitalRegionalReportPlan: '5.regionalTotalCapitalRegionalReportPlan',
    regionalTotalCapitalRegionalReportPlanChecker:
      '5.regionalTotalCapitalRegionalReportPlanChecker',
    regionalTotalCapitalRegionalReportPlanCorrect:
      '5.regionalTotalCapitalRegionalReportPlanCorrect',
    regionalTotalCapitalRegionalReportFact: '5.regionalTotalCapitalRegionalReportFact',
    regionalTotalCapitalRegionalReportFactChecker:
      '5.regionalTotalCapitalRegionalReportFactChecker',
    regionalTotalCapitalRegionalReportFactCorrect:
      '5.regionalTotalCapitalRegionalReportFactCorrect',
  },
  '6': {
    federalSzpkReportsInfo: '6.federalSzpkReportsInfo',
    notFederalSzpkReportsInfo: '6.notFederalSzpkReportsInfo',

    investorCapitalRegionalReportPlan: '6.investorCapitalRegionalReportPlan',
    investorCapitalRegionalReportFact: '6.investorCapitalRegionalReportFact',

    federalInvestorCapitalRegionalReportPlan: '6.federalInvestorCapitalRegionalReportPlan',
    federalInvestorCapitalRegionalReportPlanChecker:
      '6.federalInvestorCapitalRegionalReportPlanChecker',
    federalInvestorCapitalRegionalReportPlanCorrect:
      '6.federalInvestorCapitalRegionalReportPlanCorrect',
    federalInvestorCapitalRegionalReportFact: '6.federalInvestorCapitalRegionalReportFact',
    federalInvestorCapitalRegionalReportFactChecker:
      '6.federalInvestorCapitalRegionalReportFactChecker',
    federalInvestorCapitalRegionalReportFactCorrect:
      '6.federalInvestorCapitalRegionalReportFactCorrect',

    regionalInvestorCapitalRegionalReportPlan: '6.regionalInvestorCapitalRegionalReportPlan',
    regionalInvestorCapitalRegionalReportPlanChecker:
      '6.regionalInvestorCapitalRegionalReportPlanChecker',
    regionalInvestorCapitalRegionalReportPlanCorrect:
      '6.regionalInvestorCapitalRegionalReportPlanCorrect',
    regionalInvestorCapitalRegionalReportFact: '6.regionalInvestorCapitalRegionalReportFact',
    regionalInvestorCapitalRegionalReportFactChecker:
      '6.regionalInvestorCapitalRegionalReportFactChecker',
    regionalInvestorCapitalRegionalReportFactCorrect:
      '6.regionalInvestorCapitalRegionalReportFactCorrect',
  },
  '7': {
    federalSzpkReportsInfo: '7.federalSzpkReportsInfo',
    notFederalSzpkReportsInfo: '7.notFederalSzpkReportsInfo',

    newWorkPlacesRegionalReportPlan: '7.newWorkPlacesRegionalReportPlan',
    newWorkPlacesRegionalReportFact: '7.newWorkPlacesRegionalReportFact',

    federalNewWorkPlacesRegionalReportPlan: '7.federalNewWorkPlacesRegionalReportPlan',
    federalNewWorkPlacesRegionalReportPlanChecker:
      '7.federalNewWorkPlacesRegionalReportPlanChecker',
    federalNewWorkPlacesRegionalReportPlanCorrect:
      '7.federalNewWorkPlacesRegionalReportPlanCorrect',
    federalNewWorkPlacesRegionalReportFact: '7.federalNewWorkPlacesRegionalReportFact',
    federalNewWorkPlacesRegionalReportFactChecker:
      '7.federalNewWorkPlacesRegionalReportFactChecker',
    federalNewWorkPlacesRegionalReportFactCorrect:
      '7.federalNewWorkPlacesRegionalReportFactCorrect',

    regionalNewWorkPlacesRegionalReportPlan: '7.regionalNewWorkPlacesRegionalReportPlan',
    regionalNewWorkPlacesRegionalReportPlanChecker:
      '7.regionalNewWorkPlacesRegionalReportPlanChecker',
    regionalNewWorkPlacesRegionalReportPlanCorrect:
      '7.regionalNewWorkPlacesRegionalReportPlanCorrect',
    regionalNewWorkPlacesRegionalReportFact: '7.regionalNewWorkPlacesRegionalReportFact',
    regionalNewWorkPlacesRegionalReportFactChecker:
      '7.regionalNewWorkPlacesRegionalReportFactChecker',
    regionalNewWorkPlacesRegionalReportFactCorrect:
      '7.regionalNewWorkPlacesRegionalReportFactCorrect',
  },
  '8': {
    federalSzpkReportsInfo: '8.federalSzpkReportsInfo',
    notFederalSzpkReportsInfo: '8.notFederalSzpkReportsInfo',

    compensationValueRegionalReportPlan: '8.compensationValueRegionalReportPlan',
    compensationValueRegionalReportFact: '8.compensationValueRegionalReportFact',

    federalCompensationValueRegionalReportPlan: '8.federalCompensationValueRegionalReportPlan',
    federalCompensationValueRegionalReportPlanChecker:
      '8.federalCompensationValueRegionalReportPlanChecker',
    federalCompensationValueRegionalReportPlanCorrect:
      '8.federalCompensationValueRegionalReportPlanCorrect',
    federalCompensationValueRegionalReportFact: '8.federalCompensationValueRegionalReportFact',
    federalCompensationValueRegionalReportFactChecker:
      '8.federalCompensationValueRegionalReportFactChecker',
    federalCompensationValueRegionalReportFactCorrect:
      '8.federalCompensationValueRegionalReportFactCorrect',

    regionalCompensationValueRegionalReportPlan: '8.regionalCompensationValueRegionalReportPlan',
    regionalCompensationValueRegionalReportPlanChecker:
      '8.regionalCompensationValueRegionalReportPlanChecker',
    regionalCompensationValueRegionalReportPlanCorrect:
      '8.regionalCompensationValueRegionalReportPlanCorrect',
    regionalCompensationValueRegionalReportFact: '8.regionalCompensationValueRegionalReportFact',
    regionalCompensationValueRegionalReportFactChecker:
      '8.regionalCompensationValueRegionalReportFactChecker',
    regionalCompensationValueRegionalReportFactCorrect:
      '8.regionalCompensationValueRegionalReportFactCorrect',
  },
  '9': {
    economicSpheresRegionalReport: '9.economicSpheresRegionalReport',
  },
  '10': {
    szpksChangeTerminate: '10.szpksChangeTerminate',
  },
  '11': {
    federalChangeTerminationSzpkCountRegionalReport:
      '11.federalChangeTerminationSzpkCountRegionalReport',
    federalChangeTerminationSzpkTotalCapitalRegionalReport:
      '11.federalChangeTerminationSzpkTotalCapitalRegionalReport',
    federalChangeTerminationSzpkInvestorCapitalRegionalReport:
      '11.federalChangeTerminationSzpkInvestorCapitalRegionalReport',
    federalChangeTerminationReasonCountRegionalReport:
      '11.federalChangeTerminationReasonCountRegionalReport',

    federalChangeSzpkCountRegionalReport: '11.federalChangeSzpkCountRegionalReport',
    federalChangeSzpkTotalCapitalRegionalReport: '11.federalChangeSzpkTotalCapitalRegionalReport',
    federalChangeSzpkInvestorCapitalRegionalReport:
      '11.federalChangeSzpkInvestorCapitalRegionalReport',
    federalChangeReasonCountRegionalReport: '11.federalChangeReasonCountRegionalReport',

    federalTerminationSzpkCountRegionalReport: '11.federalTerminationSzpkCountRegionalReport',
    federalTerminationSzpkTotalCapitalRegionalReport:
      '11.federalTerminationSzpkTotalCapitalRegionalReport',
    federalTerminationSzpkInvestorCapitalRegionalReport:
      '11.federalTerminationSzpkInvestorCapitalRegionalReport',
    federalTerminationReasonCountRegionalReport: '11.federalTerminationReasonCountRegionalReport',
  },
  '12': {
    regionalChangeTerminationSzpkCountRegionalReport:
      '12.regionalChangeTerminationSzpkCountRegionalReport',
    regionalChangeTerminationSzpkTotalCapitalRegionalReport:
      '12.regionalChangeTerminationSzpkTotalCapitalRegionalReport',
    regionalChangeTerminationSzpkInvestorCapitalRegionalReport:
      '12.regionalChangeTerminationSzpkInvestorCapitalRegionalReport',
    regionalChangeTerminationReasonCountRegionalReport:
      '12.regionalChangeTerminationReasonCountRegionalReport',

    regionalChangeSzpkCountRegionalReport: '12.regionalChangeSzpkCountRegionalReport',
    regionalChangeSzpkTotalCapitalRegionalReport: '12.regionalChangeSzpkTotalCapitalRegionalReport',
    regionalChangeSzpkInvestorCapitalRegionalReport:
      '12.regionalChangeSzpkInvestorCapitalRegionalReport',
    regionalChangeReasonCountRegionalReport: '12.regionalChangeReasonCountRegionalReport',

    regionalTerminationSzpkCountRegionalReport: '12.regionalTerminationSzpkCountRegionalReport',
    regionalTerminationSzpkTotalCapitalRegionalReport:
      '12.regionalTerminationSzpkTotalCapitalRegionalReport',
    regionalTerminationSzpkInvestorCapitalRegionalReport:
      '12.regionalTerminationSzpkInvestorCapitalRegionalReport',
    regionalTerminationReasonCountRegionalReport: '12.regionalTerminationReasonCountRegionalReport',
  },
  additionalForms: {
    federalSzpkReportsInfo: 'additionalForms.federalSzpkReportsInfo',
    notFederalSzpkReportsInfo: 'additionalForms.notFederalSzpkReportsInfo',
    federalSzpkReportsInfoWithinMonitoringDates:
      'additionalForms.federalSzpkReportsInfoWithinMonitoringDates',
    notFederalSzpkReportsInfoWithinMonitoringDates:
      'additionalForms.notFederalSzpkReportsInfoWithinMonitoringDates',
  },
} as const

export { dataOnFulfillmentOfTermsSZPKBlockValues, defaultDataOnFulfillmentOfTermsSZPKFormValues }
