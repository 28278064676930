import { useCallback } from 'react'
import { FieldErrors, UnpackNestedValue } from 'react-hook-form'

import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import { generateMenuListForAAgreementOnCreationOtherEstateObjects } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/adapters/Menu.adapter'
import { generateFormModifierValuesForAAgreementOnCreationOtherEstateObjectsForm } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/adapters/Modifier.adapter'
import { generateOverridePropsForAAgreementOnCreationOtherEstateObjects } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/adapters/OverrideProps.adapter'
import { generatePropertiesObjectForAAgreementOnCreationOtherEstateObjectsForm } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/adapters/PropertiesObject.adapter'
import { generateRHFObjectForAAgreementOnCreationOtherEstateObjectsForm } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/adapters/RHF.adapter'
import {
  AAgreementOnCreationOtherEstateObjectsFormModifierValues,
  AAgreementOnCreationOtherEstateObjectsFormValues,
} from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'
import { IOverridePropsFromClass } from '@services/Properties/Properties.entity'

const useAAgreementOnCreationOtherEstateObjectsAdapters = () => {
  const generateRHFObject = useCallback(
    (
      objectFromPropertyState: Record<string, any>,
    ): AAgreementOnCreationOtherEstateObjectsFormValues =>
      generateRHFObjectForAAgreementOnCreationOtherEstateObjectsForm(objectFromPropertyState),
    [],
  )

  const generatePropertiesObject = useCallback(
    (objectFromPropertyState: Record<string, any>): Record<string, unknown> =>
      generatePropertiesObjectForAAgreementOnCreationOtherEstateObjectsForm(
        objectFromPropertyState,
      ),
    [],
  )

  const generateMenuListByFormValues = useCallback(
    (
      formValues:
        | AAgreementOnCreationOtherEstateObjectsFormValues
        | UnpackNestedValue<AAgreementOnCreationOtherEstateObjectsFormValues>,
      initialHashMap: NestedMenuHashMap,
      formErrors: FieldErrors<AAgreementOnCreationOtherEstateObjectsFormValues>,
    ): NestedMapOfMenu[] =>
      generateMenuListForAAgreementOnCreationOtherEstateObjects(
        formValues,
        initialHashMap,
        formErrors,
      ),
    [],
  )

  const generateOverrideProps = useCallback(
    (objectFromPropertyState: Record<string, any>): IOverridePropsFromClass[] =>
      generateOverridePropsForAAgreementOnCreationOtherEstateObjects(objectFromPropertyState),
    [],
  )

  const generateFormModifierValues = useCallback(
    (
      objectFromPropertyState: Record<string, any>,
    ): AAgreementOnCreationOtherEstateObjectsFormModifierValues =>
      generateFormModifierValuesForAAgreementOnCreationOtherEstateObjectsForm(
        objectFromPropertyState,
      ),
    [],
  )

  return {
    generateRHFObject,
    generatePropertiesObject,
    generateOverrideProps,
    generateMenuListByFormValues,
    generateFormModifierValues,
  }
}

export { useAAgreementOnCreationOtherEstateObjectsAdapters }
