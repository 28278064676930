import React, { FC } from 'react'
import { useFormContext, UseFormReturn, useWatch } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import FormObjectTooltipControl from '@components/DocumentFormComponents/FormControls/FormObjectTooltipControl'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementOnChangingCharacteristicsOfObjectsBlockValues } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/const'
import ExploitationItem from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Forms/5/AdditionalForms/MainTemplate/ConstructionObject/Forms/CExploitationForm/ExploitationItem'
import {
  ObjectExploitationObjectsPathName,
  ObjectExploitationPathName,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Forms/5/types'
import { useAAgreementOnChangingCharacteristicsOfObjectsManager } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Manager'
import {
  AAgreementOnChangingCharacteristicsOfObjectsFormModifierValues,
  AAgreementOnChangingCharacteristicsOfObjectsFormValues,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/types'
import {
  AAgreementOnChangingCharacteristicsOfObjectsFieldArrayControlUpdateWatcher,
  AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/watcher'
import Button from '@components/NewDesign/Button'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { objOfDateFormats } from '@constants/dateFormats'
import CircleAddIcon from '@icons/CircleAddIcon.svg'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import dayjs from 'dayjs'

import { eExploitationAAgreementOnChangingCharacteristicsOfObjectsValidationMap } from './validation'

interface CExploitationFormProps {
  isNew: boolean
  indexOfBlock: number
  name: ObjectExploitationPathName
  formModifierInstance: UseFormReturn<AAgreementOnChangingCharacteristicsOfObjectsFormModifierValues>
}

const CExploitationForm: FC<CExploitationFormProps> = ({
  isNew,
  indexOfBlock,
  name,
  formModifierInstance,
}) => {
  const formInstance = useFormContext<AAgreementOnChangingCharacteristicsOfObjectsFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: {
      handleChangeValue,
      debouncedHandleChangeValue,
      handleAddItemToListWithOutValue,
      handleRemoveItemFromList,
      handleUpElementInList,
      handleDownElementInList,
    },
    preparedProps: { subscribableControl },
  } = useAAgreementOnChangingCharacteristicsOfObjectsManager()

  const { fields: exploitationForms } = useFieldArraySubscribableControl<
    AAgreementOnChangingCharacteristicsOfObjectsFormValues,
    ObjectExploitationObjectsPathName,
    'keyNameId'
  >({
    name: `${name}.exploitationObjects`,
    control: formInstance.control,
    keyName: 'keyNameId',
    watcher: AAgreementOnChangingCharacteristicsOfObjectsFieldArrayControlUpdateWatcher,
  })

  const { getSubscribableControlProps, getCalendarInputProps, getFormFieldControlProps } =
    useFormComponentPresets({
      isNew,
      editMode,
      blockViewIsValidating,
      formInstance,
      subscribableControl,
      watcher: AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher,
    })

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const stepEndDate = useWatch({
    name: `${aAgreementOnChangingCharacteristicsOfObjectsBlockValues['5'].constructionStages}.${indexOfBlock}.stepEndDate`,
    control: formInstance.control,
  })

  const handleAddExploitation = async () => {
    await handleAddItemToListWithOutValue?.(`${name}.exploitationObjects`)
  }

  const handleRemoveExploitation = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${name}.exploitationObjects.${index}`,
      `${name}.exploitationObjects`,
    )
  }

  const handleChangeElementOrderToUp = (index: number) => async () => {
    await handleUpElementInList?.(`${name}.exploitationObjects.${index}`)
  }

  const handleChangeElementOrderToDown = (index: number) => async () => {
    await handleDownElementInList?.(`${name}.exploitationObjects.${index}`)
  }

  if (!formInstance) return null

  return (
    <Container className="pb-0 pt-2 px-0">
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <RowWithBorder
              disableBottomDefaultStyles
              titleHeightFallback={'40px'}
              title={'Ввод в эксплуатацию объектов недвижимости планируется осуществить до'}
            >
              <FormFieldControl
                {...getFormFieldControlProps({
                  path: `${name}.objectCommissioningEndDate`,
                  formFieldTooltipProps: {
                    onChange: () =>
                      setTimeout(
                        () => handleChangeValue?.(`${name}.objectCommissioningEndDate`),
                        0,
                      ),
                    onDifference: handleUpdateChanges,
                  },
                })}
              >
                <ControlledCalendarInput
                  {...getCalendarInputProps({
                    name: `${name}.objectCommissioningEndDate`,
                    rules: {
                      ...eExploitationAAgreementOnChangingCharacteristicsOfObjectsValidationMap.objectCommissioningEndDate,
                      validate: {
                        positiveObjectStateRegistrationEndDate: (value) => {
                          if (
                            isDateValidForDayjs(value) &&
                            dayjs(value, objOfDateFormats.defaultFormat) >
                              dayjs(stepEndDate, objOfDateFormats.defaultFormat)
                          )
                            return 'дата ввода всех объектов в эксплуатацию должна быть меньше или равна дате регистрации всех объектов'
                        },
                      },
                    },
                    calendarInputProps: {
                      dataTestId: 'objectCommissioningEndDate',
                    },
                    onBlur: () =>
                      setTimeout(
                        () => handleChangeValue?.(`${name}.objectCommissioningEndDate`),
                        0,
                      ),
                    onCalendarChange: () =>
                      setTimeout(
                        () => handleChangeValue?.(`${name}.objectCommissioningEndDate`),
                        0,
                      ),
                    onInputChange: () =>
                      debouncedHandleChangeValue?.(`${name}.objectCommissioningEndDate`),
                  })}
                />
              </FormFieldControl>
            </RowWithBorder>
          </Col>
        </Row>
        <Stack direction="vertical" gap={!!exploitationForms.length ? 3 : 0} className={'mt-3'}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${name}.exploitationObjects`,
            })}
          >
            <FlipperList list={exploitationForms}>
              {exploitationForms.map((exploitation, index) => {
                const formName = `${name}.exploitationObjects.${index}` as const

                return (
                  <SubscribableControl
                    key={exploitation.id}
                    {...getSubscribableControlProps({
                      path: formName,
                    })}
                  >
                    <FormObjectTooltipControl
                      name={formName}
                      control={formModifierInstance.control}
                      onDifference={handleUpdateChanges}
                    >
                      <ExploitationItem
                        id={exploitation.id}
                        anchorId={exploitation.anchorId}
                        formName={formName}
                        indexOfBlock={indexOfBlock}
                        blockViewIsValidating={blockViewIsValidating}
                        editMode={editMode}
                        formInstance={formInstance}
                        formModifierInstance={formModifierInstance}
                        indexOfExploitation={index}
                        lastIndexOfExploitation={exploitationForms.length - 1}
                        onDeleteExploitation={handleRemoveExploitation(index)}
                        onMoveTop={handleChangeElementOrderToUp(index)}
                        onMoveBottom={handleChangeElementOrderToDown(index)}
                      />
                    </FormObjectTooltipControl>
                  </SubscribableControl>
                )
              })}
            </FlipperList>
          </SubscribableControl>
          {editMode && (
            <Row className={'px-1'}>
              <Col xs={9}>
                <AsyncWrapper promise={handleAddExploitation}>
                  {({ isLoading, wrappedPromise }) => {
                    return (
                      <Button
                        disabled={isLoading}
                        variant={'buttonSMedium'}
                        size={'2xs'}
                        view={'plain'}
                        leadingIcon={{
                          src: CircleAddIcon,
                        }}
                        loaderProps={{
                          loading: isLoading,
                          placement: 'trailing',
                          variant: 'lite',
                        }}
                        onClick={wrappedPromise}
                      >
                        Указать объект, введенный в эксплуатацию
                      </Button>
                    )
                  }}
                </AsyncWrapper>
              </Col>
            </Row>
          )}
        </Stack>
      </Stack>
    </Container>
  )
}

export default CExploitationForm
