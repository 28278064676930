import { FieldErrors, UnpackNestedValue } from 'react-hook-form'

import { NestedMenuHelpers } from '@components/DocumentFormComponents/NestedMenu/helpers'
import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnCreationOtherEstateObjectsFormValues,
  IConstructionStage,
} from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'
import { compact } from '@helpers/array/compact'
import { isNotEmptyString, isUndefined } from '@helpers/checkTypes'

const { getMenuListError } = NestedMenuHelpers

const generateMenuListForAAgreementOnCreationOtherEstateObjects = (
  formValues:
    | AAgreementOnCreationOtherEstateObjectsFormValues
    | UnpackNestedValue<AAgreementOnCreationOtherEstateObjectsFormValues>,
  initialHashMap: NestedMenuHashMap,
  formErrors: FieldErrors<AAgreementOnCreationOtherEstateObjectsFormValues>,
): NestedMapOfMenu[] => {
  const getMenuSectionWithError = (numberSectionOfMenu: keyof NestedMenuHashMap) => {
    if (isUndefined(initialHashMap[numberSectionOfMenu])) return

    const preparedChildrenSections = initialHashMap[numberSectionOfMenu].children.map((item) => ({
      ...item,
      hasError: !!formErrors?.[item.id],
    }))

    return {
      ...initialHashMap[numberSectionOfMenu],
      hasError:
        !!formErrors?.[numberSectionOfMenu] ||
        preparedChildrenSections.some((item) => item.hasError),
      children: preparedChildrenSections,
    }
  }

  const getPreparedConstructionStagesAnchorMenu = (
    step: IConstructionStage,
    indexOfStep: number,
  ): NestedMapOfMenu => {
    const constructionStageId = `${initialHashMap['5'].path}.constructionStages.${indexOfStep}`
    const stepError = formErrors?.['5']?.constructionStages?.[indexOfStep]

    const preparedConstructionStageMenu = ((): NestedMapOfMenu[] => {
      if (!step.isShowConstructionObjects) return []

      const constructionObjectsId = `${constructionStageId}.constructionObjects`
      const permissionsObjectsId = `${constructionStageId}.permissionsObjects`
      const exploitationObjectsId = `${constructionStageId}.exploitation.exploitationObjects`
      const registrationObjectsId = `${constructionStageId}.registration.registrationObjects`

      const preparedConstructionObjects = ((): NestedMapOfMenu[] => {
        if (!step.constructionObjects || !step.constructionObjects.length) return []

        return step.constructionObjects.map((object, indexOfObject) => ({
          id: `${step.anchorId}.1.${indexOfObject + 1}`,
          path: `${constructionObjectsId}.${indexOfObject}`,
          title: isNotEmptyString(object.objectName)
            ? object.objectName
            : `Основной объект №${indexOfObject + 1}`,
          isCollapsed: false,
          hasError: !!stepError?.constructionObjects?.[indexOfObject],
          parentBaseId: `${step.anchorId}.1`,
          children: [],
        }))
      })()

      const preparedPermissionObjects = ((): NestedMapOfMenu[] => {
        if (!step.permissionsObjects || !step.permissionsObjects.length) return []

        return step.permissionsObjects.map((object, indexOfObject) => ({
          id: `${step.anchorId}.2.${indexOfObject + 1}`,
          path: `${permissionsObjectsId}.${indexOfObject}`,
          parentBaseId: `${step.anchorId}.2`,
          isCollapsed: false,
          hasError: !!stepError?.permissionsObjects?.[indexOfObject],
          title: isNotEmptyString(object.permissionKind)
            ? object.permissionKind
            : `Разрешение №${indexOfObject + 1}`,
          children: [],
        }))
      })()

      const preparedExploitationObjects = ((): NestedMapOfMenu[] => {
        if (!step.exploitation.exploitationObjects || !step.exploitation.exploitationObjects.length)
          return []

        return step?.exploitation?.exploitationObjects.map((object, indexOfObject) => ({
          id: `${step.anchorId}.3.${indexOfObject + 1}`,
          path: `${exploitationObjectsId}.${indexOfObject}`,
          parentBaseId: `${step.anchorId}.3`,
          isCollapsed: false,
          hasError: !!stepError?.exploitation?.exploitationObjects?.[indexOfObject],
          title: isNotEmptyString(object.objectName)
            ? object.objectName
            : `Эксплуатация №${indexOfObject + 1}`,
          children: [],
        }))
      })()

      const preparedRegistrationObjects = ((): NestedMapOfMenu[] => {
        if (!step.registration.registrationObjects || !step.registration.registrationObjects.length)
          return []

        return step.registration.registrationObjects.map((object, indexOfObject) => ({
          id: `${step.anchorId}.4.${indexOfObject + 1}`,
          path: `${registrationObjectsId}.${indexOfObject}`,
          parentBaseId: `${step.anchorId}.4`,
          isCollapsed: false,
          hasError: !!stepError?.registration?.registrationObjects?.[indexOfObject],
          title: isNotEmptyString(object.objectName)
            ? object.objectName
            : `Регистрация №${indexOfObject + 1}`,
          children: [],
        }))
      })()

      return [
        {
          id: `${step.anchorId}.1`,
          path: constructionObjectsId,
          title: 'Объекты недвижимости',
          parentBaseId: step.anchorId,
          isCollapsed: false,
          hasError: !!stepError && getMenuListError(stepError?.constructionObjects),
          children: preparedConstructionObjects,
        },
        {
          id: `${step.anchorId}.2`,
          path: permissionsObjectsId,
          title: 'Разрешения на объекты',
          parentBaseId: step.anchorId,
          isCollapsed: false,
          hasError: !!stepError && getMenuListError(stepError.permissionsObjects),
          children: preparedPermissionObjects,
        },
        {
          id: `${step.anchorId}.3`,
          path: exploitationObjectsId,
          title: 'Эксплуатация объектов',
          isCollapsed: false,
          hasError: !!stepError?.exploitation,
          parentBaseId: step.anchorId,
          children: preparedExploitationObjects,
        },
        {
          id: `${step.anchorId}.4`,
          path: registrationObjectsId,
          title: 'Регистрация объектов',
          isCollapsed: false,
          hasError: !!stepError?.registration,
          parentBaseId: step.anchorId,
          children: preparedRegistrationObjects,
        },
      ]
    })()

    return {
      path: constructionStageId,
      id: step.anchorId,
      title: isNotEmptyString(step.stepDescription)
        ? step.stepDescription
        : `Мероприятие ${indexOfStep + 1}`,
      isCollapsed: false,
      hasError: !!stepError,
      parentBaseId: initialHashMap['5'].id,
      children: preparedConstructionStageMenu,
    }
  }

  const preparedChildrenOfConstructionStages = formValues['5'].constructionStages.map(
    getPreparedConstructionStagesAnchorMenu,
  )

  const preparedHashMap: Record<string, NestedMapOfMenu | undefined> = {
    '1': getMenuSectionWithError('1'),
    '2': getMenuSectionWithError('2'),
    '3': getMenuSectionWithError('3'),
    '4': getMenuSectionWithError('4'),
    '5': {
      ...initialHashMap['5'],
      hasError: preparedChildrenOfConstructionStages.some(({ hasError }) => hasError),
      children: preparedChildrenOfConstructionStages,
    },
  }

  return compact([
    preparedHashMap['1'],
    preparedHashMap['2'],
    preparedHashMap['3'],
    preparedHashMap['4'],
    preparedHashMap['5'],
  ]) as NestedMapOfMenu[]
}

export { generateMenuListForAAgreementOnCreationOtherEstateObjects }
