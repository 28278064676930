import { useCallback } from 'react'
import { FieldErrors, UnpackNestedValue } from 'react-hook-form'

import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import { generateFormModifierValuesForAAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/adapters/Modifier.adapter'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { IOverridePropsFromClass } from '@services/Properties/Properties.entity'

import { generateMenuListForAAgreementOnNonConclusionOrNonPerformanceOfConcessionA } from './Menu.adapter'
import { generateOverridePropsForAAgreementOnNonConclusionOrNonPerformanceOfConcessionA } from './OverrideProps.adapter'
import { generatePrefetchedPropsForAAgreementOnNonConclusionOrNonPerformanceOfConcessionA } from './PrefetchedProps.adapter'
import { generatePropertiesObjectForAAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm } from './PropertiesObject.adapter'
import { generateRHFObjectForAAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm } from './RHF.adapter'

const useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAAdapters = () => {
  const generateRHFObject = useCallback(
    (
      objectFromPropertyState: Record<string, any>,
    ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues =>
      generateRHFObjectForAAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm(
        objectFromPropertyState,
      ),
    [],
  )

  const generatePropertiesObject = useCallback(
    (objectFromPropertyState: Record<string, any>): Record<string, unknown> =>
      generatePropertiesObjectForAAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm(
        objectFromPropertyState,
      ),
    [],
  )

  const generateOverrideProps = useCallback(
    (objectFromPropertyState: Record<string, any>): IOverridePropsFromClass[] =>
      generateOverridePropsForAAgreementOnNonConclusionOrNonPerformanceOfConcessionA(
        objectFromPropertyState,
      ),
    [],
  )

  const generateMenuListByFormValues = useCallback(
    (
      formValues:
        | AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues
        | UnpackNestedValue<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>,
      initialHashMap: NestedMenuHashMap,
      formErrors: FieldErrors<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>,
    ): NestedMapOfMenu[] =>
      generateMenuListForAAgreementOnNonConclusionOrNonPerformanceOfConcessionA(
        formValues,
        initialHashMap,
        formErrors,
      ),
    [],
  )

  const generatePrefetchedPropsArray = useCallback(
    (objectFromPropertyState: Record<string, any>): string[] =>
      generatePrefetchedPropsForAAgreementOnNonConclusionOrNonPerformanceOfConcessionA(
        objectFromPropertyState,
      ),
    [],
  )

  const generateFormModifierValues = useCallback(
    (
      objectFromPropertyState: Record<string, any>,
    ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues =>
      generateFormModifierValuesForAAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm(
        objectFromPropertyState,
      ),
    [],
  )

  return {
    generateRHFObject,
    generatePropertiesObject,
    generateOverrideProps,
    generateMenuListByFormValues,
    generatePrefetchedPropsArray,
    generateFormModifierValues,
  }
}

export { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAAdapters }
