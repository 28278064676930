import React, { FC, ReactNode } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import FieldArraySwapper from '@components/DocumentFormComponents/FieldArraySwapper'
import Border from '@components/DocumentFormComponents/FieldView/Border'
import styles from '@components/DocumentFormComponents/FormStyles/RidFormItem.module.scss'
import { useFormCollapseControl } from '@components/DocumentFormComponents/hooks/useFormCollapseControl'
import ObjectStepWrapper from '@components/DocumentFormComponents/ObjectStepWrapper'
import ObjectStepWrapperHeaderNode from '@components/DocumentFormComponents/ObjectStepWrapper/ObjectStepWrapperHeaderNode'
import RidFormItem from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/AdditionalForms/MainTemplate/RidObject/Forms/RForm/RidFormItem'
import { SeventhRidObjectsArrayPathName } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldCollapseControlUpdateWatcher } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import cn from 'classnames'

interface RFormItemProps {
  id: string
  anchorId: string
  formName: SeventhRidObjectsArrayPathName
  blockViewIsValidating: boolean
  editMode: boolean
  formInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>
  formModifierInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>
  indexOfRidForm: number
  lastIndexOfRidForm: number
  onDeleteRidForm: VoidFunction
  onMoveTop: () => Promise<void>
  onMoveBottom: () => Promise<void>
  leftAddons?: ReactNode
}

const RFormItem: FC<RFormItemProps> = ({
  id,
  anchorId,
  blockViewIsValidating,
  editMode,
  formName,
  formInstance,
  formModifierInstance,
  indexOfRidForm,
  lastIndexOfRidForm,
  onDeleteRidForm,
  onMoveTop,
  onMoveBottom,
  leftAddons,
}) => {
  const { isExpanded: isShowRidFormItemContent, onToggleCollapse: toggleShowRidFormItemContent } =
    useFormCollapseControl({
      initialExpanded: true,
      name: formName,
      watcher:
        AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldCollapseControlUpdateWatcher,
    })

  const title = useWatch({
    name: `${formName}.title`,
    control: formInstance.control,
  })

  return (
    <ObjectStepWrapper
      disableHeaderPaddingLeft
      disableBorder
      stepId={id}
      id={anchorId}
      headerNode={
        <ObjectStepWrapperHeaderNode
          leftAddons={leftAddons}
          buttonProps={{
            onClick: toggleShowRidFormItemContent,
            trailingIcon: {
              className: cn({
                [styles['ridFormItem__header-button__icon--active']]: isShowRidFormItemContent,
              }),
            },
          }}
        >
          {title}
        </ObjectStepWrapperHeaderNode>
      }
      additionalHeaderNode={
        editMode && (
          <FieldArraySwapper
            index={indexOfRidForm}
            lastIndex={lastIndexOfRidForm}
            onMoveTop={onMoveTop}
            onMoveBottom={onMoveBottom}
          />
        )
      }
      onRemoveObjectStep={editMode ? onDeleteRidForm : undefined}
    >
      <Row>
        <Col xs={12}>
          <Stack direction="vertical" gap={3}>
            <Row>
              <RidFormItem
                isShowRidFormItemContent={isShowRidFormItemContent}
                blockViewIsValidating={blockViewIsValidating}
                editMode={editMode}
                formInstance={formInstance}
                formModifierInstance={formModifierInstance}
                formName={formName}
              />
            </Row>
            <Row>
              <Col xs={12}>
                <Border />
              </Col>
            </Row>
          </Stack>
        </Col>
      </Row>
    </ObjectStepWrapper>
  )
}

export default RFormItem
