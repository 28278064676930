import React, { FC, memo, useCallback, useEffect, useMemo } from 'react'
import { Path, useForm } from 'react-hook-form'

import DocumentDataView from '@components/DocumentFormComponents/DocumentDataView'
import { useParseFormError } from '@components/DocumentFormComponents/hooks/useParseFormError'
import { getFormattedValue } from '@components/NewDesign/AmountInput/utils'
import Button from '@components/NewDesign/Button'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import { ControlledMaskInput } from '@components/NewDesign/MaskedInput/ControlledMaskInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import { OrganizationInfoHelpersService } from '@components/OrganizationInfo/helpers'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { RolesTypes } from '@constants/types'
import { defaultRHFValidation, lengthValidate, trimmedValueValidate } from '@constants/validations'
import { useAuthContext } from '@context/AuthContext'
import { Entries } from '@globalTypes/typeFest'
import { isArray, isAxiosError, isNotEmptyString, isString } from '@helpers/checkTypes'
import { useBooleanState } from '@hooks/useBooleanState'
import { IOrganizationInfo } from '@services/Organization/organization.entity'
import cn from 'classnames'

import styles from './MainInfoGrid.module.scss'

interface OrganizationInfoGridProps {
  editMode: boolean
  organization: IOrganizationInfo
  onOrganizationChange: (organizationInfo: IOrganizationInfo) => Promise<void>
  onOrganizationChangeCancel: VoidFunction
  requiredFields?: Record<Path<IOrganizationInfo>, boolean>
}

const { generateAddressInOneString } = OrganizationInfoHelpersService

const OrganizationMainInfoGrid: FC<OrganizationInfoGridProps> = ({
  editMode,
  requiredFields,
  organization,
  onOrganizationChange,
  onOrganizationChangeCancel,
}) => {
  const { booleanState: saveButtonIsLoading, setBooleanState: setButtonIsLoading } =
    useBooleanState()

  const { checkingRole } = useAuthContext()

  const isOMSU = !!checkingRole?.(RolesTypes.OMSU)

  const formInstance = useForm<IOrganizationInfo>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: organization,
  })

  const { applyErrorsFromViolations } = useParseFormError(formInstance)

  const { control, formState, watch, reset, trigger, handleSubmit } = formInstance

  const organizationFromForm = watch()

  const organizationAddress = generateAddressInOneString({
    index: organizationFromForm.addressIndex || '',
    region: '',
    city: organizationFromForm.addressCity,
    roadNetworkElement: organizationFromForm.addressRoadNetworkElement,
    district: organizationFromForm.addressDistrict,
    house: organizationFromForm.addressHouse,
    subject: organizationFromForm.addressSubject,
    room: organizationFromForm.addressRoom,
    planningStructureElement: organizationFromForm.addressPlanningStructureElement,
    area: organizationFromForm.addressArea,
    roomPart: organizationFromForm.addressRoomPart,
  })

  const preparedCapital =
    (organizationFromForm.capital &&
      `${getFormattedValue(String(organizationFromForm.capital), 'RUB', 100, false)} ₽`) ||
    '-'

  const handleOrganizationFormChange = async (newOrganizationInfo: IOrganizationInfo) => {
    const preparedOrganizationInfo = (
      Object.entries(newOrganizationInfo) as Entries<IOrganizationInfo>
    ).reduce((previousValue, [key, value]) => {
      if (!isString(value)) {
        return {
          ...previousValue,
          [key]: value,
        }
      }

      const trimmedValue = value.trim()

      return {
        ...previousValue,
        [key]: isNotEmptyString(trimmedValue) ? trimmedValue : null,
      }
    }, {} as IOrganizationInfo)

    setButtonIsLoading(true)
    try {
      await onOrganizationChange(preparedOrganizationInfo)

      reset(newOrganizationInfo)
    } catch (e) {
      if (!isAxiosError(e)) throw e

      const errorResponse = e.response?.data
      if (!errorResponse) throw e

      if (errorResponse && isArray(errorResponse.violations)) {
        applyErrorsFromViolations(errorResponse.violations)
      }

      throw e
    } finally {
      setButtonIsLoading(false)
    }
  }

  const handleOrganizationCancel = () => {
    reset()
    onOrganizationChangeCancel()
  }

  const isFieldRequired = useCallback(
    (field: Path<IOrganizationInfo>) => {
      return requiredFields?.[field]
    },
    [requiredFields],
  )

  const conditionToPaymentDetailsRender = useMemo(() => {
    return isFieldRequired('paymentDetails') || isOMSU
  }, [isFieldRequired, isOMSU])

  // триггерит валидацию полей в самый первый раз, если включен режим редактирования
  useEffect(() => {
    if (!requiredFields || (requiredFields && !Object.keys(requiredFields).length) || !editMode)
      return

    trigger(Object.keys(requiredFields) as Path<IOrganizationInfo>[])
  }, [editMode])

  return (
    <div className={styles.mainInfoGrid__container}>
      <Stack direction={'vertical'} gap={3}>
        <Row className={styles.mainInfoGrid__row}>
          <Col xs={12}>
            <Stack direction={'horizontal'} gap={0}>
              <Col
                xs={7}
                className={cn(
                  styles['mainInfoGrid__col--read-mode'],
                  styles.mainInfoGrid__leftSide,
                )}
              >
                <DocumentDataView suptitle={'Полное наименование'}>
                  {organizationFromForm.fullName}
                </DocumentDataView>
              </Col>
              <Col xs={5} className={styles.mainInfoGrid__rightSide}>
                <Row className={styles.mainInfoGrid__rightRow}>
                  <Col xs={5} className={styles['mainInfoGrid__col--read-mode']}>
                    <DocumentDataView suptitle={'ИНН'}>{organizationFromForm.inn}</DocumentDataView>
                  </Col>
                  <Col xs={5} className={styles['mainInfoGrid__col--read-mode']}>
                    <DocumentDataView suptitle={'ОГРН'}>
                      {organizationFromForm.ogrn}
                    </DocumentDataView>
                  </Col>
                </Row>
              </Col>
            </Stack>
          </Col>
        </Row>
        <Row className={styles.mainInfoGrid__row}>
          <Col xs={12}>
            <Stack direction={'horizontal'} gap={0} className={styles.mainInfoGrid__stack}>
              <Col
                xs={7}
                className={cn(
                  styles['mainInfoGrid__col--read-mode'],
                  styles.mainInfoGrid__leftSide,
                )}
              >
                <DocumentDataView suptitle={'Юридический адрес'}>
                  {organizationAddress}
                </DocumentDataView>
              </Col>
              <Col xs={5} className={styles.mainInfoGrid__rightSide}>
                <Row className={styles.mainInfoGrid__rightRow}>
                  <Col xs={5} className={styles['mainInfoGrid__col--read-mode']}>
                    <DocumentDataView suptitle={'КПП'}>{organizationFromForm.kpp}</DocumentDataView>
                  </Col>
                  <Col xs={5} className={styles['mainInfoGrid__col--read-mode']}>
                    <DocumentDataView suptitle={'ОКВЭД'}>
                      {organizationFromForm.okved}
                    </DocumentDataView>
                  </Col>
                </Row>
              </Col>
            </Stack>
          </Col>
        </Row>
        <Row className={styles.mainInfoGrid__row}>
          <Col xs={12}>
            <Stack direction={'horizontal'} gap={0} className={styles.mainInfoGrid__stack}>
              <Col
                xs={7}
                className={cn(
                  styles['mainInfoGrid__col--read-mode'],
                  styles.mainInfoGrid__leftSide,
                )}
              >
                <DocumentDataView suptitle={'Уставный капитал'}>{preparedCapital}</DocumentDataView>
              </Col>
              <Col xs={5} className={styles.mainInfoGrid__rightSide}>
                <Row className={styles.mainInfoGrid__rightRow}>
                  <Col
                    xs={5}
                    className={cn(styles['mainInfoGrid__col--edit-mode'], {
                      [styles['mainInfoGrid__col--read-mode']]: !editMode,
                    })}
                  >
                    {!editMode ? (
                      <DocumentDataView suptitle={'ОКПО'}>
                        {organizationFromForm.okpo}
                      </DocumentDataView>
                    ) : (
                      <ControlledMaskInput
                        control={control}
                        name={'okpo'}
                        inputProps={{
                          fixWidth: true,
                          view: 'secondary',
                          size: 'xl',
                          maskPlaceholder: '',
                          mask: '9999999999',
                          label: 'ОКПО',
                        }}
                        rules={{
                          required: isFieldRequired('okpo') && defaultRHFValidation.required,
                          pattern: {
                            value: /^(\d{8}|\d{10})$/,
                            message: 'ОКПО должен состоять из 8 или 10 цифр',
                          },
                        }}
                      />
                    )}
                  </Col>
                  <Col
                    xs={5}
                    className={cn(styles['mainInfoGrid__col--edit-mode'], {
                      [styles['mainInfoGrid__col--read-mode']]: !editMode,
                    })}
                  >
                    {!editMode ? (
                      <DocumentDataView suptitle={'ОКТМО'}>
                        {organizationFromForm.oktmo}
                      </DocumentDataView>
                    ) : (
                      <ControlledMaskInput
                        control={control}
                        name={'oktmo'}
                        inputProps={{
                          fixWidth: true,
                          view: 'secondary',
                          size: 'xl',
                          label: 'ОКТМО',
                          mask: '99999999999',
                          maskPlaceholder: '',
                        }}
                        rules={{
                          required: isFieldRequired('oktmo') && defaultRHFValidation.required,
                          pattern: {
                            value: /^(\d{8}|\d{11})$/,
                            message: 'ОКТМО должен состоять из 8 или 11 цифр',
                          },
                        }}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Stack>
          </Col>
        </Row>
        <Row className={styles.mainInfoGrid__row}>
          <Col xs={12}>
            <Stack direction={'horizontal'} gap={0} className={styles.mainInfoGrid__stack}>
              <Col
                xs={7}
                className={cn(styles.mainInfoGrid__leftSide, {
                  [styles['mainInfoGrid__col--read-mode']]: !editMode,
                })}
              >
                {conditionToPaymentDetailsRender && (
                  <>
                    {!editMode ? (
                      <DocumentDataView suptitle={'Платежные реквизиты'}>
                        {organizationFromForm.paymentDetails}
                      </DocumentDataView>
                    ) : (
                      <ControlledTextarea
                        control={control}
                        name={'paymentDetails'}
                        textareaProps={{
                          fixWidth: true,
                          size: 'xl',
                          label: 'Платежные реквизиты',
                        }}
                        rules={{
                          required:
                            isFieldRequired('paymentDetails') && defaultRHFValidation.required,
                          validate: {
                            ...(isFieldRequired('paymentDetails') && {
                              trimmedValueValidate,
                            }),
                            positiveLength: (value) =>
                              isNotEmptyString(value) ? lengthValidate(value, 1500) : undefined,
                          },
                        }}
                      />
                    )}
                  </>
                )}
              </Col>
              <Col xs={5} className={cn(styles.mainInfoGrid__rightSide)}>
                <Stack direction={'vertical'} gap={3}>
                  <Row className={styles.mainInfoGrid__rightRow}>
                    <Col
                      xs={5}
                      className={cn(styles['mainInfoGrid__col--edit-mode'], {
                        [styles['mainInfoGrid__col--read-mode']]: !editMode,
                      })}
                    >
                      {!editMode ? (
                        <DocumentDataView suptitle={'Номер телефона'}>
                          {organizationFromForm.phone}
                        </DocumentDataView>
                      ) : (
                        <ControlledMaskInput
                          control={control}
                          name={'phone'}
                          inputProps={{
                            fixWidth: true,
                            view: 'secondary',
                            size: 'xl',
                            mask: '+7 (999) 999-99-99',
                            label: 'Номер телефона',
                          }}
                          rules={{
                            required: isFieldRequired('phone') && defaultRHFValidation.required,
                            validate: (value) => {
                              if (!isString(value)) return

                              return !value.includes('_') || 'некорректный номер'
                            },
                          }}
                        />
                      )}
                    </Col>
                    <Col
                      xs={5}
                      className={cn(styles['mainInfoGrid__col--edit-mode'], {
                        [styles['mainInfoGrid__col--read-mode']]: !editMode,
                      })}
                    >
                      {!editMode ? (
                        <DocumentDataView suptitle={'Добавочный номер'}>
                          {organizationFromForm.phoneExtension}
                        </DocumentDataView>
                      ) : (
                        <ControlledMaskInput
                          control={control}
                          name={'phoneExtension'}
                          inputProps={{
                            fixWidth: true,
                            view: 'secondary',
                            size: 'xl',
                            maskPlaceholder: '',
                            mask: '9999999999',
                            label: 'Добавочный номер',
                            caption: 'если есть',
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row className={cn(styles.mainInfoGrid__emailRow, styles.mainInfoGrid__rightRow)}>
                    <Col
                      xs={5}
                      className={cn(styles['mainInfoGrid__col--edit-mode'], {
                        [styles['mainInfoGrid__col--read-mode']]: !editMode,
                      })}
                    >
                      {!editMode ? (
                        <DocumentDataView suptitle={'Email'}>
                          {organizationFromForm.email}
                        </DocumentDataView>
                      ) : (
                        <ControlledInput
                          control={control}
                          name={'email'}
                          rules={{
                            required: isFieldRequired('email') && defaultRHFValidation.required,
                            pattern: defaultRHFValidation.email,
                          }}
                          inputProps={{
                            label: 'Email',
                            autoComplete: 'off',
                            view: 'secondary',
                            size: 'xl',
                          }}
                        />
                      )}
                    </Col>
                    <Col
                      xs={5}
                      className={cn(styles['mainInfoGrid__col--edit-mode'], {
                        [styles['mainInfoGrid__col--read-mode']]: !editMode,
                      })}
                    >
                      {!editMode ? (
                        <DocumentDataView suptitle={'Факс'}>
                          {organizationFromForm.fax}
                        </DocumentDataView>
                      ) : (
                        <ControlledMaskInput
                          control={control}
                          name={'fax'}
                          inputProps={{
                            fixWidth: true,
                            view: 'secondary',
                            size: 'xl',
                            mask: '+7 (999) 999-99-99',
                            label: 'Факс',
                          }}
                          rules={{
                            validate: (value) => {
                              if (!isString(value)) return

                              return !value.includes('_') || 'некорректный номер'
                            },
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                </Stack>
              </Col>
            </Stack>
          </Col>
        </Row>
        <Row className={styles.mainInfoGrid__row}>
          <Stack direction={'horizontal'}>
            {editMode && (
              <Button
                disabled={saveButtonIsLoading || !formState.isValid}
                size={'l'}
                view={'filled'}
                loaderProps={{
                  variant: 'lite',
                  placement: 'leading',
                  loading: saveButtonIsLoading,
                }}
                onClick={handleSubmit(handleOrganizationFormChange)}
              >
                Сохранить
              </Button>
            )}
            {editMode && (
              <Button
                disabled={saveButtonIsLoading}
                className={styles.mainInfoGrid__action}
                color={'negative'}
                size={'l'}
                view={'filled'}
                onClick={handleOrganizationCancel}
              >
                Отменить
              </Button>
            )}
          </Stack>
        </Row>
      </Stack>
    </div>
  )
}

export default memo(OrganizationMainInfoGrid)
