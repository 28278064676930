import React, { FC, ReactNode } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import FieldArraySwapper from '@components/DocumentFormComponents/FieldArraySwapper'
import Border from '@components/DocumentFormComponents/FieldView/Border'
import ObjectStepWrapper from '@components/DocumentFormComponents/ObjectStepWrapper'
import ExploitationFormItem from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms/5/AdditionalForms/MainTemplate/ConstructionObject/Forms/CExploitationForm/ExploitationFormItem'
import { ObjectExploitationObjectsArrayPathName } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms/5/types'
import { AAgreementOnCreationOtherEstateObjectsFormValues } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface ExploitationItemProps {
  id: string
  anchorId: string
  formName: ObjectExploitationObjectsArrayPathName
  blockViewIsValidating: boolean
  editMode: boolean
  indexOfBlock: number
  formInstance: UseFormReturn<AAgreementOnCreationOtherEstateObjectsFormValues>
  indexOfExploitation: number
  lastIndexOfExploitation: number
  onDeleteExploitation?: VoidFunction

  onMoveTop?: () => Promise<void>
  onMoveBottom?: () => Promise<void>
  leftAddons?: ReactNode
}

const ExploitationItem: FC<ExploitationItemProps> = ({
  id,
  anchorId,
  blockViewIsValidating,
  editMode,
  formInstance,
  formName,
  indexOfBlock,
  indexOfExploitation,
  lastIndexOfExploitation,
  onDeleteExploitation,
  onMoveTop,
  onMoveBottom,
  leftAddons,
}) => {
  const isNew = useWatch({
    name: `${formName}.isNew`,
    control: formInstance.control,
  })

  const title = useWatch({
    name: `${formName}.title`,
    control: formInstance.control,
  })

  return (
    <ObjectStepWrapper
      disableBorder
      stepId={id}
      id={anchorId}
      leftAddons={leftAddons}
      headerNode={<Typography.Body variant={'bodyMSemibold'}>{title}</Typography.Body>}
      additionalHeaderNode={
        isNew &&
        editMode && (
          <FieldArraySwapper
            index={indexOfExploitation}
            lastIndex={lastIndexOfExploitation}
            onMoveTop={onMoveTop}
            onMoveBottom={onMoveBottom}
          />
        )
      }
      onRemoveObjectStep={isNew && editMode ? onDeleteExploitation : undefined}
    >
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <ExploitationFormItem
            formName={formName}
            blockViewIsValidating={blockViewIsValidating}
            formInstance={formInstance}
            indexOfBlock={indexOfBlock}
          />
        </Row>
        <Row>
          <Col xs={12}>
            <Border />
          </Col>
        </Row>
      </Stack>
    </ObjectStepWrapper>
  )
}

export default ExploitationItem
