import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { AAgreementAccessionMunicipalFormValues } from '@components/Forms/AAgreementAccessionMunicipalForm/types'

const generateRHFObjectForAAgreementAccessionMunicipalForm = (
  objectFromPropertyState: Record<string, any>,
): AAgreementAccessionMunicipalFormValues => {
  return {
    '1': RHFBasicAdapters.getRFAdapter(objectFromPropertyState),
    '2': {
      subjects: RHFBasicAdapters.getSubjectsAdapter(objectFromPropertyState),
    },
    '3': {
      municipalities: RHFBasicAdapters.getMunicipalitiesAdapter(objectFromPropertyState),
    },
    '4': RHFBasicAdapters.getNewMunicipalityAdapter(objectFromPropertyState),
    '5': RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    '6': {
      ruleLinkMunicipalNpa: objectFromPropertyState.ruleLinkMunicipalNpa?.value,
      extraMunicipalAccessionLinkCompensation:
        objectFromPropertyState.extraMunicipalAccessionLinkCompensation?.value,
      extraMunicipalAccessionLinkStabilization:
        objectFromPropertyState.extraMunicipalAccessionLinkStabilization?.value,
      isMunicipalityReadyToCompensate:
        objectFromPropertyState.isMunicipalityReadyToCompensate?.value,
    },
    additionalFields: {
      szpkNumber: objectFromPropertyState.szpkNumber?.value,
      initialMunicipalities: objectFromPropertyState.municipalities?.value,
    },
  }
}

export { generateRHFObjectForAAgreementAccessionMunicipalForm }
