import React, { FC, ReactNode } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import GroupWithRemoveButton from '@components/DocumentFormComponents/GroupWithRemoveButton'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import { SeventhConstructionObjectAreasArrayPathName } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/types'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'

import { objectAreaValidationMap } from './validation'

interface ObjectAreaItemProps {
  editMode: boolean
  blockViewIsValidating: boolean
  formInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>
  formModifierInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>
  name: SeventhConstructionObjectAreasArrayPathName
  id: string
  index: number
  leftAddons?: ReactNode
  onRemove?: () => Promise<void>
}

const ObjectAreaItem: FC<ObjectAreaItemProps> = ({
  editMode,
  blockViewIsValidating,
  id,
  index,
  name,
  formInstance,
  formModifierInstance,
  leftAddons,
  onRemove,
}) => {
  const {
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const isNew = useWatch({
    name: `${name}.isNew`,
    control: formModifierInstance.control,
  })

  const { getFormFieldControlProps, getInputProps, getAmountInputProps } = useFormComponentPresets({
    isNew,
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  return (
    <GroupWithRemoveButton
      disableBottomBorder
      id={id}
      title={`Земельный участок №${index + 1}`}
      leftAddons={leftAddons}
      onRemove={onRemove}
    >
      <Row>
        <Col xs={6}>
          <FormFieldControl
            {...getFormFieldControlProps({
              path: `${name}.objectAreaNumber`,
              formFieldTooltipProps: {
                onChange: () =>
                  setTimeout(() => {
                    handleChangeValue?.(`${name}.objectAreaNumber`)
                  }, 0),
                onDifference: handleUpdateChanges,
              },
            })}
          >
            <ControlledInput
              {...getInputProps({
                name: `${name}.objectAreaNumber`,
                rules: objectAreaValidationMap.objectAreaNumber,
                inputProps: {
                  label: 'Кадастровый номер з/у',
                  caption: 'если применимо',
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(`${name}.objectAreaNumber`)
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(`${name}.objectAreaNumber`)
                  }, 0),
              })}
            />
          </FormFieldControl>
        </Col>
        <Col xs={6}>
          <FormFieldControl
            {...getFormFieldControlProps({
              path: `${name}.objectAreaSquare`,
              formFieldTooltipProps: {
                onChange: () =>
                  setTimeout(() => {
                    handleChangeValue?.(`${name}.objectAreaSquare`)
                  }, 0),
                onDifference: handleUpdateChanges,
              },
            })}
          >
            <ControlledAmountInput
              {...getAmountInputProps({
                name: `${name}.objectAreaSquare`,
                rules: objectAreaValidationMap.objectAreaSquare,
                inputProps: {
                  label: 'Площадь з/у, кв. м',
                  suffix: '',
                  caption: 'если применимо',
                  minorPartView: 'withZeroMinorPart',
                  minority: 100000,
                },
                onBlur: () =>
                  setTimeout(() => {
                    handleChangeValue?.(`${name}.objectAreaSquare`)
                  }, 0),
                onChange: () =>
                  setTimeout(() => {
                    debouncedHandleChangeValue?.(`${name}.objectAreaSquare`)
                  }, 0),
              })}
            />
          </FormFieldControl>
        </Col>
      </Row>
    </GroupWithRemoveButton>
  )
}

export default ObjectAreaItem
