import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import {
  AAgreementOnChangingCharacteristicsOfObjectArea,
  AAgreementOnChangingCharacteristicsOfObjectOption,
  AAgreementOnChangingCharacteristicsOfObjectRegion,
  IConstructionExploitation,
  IConstructionObject,
  IConstructionPermission,
  IConstructionRegistration,
  StepCompensation,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/types'
import {
  AAgreementOnChangingCharacteristicsOfObjectsFormValues,
  IConstructionStage,
  IConstructionSubObject,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/types'
import { isArray } from '@helpers/checkTypes'

const generateRHFObjectForAAgreementOnChangingCharacteristicsOfObjectsForm = (
  objectFromPropertyState: Record<string, any>,
): AAgreementOnChangingCharacteristicsOfObjectsFormValues => {
  const getPreparedObjectRegions = (
    objectRegions: any[],
  ): AAgreementOnChangingCharacteristicsOfObjectRegion[] => {
    if (!isArray(objectRegions) || !objectRegions.length) return []

    return objectRegions.map(
      (objectRegion): AAgreementOnChangingCharacteristicsOfObjectRegion => ({
        id: objectRegion?.value?.objectRegionCode?.propertyId,
        objectRegion: objectRegion?.value?.objectRegionCode?.value || '',
        objectRegionCommited: objectRegion?.value?.objectRegionCode?.commitedValue || '',
      }),
    )
  }

  const preparedConstructionStages: IConstructionStage[] = (() => {
    return objectFromPropertyState.steps?.value.map((step, indexOfStep): IConstructionStage => {
      const stepAnchorId = `5.${indexOfStep + 1}`

      const preparedStepCompensations = ((): StepCompensation[] => {
        if (
          !isArray(step?.value.stepCompensations?.value) ||
          !step?.value?.stepCompensations?.value.length
        )
          return []

        return step?.value?.stepCompensations?.value?.map(
          (stepCompensation): StepCompensation => ({
            id: stepCompensation.propertyId,
            stepCompensationType: !!stepCompensation.value.stepCompensationType?.value?.id
              ? {
                  displayValue: stepCompensation.value?.stepCompensationType?.value?.name || '',
                  value: stepCompensation.value?.stepCompensationType?.value?.id || '',
                }
              : null,
            stepCompensationTypeCommited: !!stepCompensation.value.stepCompensationType
              ?.commitedValue?.id
              ? {
                  displayValue:
                    stepCompensation.value?.stepCompensationType?.commitedValue?.name || '',
                  value: stepCompensation.value?.stepCompensationType?.commitedValue?.id || '',
                }
              : null,
            stepCompensationPeriod:
              stepCompensation.value?.stepCompensationPeriod?.value?.name || '',
            stepCompensationPeriodCommited:
              stepCompensation.value?.stepCompensationPeriod?.commitedValue?.name || '',
          }),
        )
      })()

      const checkboxes = (() => {
        const chosenCheckboxes = {
          isConstruction: false,
          isReconstruction: false,
          isModernization: false,
          isCreateRID: false,
        }

        step.value.stepActivityNames?.value.forEach((stepActivity) => {
          if (stepActivity.value?.name === 'Строительство (создание)') {
            chosenCheckboxes['isConstruction'] = true
          }

          if (stepActivity.value?.name === 'Реконструкция') {
            chosenCheckboxes['isReconstruction'] = true
          }

          if (stepActivity.value?.name === 'Модернизация') {
            chosenCheckboxes['isModernization'] = true
          }

          if (stepActivity.value?.name === 'Создание РИД') {
            chosenCheckboxes['isCreateRID'] = true
          }
        })

        return chosenCheckboxes
      })()

      const preparedConstructionObjects = (() => {
        const objects = step.value.objects?.value?.filter((object) => {
          return (
            object.value.isMainObject?.value &&
            !object.value.isSubObject?.value &&
            !object.value.objectOtherActivityName?.value?.id
          )
        })

        const subObjects = step.value.objects?.value?.filter((object) => {
          return (
            object.value.isSubObject?.value &&
            !object.value.objectOtherActivityName?.value?.id &&
            object.value.parentSubObject?.value
          )
        })

        const preparedConstructionObjects: IConstructionObject[] = objects?.map(
          (object, indexOfObject): IConstructionObject => {
            const objectAnchorId = `${stepAnchorId}.1.${indexOfObject + 1}`

            const preparedSubObjects: IConstructionSubObject[] = subObjects
              ?.filter((subObject) => {
                return object.permanentId === subObject.value.parentSubObject?.value?.id
              })
              .map((subObject, indexOfSubObject): IConstructionSubObject => {
                return {
                  anchorId: `${objectAnchorId}.${indexOfSubObject + 1}`,
                  id: subObject.propertyId,
                  objectName: subObject.value.objectName?.value,
                  objectNameCommited: subObject.value.objectName?.commitedValue,
                  objectRegions: getPreparedObjectRegions(subObject.value?.objectRegions?.value),
                  objectConstructionKind: subObject.value?.objectConstructionKind?.value,
                  objectConstructionKindCommited:
                    subObject.value?.objectConstructionKind?.commitedValue,
                  objectPurpose: subObject.value?.objectPurpose?.value,
                  objectPurposeCommited: subObject.value?.objectPurpose?.commitedValue,
                  objectConstructionSquare: subObject.value?.objectConstructionSquare?.value,
                  objectConstructionSquareCommited:
                    subObject.value?.objectConstructionSquare?.commitedValue,
                  objectSquare: subObject.value.objectSquare?.value,
                  objectSquareCommited: subObject.value.objectSquare?.commitedValue,
                  objectNonLivingAreaSquare: subObject.value?.objectNonLivingAreaSquare?.value,
                  objectNonLivingAreaSquareCommited:
                    subObject.value?.objectNonLivingAreaSquare?.commitedValue,
                  objectLivingAreaSquare: subObject.value?.objectLivingAreaSquare?.value,
                  objectLivingAreaSquareCommited:
                    subObject.value?.objectLivingAreaSquare?.commitedValue,
                  objectFloor: subObject.value.objectFloor?.value,
                  objectFloorCommited: subObject.value.objectFloor?.commitedValue,
                  objectUndergroundFloor: subObject.value?.objectUndergroundFloor?.value,
                  objectUndergroundFloorCommited:
                    subObject.value.objectUndergroundFloor?.commitedValue,
                  objectHeight: subObject.value?.objectHeight?.value,
                  objectHeightCommited: subObject.value?.objectHeight?.commitedValue,
                  objectDetails: subObject.value.objectDetails?.value,
                  objectDetailsCommited: subObject.value.objectDetails?.commitedValue,
                  objectIsInfrastructure: subObject.value.isInfrastructure?.value,
                  objectIsInfrastructureCommited: subObject.value.isInfrastructure?.commitedValue,
                  objectInfrastructureType: !!subObject.value.infrastructureType?.value?.id
                    ? {
                        value: subObject.value.infrastructureType.value?.id || '',
                        displayValue: subObject.value.infrastructureType.value?.name || '',
                      }
                    : null,
                  objectInfrastructureTypeCommited: !!subObject.value.infrastructureType
                    ?.commitedValue?.id
                    ? {
                        value: subObject.value.infrastructureType.commitedValue?.id || '',
                        displayValue: subObject.value.infrastructureType.commitedValue?.name || '',
                      }
                    : null,
                  oldIsCapitalObject: subObject.value.oldIsCapitalObject?.value,
                  oldIsCapitalObjectCommited: subObject.value.oldIsCapitalObject?.commitedValue,
                  oldCreatedDocument: subObject.value.oldCreatedDocument?.value,
                  oldCreatedDocumentCommited: subObject.value.oldCreatedDocument?.commitedValue,
                  oldCreatedOrganization: subObject.value.oldCreatedOrganization?.value,
                  oldCreatedOrganizationCommited:
                    subObject.value.oldCreatedOrganization?.commitedValue,
                }
              })

            const preparedObjectAreas =
              isArray(object.value?.objectAreas?.value) && object.value?.objectAreas?.value?.length
                ? object.value?.objectAreas?.value.map(
                    (objectArea): AAgreementOnChangingCharacteristicsOfObjectArea => {
                      return {
                        id: objectArea.propertyId,
                        objectAreaNumber: objectArea.value.objectAreaNumber?.value || '',
                        objectAreaNumberCommited: objectArea.value.objectAreaNumber?.commitedValue,
                        objectAreaSquare: objectArea.value.objectAreaSquare?.value || '',
                        objectAreaSquareCommited: objectArea.value.objectAreaSquare?.commitedValue,
                      }
                    },
                  )
                : []

            return {
              anchorId: objectAnchorId,
              id: object.permanentId,
              objectName: object.value.objectName?.value,
              objectNameCommited: object.value.objectName?.commitedValue,
              objectRegions: getPreparedObjectRegions(object.value?.objectRegions?.value),
              objectAreas: preparedObjectAreas,
              objectConstructionKind: object.value?.objectConstructionKind?.value,
              objectConstructionKindCommited: object.value?.objectConstructionKind?.commitedValue,
              objectPurpose: object.value?.objectPurpose?.value,
              objectPurposeCommited: object.value?.objectPurpose?.commitedValue,
              objectConstructionSquare: object.value?.objectConstructionSquare?.value,
              objectConstructionSquareCommited:
                object.value?.objectConstructionSquare?.commitedValue,
              objectSquare: object.value.objectSquare?.value,
              objectSquareCommited: object.value.objectSquare?.commitedValue,
              objectNonLivingAreaSquare: object.value?.objectNonLivingAreaSquare?.value,
              objectNonLivingAreaSquareCommited:
                object.value?.objectNonLivingAreaSquare?.commitedValue,
              objectLivingAreaSquare: object.value?.objectLivingAreaSquare?.value,
              objectLivingAreaSquareCommited: object.value?.objectLivingAreaSquare?.commitedValue,
              objectFloor: object.value.objectFloor?.value,
              objectFloorCommited: object.value.objectFloor?.commitedValue,
              objectUndergroundFloor: object.value?.objectUndergroundFloor?.value,
              objectUndergroundFloorCommited: object.value.objectUndergroundFloor?.commitedValue,
              objectHeight: object.value?.objectHeight?.value,
              objectHeightCommited: object.value?.objectHeight?.commitedValue,
              objectDetails: object.value.objectDetails?.value,
              objectDetailsCommited: object.value.objectDetails?.commitedValue,
              objectIsInfrastructure: object.value.isInfrastructure?.value,
              objectIsInfrastructureCommited: object.value.isInfrastructure?.commitedValue,
              objectInfrastructureType: !!object.value.infrastructureType?.value?.id
                ? {
                    value: object.value.infrastructureType.value?.id || '',
                    displayValue: object.value.infrastructureType.value?.name || '',
                  }
                : null,
              objectInfrastructureTypeCommited: !!object.value.infrastructureType?.commitedValue?.id
                ? {
                    value: object.value.infrastructureType.commitedValue?.id || '',
                    displayValue: object.value.infrastructureType.commitedValue?.name || '',
                  }
                : null,
              subObjects: preparedSubObjects,
              oldIsCapitalObject: object.value.oldIsCapitalObject?.value,
              oldIsCapitalObjectCommited: object.value.oldIsCapitalObject?.commitedValue,
              oldCreatedDocument: object.value.oldCreatedDocument?.value,
              oldCreatedDocumentCommited: object.value.oldCreatedDocument?.commitedValue,
              oldCreatedOrganization: object.value.oldCreatedOrganization?.value,
              oldCreatedOrganizationCommited: object.value.oldCreatedOrganization?.commitedValue,
            }
          },
        )

        return preparedConstructionObjects
      })()

      const preparedPermissions = ((): IConstructionPermission[] => {
        if (!step.value.permissions?.value || !step.value.permissions.value.length) return []

        return step.value.permissions?.value.map(
          (permission, indexOfPermission): IConstructionPermission => {
            const preparedObjectNamePermissions =
              ((): AAgreementOnChangingCharacteristicsOfObjectOption[] =>
                permission.value?.permissionObjects?.value?.map(
                  (permissionObject): AAgreementOnChangingCharacteristicsOfObjectOption => ({
                    id: permissionObject.propertyId,
                    objectOption: !!permissionObject?.value?.id
                      ? {
                          value: permissionObject?.value?.id || '',
                          displayValue: permissionObject?.value?.objectName || '',
                        }
                      : null,
                  }),
                ))()

            return {
              anchorId: `${stepAnchorId}.2.${indexOfPermission + 1}`,
              id: permission.propertyId,
              title: `РАЗРЕШЕНИЕ №${indexOfPermission + 1}`,
              permissionKind: !!permission.value.permissionKind?.value?.id
                ? {
                    value: permission.value.permissionKind?.value?.id || '',
                    displayValue: permission.value.permissionKind?.value?.name || '',
                  }
                : null,
              permissionKindCommited: !!permission.value.permissionKind?.commitedValue?.id
                ? {
                    value: permission.value.permissionKind?.commitedValue?.id || '',
                    displayValue: permission.value.permissionKind?.commitedValue?.name || '',
                  }
                : null,
              permissionOrganization: permission.value.permissionOrganization?.value,
              permissionOrganizationCommited:
                permission.value.permissionOrganization?.commitedValue,
              objectNamePermissions: preparedObjectNamePermissions,
              isReceivedPermission: permission.value.isReceivedPermission?.value,
              isReceivedPermissionCommited: permission.value.isReceivedPermission?.commitedValue,
              permissionDate: permission.value.permissionDate?.value,
              permissionDateCommited: permission.value.permissionDate?.commitedValue,
              permissionNumber: permission.value.permissionNumber?.value,
              permissionNumberCommited: permission.value.permissionNumber?.commitedValue,
              receivedPermissionDate: permission.value.receivedPermissionDate?.value,
              receivedPermissionDateCommited:
                permission.value.receivedPermissionDate?.commitedValue,
            }
          },
        )
      })()

      const preparedExploitationObjects = ((): IConstructionExploitation[] => {
        if (!step.value.commissionObjects?.value || !step.value.commissionObjects?.value.length)
          return []

        return step.value.commissionObjects?.value.map(
          (exploitation, indexOfExploitation): IConstructionExploitation => ({
            anchorId: `${stepAnchorId}.3.${indexOfExploitation + 1}`,
            id: exploitation.propertyId,
            title: `Ввод в эксплуатацию №${indexOfExploitation + 1}`,
            objectName: !!exploitation.value?.commissionObjectId?.value?.id
              ? {
                  value: exploitation.value?.commissionObjectId?.value?.id || '',
                  displayValue: exploitation.value?.commissionObjectId?.value?.objectName || '',
                }
              : null,
            objectCommissioningDate: exploitation.value?.commissionObjectDate?.value,
            objectCommissioningDateCommited:
              exploitation.value?.commissionObjectDate?.commitedValue,
            objectPermissionCommissionNumber: !!exploitation.value?.commissionPermissionNumber
              ?.value?.id
              ? {
                  value: exploitation.value?.commissionPermissionNumber?.value?.id || '',
                  displayValue:
                    exploitation.value?.commissionPermissionNumber?.value?.permissionNumber || '',
                }
              : null,
          }),
        )
      })()

      const preparedRegistrationObjects = ((): IConstructionRegistration[] => {
        if (!step.value.registrationObjects?.value || !step.value.registrationObjects?.value.length)
          return []

        return step.value.registrationObjects?.value.map(
          (registration, indexOfRegistration): IConstructionRegistration => ({
            anchorId: `${stepAnchorId}.4.${indexOfRegistration + 1}`,
            id: registration.propertyId,
            title: `Регистрация №${indexOfRegistration + 1}`,
            objectName: !!registration.value?.registrationObjectId?.value?.id
              ? {
                  value: registration.value?.registrationObjectId?.value?.id || '',
                  displayValue: registration.value?.registrationObjectId?.value?.objectName || '',
                }
              : null,
            objectType: registration.value?.stateRegistrationType?.value,
            objectTypeCommited: registration.value?.stateRegistrationType?.commitedValue,
            estateObjectStateRegistrationDate:
              registration.value?.objectStateRegistrationDate?.value,
            estateObjectStateRegistrationDateCommited:
              registration.value?.objectStateRegistrationDate?.commitedValue,
            estateObjectStateRegistrationNumber:
              registration.value?.objectStateRegistrationNumber?.value,
            estateObjectStateRegistrationNumberCommited:
              registration.value?.objectStateRegistrationNumber?.commitedValue,
            estateObjectStateRegistrationOrganization:
              registration.value?.objectStateRegistrationOrganization?.value,
            estateObjectStateRegistrationOrganizationCommited:
              registration.value?.objectStateRegistrationOrganization?.commitedValue,
          }),
        )
      })()

      return {
        id: step.propertyId,
        anchorId: stepAnchorId,
        stepNumber: step.value.stepNumber?.value,
        stepNumberCommitted: step.value.stepNumber?.commitedValue,
        stepDescription: step.value.stepDescription?.value,
        stepDescriptionCommited: step.value.stepDescription?.commitedValue,
        stepCompensationValue: step.value.stepCompensationValue?.value,
        stepCompensationValueCommited: step.value.stepCompensationValue?.commitedValue,
        stepCompensationForm: !!step.value.stepCompensationForm?.value?.id
          ? {
              value: step.value?.stepCompensationForm?.value?.id || '',
              displayValue: step.value?.stepCompensationForm?.value?.name || '',
            }
          : null,
        stepCompensationFormCommited: !!step.value.stepCompensationForm?.commitedValue?.id
          ? {
              value: step.value?.stepCompensationForm?.commitedValue?.id || '',
              displayValue: step.value?.stepCompensationForm?.commitedValue?.name || '',
            }
          : null,

        // Нужен для валидации
        stepStartDate: step.value.stepStartDate?.value,

        stepEndDate: step.value.stepEndDate?.value,
        stepEndDateCommited: step.value.stepEndDate?.commitedValue,

        //Фейковое свойство для расчётов
        stepActivityNames: step.value.stepActivityNames?.value,

        stepCompensations: preparedStepCompensations,

        ...checkboxes,
        constructionObjects: preparedConstructionObjects,
        permissionsObjects: preparedPermissions,

        exploitation: {
          objectCommissioningEndDate: step.value.objectCommissioningEndDate?.value,
          objectCommissioningEndDateCommited: step.value.objectCommissioningEndDate?.commitedValue,
          exploitationObjects: preparedExploitationObjects,
        },

        registration: {
          typeObjectStateRegistration: step.value.typeObjectStateRegistration?.value,
          typeObjectStateRegistrationCommited:
            step.value.typeObjectStateRegistration?.commitedValue,
          objectStateRegistrationEndDate: step.value.objectStateRegistrationEndDate?.value,
          objectStateRegistrationEndDateCommited:
            step.value.objectStateRegistrationEndDate?.commitedValue,
          registrationObjects: preparedRegistrationObjects,
        },
      }
    })
  })()

  return {
    '1': RHFBasicAdapters.getRFAdapter(objectFromPropertyState),
    '2': {
      subjects: RHFBasicAdapters.getSubjectsAdapter(objectFromPropertyState),
    },
    '3': {
      municipalities: RHFBasicAdapters.getMunicipalitiesAdapter(objectFromPropertyState),
    },
    '4': RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    '5': {
      constructionStages: preparedConstructionStages,
    },
    additionalFields: {
      projectEndDate: objectFromPropertyState?.projectEndDate?.value,
      isFederal: objectFromPropertyState?.isFederal?.value,
      isPaper: objectFromPropertyState.isPaper?.value,
      initialMunicipalities: objectFromPropertyState.municipalities?.value,
      allProjectObjectsLength: isArray(objectFromPropertyState.steps?.value)
        ? objectFromPropertyState.steps?.value.reduce((accum, currentValue) => {
            if (isArray(currentValue.value.objects?.value)) {
              return accum + currentValue.value.objects?.value.length
            }

            return accum
          }, 0)
        : 0,
    },
  }
}

export { generateRHFObjectForAAgreementOnChangingCharacteristicsOfObjectsForm }
