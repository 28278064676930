import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import FieldView from '@components/DocumentFormComponents/FieldView'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { registrationCertificateRelatedContractItemValidationMap } from '@components/Forms/RegistrationCertificateForm/Forms/7/RelatedContractListRegisteredDocumentValueItem/validation'
import { RelatedContractListRegisteredDocumentValuesArrayPathName } from '@components/Forms/RegistrationCertificateForm/Forms/7/types'
import { useRegistrationCertificateManager } from '@components/Forms/RegistrationCertificateForm/Manager'
import {
  RegistrationCertificateFormModifierValues,
  RegistrationCertificateFormValues,
} from '@components/Forms/RegistrationCertificateForm/types'
import { RegistrationCertificateFieldsControlUpdateWatcher } from '@components/Forms/RegistrationCertificateForm/watcher'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import ControlledTextarea from '@components/NewDesign/Textarea/ControlledTextarea'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'

interface RelatedContractListRegisteredDocumentValueItemProps {
  id: string
  formName: RelatedContractListRegisteredDocumentValuesArrayPathName
  formInstance: UseFormReturn<RegistrationCertificateFormValues>
  formModifierInstance: UseFormReturn<RegistrationCertificateFormModifierValues>
  onRemove?: () => Promise<void>
}

const RelatedContractListRegisteredDocumentValueItem: FC<
  RelatedContractListRegisteredDocumentValueItemProps
> = ({ id, formInstance, formModifierInstance, formName, onRemove }) => {
  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleChangeValue, debouncedHandleChangeValue, handleChangePublicValueInList },
    preparedProps: { subscribableControl },
  } = useRegistrationCertificateManager()

  const { getSubscribableControlProps, getInputProps, getCalendarInputProps, getTextareaProps } =
    useFormComponentPresets({
      editMode,
      blockViewIsValidating,
      formInstance,
      watcher: RegistrationCertificateFieldsControlUpdateWatcher,
      subscribableControl,
    })

  return (
    <FieldView.StepTemplate id={id} onRemoveStep={onRemove}>
      <FieldView.ContainerWithPublic
        editMode={editMode}
        control={formModifierInstance.control}
        publicFormName={`${formName}.isPublic`}
        formIconWithTooltipProps={{
          tooltipContent: 'Данные будут отображены в публичной части реестра СЗПК',
        }}
        onPublic={handleChangePublicValueInList}
      >
        <Row>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.relatedContractNumberRegisteredDocument`,
              })}
            >
              <ControlledInput
                {...getInputProps({
                  name: `${formName}.relatedContractNumberRegisteredDocument`,
                  rules:
                    registrationCertificateRelatedContractItemValidationMap.relatedContractNumberRegisteredDocument,
                  inputProps: {
                    label: 'Номер',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${formName}.relatedContractNumberRegisteredDocument`)
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        `${formName}.relatedContractNumberRegisteredDocument`,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
          <Col xs={6}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.relatedContractDateRegisteredDocument`,
              })}
            >
              <ControlledCalendarInput
                {...getCalendarInputProps({
                  name: `${formName}.relatedContractDateRegisteredDocument`,
                  rules:
                    registrationCertificateRelatedContractItemValidationMap.relatedContractDateRegisteredDocument,
                  calendarInputProps: {
                    label: 'Дата заключения',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${formName}.relatedContractDateRegisteredDocument`)
                    }, 0),
                  onInputChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        `${formName}.relatedContractDateRegisteredDocument`,
                      )
                    }, 0),
                  onCalendarChange: () =>
                    setTimeout(() => {
                      handleChangeValue?.(`${formName}.relatedContractDateRegisteredDocument`)
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.relatedContractDescriptionRegisteredDocument`,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: `${formName}.relatedContractDescriptionRegisteredDocument`,
                  rules:
                    registrationCertificateRelatedContractItemValidationMap.relatedContractDescriptionRegisteredDocument,
                  textareaProps: {
                    label: 'Предмет договора',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(
                        `${formName}.relatedContractDescriptionRegisteredDocument`,
                      )
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        `${formName}.relatedContractDescriptionRegisteredDocument`,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.relatedContractOrganizationRegisteredDocument`,
              })}
            >
              <ControlledTextarea
                {...getTextareaProps({
                  name: `${formName}.relatedContractOrganizationRegisteredDocument`,
                  rules:
                    registrationCertificateRelatedContractItemValidationMap.relatedContractOrganizationRegisteredDocument,
                  textareaProps: {
                    label: 'Публично правовое образование, которому подлежит возмещать ущерб',
                  },
                  onBlur: () =>
                    setTimeout(() => {
                      handleChangeValue?.(
                        `${formName}.relatedContractOrganizationRegisteredDocument`,
                      )
                    }, 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(
                        `${formName}.relatedContractOrganizationRegisteredDocument`,
                      )
                    }, 0),
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
      </FieldView.ContainerWithPublic>
    </FieldView.StepTemplate>
  )
}

export default RelatedContractListRegisteredDocumentValueItem
