import { PropertiesPropsBasicAdapters } from '@components/DocumentFormComponents/adapters/PropertiesProps.adapter'

const generatePropertiesObjectForNotificationFillingAppConcludingForm = (
  objectFromPropertyState: Record<string, any>,
): Record<string, unknown> => {
  return {
    '1': {
      ...PropertiesPropsBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
    },
    '2': {
      ...PropertiesPropsBasicAdapters.getUOAdapter(objectFromPropertyState),
    },
    '3': {
      ...PropertiesPropsBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      investorFullNameGenitive: {
        propertyId: objectFromPropertyState?.investorFullNameGenitive?.propertyId,
        type: objectFromPropertyState?.investorFullNameGenitive?.type,
        lastUpdateDt: objectFromPropertyState?.investorFullNameGenitive?.lastUpdateDt,
      },
    },
    '4': {
      projectName: {
        propertyId: objectFromPropertyState.projectName?.propertyId,
        type: objectFromPropertyState.projectName?.type,
        lastUpdateDt: objectFromPropertyState.projectName?.lastUpdateDt,
      },
      extraTechError: {
        propertyId: objectFromPropertyState.extraTechError?.propertyId,
        type: objectFromPropertyState.extraTechError?.type,
        lastUpdateDt: objectFromPropertyState.extraTechError?.lastUpdateDt,
      },
      extraSetDocumentError: {
        propertyId: objectFromPropertyState.extraSetDocumentError?.propertyId,
        type: objectFromPropertyState.extraSetDocumentError?.type,
        lastUpdateDt: objectFromPropertyState.extraSetDocumentError?.lastUpdateDt,
      },
      extraRuleLinkError: {
        propertyId: objectFromPropertyState.extraRuleLinkError?.propertyId,
        type: objectFromPropertyState.extraRuleLinkError?.type,
        lastUpdateDt: objectFromPropertyState.extraRuleLinkError?.lastUpdateDt,
      },
      ruleLinkExtraError: {
        propertyId: objectFromPropertyState.ruleLinkExtraError?.propertyId,
        type: objectFromPropertyState.ruleLinkExtraError?.type,
        lastUpdateDt: objectFromPropertyState.ruleLinkExtraError?.lastUpdateDt,
      },
      extraOtherError: {
        propertyId: objectFromPropertyState.extraOtherError?.propertyId,
        type: objectFromPropertyState.extraOtherError?.type,
        lastUpdateDt: objectFromPropertyState.extraOtherError?.lastUpdateDt,
      },
      extraDaysRevision: {
        propertyId: objectFromPropertyState.extraDaysRevision?.propertyId,
        type: objectFromPropertyState.extraDaysRevision?.type,
        lastUpdateDt: objectFromPropertyState.extraDaysRevision?.lastUpdateDt,
      },
    },
  }
}

export { generatePropertiesObjectForNotificationFillingAppConcludingForm }
