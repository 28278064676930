import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { MapOfMenu } from '@components/DocumentFormComponents/types'

const notificationOfIdentifiedNPAListErrorsMenuHashMap: NestedMenuHashMap = {
  '1': {
    id: '1',
    path: '1',
    title: 'Сведения о документе',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '2': {
    id: '2',
    path: '2',
    title: 'Уполномоченная организация',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '3': {
    id: '3',
    path: '3',
    title: 'Организация, реализующая проект',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
  '4': {
    id: '4',
    path: '4',
    title: 'Выявленные несоответствия',
    parentBaseId: null,
    isCollapsed: false,
    hasError: false,
    children: [],
  },
}

const notificationOfIdentifiedNPAListErrorsMenuState = Object.values(
  notificationOfIdentifiedNPAListErrorsMenuHashMap,
)

const mapOfNotificationOfIdentifiedNPAListErrorsMenu =
  notificationOfIdentifiedNPAListErrorsMenuState.map(
    (menu): MapOfMenu => ({
      id: menu.id,
      title: menu.title,
    }),
  )

export {
  mapOfNotificationOfIdentifiedNPAListErrorsMenu,
  notificationOfIdentifiedNPAListErrorsMenuHashMap,
  notificationOfIdentifiedNPAListErrorsMenuState,
}
