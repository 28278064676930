import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  RegistrationCertificateFormValues,
  RegistrationCertificateMunicipality,
} from '@components/Forms/RegistrationCertificateForm/types'
import { defaultRHFValidation } from '@constants/validations'

const municipalityRegistrationCertificateValidationMap: FormValuesValidationSection<
  keyof RegistrationCertificateMunicipality,
  RegistrationCertificateFormValues
> = {
  municipalityHeaderName: {
    required: defaultRHFValidation.required,
  },
}

export { municipalityRegistrationCertificateValidationMap }
