import React, { FC } from 'react'
import { useFormContext, UseFormReturn, useWatch } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormFieldControl from '@components/DocumentFormComponents/FormControls/FormFieldControl'
import FormObjectTooltipControl from '@components/DocumentFormComponents/FormControls/FormObjectTooltipControl'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/const'
import {
  SeventhRegistrationObjectsPathName,
  SeventhRegistrationPathName,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/types'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import Button from '@components/NewDesign/Button'
import { ControlledCalendarInput } from '@components/NewDesign/CalendarInput/ControlledCalendarInput'
import { ControlledInput } from '@components/NewDesign/Input/ControlledInput'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { objOfDateFormats } from '@constants/dateFormats'
import CircleAddIcon from '@icons/CircleAddIcon.svg'
import { isDateValidForDayjs } from '@services/Dayjs/Dayjs.entity'
import DayjsService from '@services/Dayjs/Dayjs.service'

import RegistrationItem from './RegistrationItem'
import { eRegistrationAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap } from './validation'

interface CRegistrationFormProps {
  isNew: boolean
  name: SeventhRegistrationPathName
  indexOfBlock: number
  formModifierInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>
}

const CRegistrationForm: FC<CRegistrationFormProps> = ({
  isNew,
  name,
  indexOfBlock,
  formModifierInstance,
}) => {
  const formInstance =
    useFormContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: {
      handleRemoveItemFromList,
      handleAddItemToListWithOutValue,
      handleUpElementInList,
      handleDownElementInList,
      debouncedHandleChangeValue,
      handleChangeValue,
    },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const {
    getSubscribableControlProps,
    getFormFieldControlProps,
    getInputProps,
    getCalendarInputProps,
  } = useFormComponentPresets({
    isNew,
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  const { fields: registrationForms } = useFieldArraySubscribableControl<
    AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
    SeventhRegistrationObjectsPathName,
    'keyNameId'
  >({
    name: `${name}.registrationObjects`,
    control: formInstance.control,
    keyName: 'keyNameId',
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  })

  const handleAddRegistration = async () => {
    await handleAddItemToListWithOutValue?.(`${name}.registrationObjects`)
  }

  const handleRemoveRegistration = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${name}.registrationObjects.${index}`,
      `${name}.registrationObjects`,
    )
  }

  const handleChangeElementOrderToUp = (index: number) => async () => {
    await handleUpElementInList?.(`${name}.registrationObjects.${index}`)
  }

  const handleChangeElementOrderToDown = (index: number) => async () => {
    await handleDownElementInList?.(`${name}.registrationObjects.${index}`)
  }

  const stepEndDate = useWatch({
    name: `${aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['7'].stages}.${indexOfBlock}.stepEndDate`,
    control: formInstance.control,
  })

  const positiveStepEndDate = (text: string) => (value: unknown) => {
    if (
      isDateValidForDayjs(value) &&
      DayjsService.dayjs(value, objOfDateFormats.defaultFormat) >
        DayjsService.dayjs(stepEndDate, objOfDateFormats.defaultFormat)
    )
      return text
  }

  if (!formInstance) return null

  return (
    <Container className="p-0 pt-3 px-0">
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={8}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: `${name}.typeObjectStateRegistration`,
                formFieldTooltipProps: {
                  onChange: () =>
                    setTimeout(() => handleChangeValue?.(`${name}.typeObjectStateRegistration`), 0),
                  onDifference: handleUpdateChanges,
                },
              })}
            >
              <ControlledInput
                {...getInputProps({
                  name: `${name}.typeObjectStateRegistration`,
                  rules:
                    eRegistrationAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap.typeObjectStateRegistration,
                  inputProps: {
                    label: 'Вид права, подлежащего гос.регистрации',
                    dataTestId: 'typeObjectStateRegistration',
                  },
                  onBlur: () =>
                    setTimeout(() => handleChangeValue?.(`${name}.typeObjectStateRegistration`), 0),
                  onChange: () =>
                    setTimeout(() => {
                      debouncedHandleChangeValue?.(`${name}.typeObjectStateRegistration`)
                    }, 0),
                })}
              />
            </FormFieldControl>
          </Col>
          <Col xs={4}>
            <FormFieldControl
              {...getFormFieldControlProps({
                path: `${name}.objectStateRegistrationEndDate`,
                formFieldTooltipProps: {
                  onChange: () =>
                    setTimeout(
                      () => handleChangeValue?.(`${name}.objectStateRegistrationEndDate`),
                      0,
                    ),
                  onDifference: handleUpdateChanges,
                },
              })}
            >
              <ControlledCalendarInput
                {...getCalendarInputProps({
                  name: `${name}.objectStateRegistrationEndDate`,
                  rules: {
                    ...eRegistrationAAgreementOnNonConclusionOrNonPerformanceOfConcessionAValidationMap.objectStateRegistrationEndDate,
                    validate: {
                      positiveStepEndDate: (value) => {
                        return (
                          (isDateValidForDayjs(value) &&
                            DayjsService.dayjs(value, objOfDateFormats.defaultFormat).isSame(
                              DayjsService.dayjs(stepEndDate, objOfDateFormats.defaultFormat),
                              'd',
                            )) ||
                          'дата регистрации всех объектов должна быть равна дате окончания этапа'
                        )
                      },
                    },
                  },
                  calendarInputProps: {
                    label: 'Дата регистрации',
                  },
                  onBlur: () =>
                    setTimeout(
                      () => handleChangeValue?.(`${name}.objectStateRegistrationEndDate`),
                      0,
                    ),
                  onInputChange: () =>
                    debouncedHandleChangeValue?.(`${name}.objectStateRegistrationEndDate`),
                  onCalendarChange: () =>
                    setTimeout(
                      () => handleChangeValue?.(`${name}.objectStateRegistrationEndDate`),
                      0,
                    ),
                })}
              />
            </FormFieldControl>
          </Col>
        </Row>
        <Stack direction="vertical" gap={!!registrationForms.length ? 3 : 0} className={'mt-3'}>
          <SubscribableControl
            {...getSubscribableControlProps({
              path: `${name}.registrationObjects`,
            })}
          >
            <FlipperList list={registrationForms}>
              {registrationForms.map((registrationForm, index) => {
                const formName = `${name}.registrationObjects.${index}` as const

                return (
                  <SubscribableControl
                    key={registrationForm.id}
                    {...getSubscribableControlProps({
                      path: formName,
                    })}
                  >
                    <FormObjectTooltipControl
                      name={formName}
                      control={formModifierInstance.control}
                      onDifference={handleUpdateChanges}
                    >
                      <RegistrationItem
                        id={registrationForm.id}
                        anchorId={registrationForm.anchorId}
                        formName={formName}
                        blockViewIsValidating={blockViewIsValidating}
                        editMode={editMode}
                        formInstance={formInstance}
                        formModifierInstance={formModifierInstance}
                        indexOfRegistration={index}
                        lastIndexOfRegistration={registrationForms.length - 1}
                        positiveStepEndDate={positiveStepEndDate}
                        onMoveTop={handleChangeElementOrderToUp(index)}
                        onMoveBottom={handleChangeElementOrderToDown(index)}
                        onDeleteRegistration={handleRemoveRegistration(index)}
                      />
                    </FormObjectTooltipControl>
                  </SubscribableControl>
                )
              })}
            </FlipperList>
          </SubscribableControl>
          {editMode && (
            <Row className={'px-1'}>
              <Col xs={9}>
                <AsyncWrapper promise={handleAddRegistration}>
                  {({ isLoading, wrappedPromise }) => {
                    return (
                      <Button
                        disabled={isLoading}
                        leadingIcon={{ src: CircleAddIcon }}
                        variant={'buttonSMedium'}
                        size={'2xs'}
                        view={'plain'}
                        loaderProps={{
                          loading: isLoading,
                          placement: 'trailing',
                          variant: 'lite',
                        }}
                        onClick={wrappedPromise}
                      >
                        Указать зарегистрированный объект
                      </Button>
                    )
                  }}
                </AsyncWrapper>
              </Col>
            </Row>
          )}
        </Stack>
      </Stack>
    </Container>
  )
}

export default CRegistrationForm
