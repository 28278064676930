import { DfosStage } from '@constants/types'
import {
  mapOfStageStatementFilters,
} from '@routes/NewStatements/const'
import {
  BasketFilterValue,
  FilterFactory,
  StatementFilterFormValues,
} from '@routes/NewStatements/types'

class ROIVFilterController implements FilterFactory {
  readonly mapOfFiltersByType: Partial<
    Record<BasketFilterValue, Partial<StatementFilterFormValues>>
  > = {
    all: {
      stage: 'all',
      statementLvl: 'all',
      actionAllowed: 'all',
    },
    concluded: {
      stage: mapOfStageStatementFilters.active,
      dfoStageInclude: [
        DfosStage.REGISTERED,
        DfosStage.SIGNED,
        DfosStage.SIGNED_AFTER_DEADLINE,
        DfosStage.TERMINATING,
      ],
      isRegistered: true,
    },
    consideration: {
      stage: mapOfStageStatementFilters.active,
      dfoStageExclude: [
        DfosStage.DRAFT,
        DfosStage.INITIATOR_REVOKED,
        DfosStage.REJECTED,
        DfosStage.PARTNER_REJECTED,
        DfosStage.PARTNER_REVOKED,
        DfosStage.STOPPED,
        DfosStage.INITIATOR_REVOKED_NOTICE,
        DfosStage.NOTIFIED,
        DfosStage.PREPARING,
        DfosStage.COMPLEMENTING,
        DfosStage.SIGNED,
        DfosStage.SIGNED_AFTER_DEADLINE,
        DfosStage.TERMINATING,
        DfosStage.REGISTERED,
        DfosStage.FIRST_SIGNER_REJECTED,
        DfosStage.FIRST_CHECKER_REJECTED,
      ],
    },
    archive: {
      dfoStageInclude: [
        DfosStage.INITIATOR_REVOKED,
        DfosStage.REJECTED,
        DfosStage.PARTNER_REVOKED,
        DfosStage.PARTNER_REJECTED,
        DfosStage.STOPPED,
        DfosStage.INITIATOR_REVOKED_NOTICE,
        DfosStage.FIRST_SIGNER_REJECTED,
        DfosStage.FIRST_CHECKER_REJECTED,
        DfosStage.TERMINATED,
      ],
    },
  }

  getAllMapOfFilters() {
    return this.mapOfFiltersByType
  }

  getFilters(tabValue: BasketFilterValue): StatementFilterFormValues | Record<string, unknown> {
    if (!this.mapOfFiltersByType[tabValue]) return {}
    return this.mapOfFiltersByType[tabValue] as StatementFilterFormValues
  }
}

export { ROIVFilterController }
