import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormListTooltipControl from '@components/DocumentFormComponents/FormControls/FormListTooltipControl'
import FormObjectTooltipControl from '@components/DocumentFormComponents/FormControls/FormObjectTooltipControl'
import Group from '@components/DocumentFormComponents/Group'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import ObjectAreaItem from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/AdditionalForms/MainTemplate/ConstructionObject/Forms/CObjectsForm/ObjectFormItem/ObjectAreasList/ObjectAreaItem'
import {
  SeventhConstructionObjectAreasPathName,
  SeventhConstructionObjectsArrayPathName,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/types'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import Button from '@components/NewDesign/Button'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import CircleAddIcon from '@icons/CircleAddIcon.svg'

interface ObjectAreasListProps {
  editMode: boolean
  blockViewIsValidating: boolean
  formInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>
  formModifierInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>
  formName: SeventhConstructionObjectsArrayPathName
}

const ObjectAreasList: FC<ObjectAreasListProps> = ({
  editMode,
  blockViewIsValidating,
  formInstance,
  formModifierInstance,
  formName,
}) => {
  const {
    handlers: { handleAddItemToListWithOutValue, handleRemoveItemFromList },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
  })

  const { fields: objectAreas } = useFieldArraySubscribableControl<
    AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
    SeventhConstructionObjectAreasPathName,
    'keyNameId'
  >({
    control: formInstance.control,
    name: `${formName}.objectAreas`,
    keyName: 'keyNameId',
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  })

  const handleAddNewObjectArea = async () => {
    await handleAddItemToListWithOutValue?.(`${formName}.objectAreas`)
  }

  const handleRemoveObjectArea = (index: number) => async () => {
    await handleRemoveItemFromList?.(`${formName}.objectAreas.${index}`, `${formName}.objectAreas`)
  }

  return (
    <FormListTooltipControl
      disablePaddingRight
      enablePaddingLeft
      name={`${formName}.objectAreas`}
      control={formModifierInstance.control}
      onDifference={handleUpdateChanges}
    >
      {({ iconWithTooltip }) => (
        <Group disableBottomBorder title="Земельные участки" leftAddons={iconWithTooltip}>
          <Stack direction={'vertical'} gap={!objectAreas.length ? 0 : 3}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.objectAreas`,
              })}
            >
              <FlipperList list={objectAreas}>
                {objectAreas.map((objectArea, index) => {
                  const objectAreaFormName = `${formName}.objectAreas.${index}` as const

                  return (
                    <FormObjectTooltipControl
                      key={objectArea.id}
                      name={objectAreaFormName}
                      control={formModifierInstance.control}
                      onDifference={handleUpdateChanges}
                    >
                      <ObjectAreaItem
                        editMode={editMode}
                        blockViewIsValidating={blockViewIsValidating}
                        index={index}
                        id={objectArea.id}
                        name={objectAreaFormName}
                        formInstance={formInstance}
                        formModifierInstance={formModifierInstance}
                        onRemove={editMode ? handleRemoveObjectArea(index) : undefined}
                      />
                    </FormObjectTooltipControl>
                  )
                })}
              </FlipperList>
            </SubscribableControl>
            {editMode && (
              <Row className={'px-1'}>
                <Col xs={12}>
                  <AsyncWrapper promise={handleAddNewObjectArea}>
                    {({ isLoading, wrappedPromise }) => {
                      return (
                        <Button
                          disabled={isLoading}
                          leadingIcon={{ src: CircleAddIcon }}
                          variant={'buttonSMedium'}
                          size={'2xs'}
                          view={'plain'}
                          loaderProps={{
                            loading: isLoading,
                            placement: 'trailing',
                            variant: 'lite',
                          }}
                          onClick={wrappedPromise}
                        >
                          Добавить данные об участке (если применимо)
                        </Button>
                      )
                    }}
                  </AsyncWrapper>
                </Col>
              </Row>
            )}
          </Stack>
        </Group>
      )}
    </FormListTooltipControl>
  )
}

export default ObjectAreasList
