import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import {
  RegistrationCertificateFormModifierValues,
  RegistrationCertificateFormValues,
} from '@components/Forms/RegistrationCertificateForm/types'

const { getDefaultInfoAboutDocument, getDefaultFormFieldModifier, getDefaultInitiatorFieldState } =
  FieldStateHelpers

const mapOfRegistrationCertificateMenu = [
  { id: '1', title: 'Сведения о документе' },
  { id: '2', title: 'Уполномоченное должностное лицо' },
  { id: '3', title: 'Организация, реализующая проект' },
  { id: '4', title: 'Публично-правовые образования, являющиеся сторонами СЗПК' },
  { id: '5', title: 'Сведения о соглашении' },
  { id: '6', title: 'Заключенные дополнительные соглашения' },
  { id: '7', title: 'Связанные договоры' },
  { id: '8', title: 'Договоры, признанные связанными' },
  { id: '9', title: 'Сведения о результатах мониторинга' },
  { id: '10', title: 'Иные сведения' },
  { id: '11', title: 'Сведения о прекращении действия соглашения' },
]

const defaultRegistrationCertificateFormValues: RegistrationCertificateFormValues = {
  '1': getDefaultInfoAboutDocument(),
  '2': getDefaultInitiatorFieldState(),
  '3': {
    investorFullName: '',
    investorName: '',
    isProjectCompany: false,
    investorAddressFullEgrulFormat: '',
    investorInn: '',
    investorOgrn: '',
    investorEmail: '',
    investorPhone: '',
    investorHeaderName: null,
  },
  '4': {
    rfFullName: '',
    subjects: [],
    municipalities: [],
  },
  '5': {
    projectName: '',
    szpkSignDate: '',
    investorCapital: '',
    factMadeInvestorCapital: '',
    factRemainsInvestorCapital: '',
    stabilizationPeriod: '',
    plannedTax: [],
    generalPlannedTax: '',
    stepRegisteredDateValues: [],
  },
  '6': {
    extraRegisteredDocuments: [],
  },
  '7': {
    relatedContractListRegisteredDocumentValues: [],
  },
  '8': {
    relatedContracts: [],
  },
  '9': {
    infoMonitoringReport: '',
    infoMonitoringResult: '',
  },
  '10': {
    infoBudgetRF: '',
    infoDamageFormatCompensation: '',
    infoViolationStabilizationPeriod: '',
    infoPartner: '',
    infoDeposit: '',
    infoCession: '',
  },
  '11': {
    agreementTerminationReason: null,
    agreementTerminationReasonDescription: '',
    agreementTerminationReasonInvalidPart: '',
  },
  additionalFields: {
    isFederal: true
  }
}

const defaultRegistrationCertificateFormModifierValues: RegistrationCertificateFormModifierValues =
  {
    '3': {
      investorFullName: getDefaultFormFieldModifier(),
      investorName: getDefaultFormFieldModifier(),
      isProjectCompany: getDefaultFormFieldModifier(),
      investorAddressFullEgrulFormat: getDefaultFormFieldModifier(),
      investorInn: getDefaultFormFieldModifier(),
      investorOgrn: getDefaultFormFieldModifier(),
      investorEmail: getDefaultFormFieldModifier(),
      investorPhone: getDefaultFormFieldModifier(),
      investorHeaderName: getDefaultFormFieldModifier(),
    },
    '4': {
      rfFullName: getDefaultFormFieldModifier(),
      subjects: [],
      municipalities: [],
    },
    '5': {
      projectName: getDefaultFormFieldModifier(),
      szpkSignDate: getDefaultFormFieldModifier(),
      investorCapital: getDefaultFormFieldModifier(),
      factMadeInvestorCapital: getDefaultFormFieldModifier(),
      factRemainsInvestorCapital: getDefaultFormFieldModifier(),
      stabilizationPeriod: getDefaultFormFieldModifier(),
      plannedTax: getDefaultFormFieldModifier(),
      stepRegisteredDateValues: [],
    },
    '6': {
      extraRegisteredDocuments: [],
    },
    '7': {
      relatedContractListRegisteredDocumentValues: [],
    },
    '8': {
      relatedContracts: [],
    },
    '9': {
      infoMonitoringReport: getDefaultFormFieldModifier(),
      infoMonitoringResult: getDefaultFormFieldModifier(),
    },
    '10': {
      infoBudgetRF: getDefaultFormFieldModifier(),
      infoDamageFormatCompensation: getDefaultFormFieldModifier(),
      infoViolationStabilizationPeriod: getDefaultFormFieldModifier(),
      infoPartner: getDefaultFormFieldModifier(),
      infoDeposit: getDefaultFormFieldModifier(),
      infoCession: getDefaultFormFieldModifier(),
    },
    '11': {
      agreementTerminationReasonDescription: getDefaultFormFieldModifier(),
      agreementTerminationReasonInvalidPart: getDefaultFormFieldModifier(),
    },
  }

const registrationCertificateBlockValues = {
  '1': {
    documentDateSign: '1.documentDateSign',
    documentNumber: '1.documentNumber',
    documentReceivers: '1.documentReceivers',
  },
  '2': {
    initiatorHeaderName: '2.initiatorHeaderName',
    initiatorHeaderNameGenitive: '2.initiatorHeaderNameGenitive',
    initiatorHeaderPosition: '2.initiatorHeaderPosition',
    initiatorHeaderPositionGenitive: '2.initiatorHeaderPositionGenitive',
    initiatorHeaderReasonGenitive: '2.initiatorHeaderReasonGenitive',
  },
  '3': {
    investorFullName: '3.investorFullName',
    investorName: '3.investorName',
    isProjectCompany: '3.isProjectCompany',
    investorAddressFullEgrulFormat: '3.investorAddressFullEgrulFormat',
    investorInn: '3.investorInn',
    investorOgrn: '3.investorOgrn',
    investorEmail: '3.investorEmail',
    investorPhone: '3.investorPhone',
    investorHeaderName: '3.investorHeaderName',
  },
  '4': {
    rfFullName: '4.rfFullName',
    subjects: '4.subjects',
    municipalities: '4.municipalities',
  },
  '5': {
    projectName: '5.projectName',
    szpkSignDate: '5.szpkSignDate',
    investorCapital: '5.investorCapital',
    factMadeInvestorCapital: '5.factMadeInvestorCapital',
    factRemainsInvestorCapital: '5.factRemainsInvestorCapital',
    stabilizationPeriod: '5.stabilizationPeriod',
    plannedTax: '5.plannedTax',
    generalPlannedTax: '5.generalPlannedTax',
    stepRegisteredDateValues: '5.stepRegisteredDateValues',
  },
  '6': {
    extraRegisteredDocuments: '6.extraRegisteredDocuments',
  },
  '7': {
    relatedContractListRegisteredDocumentValues: '7.relatedContractListRegisteredDocumentValues',
  },
  '8': {
    relatedContracts: '8.relatedContracts',
  },
  '9': {
    infoMonitoringReport: '9.infoMonitoringReport',
    infoMonitoringResult: '9.infoMonitoringResult',
  },
  '10': {
    infoBudgetRF: '10.infoBudgetRF',
    infoDamageFormatCompensation: '10.infoDamageFormatCompensation',
    infoViolationStabilizationPeriod: '10.infoViolationStabilizationPeriod',
    infoPartner: '10.infoPartner',
    infoDeposit: '10.infoDeposit',
    infoCession: '10.infoCession',
  },
  '11': {
    agreementTerminationReason: '11.agreementTerminationReason',
    agreementTerminationReasonDescription: '11.agreementTerminationReasonDescription',
    agreementTerminationReasonInvalidPart: '11.agreementTerminationReasonInvalidPart',
  },
  additionalFields: {
    isFederal: 'additionalFields.isFederal',
  },
} as const

export {
  defaultRegistrationCertificateFormModifierValues,
  defaultRegistrationCertificateFormValues,
  mapOfRegistrationCertificateMenu,
  registrationCertificateBlockValues,
}
