import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAAnotherStage,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionExploitation,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionObject,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionPermission,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionRegistration,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionSubObject,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAManufactoringGood,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAMeasureObject,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAObjectArea,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAObjectOption,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAObjectRegion,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAProjectResult,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionARidExploitation,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionARidNameRegistration,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionARidObject,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionARidRegistration,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStage,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStageCompensation,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStageOldStepExpense,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStepAnotherStage,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionATechnologicalRequirements,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { isArray } from '@helpers/checkTypes'

const generateRHFObjectForAAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm = (
  objectFromPropertyState: Record<string, any>,
): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues => {
  const getPreparedObjectRegions = (
    objectRegions: any[],
  ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAObjectRegion[] => {
    if (!isArray(objectRegions) || !objectRegions.length) return []

    return objectRegions.map(
      (objectRegion): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAObjectRegion => ({
        id: objectRegion?.value?.objectRegionCode?.propertyId,
        objectRegion: objectRegion?.value?.objectRegionCode?.value || '',
        objectRegionCommited: objectRegion?.value?.objectRegionCode?.commitedValue || '',
      }),
    )
  }

  const preparedProjectResults: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAProjectResult[] =
    isArray(objectFromPropertyState.projectResults?.value)
      ? objectFromPropertyState.projectResults?.value.map(
          (item): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAProjectResult => {
            return {
              id: item.propertyId,
              projectResultName: item.value.projectResultName?.value,
              projectResultNameCommited: item.value.projectResultName?.commitedValue,
              isStabilizationResult: item.value.isStabilizationResult?.value,
              isStabilizationResultCommited: item.value.isStabilizationResult?.commitedValue,
              projectResultCodeTnVed: item.value.projectResultCodeTnVed?.value,
              projectResultCodeTnVedCommited: item.value.projectResultCodeTnVed?.commitedValue,
              volumeOfProduction: isArray(item.value.projectResultValues?.value)
                ? item.value.projectResultValues?.value
                    .sort(
                      (projectResultA, projectResultB) =>
                        projectResultA.order - projectResultB.order,
                    )
                    .map(
                      (
                        projectResult,
                      ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAManufactoringGood => ({
                        id: projectResult.propertyId,
                        projectResultYear: projectResult.value.projectResultYear?.value,
                        projectResultValue: projectResult.value.projectResultYearValue?.value,
                        projectResultValueCommited:
                          projectResult.value.projectResultYearValue?.commitedValue,
                        projectResultUnit: projectResult.value.projectResultValueUnit?.value,
                        projectResultUnitCommited:
                          projectResult.value.projectResultValueUnit?.commitedValue,
                      }),
                    )
                : [],
            }
          },
        )
      : []

  const preparedStages: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStage[] = (() => {
    const eventOfStagesAnchorId = '7'

    return objectFromPropertyState.steps?.value.map(
      (step, indexOfStep): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStage => {
        const stepAnchorId = `${eventOfStagesAnchorId}.${indexOfStep + 1}`

        const checkboxes = (() => {
          const chosenCheckboxes = {
            isConstruction: false,
            isReconstruction: false,
            isModernization: false,
            isCreateRID: false,
          }

          step.value.stepActivityNames?.value.forEach((stepActivity) => {
            if (stepActivity.value?.name === 'Строительство (создание)') {
              chosenCheckboxes['isConstruction'] = true
            }

            if (stepActivity.value?.name === 'Реконструкция') {
              chosenCheckboxes['isReconstruction'] = true
            }

            if (stepActivity.value?.name === 'Модернизация') {
              chosenCheckboxes['isModernization'] = true
            }

            if (stepActivity.value?.name === 'Создание РИД') {
              chosenCheckboxes['isCreateRID'] = true
            }
          })

          return chosenCheckboxes
        })()

        const preparedConstructionObjects = (() => {
          const objects = step.value.objects?.value?.filter((object) => {
            return (
              object.value.isMainObject?.value &&
              !object.value.isSubObject?.value &&
              !object.value.objectOtherActivityName?.value?.id
            )
          })

          const subObjects = step.value.objects?.value?.filter((object) => {
            return (
              object.value.isSubObject?.value &&
              !object.value.objectOtherActivityName?.value?.id &&
              object.value.parentSubObject?.value
            )
          })

          const preparedConstructionObjects: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionObject[] =
            objects?.map(
              (
                object,
                indexOfObject,
              ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionObject => {
                const objectAnchorId = `${stepAnchorId}.1.${indexOfObject + 1}`

                const preparedSubObjects: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionSubObject[] =
                  subObjects
                    ?.filter((subObject) => {
                      return object.permanentId === subObject.value.parentSubObject?.value?.id
                    })
                    .map(
                      (
                        subObject,
                        indexOfSubObject,
                      ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionSubObject => {
                        return {
                          anchorId: `${objectAnchorId}.${indexOfSubObject + 1}`,
                          id: subObject.propertyId,
                          objectName: subObject.value.objectName?.value,
                          objectNameCommited: subObject.value.objectName?.commitedValue,
                          objectRegions: getPreparedObjectRegions(
                            subObject.value?.objectRegions?.value,
                          ),
                          objectConstructionKind: subObject.value?.objectConstructionKind?.value,
                          objectConstructionKindCommited:
                            subObject.value?.objectConstructionKind?.commitedValue,
                          objectPurpose: subObject.value?.objectPurpose?.value,
                          objectPurposeCommited: subObject.value?.objectPurpose?.commitedValue,
                          objectConstructionSquare:
                            subObject.value?.objectConstructionSquare?.value,
                          objectConstructionSquareCommited:
                            subObject.value?.objectConstructionSquare?.commitedValue,
                          objectSquare: subObject.value.objectSquare?.value,
                          objectSquareCommited: subObject.value.objectSquare?.commitedValue,
                          objectNonLivingAreaSquare:
                            subObject.value?.objectNonLivingAreaSquare?.value,
                          objectNonLivingAreaSquareCommited:
                            subObject.value?.objectNonLivingAreaSquare?.commitedValue,
                          objectLivingAreaSquare: subObject.value?.objectLivingAreaSquare?.value,
                          objectLivingAreaSquareCommited:
                            subObject.value?.objectLivingAreaSquare?.commitedValue,
                          objectFloor: subObject.value.objectFloor?.value,
                          objectFloorCommited: subObject.value.objectFloor?.commitedValue,
                          objectUndergroundFloor: subObject.value?.objectUndergroundFloor?.value,
                          objectUndergroundFloorCommited:
                            subObject.value.objectUndergroundFloor?.commitedValue,
                          objectHeight: subObject.value?.objectHeight?.value,
                          objectHeightCommited: subObject.value?.objectHeight?.commitedValue,
                          objectDetails: subObject.value.objectDetails?.value,
                          objectDetailsCommited: subObject.value.objectDetails?.commitedValue,
                          objectIsInfrastructure: subObject.value.isInfrastructure?.value,
                          objectIsInfrastructureCommited:
                            subObject.value.isInfrastructure?.commitedValue,
                          objectInfrastructureType: !!subObject.value.infrastructureType?.value?.id
                            ? {
                                value: subObject.value.infrastructureType.value?.id || '',
                                displayValue: subObject.value.infrastructureType.value?.name || '',
                              }
                            : null,
                          objectInfrastructureTypeCommited: !!subObject.value.infrastructureType
                            ?.commitedValue?.id
                            ? {
                                value: subObject.value.infrastructureType.commitedValue?.id || '',
                                displayValue:
                                  subObject.value.infrastructureType.commitedValue?.name || '',
                              }
                            : null,
                          oldIsCapitalObject: subObject.value.oldIsCapitalObject?.value,
                          oldIsCapitalObjectCommited:
                            subObject.value.oldIsCapitalObject?.commitedValue,
                          oldCreatedDocument: subObject.value.oldCreatedDocument?.value,
                          oldCreatedDocumentCommited:
                            subObject.value.oldCreatedDocument?.commitedValue,
                          oldCreatedOrganization: subObject.value.oldCreatedOrganization?.value,
                          oldCreatedOrganizationCommited:
                            subObject.value.oldCreatedOrganization?.commitedValue,
                        }
                      },
                    )

                const preparedObjectAreas =
                  isArray(object.value?.objectAreas?.value) &&
                  object.value?.objectAreas?.value?.length
                    ? object.value?.objectAreas?.value.map(
                        (
                          objectArea,
                        ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAObjectArea => {
                          return {
                            id: objectArea.propertyId,
                            objectAreaNumber: objectArea.value.objectAreaNumber?.value || '',
                            objectAreaNumberCommited:
                              objectArea.value.objectAreaNumber?.commitedValue || '',
                            objectAreaSquare: objectArea.value.objectAreaSquare?.value || '',
                            objectAreaSquareCommited:
                              objectArea.value.objectAreaSquare?.commitedValue || '',
                          }
                        },
                      )
                    : []

                return {
                  anchorId: objectAnchorId,
                  id: object.permanentId,
                  objectName: object.value.objectName?.value,
                  objectNameCommited: object.value.objectName?.commitedValue,
                  objectRegions: getPreparedObjectRegions(object.value?.objectRegions?.value),
                  objectAreas: preparedObjectAreas,
                  objectConstructionKind: object.value?.objectConstructionKind?.value,
                  objectConstructionKindCommited:
                    object.value?.objectConstructionKind?.commitedValue,
                  objectPurpose: object.value?.objectPurpose?.value,
                  objectPurposeCommited: object.value?.objectPurpose?.commitedValue,
                  objectConstructionSquare: object.value?.objectConstructionSquare?.value,
                  objectConstructionSquareCommited:
                    object.value?.objectConstructionSquare?.commitedValue,
                  objectSquare: object.value.objectSquare?.value,
                  objectSquareCommited: object.value.objectSquare?.commitedValue,
                  objectNonLivingAreaSquare: object.value?.objectNonLivingAreaSquare?.value,
                  objectNonLivingAreaSquareCommited:
                    object.value?.objectNonLivingAreaSquare?.commitedValue,
                  objectLivingAreaSquare: object.value?.objectLivingAreaSquare?.value,
                  objectLivingAreaSquareCommited:
                    object.value?.objectLivingAreaSquare?.commitedValue,
                  objectFloor: object.value.objectFloor?.value,
                  objectFloorCommited: object.value.objectFloor?.commitedValue,
                  objectUndergroundFloor: object.value?.objectUndergroundFloor?.value,
                  objectUndergroundFloorCommited:
                    object.value.objectUndergroundFloor?.commitedValue,
                  objectHeight: object.value?.objectHeight?.value,
                  objectHeightCommited: object.value?.objectHeight?.commitedValue,
                  objectDetails: object.value.objectDetails?.value,
                  objectDetailsCommited: object.value.objectDetails?.commitedValue,
                  objectIsInfrastructure: object.value.isInfrastructure?.value,
                  objectIsInfrastructureCommited: object.value.isInfrastructure?.commitedValue,
                  objectInfrastructureType: !!object.value.infrastructureType?.value?.id
                    ? {
                        value: object.value.infrastructureType.value?.id || '',
                        displayValue: object.value.infrastructureType.value?.name || '',
                      }
                    : null,
                  objectInfrastructureTypeCommited: !!object.value.infrastructureType?.commitedValue
                    ?.id
                    ? {
                        value: object.value.infrastructureType.commitedValue?.id || '',
                        displayValue: object.value.infrastructureType.commitedValue?.name || '',
                      }
                    : null,
                  oldIsCapitalObject: object.value.oldIsCapitalObject?.value,
                  oldIsCapitalObjectCommited: object.value.oldIsCapitalObject?.commitedValue,
                  oldCreatedDocument: object.value.oldCreatedDocument?.value,
                  oldCreatedDocumentCommited: object.value.oldCreatedDocument?.commitedValue,
                  oldCreatedOrganization: object.value.oldCreatedOrganization?.value,
                  oldCreatedOrganizationCommited:
                    object.value.oldCreatedOrganization?.commitedValue,
                  subObjects: preparedSubObjects,
                }
              },
            )

          return preparedConstructionObjects
        })()

        const preparedPermissions =
          ((): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionPermission[] => {
            if (!step.value.permissions?.value || !step.value.permissions.value.length) return []

            return step.value.permissions?.value.map(
              (
                permission,
                indexOfPermission,
              ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionPermission => {
                const preparedObjectNamePermissions =
                  ((): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAObjectOption[] =>
                    permission.value?.permissionObjects?.value?.map(
                      (
                        permissionObject,
                      ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAObjectOption => ({
                        id: permissionObject.propertyId,
                        objectOption: !!permissionObject?.value?.id
                          ? {
                              value: permissionObject?.value?.id || '',
                              displayValue: permissionObject?.value?.objectName || '',
                            }
                          : null,
                      }),
                    ))()

                return {
                  anchorId: `${stepAnchorId}.2.${indexOfPermission + 1}`,
                  id: permission.propertyId,
                  title: `РАЗРЕШЕНИЕ №${indexOfPermission + 1}`,
                  permissionKind: !!permission.value.permissionKind?.value?.id
                    ? {
                        value: permission.value.permissionKind?.value?.id || '',
                        displayValue: permission.value.permissionKind?.value?.name || '',
                      }
                    : null,
                  permissionKindCommited: !!permission.value.permissionKind?.commitedValue?.id
                    ? {
                        value: permission.value.permissionKind?.commitedValue?.id || '',
                        displayValue: permission.value.permissionKind?.commitedValue?.name || '',
                      }
                    : null,
                  permissionOrganization: permission.value.permissionOrganization?.value,
                  permissionOrganizationCommited:
                    permission.value.permissionOrganization?.commitedValue,
                  objectNamePermissions: preparedObjectNamePermissions,
                  isReceivedPermission: permission.value.isReceivedPermission?.value,
                  isReceivedPermissionCommited:
                    permission.value.isReceivedPermission?.commitedValue,
                  permissionDate: permission.value.permissionDate?.value,
                  permissionDateCommited: permission.value.permissionDate?.commitedValue,
                  permissionNumber: permission.value.permissionNumber?.value,
                  permissionNumberCommited: permission.value.permissionNumber?.commitedValue,
                  receivedPermissionDate: permission.value.receivedPermissionDate?.value,
                  receivedPermissionDateCommited:
                    permission.value.receivedPermissionDate?.commitedValue,
                }
              },
            )
          })()

        const preparedExploitationObjects =
          ((): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionExploitation[] => {
            if (!step.value.commissionObjects?.value || !step.value.commissionObjects?.value.length)
              return []

            return step.value.commissionObjects?.value.map(
              (
                exploitation,
                indexOfExploitation,
              ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionExploitation => ({
                anchorId: `${stepAnchorId}.3.${indexOfExploitation + 1}`,
                id: exploitation.propertyId,
                title: `Ввод в эксплуатацию №${indexOfExploitation + 1}`,
                objectName: !!exploitation.value?.commissionObjectId?.value?.id
                  ? {
                      value: exploitation.value?.commissionObjectId?.value?.id || '',
                      displayValue: exploitation.value?.commissionObjectId?.value?.objectName || '',
                    }
                  : null,
                objectCommissioningDate: exploitation.value?.commissionObjectDate?.value,
                objectCommissioningDateCommited:
                  exploitation.value?.commissionObjectDate?.commitedValue,
                objectPermissionCommissionNumber: !!exploitation.value?.commissionPermissionNumber
                  ?.value?.id
                  ? {
                      value: exploitation.value?.commissionPermissionNumber?.value?.id || '',
                      displayValue:
                        exploitation.value?.commissionPermissionNumber?.value?.permissionNumber ||
                        '',
                    }
                  : null,
              }),
            )
          })()

        const preparedRegistrationObjects =
          ((): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionRegistration[] => {
            if (
              !step.value.registrationObjects?.value ||
              !step.value.registrationObjects?.value.length
            )
              return []

            return step.value.registrationObjects?.value.map(
              (
                registration,
                indexOfRegistration,
              ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAConstructionRegistration => ({
                anchorId: `${stepAnchorId}.4.${indexOfRegistration + 1}`,
                id: registration.propertyId,
                title: `Регистрация №${indexOfRegistration + 1}`,
                objectName: !!registration.value?.registrationObjectId?.value?.id
                  ? {
                      value: registration.value?.registrationObjectId?.value?.id || '',
                      displayValue:
                        registration.value?.registrationObjectId?.value?.objectName || '',
                    }
                  : null,
                objectType: registration.value?.stateRegistrationType?.value,
                objectTypeCommited: registration.value?.stateRegistrationType?.commitedValue,
                estateObjectStateRegistrationDate:
                  registration.value?.objectStateRegistrationDate?.value,
                estateObjectStateRegistrationDateCommited:
                  registration.value?.objectStateRegistrationDate?.commitedValue,
                estateObjectStateRegistrationNumber:
                  registration.value?.objectStateRegistrationNumber?.value,
                estateObjectStateRegistrationNumberCommited:
                  registration.value?.objectStateRegistrationNumber?.commitedValue,
                estateObjectStateRegistrationOrganization:
                  registration.value?.objectStateRegistrationOrganization?.value,
                estateObjectStateRegistrationOrganizationCommited:
                  registration.value?.objectStateRegistrationOrganization?.commitedValue,
              }),
            )
          })()

        const preparedRids =
          ((): AAgreementOnNonConclusionOrNonPerformanceOfConcessionARidObject[] => {
            if (!step.value.rids?.value || !step.value.rids?.value.length) return []

            return step.value.rids?.value.map(
              (
                rid,
                indexOfRid,
              ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionARidObject => ({
                id: rid.propertyId,
                title: `РИД №${indexOfRid + 1}`,
                anchorId: `${stepAnchorId}.5.${indexOfRid + 1}`,
                ridKind: rid.value?.ridKind?.value,
                ridKindCommited: rid.value?.ridKind?.commitedValue,
                ridName: rid.value?.ridName?.value,
                ridNameCommited: rid.value?.ridName?.commitedValue,
                ridAddress: rid.value?.ridAddress?.value,
                ridAddressCommited: rid.value?.ridAddress?.commitedValue,
                ridOwner: rid.value?.ridOwner?.value,
                ridOwnerCommited: rid.value?.ridOwner?.commitedValue,
                ridDetails: rid.value?.ridDetails?.value,
                ridDetailsCommited: rid.value?.ridDetails?.commitedValue,
              }),
            )
          })()

        const preparedExploitationRids =
          ((): AAgreementOnNonConclusionOrNonPerformanceOfConcessionARidExploitation[] => {
            if (!step.value.commissionRids?.value || !step.value.commissionRids?.value.length)
              return []

            return step.value.commissionRids?.value.map(
              (
                exploitation,
                indexOfExploitation,
              ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionARidExploitation => ({
                anchorId: `${stepAnchorId}.6.${indexOfExploitation + 1}`,
                id: exploitation.propertyId,
                title: `Ввод в эксплуатацию №${indexOfExploitation + 1}`,
                ridName: !!exploitation.value?.commissionRidId?.value?.id
                  ? {
                      value: exploitation.value?.commissionRidId?.value?.id || '',
                      displayValue: exploitation.value?.commissionRidId?.value?.ridName || '',
                    }
                  : null,
                ridCommissionNumber: exploitation.value?.ridCommissionNumber?.value,
                ridCommissionNumberCommited: exploitation.value?.ridCommissionNumber?.commitedValue,
                ridCommissioningDate: exploitation.value?.ridCommissioningDate?.value,
                ridCommissioningDateCommited:
                  exploitation.value?.ridCommissioningDate?.commitedValue,
              }),
            )
          })()

        const preparedRegistrationRids =
          ((): AAgreementOnNonConclusionOrNonPerformanceOfConcessionARidRegistration[] => {
            if (!step.value.registrationRids?.value || !step.value.registrationRids?.value.length)
              return []

            return step.value.registrationRids?.value.map(
              (
                registration,
                indexOfRegistration,
              ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionARidRegistration => ({
                anchorId: `${stepAnchorId}.7.${indexOfRegistration + 1}`,
                id: registration.propertyId,
                title: `Регистрация №${indexOfRegistration + 1}`,
                ridName: !!registration.value?.registrationRidId.value?.id
                  ? {
                      value: registration.value?.registrationRidId.value?.id || '',
                      displayValue: registration.value?.registrationRidId.value?.ridName || '',
                    }
                  : null,
                ridStateRegistrationNumber: registration.value?.ridStateRegistrationNumber?.value,
                ridStateRegistrationNumberCommited:
                  registration.value?.ridStateRegistrationNumber?.commitedValue,
                ridStateRegistrationOrganization:
                  registration.value?.ridStateRegistrationOrganization?.value,
                ridStateRegistrationOrganizationCommited:
                  registration.value?.ridStateRegistrationOrganization?.commitedValue,
              }),
            )
          })()

        const preparedRidNameRegistrations =
          ((): AAgreementOnNonConclusionOrNonPerformanceOfConcessionARidNameRegistration[] => {
            if (
              !step.value?.ridStateRegistrationIds?.value ||
              !step.value.ridStateRegistrationIds?.value.length
            )
              return []

            return step.value.ridStateRegistrationIds?.value.map(
              (
                registrationRid,
              ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionARidNameRegistration => ({
                id: registrationRid.propertyId,
                ridOption: !!registrationRid.value?.id
                  ? {
                      value: registrationRid.value?.id || '',
                      displayValue: registrationRid.value?.ridName || '',
                    }
                  : null,
              }),
            )
          })()

        return {
          id: step.permanentId,
          anchorId: stepAnchorId,
          stepNumber: step.value.stepNumber?.value,
          stepNumberCommited: step.value.stepNumber?.commitedValue,
          stepDescription: step.value.stepDescription?.value,
          stepDescriptionCommited: step.value.stepDescription?.commitedValue,
          stepStartDate: step.value.stepStartDate?.value,
          stepStartDateCommited: step.value.stepStartDate?.commitedValue,
          stepEndDate: step.value.stepEndDate?.value,
          stepEndDateCommited: step.value.stepEndDate?.commitedValue,
          stepInvestorCapitalValue: !!objectFromPropertyState.isPaper?.value
            ? step.value.stepInvestorCapitalValueVAT?.value
            : step.value.stepInvestorCapitalValue?.value,
          stepInvestorCapitalValueCommited: !!objectFromPropertyState.isPaper?.value
            ? step.value.stepInvestorCapitalValueVAT?.commitedValue
            : step.value.stepInvestorCapitalValue?.commitedValue,
          stepCompensationValue: step.value.stepCompensationValue?.value,
          stepCompensationValueCommited: step.value.stepCompensationValue?.commitedValue,
          stepCompensationForm: !!step.value.stepCompensationForm?.value?.id
            ? {
                value: step.value.stepCompensationForm?.value?.id || '',
                displayValue: step.value.stepCompensationForm?.value?.name || '',
              }
            : null,
          stepCompensationFormCommited: !!step.value.stepCompensationForm?.commitedValue?.id
            ? {
                value: step.value.stepCompensationForm?.commitedValue?.id || '',
                displayValue: step.value.stepCompensationForm?.commitedValue?.name || '',
              }
            : null,
          stepCompensations:
            isArray(step.value.stepCompensations?.value) &&
            step.value.stepCompensations?.value.length
              ? step.value.stepCompensations?.value.map(
                  (
                    stepCompensation,
                  ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStageCompensation => ({
                    id: stepCompensation.propertyId,
                    stepCompensationType: !!stepCompensation.value.stepCompensationType?.value?.id
                      ? {
                          displayValue:
                            stepCompensation.value?.stepCompensationType?.value?.name || '',
                          value: stepCompensation.value?.stepCompensationType?.value?.id || '',
                        }
                      : null,
                    stepCompensationTypeCommited: !!stepCompensation.value.stepCompensationType
                      ?.commitedValue?.id
                      ? {
                          displayValue:
                            stepCompensation.value?.stepCompensationType?.commitedValue?.name || '',
                          value:
                            stepCompensation.value?.stepCompensationType?.commitedValue?.id || '',
                        }
                      : null,
                    stepCompensationPeriod:
                      stepCompensation.value?.stepCompensationPeriod?.value?.name || '',
                    stepCompensationPeriodCommited:
                      stepCompensation.value?.stepCompensationPeriod?.commitedValue?.name || '',
                  }),
                )
              : [],

          stepInvestorCapitalDate: step.value.stepInvestorCapitalDate?.value,
          stepInvestorCapitalDateCommited: step.value.stepInvestorCapitalDate?.commitedValue,
          oldStepInvestorCapitalSources: isArray(step.value.oldStepInvestorCapitalSources?.value)
            ? step.value.oldStepInvestorCapitalSources?.value.map((source) => ({
                id: source?.propertyId,
                oldStepCapitalSourceName: source?.value || '',
                oldStepCapitalSourceNameCommited: source?.commitedValue || '',
              }))
            : [],
          oldStepExpenses:
            isArray(objectFromPropertyState.oldStepExpenses?.value) &&
            objectFromPropertyState.oldStepExpenses?.value.length
              ? objectFromPropertyState.oldStepExpenses?.value
                  .filter((oldStepExpense) => {
                    return oldStepExpense.value?.oldStepIdExpense?.value?.id === step.permanentId
                  })
                  .map(
                    (
                      oldStepExpense,
                    ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStageOldStepExpense => ({
                      id: oldStepExpense.propertyId,
                      oldStepExpenseName: oldStepExpense.value.oldStepExpenseName?.value,
                      oldStepExpenseNameCommited:
                        oldStepExpense.value.oldStepExpenseName?.commitedValue,
                      oldStepExpenseValue: oldStepExpense.value?.oldStepExpenseValue?.value,
                      oldStepExpenseValueCommited:
                        oldStepExpense.value.oldStepExpenseValue?.commitedValue,
                    }),
                  )
              : [],

          //Фейковое свойство для расчётов
          stepActivityNames: step.value.stepActivityNames?.value,

          ...checkboxes,
          constructionStage: {
            constructionObjects: preparedConstructionObjects,
            permissionsObjects: preparedPermissions,

            exploitation: {
              objectCommissioningEndDate: step.value.objectCommissioningEndDate?.value,
              objectCommissioningEndDateCommited:
                step.value.objectCommissioningEndDate?.commitedValue,
              exploitationObjects: preparedExploitationObjects,
            },

            registration: {
              typeObjectStateRegistration: step.value.typeObjectStateRegistration?.value,
              typeObjectStateRegistrationCommited:
                step.value.typeObjectStateRegistration?.commitedValue,
              objectStateRegistrationEndDate: step.value.objectStateRegistrationEndDate?.value,
              objectStateRegistrationEndDateCommited:
                step.value.objectStateRegistrationEndDate?.commitedValue,
              registrationObjects: preparedRegistrationObjects,
            },
          },
          ridStage: {
            ridObjects: preparedRids,

            exploitation: {
              ridCommissioningEndDate: step.value.ridCommissioningEndDate?.value,
              ridCommissioningEndDateCommited: step.value.ridCommissioningEndDate?.commitedValue,
              ridExploitationObjects: preparedExploitationRids,
            },

            registration: {
              ridNames: preparedRidNameRegistrations,
              ridStateRegistrationEndDate: step.value.ridStateRegistrationEndDate?.value,
              ridStateRegistrationEndDateCommited:
                step.value.ridStateRegistrationEndDate?.commitedValue,
              ridRegistrationsObjects: preparedRegistrationRids,
            },
          },
        }
      },
    )
  })()

  const preparedStepActivityOthers = isArray(objectFromPropertyState.steps?.value)
    ? objectFromPropertyState.steps?.value.map(
        (
          step,
          indexOfStep,
        ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStepAnotherStage => {
          return {
            id: step.propertyId,
            activityOthers: step.value.stepActivityOthers?.value.map(
              (
                stepActivity,
                indexOfActivity,
              ): Omit<
                AAgreementOnNonConclusionOrNonPerformanceOfConcessionAAnotherStage,
                'frontId'
              > => {
                const activityAnchorId = `8.${indexOfStep + 1}.${indexOfActivity + 1}`

                const preparedMeasureObjects: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAMeasureObject[] =
                  step.value.objects?.value
                    .filter((object) => {
                      return (
                        object.value.objectOtherActivityName?.value.id === stepActivity.permanentId
                      )
                    })
                    .map(
                      (
                        object,
                        indexOfObject,
                      ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAMeasureObject => {
                        const preparedObjectAreas =
                          isArray(object.value?.objectAreas?.value) &&
                          object.value?.objectAreas?.value?.length
                            ? object.value?.objectAreas?.value.map(
                                (
                                  objectArea,
                                ): AAgreementOnNonConclusionOrNonPerformanceOfConcessionAObjectArea => {
                                  return {
                                    id: objectArea.propertyId,
                                    objectAreaNumber:
                                      objectArea.value.objectAreaNumber?.value || '',
                                    objectAreaNumberCommited:
                                      objectArea.value.objectAreaNumber?.commitedValue || '',
                                    objectAreaSquare:
                                      objectArea.value.objectAreaSquare?.value || '',
                                    objectAreaSquareCommited:
                                      objectArea.value.objectAreaSquare?.commitedValue || '',
                                  }
                                },
                              )
                            : []

                        return {
                          anchorId: `${activityAnchorId}.${indexOfObject + 1}`,
                          id: object.propertyId,
                          title: `ОБЪЕКТ №${indexOfObject + 1}`,
                          objectName: object.value.objectName?.value,
                          objectNameCommited: object.value.objectName?.commitedValue,
                          objectRegions: getPreparedObjectRegions(
                            object.value?.objectRegions?.value,
                          ),
                          objectAreas: preparedObjectAreas,
                          objectConstructionKind: object.value?.objectConstructionKind?.value,
                          objectConstructionKindCommited:
                            object.value?.objectConstructionKind?.commitedValue,
                          objectPurpose: object.value?.objectPurpose?.value,
                          objectPurposeCommited: object.value?.objectPurpose?.commitedValue,
                          objectConstructionSquare: object.value?.objectConstructionSquare?.value,
                          objectConstructionSquareCommited:
                            object.value?.objectConstructionSquare?.commitedValue,
                          objectSquare: object.value.objectSquare?.value,
                          objectSquareCommited: object.value.objectSquare?.commitedValue,
                          objectNonLivingAreaSquare: object.value?.objectNonLivingAreaSquare?.value,
                          objectNonLivingAreaSquareCommited:
                            object.value?.objectNonLivingAreaSquare?.commitedValue,
                          objectLivingAreaSquare: object.value?.objectLivingAreaSquare?.value,
                          objectLivingAreaSquareCommited:
                            object.value?.objectLivingAreaSquare?.commitedValue,
                          objectFloor: object.value.objectFloor?.value,
                          objectFloorCommited: object.value.objectFloor?.commitedValue,
                          objectUndergroundFloor: object.value?.objectUndergroundFloor?.value,
                          objectUndergroundFloorCommited:
                            object.value?.objectUndergroundFloor?.commitedValue,
                          objectHeight: object.value?.objectHeight?.value,
                          objectHeightCommited: object.value?.objectHeight?.commitedValue,
                          objectDetails: object.value.objectDetails?.value,
                          objectDetailsCommited: object.value.objectDetails?.commitedValue,
                          objectIsInfrastructure: object.value.isInfrastructure?.value,
                          objectIsInfrastructureCommited:
                            object.value.isInfrastructure?.commitedValue,
                          objectInfrastructureType: !!object.value.infrastructureType?.value?.id
                            ? {
                                value: object.value.infrastructureType?.value?.id || '',
                                displayValue: object.value.infrastructureType?.value?.name || '',
                              }
                            : null,
                          objectInfrastructureTypeCommited: !!object.value.infrastructureType
                            ?.commitedValue?.id
                            ? {
                                value: object.value.infrastructureType?.commitedValue?.id || '',
                                displayValue:
                                  object.value.infrastructureType?.commitedValue?.name || '',
                              }
                            : null,
                        }
                      },
                    )

                return {
                  anchorId: activityAnchorId,
                  id: stepActivity.propertyId,
                  permanentId: stepActivity.permanentId,
                  otherActivityName: stepActivity.value.otherActivityName?.value,
                  otherActivityNameCommited: stepActivity.value.otherActivityName?.commitedValue,
                  otherActivityEndDate: stepActivity.value.otherActivityEndDate?.value,
                  otherActivityEndDateCommited:
                    stepActivity.value.otherActivityEndDate?.commitedValue,
                  isOtherActivityInfrastructure:
                    stepActivity.value.isOtherActivityInfrastructure?.value,
                  isOtherActivityInfrastructureCommited:
                    stepActivity.value.isOtherActivityInfrastructure?.commitedValue,
                  otherActivityInfrastructureTypeName: !!stepActivity.value
                    .otherActivityInfrastructureType?.value?.id
                    ? {
                        value: stepActivity.value.otherActivityInfrastructureType?.value?.id || '',
                        displayValue:
                          stepActivity.value.otherActivityInfrastructureType?.value?.name || '',
                      }
                    : null,

                  stepNumber: step.value.stepActivityOthers?.propertyId,
                  measureObjects: preparedMeasureObjects,
                }
              },
            ),
          }
        },
      )
    : []

  const preparedTechnologicalRequirements =
    ((): AAgreementOnNonConclusionOrNonPerformanceOfConcessionATechnologicalRequirements[] => {
      if (
        !isArray(objectFromPropertyState.projectNpas?.value) ||
        !objectFromPropertyState.projectNpas?.value.length
      )
        return []

      return objectFromPropertyState.projectNpas.value.map(
        (npa): AAgreementOnNonConclusionOrNonPerformanceOfConcessionATechnologicalRequirements => {
          return {
            id: npa.propertyId,
            projectNpaName: npa.value,
            projectNpaNameCommited: npa.commitedValue,
          }
        },
      )
    })()

  return {
    '1': RHFBasicAdapters.getRFAdapter(objectFromPropertyState),
    '2': {
      subjects: RHFBasicAdapters.getSubjectsAdapter(objectFromPropertyState),
    },
    '3': {
      municipalities: RHFBasicAdapters.getMunicipalitiesAdapter(objectFromPropertyState),
    },
    '4': RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    '5': {
      concessionFail: !!objectFromPropertyState.concessionFail?.value?.id
        ? {
            value: objectFromPropertyState.concessionFail?.value?.id || '',
            displayValue: objectFromPropertyState.concessionFail?.value?.name || '',
          }
        : null,
      concessionFailDocument: objectFromPropertyState.concessionFailDocument?.value,
    },
    '6': {
      projectPurpose: objectFromPropertyState.projectPurpose?.value,
      projectPurposeCommited: objectFromPropertyState.projectPurpose?.commitedValue,
      totalCapital: objectFromPropertyState.totalCapital?.value,
      totalCapitalCommited: objectFromPropertyState.totalCapital?.commitedValue,
      investorCapital: !!objectFromPropertyState.isPaper?.value
        ? objectFromPropertyState.investorCapitalVAT?.value
        : objectFromPropertyState.investorCapital?.value,
      investorCapitalCommited: !!objectFromPropertyState.isPaper?.value
        ? objectFromPropertyState.investorCapitalVAT?.commitedValue
        : objectFromPropertyState.investorCapital?.commitedValue,
      remainsInvestorCapital: !!objectFromPropertyState.isPaper?.value
        ? objectFromPropertyState.remainsInvestorCapitalVAT?.value
        : objectFromPropertyState.remainsInvestorCapital?.value,
      remainsInvestorCapitalCommited: !!objectFromPropertyState.isPaper?.value
        ? objectFromPropertyState.remainsInvestorCapitalVAT?.commitedValue
        : objectFromPropertyState.remainsInvestorCapital?.commitedValue,
      projectSupportCompensation:
        (isArray(objectFromPropertyState.projectCompensations?.value)
          ? objectFromPropertyState.projectCompensations?.value.find(
              (item) =>
                item.value.projectCompensationType?.value?.name === 'Обеспечивающая инфраструктура',
            )?.value.projectCompensationValue?.value
          : '') || '',
      projectSupportCompensationCommited:
        (isArray(objectFromPropertyState.projectCompensations?.value)
          ? objectFromPropertyState.projectCompensations?.value.find(
              (item) =>
                item.value.projectCompensationType?.commitedValue?.name ===
                'Обеспечивающая инфраструктура',
            )?.value.projectCompensationValue?.commitedValue
          : '') || '',
      projectRelatedCompensation:
        (isArray(objectFromPropertyState.projectCompensations?.value)
          ? objectFromPropertyState.projectCompensations?.value.find(
              (item) =>
                item.value.projectCompensationType?.value?.name === 'Сопутствующая инфраструктура',
            )?.value.projectCompensationValue?.value
          : '') || '',
      projectRelatedCompensationCommited:
        (isArray(objectFromPropertyState.projectCompensations?.value)
          ? objectFromPropertyState.projectCompensations?.value.find(
              (item) =>
                item.value.projectCompensationType?.commitedValue?.name ===
                'Сопутствующая инфраструктура',
            )?.value.projectCompensationValue?.commitedValue
          : '') || '',
    },
    '7': {
      stages: preparedStages,
    },
    '8': {
      stepActivityOthers: preparedStepActivityOthers,
    },
    '9': {
      projectResultEcoActivity: {
        ecoActivityName: !!objectFromPropertyState.projectResultEcoActivity?.value?.ecoActivityName
          ?.value?.id
          ? {
              value:
                objectFromPropertyState.projectResultEcoActivity?.value.ecoActivityName?.value
                  ?.id || '',
              displayValue:
                objectFromPropertyState.projectResultEcoActivity?.value.ecoActivityName?.value
                  ?.name || '',
            }
          : null,
        ecoActivityNameCommited: !!objectFromPropertyState.projectResultEcoActivity?.value
          ?.ecoActivityName?.commitedValue?.id
          ? {
              value:
                objectFromPropertyState.projectResultEcoActivity?.value.ecoActivityName
                  ?.commitedValue?.id || '',
              displayValue:
                objectFromPropertyState.projectResultEcoActivity?.value.ecoActivityName
                  ?.commitedValue?.name || '',
            }
          : null,
        ecoActivityProgramNameInstrumental:
          objectFromPropertyState.projectResultEcoActivity?.value
            .ecoActyvityProgrammNameInstrumental?.value,
        ecoActivityProgramNameInstrumentalCommited:
          objectFromPropertyState.projectResultEcoActivity?.value
            .ecoActyvityProgrammNameInstrumental?.commitedValue,
        ecoActivityEndDate:
          objectFromPropertyState.projectResultEcoActivity?.value.ecoActivityEndDate?.value,
        ecoActivityEndDateCommited:
          objectFromPropertyState.projectResultEcoActivity?.value.ecoActivityEndDate?.commitedValue,
      },
      projectResultActivity: objectFromPropertyState.projectResultActivities?.value
        .map((resultActivity) =>
          !!resultActivity.value?.id
            ? {
                value: resultActivity.value?.id,
                displayValue: resultActivity.value?.name || '',
              }
            : null,
        )
        .filter(Boolean),
      otherProjectResultActivity: objectFromPropertyState?.otherProjectResultActivity?.value || '',
      otherProjectResultActivityCommited:
        objectFromPropertyState?.otherProjectResultActivity?.commitedValue || '',
      projectResults: preparedProjectResults,
    },
    '10': {
      technologicalRequirements: preparedTechnologicalRequirements,
    },
    '11': {
      extraMunicipalAccessionLinkCompensation:
        objectFromPropertyState.extraMunicipalAccessionLinkCompensation?.value,
      extraMunicipalAccessionLinkCompensationCommited:
        objectFromPropertyState.extraMunicipalAccessionLinkCompensation?.commitedValue,
      extraMunicipalAccessionLinkStabilization:
        objectFromPropertyState.extraMunicipalAccessionLinkStabilization?.value,
      extraMunicipalAccessionLinkStabilizationCommited:
        objectFromPropertyState.extraMunicipalAccessionLinkStabilization?.commitedValue,
      stabilizationPeriod: objectFromPropertyState.stabilizationPeriod?.value,
      stabilizationPeriodCommited: objectFromPropertyState.stabilizationPeriod?.commitedValue,
      oldStabilizationPeriodTaxDuty: objectFromPropertyState.oldStabilizationPeriodTaxDuty?.value,
      oldStabilizationPeriodTaxDutyCommited:
        objectFromPropertyState.oldStabilizationPeriodTaxDuty?.commitedValue,
      oldStabilizationPeriodFederalStateSupport:
        objectFromPropertyState.oldStabilizationPeriodFederalStateSupport?.value,
      oldStabilizationPeriodFederalStateSupportCommited:
        objectFromPropertyState.oldStabilizationPeriodFederalStateSupport?.commitedValue,
      oldStabilizationPeriodRegionalStateSupport:
        objectFromPropertyState.oldStabilizationPeriodRegionalStateSupport?.value,
      oldStabilizationPeriodRegionalStateSupportCommited:
        objectFromPropertyState.oldStabilizationPeriodRegionalStateSupport?.commitedValue,
    },
    '12': {
      rightDutySubjectForestResource: objectFromPropertyState.rightDutySubjectWaterResource?.value,
      rightDutySubjectForestResourceCommited:
        objectFromPropertyState.rightDutySubjectWaterResource?.commitedValue,
      rightDutySubjectWaterResource: objectFromPropertyState.rightDutySubjectForestResource?.value,
      rightDutySubjectWaterResourceCommited:
        objectFromPropertyState.rightDutySubjectForestResource?.commitedValue,
      rightDutyRfForestResource: objectFromPropertyState.rightDutyRfWaterResource?.value,
      rightDutyRfForestResourceCommited:
        objectFromPropertyState.rightDutyRfForestResource?.commitedValue,
      rightDutyRfWaterResource: objectFromPropertyState.rightDutyRfForestResource?.value,
      rightDutyRfWaterResourceCommited:
        objectFromPropertyState.rightDutyRfForestResource?.commitedValue,
    },
    additionalFields: {
      isFederal: objectFromPropertyState.isFederal?.value,
      isMunicipalityParticipant: !!objectFromPropertyState.municipalities?.value?.length,
      isPaper: objectFromPropertyState.isPaper?.value,
      projectStartDate: objectFromPropertyState.projectStartDate?.value,
      projectEndDate: objectFromPropertyState.projectEndDate?.value,
      anotherStageOptions: isArray(objectFromPropertyState.steps?.value)
        ? objectFromPropertyState.steps?.value.map((step) => ({
            displayValue: step.value.stepNumber?.value,
            value: step.value.stepActivityOthers?.propertyId,
          }))
        : [],
      allProjectObjectsLength: isArray(objectFromPropertyState.steps?.value)
        ? objectFromPropertyState.steps?.value.reduce((accum, currentValue) => {
            if (isArray(currentValue.value.objects?.value)) {
              return accum + currentValue.value.objects?.value.length
            }

            return accum
          }, 0)
        : 0,
    },
  }
}

export { generateRHFObjectForAAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm }
