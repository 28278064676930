import React, { FC, memo, ReactNode } from 'react'
import { UseFormReturn } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import FieldArraySwapper from '@components/DocumentFormComponents/FieldArraySwapper'
import FlippedWithCollapse from '@components/DocumentFormComponents/FlippedWithCollapse'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormObjectTooltipControl from '@components/DocumentFormComponents/FormControls/FormObjectTooltipControl'
import styles from '@components/DocumentFormComponents/FormStyles/ObjectItem.module.scss'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormCollapseControl } from '@components/DocumentFormComponents/hooks/useFormCollapseControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { useNestedMenuHandlersManager } from '@components/DocumentFormComponents/NestedMenu/Manager'
import ObjectStepWrapperHeaderNode from '@components/DocumentFormComponents/ObjectStepWrapper/ObjectStepWrapperHeaderNode'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import ObjectFormItem from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Forms/5/AdditionalForms/MainTemplate/ConstructionObject/Forms/CObjectsForm/ObjectFormItem'
import SubObjectItem from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Forms/5/AdditionalForms/MainTemplate/ConstructionObject/Forms/CObjectsForm/SubObjectItem'
import {
  ConstructionObjectsArrayPathName,
  ConstructionSubObjectsPathName,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Forms/5/types'
import { useAAgreementOnChangingCharacteristicsOfObjectsManager } from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/Manager'
import {
  AAgreementOnChangingCharacteristicsOfObjectsFormModifierValues,
  AAgreementOnChangingCharacteristicsOfObjectsFormValues,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/types'
import {
  AAgreementOnChangingCharacteristicsOfObjectsFieldArrayControlUpdateWatcher,
  AAgreementOnChangingCharacteristicsOfObjectsFieldCollapseControlUpdateWatcher,
  AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher,
} from '@components/Forms/AAgreementOnChangingCharacteristicsOfObjectsForm/watcher'
import Button from '@components/NewDesign/Button'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import useIsFirstRender from '@hooks/new/effects/useIsFirstRender'
import CircleAddIcon from '@icons/CircleAddIcon.svg'
import DeleteIcon from '@icons/DeleteIcon.svg'
import cn from 'classnames'

interface ObjectItemProps {
  parentId: string
  objectId: string
  blockViewIsValidating: boolean
  editMode: boolean
  //Если много объектов, необходимо все сворачивать и анмаунтить
  expandedRenderMode: boolean
  formInstance: UseFormReturn<AAgreementOnChangingCharacteristicsOfObjectsFormValues>
  formModifierInstance: UseFormReturn<AAgreementOnChangingCharacteristicsOfObjectsFormModifierValues>
  name: ConstructionObjectsArrayPathName
  indexOfObject: number
  lastIndexOfObject: number

  onDeleteObject: (index: number) => Promise<void>
  onMoveTop: (index: number) => Promise<void>
  onMoveBottom: (index: number) => Promise<void>
  leftAddons?: ReactNode
}

const ObjectItem: FC<ObjectItemProps> = ({
  parentId,
  objectId,
  blockViewIsValidating,
  editMode,
  expandedRenderMode,
  formInstance,
  formModifierInstance,
  indexOfObject,
  lastIndexOfObject,
  name,
  onDeleteObject,
  onMoveTop,
  onMoveBottom,
  leftAddons,
}) => {
  const {
    handlers: {
      handleAddItemToListWithValue,
      handleRemoveItemFromList,
      handleUpElementInList,
      handleDownElementInList,
    },
    preparedProps: { subscribableControl },
  } = useAAgreementOnChangingCharacteristicsOfObjectsManager()

  const { handleUpdateChanges } = useNestedMenuHandlersManager()

  const { fields: subObjectItems } = useFieldArraySubscribableControl<
    AAgreementOnChangingCharacteristicsOfObjectsFormValues,
    ConstructionSubObjectsPathName,
    'keyNameId'
  >({
    control: formInstance.control,
    name: `${name}.subObjects`,
    keyName: 'keyNameId',
    watcher: AAgreementOnChangingCharacteristicsOfObjectsFieldArrayControlUpdateWatcher,
  })

  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnChangingCharacteristicsOfObjectsFieldsControlUpdateWatcher,
  })

  const { isExpanded: isShowObjectItemContent, onToggleCollapse: toggleShowObjectItemContent } =
    useFormCollapseControl({
      initialExpanded: expandedRenderMode,
      name,
      watcher: AAgreementOnChangingCharacteristicsOfObjectsFieldCollapseControlUpdateWatcher,
    })

  const subObjectExpandedRenderMode = !useIsFirstRender()

  const handleDeleteObject = async () => {
    await onDeleteObject(indexOfObject)
  }

  const handleMoveTop = async () => {
    await onMoveTop(indexOfObject)
  }

  const handleMoveBottom = async () => {
    await onMoveBottom(indexOfObject)
  }

  const handleAddSubObject = async () => {
    await handleAddItemToListWithValue?.(`${name}.subObjects`, {
      isSubObject: true,
      parentSubObject: objectId,
    })
  }

  const handleDeleteSubObject = (index: number) => async () => {
    await handleRemoveItemFromList?.(`${name}.subObjects.${index}`, `${name}.subObjects`)
  }

  const handleChangeElementOrderToUp = (index: number) => async () => {
    await handleUpElementInList?.(`${name}.subObjects.${index}`)
  }

  const handleChangeElementOrderToDown = (index: number) => async () => {
    await handleDownElementInList?.(`${name}.subObjects.${index}`)
  }

  return (
    <FlippedWithCollapse flipId={objectId}>
      <Row id={parentId}>
        <Col xs={12}>
          <div className={styles.objectItem}>
            <div className={styles.objectItem__header}>
              <ObjectStepWrapperHeaderNode
                leftAddons={leftAddons}
                buttonProps={{
                  onClick: toggleShowObjectItemContent,
                  trailingIcon: {
                    className: cn({
                      [styles['objectItem__header-button__icon--active']]: isShowObjectItemContent,
                    }),
                  },
                }}
              >
                ОСНОВНОЙ ОБЪЕКТ №{indexOfObject + 1}
              </ObjectStepWrapperHeaderNode>

              {editMode && (
                <Stack direction={'horizontal'} gap={2}>
                  <FieldArraySwapper
                    index={indexOfObject}
                    lastIndex={lastIndexOfObject}
                    onMoveTop={handleMoveTop}
                    onMoveBottom={handleMoveBottom}
                  />
                  <AsyncWrapper promise={handleDeleteObject}>
                    {({ isLoading, wrappedPromise }) => (
                      <Button
                        disabled={isLoading}
                        variant={'buttonSMedium'}
                        size={'2xs'}
                        color={'negative'}
                        dataTestId={'deleteButton'}
                        view={'plain'}
                        loaderProps={{
                          loading: isLoading,
                          placement: 'trailing',
                          variant: 'lite',
                        }}
                        leadingIcon={{
                          noCurrentColorSvgFill: true,
                          src: DeleteIcon,
                        }}
                        onClick={wrappedPromise}
                      >
                        Удалить
                      </Button>
                    )}
                  </AsyncWrapper>
                </Stack>
              )}
            </div>
            <div className={styles.objectItem__content}>
              <ObjectFormItem
                isParentObject
                isShowObjectItemContent={isShowObjectItemContent}
                blockViewIsValidating={blockViewIsValidating}
                editMode={editMode}
                formInstance={formInstance}
                formModifierInstance={formModifierInstance}
                formName={name}
              />
            </div>

            <FlipperList list={subObjectItems}>
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${name}.subObjects`,
                })}
              >
                {subObjectItems.map((subObject, index) => {
                  const formName = `${name}.subObjects.${index}` as const

                  return (
                    <SubscribableControl
                      key={subObject.id}
                      {...getSubscribableControlProps({
                        path: formName,
                      })}
                    >
                      <FormObjectTooltipControl
                        name={formName}
                        control={formModifierInstance.control}
                        onDifference={handleUpdateChanges}
                      >
                        <SubObjectItem
                          subObjectId={subObject.id}
                          blockViewIsValidating={blockViewIsValidating}
                          editMode={editMode}
                          expandedRenderMode={subObjectExpandedRenderMode}
                          name={formName}
                          formInstance={formInstance}
                          formModifierInstance={formModifierInstance}
                          indexOfObject={index}
                          lastIndexOfObject={subObjectItems.length - 1}
                          onDeleteSubObject={handleDeleteSubObject(index)}
                          onMoveTop={handleChangeElementOrderToUp(index)}
                          onMoveBottom={handleChangeElementOrderToDown(index)}
                        />
                      </FormObjectTooltipControl>
                    </SubscribableControl>
                  )
                })}
              </SubscribableControl>
            </FlipperList>

            {editMode && (
              <Row className={cn(styles.objectItem__action, 'px-1')}>
                <Col xs={12}>
                  <AsyncWrapper promise={handleAddSubObject}>
                    {({ isLoading, wrappedPromise }) => {
                      return (
                        <Button
                          disabled={isLoading}
                          leadingIcon={{ src: CircleAddIcon }}
                          variant={'buttonSMedium'}
                          size={'2xs'}
                          view={'plain'}
                          dataTestId={'addSubObject'}
                          className={styles['objectItem__action-button']}
                          loaderProps={{
                            loading: isLoading,
                            placement: 'trailing',
                            variant: 'lite',
                          }}
                          onClick={wrappedPromise}
                        >
                          Добавить объект, входящий в состав основного объекта №{indexOfObject + 1}
                        </Button>
                      )
                    }}
                  </AsyncWrapper>
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
    </FlippedWithCollapse>
  )
}

export default memo(ObjectItem)
