import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValues } from '@components/Forms/NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isNotEmptyString, isString } from '@helpers/checkTypes'

const SOLICITATION_DAYS_REVISION_LIMIT = 100

const fourthSectionValidationMap: FormValuesValidationSection<
  keyof NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValues['4'],
  NotificationOfIdentifiedViolationsDuringContractRecognizeConcludedAFormValues
> = {
  ruleLinkSolicitationRelatedContractChecking: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  ruleLinkSolicitationRelatedContractReturn: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  solicitationRelatedContractTechError: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 500) : undefined),
  },
  solicitationRelatedContractRequirementError: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 500) : undefined),
  },
  solicitationRelatedContractContractKindError: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 500) : undefined),
  },
  solicitationRelatedContractDocumentError: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 500) : undefined),
  },
  solicitationRelatedContractRequirementLinkError: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  solicitationRelatedContractRuleError: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 500) : undefined),
  },
  solicitationRelatedContractRuleLinkError: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  solicitationRelatedContractRelatedContractError: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 500) : undefined),
  },
  solicitationRelatedContractCreditContractError: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 500) : undefined),
  },
  solicitationRelatedContractOtherError: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 500) : undefined),
  },
  solicitationDaysRevision: {
    required: defaultRHFValidation.required,
    validate: (value) => {
      if (!isString(value)) return

      return (
        Number(value) <= SOLICITATION_DAYS_REVISION_LIMIT ||
        `значение не должно превышать ${SOLICITATION_DAYS_REVISION_LIMIT}`
      )
    },
  },
}

export { fourthSectionValidationMap }
