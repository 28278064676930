import React, { FC } from 'react'
import { FieldArrayWithId, useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import FieldArraySwapper from '@components/DocumentFormComponents/FieldArraySwapper'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import {
  useNestedMenuHandlersManager,
} from '@components/DocumentFormComponents/NestedMenu/Manager'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/const'
import AnotherStage from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/8/AdditionalForms/AnotherStage'
import { EighthAnotherStagesPathName } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/8/types'
import { useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Manager'
import { AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/watcher'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { isNotEmptyString } from '@helpers/checkTypes'
import { nanoid } from 'nanoid'

interface StepActivityOtherBlockProps {
  isShowButton: boolean
  indexOfStep: number
  parentAnchorId: string
  name: EighthAnotherStagesPathName
}

const StepActivityOtherBlock: FC<StepActivityOtherBlockProps> = ({
  name,
  parentAnchorId,
  indexOfStep,
  isShowButton,
}) => {
  const formInstance =
    useFormContext<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>()

  const {
    state: { editMode },
    handlers: { handleRemoveItemFromList, handleUpElementInList, handleDownElementInList },
    preparedProps: { subscribableControl },
  } = useAAgreementOnNonConclusionOrNonPerformanceOfConcessionAManager()

  const { handleAddNewItemInThree } = useNestedMenuHandlersManager()

  const {
    fields: stepActivityOthers,
    append,
    remove,
    replace,
  } = useFieldArraySubscribableControl<
    AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
    EighthAnotherStagesPathName,
    'keyNameId'
  >({
    control: formInstance?.control,
    name,
    keyName: 'keyNameId',
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldArrayControlUpdateWatcher,
  })

  const { getSubscribableControlProps } = useFormComponentPresets({
    subscribableControl,
    watcher: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFieldsControlUpdateWatcher,
    formInstance,
  })

  const handleAddAnotherStage = async () => {
    append({
      id: nanoid(),
      anchorId: '',
      frontId: nanoid(),
      otherActivityName: '',
      stepNumber: '',
      otherActivityEndDate: '',
      measureObjects: [],
    })

    const stepActivityOthers = formInstance.getValues(name)

    const anotherStageAnchorId = `${name}.${stepActivityOthers.length}`

    handleAddNewItemInThree?.(parentAnchorId, {
      id: `${parentAnchorId}.${stepActivityOthers.length}`,
      path: anotherStageAnchorId,
      title: `Мероприятие №${stepActivityOthers.length}`,
      isCollapsed: false,
      hasError: false,
      parentBaseId: parentAnchorId,
      children: [],
    })
  }

  const handleRemoveAnotherStage = (index: number) => async () => {
    const stepNumber = formInstance?.getValues(`${name}.${index}.stepNumber`)

    if (isNotEmptyString(stepNumber)) {
      await handleRemoveItemFromList?.(`${name}.${index}`, name)
    } else {
      remove(index)
    }
  }

  const handleChangeElementOrderToUp =
    (index: number) =>
    async (_sourceIndex: number, _destinationIndex: number, updatedList?: unknown[]) => {
      const stepNumber = formInstance?.getValues(`${name}.${index}.stepNumber`)

      if (isNotEmptyString(stepNumber)) {
        await handleUpElementInList?.(`${name}.${index}`)
      } else {
        replace(
          updatedList as unknown as FieldArrayWithId<
            AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
            EighthAnotherStagesPathName,
            'keyNameId'
          >,
        )
      }
    }

  const handleChangeElementOrderToDown =
    (index: number) =>
    async (_sourceIndex: number, _destinationIndex: number, updatedList?: unknown[]) => {
      const stepNumber = formInstance?.getValues(`${name}.${index}.stepNumber`)

      if (isNotEmptyString(stepNumber)) {
        await handleDownElementInList?.(`${name}.${index}`)
      } else {
        replace(
          updatedList as unknown as FieldArrayWithId<
            AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
            EighthAnotherStagesPathName,
            'keyNameId'
          >,
        )
      }
    }

  const handleSuccessSelectStepNumber = (index: number) => () => {
    remove(index)
  }

  // Условие, чтобы не отрисовывать пустые контейнеры
  if ((!stepActivityOthers || !stepActivityOthers.length) && !isShowButton) return null

  return (
    <Container className={'p-0'}>
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: aAgreementOnNonConclusionOrNonPerformanceOfConcessionABlockValues['8']
              .stepActivityOthers,
          })}
        >
          <FlipperList list={stepActivityOthers} listIdName={'id'}>
            {stepActivityOthers.map((stage, index) => {
              const preparedName = `${name}.${index}` as const

              return (
                <FieldView.StepTemplate
                  anchorId={`${parentAnchorId}.${index + 1}`}
                  key={stage.id}
                  id={stage.frontId}
                  additionalNode={
                    editMode && (
                      <FieldArraySwapper
                        index={index}
                        lastIndex={stepActivityOthers.length - 1}
                        list={stepActivityOthers}
                        onMoveTop={handleChangeElementOrderToUp(index)}
                        onMoveBottom={handleChangeElementOrderToDown(index)}
                      />
                    )
                  }
                  onRemoveStep={editMode ? handleRemoveAnotherStage(index) : undefined}
                >
                  <AnotherStage
                    indexOfStep={indexOfStep}
                    name={preparedName}
                    objectName={`anotherFields.objectsFromSteps.${indexOfStep}`}
                    parentName={name}
                    anchorId={stage.anchorId}
                    onSuccessSelectStepNumber={handleSuccessSelectStepNumber(index)}
                  />
                </FieldView.StepTemplate>
              )
            })}
          </FlipperList>
        </SubscribableControl>
        {isShowButton && editMode && (
          <Row>
            <AsyncWrapper promise={handleAddAnotherStage}>
              {({ isLoading, wrappedPromise }) => {
                return (
                  <FieldView.ActionButton
                    disabled={isLoading}
                    loaderProps={{
                      loading: isLoading,
                      placement: 'trailing',
                      variant: 'lite',
                    }}
                    onClick={wrappedPromise}
                  >
                    Добавить мероприятие
                  </FieldView.ActionButton>
                )
              }}
            </AsyncWrapper>
          </Row>
        )}
      </Stack>
    </Container>
  )
}

export default StepActivityOtherBlock
