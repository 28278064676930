import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import {
  defaultCreateStatementFormValues,
  defaultProjectCompensation,
  defaultStepOfStage,
  defaultTaxType,
} from '@components/Forms/CreateStatement/const'
import {
  IStatementStepOfStage,
  IStatementTax,
  StatementCompensation,
  StatementFormValues,
} from '@components/Forms/CreateStatement/types'
import { MultipleInputValue } from '@components/NewDesign/MultipleInput/types'
import { isArray } from '@helpers/checkTypes'

const generateRHFObjectForCreateStatement = (
  objectFromPropertyState: Record<string, any>,
): StatementFormValues => {
  const preparedInvestorHeaderEmail = ((): MultipleInputValue[] | null => {
    if (
      !isArray(objectFromPropertyState.docSignerEmails?.value) ||
      !objectFromPropertyState.docSignerEmails.value.length
    )
      return null

    return objectFromPropertyState.docSignerEmails.value.map((email) => ({
      value: email.propertyId,
      displayValue: email.value,
    }))
  })()

  const preparedStepOfStages = ((): IStatementStepOfStage[] => {
    if (
      !isArray(objectFromPropertyState.steps?.value) ||
      !objectFromPropertyState.steps.value.length
    )
      return []

    return objectFromPropertyState.steps.value.map((step) => ({
      id: step.propertyId,
      stepDescription: step.value.stepDescription?.value || defaultStepOfStage.stepDescription,
      stepStartDate: step.value.stepStartDate?.value || defaultStepOfStage.stepStartDate,
      stepEndDate: step.value.stepEndDate?.value || defaultStepOfStage.stepEndDate,
    }))
  })()

  const preparedTaxesType = ((): IStatementTax[] => {
    if (
      !isArray(objectFromPropertyState.plannedTax.value) ||
      !objectFromPropertyState.plannedTax.value.length
    )
      return []

    return objectFromPropertyState.plannedTax.value
      .sort((taxA, taxB) => taxA.order - taxB.order)
      .map((tax) => ({
        id: tax.propertyId,
        taxYear: tax.value?.taxYear?.value || defaultTaxType.taxYear,
        taxYearValue: tax.value?.taxYearValue?.value || defaultTaxType.taxYearValue,
      }))
  })()

  const preparedCompensations = ((): StatementCompensation[] => {
    if (
      !isArray(objectFromPropertyState.projectCompensations.value) ||
      !objectFromPropertyState.projectCompensations?.value.length
    )
      return []

    return objectFromPropertyState.projectCompensations.value
      .sort((compensationA, compensationB) => compensationA.order - compensationB.order)
      .map(
        (compensation): StatementCompensation => ({
          id: compensation.propertyId,
          projectCompensationType: !!compensation.value.projectCompensationType.value?.id
            ? {
                value: compensation.value.projectCompensationType.value?.id || '',
                displayValue: compensation.value.projectCompensationType.value?.name || '',
              }
            : null,
          projectCompensationForm: !!compensation.value.projectCompensationForm.value?.id
            ? {
                value: compensation.value.projectCompensationForm.value?.id || '',
                displayValue: compensation.value.projectCompensationForm.value?.name || '',
              }
            : null,
          projectCompensationPeriod:
            compensation.value.projectCompensationPeriod?.value?.name || '',
          projectCompensationValue:
            compensation.value.projectCompensationValue?.value ||
            defaultProjectCompensation.projectCompensationValue,
        }),
      )
  })()

  return {
    investor: {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
      isProjectCompany:
        objectFromPropertyState.investor?.value.isProjectCompany?.value ||
        defaultCreateStatementFormValues.investor.isProjectCompany,
      isVed:
        objectFromPropertyState.investor?.value.isVed?.value ||
        defaultCreateStatementFormValues.investor.isVed,
      isKgn:
        objectFromPropertyState.investor?.value.isKgn?.value ||
        defaultCreateStatementFormValues.investor.isKgn,
      region:
        objectFromPropertyState.investor?.value?.memberOrganizationId?.value.region ||
        defaultCreateStatementFormValues.investor.region,
      city:
        objectFromPropertyState.investor?.value?.memberOrganizationId?.value.city ||
        defaultCreateStatementFormValues.investor.city,
      district:
        objectFromPropertyState.investor?.value?.memberOrganizationId?.value.district ||
        defaultCreateStatementFormValues.investor.district,
      area:
        objectFromPropertyState.investor?.value?.memberOrganizationId?.value.area ||
        defaultCreateStatementFormValues.investor.area,
      planningStructureElement:
        objectFromPropertyState.investor?.value?.memberOrganizationId?.value
          .planningStructureElement ||
        defaultCreateStatementFormValues.investor.planningStructureElement,
      roadNetworkElement:
        objectFromPropertyState.investor?.value?.memberOrganizationId?.value.roadNetworkElement ||
        defaultCreateStatementFormValues.investor.roadNetworkElement,
      house:
        objectFromPropertyState.investor?.value?.memberOrganizationId?.value.house ||
        defaultCreateStatementFormValues.investor.roadNetworkElement,
      room:
        objectFromPropertyState.investor?.value?.memberOrganizationId?.value.room ||
        defaultCreateStatementFormValues.investor.room,
      roomPart:
        objectFromPropertyState.investor?.value?.memberOrganizationId?.value.roomPart ||
        defaultCreateStatementFormValues.investor.roomPart,
      investorHeaderEmail: preparedInvestorHeaderEmail,
      investorHeaderPhone:
        objectFromPropertyState.docSignerPhone?.value ||
        defaultCreateStatementFormValues.investor.investorHeaderPhone,
      investorHeaderAddPhone:
        objectFromPropertyState.docSignerAddPhone?.value ||
        defaultCreateStatementFormValues.investor.investorHeaderAddPhone,
    },
    generalInfo: {
      economicSphere: !!objectFromPropertyState.economicSphere.value?.id
        ? {
            value: objectFromPropertyState.economicSphere.value?.id || '',
            displayValue: objectFromPropertyState.economicSphere.value?.name || '',
          }
        : defaultCreateStatementFormValues.generalInfo.economicSphere,
      otherEconomicSphere:
        objectFromPropertyState?.otherEconomicSphere?.value ||
        defaultCreateStatementFormValues.generalInfo.otherEconomicSphere,
      projectOkved:
        objectFromPropertyState.projectOkved?.value ||
        defaultCreateStatementFormValues.generalInfo.projectOkved,
      lawLinkReasonSzpk:
        objectFromPropertyState.lawLinkReasonSzpk?.value ||
        defaultCreateStatementFormValues.generalInfo.lawLinkReasonSzpk,
      ruleLinkReasonSzpk:
        objectFromPropertyState.ruleLinkReasonSzpk?.value ||
        defaultCreateStatementFormValues.generalInfo.ruleLinkReasonSzpk,
      newWorkPlaces:
        objectFromPropertyState.newWorkPlaces?.value ||
        defaultCreateStatementFormValues.generalInfo.newWorkPlaces,
      buildWorkPlaces:
        objectFromPropertyState.buildWorkPlaces?.value ||
        defaultCreateStatementFormValues.generalInfo.buildWorkPlaces,
      projectDescription:
        objectFromPropertyState.projectDescription?.value ||
        defaultCreateStatementFormValues.generalInfo.projectDescription,
    },
    stage: {
      projectStartDate:
        objectFromPropertyState.projectStartDate?.value ||
        defaultCreateStatementFormValues.stage.projectStartDate,
      projectEndDate:
        objectFromPropertyState.projectEndDate?.value ||
        defaultCreateStatementFormValues.stage.projectEndDate,
      agreementEndDate:
        objectFromPropertyState.agreementEndDate?.value ||
        defaultCreateStatementFormValues.stage.agreementEndDate,
      stepOfStages: preparedStepOfStages,
    },
    participantsAndInvestments: {
      projectDecisionDate:
        objectFromPropertyState?.projectDecisionDate?.value ||
        defaultCreateStatementFormValues.participantsAndInvestments.projectDecisionDate,
      endExploitationStage:
        objectFromPropertyState.endExploitationStage?.value ||
        defaultCreateStatementFormValues.participantsAndInvestments.endExploitationStage,
      investorCapital:
        objectFromPropertyState.investorCapital?.value ||
        defaultCreateStatementFormValues.participantsAndInvestments.investorCapital,
      investorCapitalVat:
        objectFromPropertyState.investorCapitalVAT?.value ||
        defaultCreateStatementFormValues.participantsAndInvestments.investorCapitalVat,
      totalCapitalVat:
        objectFromPropertyState.totalCapitalVAT?.value ||
        defaultCreateStatementFormValues.participantsAndInvestments.totalCapitalVat,
      totalCapital:
        objectFromPropertyState.totalCapital?.value ||
        defaultCreateStatementFormValues.participantsAndInvestments.totalCapital,
    },
    plannedTax: {
      taxesType: preparedTaxesType,
    },
    compensation: {
      projectCompensations: preparedCompensations,
    },
  }
}

export { generateRHFObjectForCreateStatement }
