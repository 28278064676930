import { FC } from 'react'
import { Path, useFormContext } from 'react-hook-form'

import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import Group, { GroupProps } from '@components/DocumentFormComponents/Group'
import { useDeviationText } from '@components/DocumentFormComponents/hooks/useDeviationText'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { FormRules } from '@components/DocumentFormComponents/types'
import { useDataOnFulfillmentOfTermsSZPKManager } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Manager'
import { DataOnFulfillmentOfTermsSZPKFormValues } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/types'
import { DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'

interface GroupWithTriplePlanAndFactReportsProps {
  isIntegersOnly?: boolean

  groupTitle: string
  groupProps?: Omit<GroupProps, 'children'>

  planFormName: Path<DataOnFulfillmentOfTermsSZPKFormValues>
  planCheckerFormName: Path<DataOnFulfillmentOfTermsSZPKFormValues>
  planCorrectFormName: Path<DataOnFulfillmentOfTermsSZPKFormValues>
  planCorrectRules: FormRules<DataOnFulfillmentOfTermsSZPKFormValues>

  factFormName: Path<DataOnFulfillmentOfTermsSZPKFormValues>
  factCheckerFormName: Path<DataOnFulfillmentOfTermsSZPKFormValues>
  factCorrectFormName: Path<DataOnFulfillmentOfTermsSZPKFormValues>
  factCorrectRules: FormRules<DataOnFulfillmentOfTermsSZPKFormValues>
}

const GroupWithTriplePlanAndFactReports: FC<GroupWithTriplePlanAndFactReportsProps> = ({
  isIntegersOnly,
  groupTitle,
  groupProps,
  planFormName,
  planCheckerFormName,
  planCorrectFormName,
  planCorrectRules,
  factFormName,
  factCheckerFormName,
  factCorrectFormName,
  factCorrectRules,
}) => {
  const formInstance = useFormContext<DataOnFulfillmentOfTermsSZPKFormValues>()

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleChangeValue, debouncedHandleChangeValue },
    preparedProps: { subscribableControl },
  } = useDataOnFulfillmentOfTermsSZPKManager()

  const { getSubscribableControlProps, getAmountInputProps } = useFormComponentPresets({
    formInstance,
    editMode,
    blockViewIsValidating,
    subscribableControl,
    watcher: DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher,
  })

  const percentDeviationTextForPlanChecker = useDeviationText(
    formInstance,
    planFormName,
    planCheckerFormName,
  )

  const percentDeviationTextForFactChecker = useDeviationText(
    formInstance,
    factFormName,
    factCheckerFormName,
  )

  return (
    <Group title={groupTitle} {...groupProps}>
      <RowWithBorder
        disableBottomDefaultStyles
        title="Плановое значение по отчетам ОРП"
        firstColumnSize={6}
        secondColumnSize={6}
      >
        <SubscribableControl
          {...getSubscribableControlProps({
            path: planFormName,
          })}
        >
          <ControlledAmountInput
            {...getAmountInputProps({
              name: planFormName,
              inputProps: {
                disabled: true,
                ...(isIntegersOnly
                  ? {
                      integersOnly: true,
                      suffix: '',
                    }
                  : {}),
              },
            })}
          />
        </SubscribableControl>
      </RowWithBorder>
      <RowWithBorder
        disableBottomDefaultStyles
        title="Плановое значение по справкам проверяющей организации"
        firstColumnSize={6}
        secondColumnSize={6}
      >
        <SubscribableControl
          {...getSubscribableControlProps({
            path: planCheckerFormName,
          })}
        >
          <ControlledAmountInput
            {...getAmountInputProps({
              name: planCheckerFormName,
              inputProps: {
                disabled: true,
                leftAddons: (
                  <FormIconWithTooltip tooltipContent={percentDeviationTextForPlanChecker} />
                ),
                ...(isIntegersOnly
                  ? {
                      integersOnly: true,
                      suffix: '',
                    }
                  : {}),
              },
            })}
          />
        </SubscribableControl>
      </RowWithBorder>
      <RowWithBorder
        disableBottomDefaultStyles
        title="Плановое значение для переноса в отчет"
        firstColumnSize={6}
        secondColumnSize={6}
      >
        <SubscribableControl
          {...getSubscribableControlProps({
            path: planCorrectFormName,
          })}
        >
          <ControlledAmountInput
            {...getAmountInputProps({
              name: planCorrectFormName,
              rules: planCorrectRules,
              onBlur: () =>
                setTimeout(() => {
                  handleChangeValue?.(planCorrectFormName)
                }, 0),
              onChange: () =>
                setTimeout(() => {
                  debouncedHandleChangeValue?.(planCorrectFormName)
                }, 0),
              inputProps: {
                ...(isIntegersOnly
                  ? {
                      integersOnly: true,
                      suffix: '',
                    }
                  : {}),
              },
            })}
          />
        </SubscribableControl>
      </RowWithBorder>
      <RowWithBorder
        disableBottomDefaultStyles
        title="Фактическое значение по отчетам ОРП"
        firstColumnSize={6}
        secondColumnSize={6}
      >
        <SubscribableControl
          {...getSubscribableControlProps({
            path: factFormName,
          })}
        >
          <ControlledAmountInput
            {...getAmountInputProps({
              name: factFormName,
              inputProps: {
                disabled: true,
                ...(isIntegersOnly
                  ? {
                      integersOnly: true,
                      suffix: '',
                    }
                  : {}),
              },
            })}
          />
        </SubscribableControl>
      </RowWithBorder>
      <RowWithBorder
        disableBottomDefaultStyles
        title="Фактическое значение по справкам проверяющей организации"
        firstColumnSize={6}
        secondColumnSize={6}
      >
        <SubscribableControl
          {...getSubscribableControlProps({
            path: factCheckerFormName,
          })}
        >
          <ControlledAmountInput
            {...getAmountInputProps({
              name: factCheckerFormName,
              inputProps: {
                disabled: true,
                leftAddons: (
                  <FormIconWithTooltip tooltipContent={percentDeviationTextForFactChecker} />
                ),
                ...(isIntegersOnly
                  ? {
                      integersOnly: true,
                      suffix: '',
                    }
                  : {}),
              },
            })}
          />
        </SubscribableControl>
      </RowWithBorder>
      <RowWithBorder
        disableBottomDefaultStyles
        title="Фактическое значение для переноса в отчет"
        firstColumnSize={6}
        secondColumnSize={6}
      >
        <SubscribableControl
          {...getSubscribableControlProps({
            path: factCorrectFormName,
          })}
        >
          <ControlledAmountInput
            {...getAmountInputProps({
              name: factCorrectFormName,
              rules: factCorrectRules,
              onBlur: () =>
                setTimeout(() => {
                  handleChangeValue?.(factCorrectFormName)
                }, 0),
              onChange: () =>
                setTimeout(() => {
                  debouncedHandleChangeValue?.(factCorrectFormName)
                }, 0),
              inputProps: {
                ...(isIntegersOnly
                  ? {
                      integersOnly: true,
                      suffix: '',
                    }
                  : {}),
              },
            })}
          />
        </SubscribableControl>
      </RowWithBorder>
    </Group>
  )
}

export default GroupWithTriplePlanAndFactReports
