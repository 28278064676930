import React, {
  Children,
  cloneElement,
  ComponentType,
  isValidElement,
  ReactElement,
  ReactNode,
} from 'react'
import { Control, FieldValues, Path, useWatch } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import Border from '@components/DocumentFormComponents/FieldView/Border'
import Button from '@components/NewDesign/Button'
import { Tooltip } from '@components/NewDesign/Tooltip'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import {
  isArray,
  isEmptyString,
  isFunction,
  isNotEmptyString,
  isUndefined,
} from '@helpers/checkTypes'
import cn from 'classnames'

import styles from './GroupWithPublic.module.scss'

const uiComponentNames = [
  'ControllerTextarea',
  'ControlledInput',
  'ControlledMultipleInput',
  'ControlledCalendarInput',
  'ControlledMaskInput',
  'ControlledAmountInput',
  'ControlledSwitch',
  'ControlledCheckbox',
  'ControlledFormSingleSelect',
  'ControlledFormMultipleSelect',
  'ControlledHierarchyReferenceBookSelect',
  'ControlledDocumentDataView',
]

interface GroupWithPublicProps<T extends FieldValues> {
  name: Path<T>
  control: Control<T>
  children: ReactNode
  controlledFormNames?: string[]
  leftAddons?: ReactNode
  tooltipContent?: ReactNode
  disableBottomBorder?: boolean
  disableTitlePaddingLeft?: boolean
  disableTitlePaddingRight?: boolean
  groupClassName?: string
  titleClassName?: string
  title?: string
  id?: string
  onPublic?: (formNames: string[], value: boolean) => void
}

const GroupWithPublic = <T extends FieldValues>({
  disableTitlePaddingLeft = false,
  disableTitlePaddingRight = false,
  title,
  id,
  name,
  control,
  leftAddons,
  tooltipContent,
  disableBottomBorder,
  controlledFormNames,
  groupClassName,
  titleClassName,
  children,
  onPublic,
}: GroupWithPublicProps<T>) => {
  const isPublic = useWatch({
    name: `${name}.isPublic` as Path<T>,
    control,
  })

  const isShowTitle = isNotEmptyString(title)

  const leftAddonsRenderCondition = !isUndefined(leftAddons)

  const isShowPublic = !isUndefined(onPublic) && !isUndefined(isPublic)

  const publicTooltipContent = isPublic
    ? 'Данные не будут отображены в публичной части реестра СЗПК'
    : 'Данные будут отображены в публичной части реестра СЗПК'

  const findFormNameRecursively = (children: ReactNode, formNames: string[]) => {
    Children.forEach(children, (child) => {
      if (!isValidElement(child)) return

      if (
        isFunction(child.type) &&
        'displayName' in child.type &&
        uiComponentNames.includes(child.type?.['displayName'])
      ) {
        if ('controllerProps' in child.props) {
          formNames.push(child.props.controllerProps.name)
        } else {
          formNames.push(child.props.name)
        }
      }

      findFormNameRecursively(child.props.children, formNames)
    })
  }

  const handlePublicOnClick = async () => {
    if (isArray(controlledFormNames) && !!controlledFormNames.length)
      return onPublic?.(controlledFormNames, !isPublic)

    const formNames: string[] = []

    findFormNameRecursively(children, formNames)

    await onPublic?.(formNames, !isPublic)
  }

  const cloneSelectComponent = (children: ReactElement) => {
    return cloneElement(children, {
      ...children.props,
      selectProps: {
        ...children.props.selectProps,
        inputProps: {
          ...children.props.selectProps.inputProps,
          labelTextClassName: cn(children.props?.selectProps?.inputProps?.labelTextClassName, {
            [styles['groupWithPublic__label--isPublic']]: isPublic,
          }),
        },
      },
    })
  }

  const cloneDataViewComponent = (children: ReactElement) => {
    return cloneElement(children, {
      ...children.props,
      suptitleTypographyProps: {
        ...children.props.suptitleTypographyProps,
        className: cn(children.props?.suptitleTypographyProps?.className, {
          [styles['groupWithPublic__label--isPublic']]: isPublic,
        }),
      },
    })
  }

  const cloneComponentByPropsName = (children: ReactElement, propsName: string) => {
    return cloneElement(children, {
      ...children.props,
      [propsName]: {
        ...children.props[propsName],
        labelTextClassName: cn(children.props[propsName]?.labelTextClassName, {
          [styles['groupWithPublic__label--isPublic']]: isPublic,
        }),
      },
    })
  }

  const cloneChild = (child: ReactElement) => {
    if ((child.type as ComponentType)?.displayName === 'ControlledDocumentDataView') {
      return cloneDataViewComponent(child)
    }

    if ('selectProps' in child.props) {
      return cloneSelectComponent(child)
    }

    if ('textareaProps' in child.props) {
      return cloneComponentByPropsName(child, 'textareaProps')
    }

    if ('calendarInputProps' in child.props) {
      return cloneComponentByPropsName(child, 'calendarInputProps')
    }

    if ('switchProps' in child.props) {
      return cloneComponentByPropsName(child, 'switchProps')
    }

    if ('checkBoxProps' in child.props) {
      return cloneComponentByPropsName(child, 'checkBoxProps')
    }

    if ('inputProps' in child.props) {
      return cloneComponentByPropsName(child, 'inputProps')
    }

    return child
  }

  const renderRecursiveMap = (children: ReactNode) => {
    return React.Children.map(children, (child) => {
      if (!isValidElement(child)) return child

      if (
        isFunction(child.type) &&
        'displayName' in child.type &&
        uiComponentNames.includes(child.type?.['displayName'])
      ) {
        return cloneChild(child)
      }

      return cloneElement(child, {
        children: renderRecursiveMap(child.props.children || null),
      })
    })
  }

  return (
    <Container
      id={id}
      className={cn(
        styles.groupWithPublic,
        'p-0',
        {
          [styles['groupWithPublic--disableMarginTop']]: !title || isEmptyString(title),
          [styles['groupWithPublic--isPublic']]: !!isPublic,
        },
        groupClassName,
      )}
    >
      <Row>
        <Col xs={12}>
          {(isShowTitle || isShowPublic) && (
            <Stack
              gap={2}
              direction={'horizontal'}
              className={cn(styles['groupWithPublic__title-wrapper'], {
                [styles['groupWithPublic__title-wrapper--withOnlyPublic']]:
                  !isShowTitle && isShowPublic,
              })}
            >
              {isShowTitle && (
                <div className={styles['groupWithPublic__title-col']}>
                  {leftAddonsRenderCondition && (
                    <div className={styles['groupWithPublic__title-leftAddons']}>{leftAddons}</div>
                  )}
                  <Typography.Body
                    variant={'bodyMMedium'}
                    className={cn(
                      styles.groupWithPublic__title,
                      {
                        [styles['groupWithPublic__title--disablePaddingLeft']]:
                          disableTitlePaddingLeft,
                        [styles['groupWithPublic__title--disablePaddingRight']]:
                          disableTitlePaddingRight,
                        [styles['groupWithPublic__title--isPublic']]: !!isPublic,
                      },
                      titleClassName,
                    )}
                  >
                    {title}
                  </Typography.Body>
                </div>
              )}
              {isShowPublic && (
                <div className={styles['groupWithPublic__button-col']}>
                  <Tooltip
                    content={tooltipContent || publicTooltipContent}
                    targetClassName={styles.tooltip__target}
                    position={'top'}
                    fallbackPlacements={['top', 'left', 'right', 'bottom']}
                  >
                    <AsyncWrapper promise={handlePublicOnClick}>
                      {({ isLoading, wrappedPromise }) => (
                        <Button
                          disabled={isLoading}
                          size={'xs'}
                          view={'plain'}
                          geometry={'round'}
                          color={isPublic ? 'negative' : 'default'}
                          variant={'buttonSMedium'}
                          className={styles.groupWithPublic__button}
                          textClassName={styles['groupWithPublic__button-text']}
                          loaderProps={{
                            loading: isLoading,
                            placement: 'trailing',
                            variant: 'lite',
                            className: styles['groupWithPublic__button-loader'],
                            wrapperClassName: styles['groupWithPublic__button-loaderWrapper'],
                          }}
                          onClick={wrappedPromise}
                        >
                          {isPublic ? 'Не публиковать' : 'Публиковать'}
                        </Button>
                      )}
                    </AsyncWrapper>
                  </Tooltip>
                </div>
              )}
            </Stack>
          )}
          {renderRecursiveMap(children)}
          {!disableBottomBorder && <Border className={styles.group__border} />}
        </Col>
      </Row>
    </Container>
  )
}

export default GroupWithPublic
