import type { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import {
  RegistrationCertificateFormValues,
  RegistrationCertificateRelatedContractListRegisteredDocumentValue,
} from '@components/Forms/RegistrationCertificateForm/types'
import { lengthValidate } from '@constants/validations'
import { isNotEmptyString } from '@helpers/checkTypes'

const registrationCertificateRelatedContractItemValidationMap: FormValuesValidationSection<
  keyof RegistrationCertificateRelatedContractListRegisteredDocumentValue,
  RegistrationCertificateFormValues
> = {
  relatedContractNumberRegisteredDocument: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 100) : undefined),
  },
  relatedContractDescriptionRegisteredDocument: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
  relatedContractOrganizationRegisteredDocument: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 1000) : undefined),
  },
}

export { registrationCertificateRelatedContractItemValidationMap }
