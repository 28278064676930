import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import {
  ICompensationMonitoring,
  ICompensationMonitoringChecker,
  InformationOfExecutionConditionsSZPKFormValues,
  IStepsMonitoring,
} from '@components/Forms/InformationOfExecutionConditionsSZPKForm/types'
import { sortByStringWithNumber } from '@helpers/array/sortByStringWithNumber'
import { isArray } from '@helpers/checkTypes'
import { convertStringToDateFormat } from '@helpers/date/convertStringToDateFormat'

const { getYearOfDateFromPropertyDateValue, formatBackendMoneyToString } = DocumentFormHelpers

const generateRHFObjectForInformationOfExecutionConditionsSZPKForm = (
  objectFromPropertyState: Record<string, any>,
): InformationOfExecutionConditionsSZPKFormValues => {
  const preparedStepsMonitoring = isArray(objectFromPropertyState.stepsMonitoring?.value)
    ? objectFromPropertyState.stepsMonitoring?.value.map((step, indexOfStep): IStepsMonitoring => {
        const stepAnchorId = `3.${indexOfStep + 1}`
        const preparedStepCompensationPeriod = isArray(
          step.value.planStepId.value.stepCompensations,
        )
          ? sortByStringWithNumber(
              Array.from(
                new Set(
                  step.value.planStepId.value.stepCompensations.map(
                    (stepCompensation) => stepCompensation.stepCompensationPeriod.name,
                  ),
                ),
              ),
            ).join(', ')
          : ''

        return {
          id: step.propertyId,
          anchorId: stepAnchorId,
          stepNumber: step.value.planStepId.value.stepNumber,
          planStepTotalCapitalValue: step.value.planStepTotalCapitalValue.value,
          planStepTotalCapitalVatValue: step.value.planStepTotalCapitalVatValue.value,
          factStepTotalCapitalVatValue: step.value?.factStepTotalCapitalVatValue?.value,
          factStepTotalCapitalValue: step.value.factStepTotalCapitalValue.value,
          stepInvestorCapitalValue: formatBackendMoneyToString(
            step.value.planStepId.value.stepInvestorCapitalValue,
          ),
          stepInvestorCapitalValueVat: formatBackendMoneyToString(
            step.value.planStepId.value?.stepInvestorCapitalValueVat,
          ),
          factStepInvestorCapitalValue: step.value.factStepInvestorCapitalValue.value,
          factStepInvestorCapitalVatValue: step.value?.factStepInvestorCapitalVatValue?.value,

          stepCompensationValue: formatBackendMoneyToString(
            step.value.planStepId.value.stepCompensationValue,
          ),
          factStepCompensationVatValue: formatBackendMoneyToString(
            step.value.planStepId.value?.factStepCompensationVatValue,
          ),
          factStepCompensationValue: step.value.factStepCompensationValue.value,
          factStepCompensationValueVat: step.value?.factStepCompensationVatValue?.value,
          stepCompensationPeriod: preparedStepCompensationPeriod,
          factStepCompensationPeriod: getYearOfDateFromPropertyDateValue(
            step.value.factStepCompensationPeriod.value,
          ),

          factStepCompensationEndPeriod: getYearOfDateFromPropertyDateValue(
            step.value.factStepCompensationEndPeriod.value,
          ),

          planStepWorkPlace: step.value.planStepWorkPlace.value,
          factStepWorkPlace: step.value.factStepWorkPlace.value,
          stepStartDate: convertStringToDateFormat(step.value.planStepId.value.stepStartDate),
          stepEndDate: convertStringToDateFormat(step.value.planStepId.value.stepEndDate),
          factStepStartDate: step.value.factStepStartDate.value,
          factStepEndDate: step.value.factStepEndDate.value,
          stepInfoInvestor: step.value.stepInfoInvestor.value,
        }
      })
    : []

  const preparedCompensationsMonitoring = (() => {
    if (
      !isArray(objectFromPropertyState.compensationsMonitoring?.value) ||
      !objectFromPropertyState.compensationsMonitoring?.value.length
    )
      return []

    return objectFromPropertyState.compensationsMonitoring?.value.map(
      (compensation, indexOfCompensation): ICompensationMonitoring => {
        const compensationAnchorId = `7.${indexOfCompensation + 1}`
        return {
          id: compensation.propertyId,
          anchorId: compensationAnchorId,
          compensationYear: getYearOfDateFromPropertyDateValue(
            compensation.value.compensationYear.value,
          ),

          compensationRelatedInfrastructure: isArray(
            compensation.value.compensationsRelatedInfrastructure?.value,
          )
            ? compensation.value.compensationsRelatedInfrastructure.value.map(
                (compensationRelated) => ({
                  id: compensationRelated.propertyId,
                  relatedInfrastructureObjectName:
                    compensationRelated.value.relatedInfrastructureObject.value.objectName,
                  relatedInfrastructureObjectValue:
                    compensationRelated.value.relatedInfrastructureObjectValue.value,
                }),
              )
            : [],

          generalCompensationRelatedInfrastructure:
            compensation.value.generalCompensationRelatedInfrastructure.value,

          compensationSupportingInfrastructure: isArray(
            compensation.value.compensationsSupportingInfrastructure?.value,
          )
            ? compensation.value.compensationsSupportingInfrastructure.value.map(
                (compensationSupporting) => ({
                  id: compensationSupporting.propertyId,
                  supportingInfrastructureObjectName:
                    compensationSupporting.value.supportingInfrastructureObject.value.objectName,
                  supportingInfrastructureObjectValue:
                    compensationSupporting.value.supportingInfrastructureObjectValue.value,
                }),
              )
            : [],

          generalCompensationSupportingInfrastructure:
            compensation.value.generalCompensationSupportingInfrastructure?.value,

          compensationPercentCredit: isArray(compensation.value.compensationsPercentCredit?.value)
            ? compensation.value.compensationsPercentCredit.value.map((compensationCredit) => {
                const preparedPercentCreditFullName =
                  (compensationCredit.value.compensationPercentCreditId.value.percentCreditName ||
                    '') +
                  (compensationCredit.value.compensationPercentCreditId.value.percentCreditNumber
                    ? ' №' +
                      compensationCredit.value.compensationPercentCreditId.value.percentCreditNumber
                    : '')

                return {
                  id: compensationCredit.propertyId,
                  percentCreditFullName: preparedPercentCreditFullName,
                  compensationRelatedContractValue:
                    compensationCredit.value.compensationRelatedContractValue.value,
                }
              })
            : [],

          generalCompensationsPercentCredit:
            compensation.value.generalCompensationsPercentCredit?.value,

          compensationDismantling: isArray(compensation.value.compensationsDismantling?.value)
            ? compensation.value.compensationsDismantling.value.map((compensationDismantling) => ({
                id: compensationDismantling.propertyId,
                dismantlingObjectName:
                  compensationDismantling.value.dismantlingObjectId.value.dismantlingObjectName,
                dismantlingObjectValue: compensationDismantling.value.dismantlingObjectValue.value,
              }))
            : [],

          generalCompensationsDismantling:
            compensation.value.generalCompensationsDismantling?.value,
          compensationExpense: isArray(compensation.value.compensationsExpenses?.value)
            ? compensation.value.compensationsExpenses.value.map((compensationExpenses) => ({
                id: compensationExpenses.propertyId,
                expenseName: compensationExpenses.value.expenseId.value.expenseName,
                expenseValue: compensationExpenses.value.expenseValue.value,
              }))
            : [],
          generalCompensationsExpenses: compensation.value.generalCompensationsExpenses.value,
          compensationSubsidyFormat: compensation.value.compensationSubsidyFormat.value,
          compensationTaxDeductionFormat: compensation.value.compensationTaxDeductionFormat.value,
          compensationDamageFormat: compensation.value.compensationDamageFormat.value,
          generalCompensation: compensation.value.generalCompensation.value,
        }
      },
    )
  })()

  const preparedCompensationsMonitoringChecker = (() => {
    if (
      !isArray(objectFromPropertyState.compensationsMonitoring?.value) ||
      !objectFromPropertyState.compensationsMonitoring?.value.length
    )
      return []

    return objectFromPropertyState.compensationsMonitoring?.value.map(
      (compensation, indexOfCompensation): ICompensationMonitoringChecker => ({
        id: compensation.propertyId,
        anchorId: `8.${indexOfCompensation + 1}`,
        compensationYear: getYearOfDateFromPropertyDateValue(
          compensation.value.compensationYear.value,
        ),
        incomeTaxFederal: compensation.value.incomeTaxFederal.value,
        incomeTaxRegional: compensation.value.incomeTaxRegional.value,
        propertyTax: compensation.value.propertyTax.value,
        landTax: compensation.value.landTax.value,
        vatTax: compensation.value.vatTax.value,
        customDuty: compensation.value.customDuty.value,
        generalTaxValue: compensation.value.generalTaxValue.value,
      }),
    )
  })()

  return {
    '1': {
      ...RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    },
    '2': {
      projectName: objectFromPropertyState.projectName?.value,
      projectDecisionDepartament: objectFromPropertyState.projectDecisionDepartament?.value,
      economicSphere: objectFromPropertyState.economicSphere?.value?.name,
      projectOkved: objectFromPropertyState.projectOkvedMonitoring?.value,
      szpkRegistrationDate: objectFromPropertyState.szpkRegistrationDate?.value,
      startPreinvestStage: objectFromPropertyState.startPreinvestStage?.value,
      endPreinvestStage: objectFromPropertyState.endPreinvestStage?.value,
      startInvestStage: objectFromPropertyState.startInvestStage?.value,
      endInvestStage: objectFromPropertyState.endInvestStage?.value,
      startExploitationStage: objectFromPropertyState.startExploitationStage?.value,
      endExploitationStage: objectFromPropertyState.endExploitationStage?.value,
      isVatMoney: objectFromPropertyState.isVatMoney?.value,
      totalCapital: objectFromPropertyState.totalCapitalVAT?.value,
      totalCapitalVAT: objectFromPropertyState.totalCapitalVAT?.value,
      investorCapital: objectFromPropertyState.investorCapital?.value,
      investorCapitalVAT: objectFromPropertyState.investorCapitalVAT?.value,
      newWorkPlaces: objectFromPropertyState.newWorkPlaces?.value,
      projectResultEffect: objectFromPropertyState.projectResultEffect?.value,
    },
    '3': {
      stepsMonitoring: preparedStepsMonitoring,
    },
    '4': {
      percentCredits:
        isArray(objectFromPropertyState.percentCredits?.value) &&
        objectFromPropertyState.percentCredits.value.length
          ? objectFromPropertyState.percentCredits?.value.map((credit) => ({
              id: credit.propertyId,
              percentCreditName: credit.value.percentCreditName?.value,
              percentCreditDate: credit.value.percentCreditDate?.value,
              percentCreditNumber: credit.value.percentCreditNumber?.value,

              percentCreditMembers:
                isArray(credit.value.percentCreditMembers?.value) &&
                credit.value.percentCreditMembers?.value.length
                  ? credit.value.percentCreditMembers?.value.map((member) => ({
                      id: member.propertyId,
                      percentCreditMember: member.value,
                    }))
                  : [],
            }))
          : [],
    },
    '5': {
      dismantlingObjects:
        isArray(objectFromPropertyState.dismantlingObjects?.value) &&
        objectFromPropertyState.dismantlingObjects.value.length
          ? objectFromPropertyState.dismantlingObjects?.value.map((dismantlingObject) => ({
              id: dismantlingObject.propertyId,
              dismantlingObjectName: dismantlingObject.value.dismantlingObjectName.value,
            }))
          : [],
    },
    '6': {
      expenses:
        isArray(objectFromPropertyState.expenses?.value) &&
        objectFromPropertyState.expenses.value.length
          ? objectFromPropertyState.expenses?.value.map((expense) => ({
              id: expense.propertyId,
              expenseName: expense.value.expenseName.value,
            }))
          : [],
    },
    '7': {
      compensationsMonitoring: preparedCompensationsMonitoring,
    },
    '8': {
      compensationsMonitoringChecker: preparedCompensationsMonitoringChecker,
    },
    '9': {
      generalStateSupportValue: objectFromPropertyState.generalStateSupportValue?.value,
      stateSupports:
        isArray(objectFromPropertyState.stateSupports?.value) &&
        objectFromPropertyState.stateSupports.value.length
          ? objectFromPropertyState.stateSupports?.value.map((stateSupport) => ({
              id: stateSupport.propertyId,
              stateSupportName: stateSupport.value.stateSupportName.value,
              stateSupportReason: stateSupport.value.stateSupportReason.value,
              stateSupportValue: stateSupport.value.stateSupportValue.value,
            }))
          : [],
    },
    '10': {
      agreementDutyReason: objectFromPropertyState.agreementDutyReason?.value,
      agreementDutyReasonDescription: objectFromPropertyState.agreementDutyReasonDescription?.value,
      changeCompensationFormatReason: objectFromPropertyState.changeCompensationFormatReason?.value,
      changeCompensationFormatReasonDescription:
        objectFromPropertyState.changeCompensationFormatReasonDescription?.value,
      changeSuggestions: objectFromPropertyState.changeSuggestions?.value,
      compensationChangeReason: objectFromPropertyState.compensationChangeReason?.value,
      compensationChangeReasonDescription:
        objectFromPropertyState.compensationChangeReasonDescription?.value,
      concessionFailReason: objectFromPropertyState.concessionFailReason?.value,
      concessionFailReasonDescription:
        objectFromPropertyState.concessionFailReasonDescription?.value,
      constructionChangeReason: objectFromPropertyState.constructionChangeReason?.value,
      constructionChangeReasonDescription:
        objectFromPropertyState.constructionChangeReasonDescription?.value,
      extendStabilizationReason: objectFromPropertyState.extendStabilizationReason?.value,
      extendStabilizationReasonDescription:
        objectFromPropertyState.extendStabilizationReasonDescription?.value,
      forceMajeureReason: objectFromPropertyState.forceMajeureReason?.value,
      forceMajeureReasonDescription: objectFromPropertyState.forceMajeureReasonDescription?.value,
      infoChangesReason: objectFromPropertyState.infoChangesReason?.value,
      infoChangesReasonDescription: objectFromPropertyState.infoChangesReasonDescription?.value,
      investmentChangeReason: objectFromPropertyState.investmentChangeReason?.value,
      investmentChangeReasonDescription:
        objectFromPropertyState.investmentChangeReasonDescription?.value,
      lawChangeReason: objectFromPropertyState.lawChangeReason?.value,
      lawChangeReasonDescription: objectFromPropertyState.lawChangeReasonDescription?.value,
      lawViolationReason: objectFromPropertyState.lawViolationReason?.value,
      lawViolationReasonDescription: objectFromPropertyState.lawViolationReasonDescription?.value,
      legalFactsReason: objectFromPropertyState.legalFactsReason?.value,
      legalFactsReasonDescription: objectFromPropertyState.legalFactsReasonDescription?.value,
      liquidationOrganisationReason: objectFromPropertyState.liquidationOrganisationReason?.value,
      liquidationOrganisationReasonDescription:
        objectFromPropertyState.liquidationOrganisationReasonDescription?.value,
      municipalAccessionReason: objectFromPropertyState.municipalAccessionReason?.value,
      municipalAccessionReasonDescription:
        objectFromPropertyState.municipalAccessionReasonDescription?.value,
      noMadeInvestorCapitalReason: objectFromPropertyState.noMadeInvestorCapitalReason?.value,
      noMadeInvestorCapitalReasonDescription:
        objectFromPropertyState.noMadeInvestorCapitalReasonDescription?.value,
      otherReason: objectFromPropertyState.otherReason?.value,
      otherReasonDescription: objectFromPropertyState.otherReasonDescription?.value,
      otherReasonName: objectFromPropertyState.otherReasonName?.value,
      relatedContractReason: objectFromPropertyState.relatedContractReason?.value,
      relatedContractReasonDescription:
        objectFromPropertyState.relatedContractReasonDescription?.value,
      rightTransferReason: objectFromPropertyState.rightTransferReason?.value,
      rightTransferReasonDescription: objectFromPropertyState.rightTransferReasonDescription?.value,
      supportingInfrastructureReason: objectFromPropertyState.supportingInfrastructureReason?.value,
      supportingInfrastructureReasonDescription:
        objectFromPropertyState.supportingInfrastructureReasonDescription?.value,
      suspensionOrganizationActivityReason:
        objectFromPropertyState.suspensionOrganizationActivityReason?.value,
      suspensionOrganizationActivityReasonDescription:
        objectFromPropertyState.suspensionOrganizationActivityReasonDescription?.value,
    },
    additionalFields: {
      isPaper: objectFromPropertyState?.isPaper?.value,
    },
  }
}

export { generateRHFObjectForInformationOfExecutionConditionsSZPKForm }
