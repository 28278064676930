import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import {
  ICompensationFact,
  ICompensationFactObject,
  ParametersApplicationReimbursementExpensesFormValues,
} from '@components/Forms/ParametersApplicationReimbursementExpensesForm/types'
import { isArray } from '@helpers/checkTypes'

const { fromYearBackendToYEARCalendar } = DocumentFormHelpers

const generateRHFObjectForParametersApplicationReimbursementExpensesForm = (
  objectFromPropertyState: Record<string, any>,
): ParametersApplicationReimbursementExpensesFormValues => {
  return {
    '1': RHFBasicAdapters.getInvestorAdapter(objectFromPropertyState),
    '2': {
      szpkNumber: objectFromPropertyState?.szpkNumber?.value,
      szpkRegistrationDate: objectFromPropertyState?.szpkRegistrationDate?.value,
      projectName: objectFromPropertyState?.projectName?.value,
    },
    '3': {
      compensationsFacts: isArray(objectFromPropertyState.compensationFacts?.value)
        ? objectFromPropertyState.compensationFacts?.value.map(
            (compensationFact, index): ICompensationFact => ({
              id: compensationFact.propertyId,
              anchorId: `3.${index}`,
              tabName: `Сведения о поддержке ОРП № ${index + 1} ${
                compensationFact?.value?.compensationFactDate?.value
                  ? `от ${compensationFact?.value?.compensationFactDate?.value}`
                  : ''
              }`,
              compensationFactYear: compensationFact?.value?.compensationFactYear?.value
                ? fromYearBackendToYEARCalendar(
                    compensationFact?.value?.compensationFactYear?.value,
                  )
                : '',
              compensationFactFormValue: compensationFact?.value?.compensationFactFormValue?.value
                ?.id
                ? {
                    displayValue: compensationFact?.value?.compensationFactFormValue?.value?.name,
                    value: compensationFact?.value?.compensationFactFormValue?.value?.id,
                  }
                : null,
              compensationFactExpenseGroupValue: !!compensationFact?.value
                ?.compensationFactExpenseGroupValue?.value?.id
                ? {
                    displayValue:
                      compensationFact?.value?.compensationFactExpenseGroupValue?.value?.name,
                    value: compensationFact?.value?.compensationFactExpenseGroupValue?.value?.id,
                  }
                : null,

              compensationFactObjects: isArray(
                compensationFact?.value?.compensationFactObjects?.value,
              )
                ? compensationFact?.value?.compensationFactObjects?.value.map(
                    (compensationFactObject): ICompensationFactObject => ({
                      id: compensationFactObject?.propertyId,
                      compensationFactObject: compensationFactObject?.value
                        ?.compensationFactObjectId?.value?.id
                        ? {
                            displayValue:
                              compensationFactObject?.value?.compensationFactObjectId?.value
                                ?.objectName ||
                              compensationFactObject?.value?.compensationFactObjectId?.value
                                ?.otherActivityName ||
                              compensationFactObject?.value?.compensationFactObjectId?.value
                                ?.ridName,
                            value:
                              compensationFactObject?.value?.compensationFactObjectId?.value?.id,
                          }
                        : null,
                      compensationFactObjectOther:
                        compensationFactObject?.value?.compensationFactObjectOther?.value,
                    }),
                  )
                : [],
              compensationFactSum: compensationFact?.value?.compensationFactSum?.value,
              compensationFactDate: compensationFact?.value?.compensationFactDate?.value,
            }),
          )
        : [],
    },
  } as ParametersApplicationReimbursementExpensesFormValues
}

export { generateRHFObjectForParametersApplicationReimbursementExpensesForm }
