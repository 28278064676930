import React from 'react'
import { useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import { useFormModifierContext } from '@components/DocumentFormComponents/FormModifierProviderWrapper'
import Group from '@components/DocumentFormComponents/Group'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { registrationCertificateBlockValues } from '@components/Forms/RegistrationCertificateForm/const'
import RelatedContractListRegisteredDocumentValueItem from '@components/Forms/RegistrationCertificateForm/Forms/7/RelatedContractListRegisteredDocumentValueItem'
import { RelatedContractListRegisteredDocumentValuesPathName } from '@components/Forms/RegistrationCertificateForm/Forms/7/types'
import { useRegistrationCertificateManager } from '@components/Forms/RegistrationCertificateForm/Manager'
import {
  RegistrationCertificateFormModifierValues,
  RegistrationCertificateFormValues,
} from '@components/Forms/RegistrationCertificateForm/types'
import {
  RegistrationCertificateFieldArrayControlUpdateWatcher,
  RegistrationCertificateFieldsControlUpdateWatcher,
} from '@components/Forms/RegistrationCertificateForm/watcher'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import styles from './RelatedContractListRegisteredDocumentValues.module.scss'

const Seventh = () => {
  const formInstance = useFormContext<RegistrationCertificateFormValues>()

  const formModifierInstance = useFormModifierContext<RegistrationCertificateFormModifierValues>()

  const {
    state: { editMode, blockViewIsValidating },
    handlers: { handleAddItemToListWithOutValue, handleRemoveItemFromList },
    preparedProps: { subscribableControl },
  } = useRegistrationCertificateManager()

  const { fields: relatedContractListRegisteredDocumentValues } = useFieldArraySubscribableControl<
    RegistrationCertificateFormValues,
    RelatedContractListRegisteredDocumentValuesPathName,
    'keyNameId'
  >({
    control: formInstance.control,
    name: registrationCertificateBlockValues['7'].relatedContractListRegisteredDocumentValues,
    watcher: RegistrationCertificateFieldArrayControlUpdateWatcher,
  })

  const { getSubscribableControlProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: RegistrationCertificateFieldsControlUpdateWatcher,
  })

  const handleAddDocumentValue = async () => {
    await handleAddItemToListWithOutValue?.(
      registrationCertificateBlockValues['7'].relatedContractListRegisteredDocumentValues,
    )
  }

  const handleRemoveDocumentValue = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${registrationCertificateBlockValues['7'].relatedContractListRegisteredDocumentValues}.${index}`,
      registrationCertificateBlockValues['7'].relatedContractListRegisteredDocumentValues,
    )
  }

  if (!formInstance) return null

  return (
    <Group
      disableBottomBorder
      groupClassName={styles['relatedContractListRegisteredDocumentValues__group']}
      titleClassName={styles['relatedContractListRegisteredDocumentValues__group-title']}
      title="Данная информация необходима для заполнения пункта 22 Справки, представляемой в Федеральное казначейство"
    >
      <Stack gap={3} direction={'vertical'}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: registrationCertificateBlockValues['7']
              .relatedContractListRegisteredDocumentValues,
          })}
        >
          <FlipperList list={relatedContractListRegisteredDocumentValues}>
            {relatedContractListRegisteredDocumentValues.map((documentValue, index) => {
              const formName =
                `${registrationCertificateBlockValues['7'].relatedContractListRegisteredDocumentValues}.${index}` as const

              return (
                <SubscribableControl
                  key={documentValue.id}
                  {...getSubscribableControlProps({
                    path: formName,
                  })}
                >
                  <RelatedContractListRegisteredDocumentValueItem
                    id={documentValue.id}
                    formName={formName}
                    formInstance={formInstance}
                    formModifierInstance={formModifierInstance}
                    onRemove={handleRemoveDocumentValue(index)}
                  />
                </SubscribableControl>
              )
            })}
          </FlipperList>
        </SubscribableControl>
        {editMode && (
          <Row>
            <AsyncWrapper promise={handleAddDocumentValue}>
              {({ isLoading, wrappedPromise }) => {
                return (
                  <FieldView.ActionButton
                    disabled={isLoading}
                    loaderProps={{
                      loading: isLoading,
                      placement: 'trailing',
                      variant: 'lite',
                    }}
                    onClick={wrappedPromise}
                  >
                    Добавить связанный договор в пункт 22
                  </FieldView.ActionButton>
                )
              }}
            </AsyncWrapper>
          </Row>
        )}
      </Stack>
    </Group>
  )
}

export default Seventh
