import React, { FC } from 'react'
import { useWatch } from 'react-hook-form'

import { ControlledDocumentDataView } from '@components/DocumentFormComponents/DocumentDataView/ControlledDocumentDataView'
import RowWithBorder from '@components/DocumentFormComponents/FieldView/RowWithBorder'
import FormIconWithTooltip from '@components/DocumentFormComponents/FormTooltip/FormIconWithTooltip'
import Group from '@components/DocumentFormComponents/Group'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import SummaryReportOfMonitoringResultsRegionReportInfoCollapse from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/3/RegionReportInfoCollapse'
import { BaseSummaryReportOfMonitoringResultsRegionReportItemProps } from '@components/Forms/SummaryReportOfMonitoringResultsForm/Forms/3/RegionReportInfoItem/types'
import { SummaryReportOfMonitoringResultsFieldsControlUpdateWatcher } from '@components/Forms/SummaryReportOfMonitoringResultsForm/watcher'
import { ControlledAmountInput } from '@components/NewDesign/AmountInput/ControlledAmount'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

const SummaryReportOfMonitoringResultsRegionReportInfoFederalNewWorkPlacesItem: FC<
  BaseSummaryReportOfMonitoringResultsRegionReportItemProps
> = ({ editMode, blockViewIsValidating, formName, index, formInstance, subscribableControl }) => {
  const federalNewWorkPlacesFactDeviation = useWatch({
    name: `${formName}.federalNewWorkPlacesFactDeviation`,
    control: formInstance.control,
  })

  const federalNewWorkPlacesPlanDeviation = useWatch({
    name: `${formName}.federalNewWorkPlacesPlanDeviation`,
    control: formInstance.control,
  })

  const { getAmountInputProps, getSubscribableControlProps, getControllerProps } =
    useFormComponentPresets({
      formInstance,
      editMode,
      blockViewIsValidating,
      subscribableControl,
      watcher: SummaryReportOfMonitoringResultsFieldsControlUpdateWatcher,
    })

  return (
    <SummaryReportOfMonitoringResultsRegionReportInfoCollapse indexOfReport={index}>
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <Col xs={12}>
            <SubscribableControl
              {...getSubscribableControlProps({
                path: `${formName}.regionName`,
              })}
            >
              <ControlledDocumentDataView
                suptitle={'Наименование региона'}
                controllerProps={getControllerProps({
                  name: `${formName}.regionName`,
                })}
              />
            </SubscribableControl>
          </Col>
        </Row>
        <Group title={'Количество рабочих мест'}>
          <Stack direction={'vertical'} gap={3}>
            <RowWithBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Количество из справок проверяющей организации. Фактическое значение'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.federalNewWorkPlacesFactChecker`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.federalNewWorkPlacesFactChecker`,
                    inputProps: {
                      disabled: true,
                      integersOnly: true,
                      suffix: '',
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Количество из регионального отчета. Фактическое значение'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.federalNewWorkPlacesFact`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.federalNewWorkPlacesFact`,
                    inputProps: {
                      disabled: true,
                      integersOnly: true,
                      suffix: '',
                      leftAddons: (
                        <FormIconWithTooltip tooltipContent={federalNewWorkPlacesFactDeviation} />
                      ),
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              firstColumnSize={6}
              secondColumnSize={6}
              title={'Количество из справок проверяющей организации. Плановое значение'}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.federalNewWorkPlacesPlanChecker`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.federalNewWorkPlacesPlanChecker`,
                    inputProps: {
                      disabled: true,
                      integersOnly: true,
                      suffix: '',
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
            <RowWithBorder
              disableBottomDefaultStyles
              title={'Количество из регионального отчета. Плановое значение'}
              firstColumnSize={6}
              secondColumnSize={6}
            >
              <SubscribableControl
                {...getSubscribableControlProps({
                  path: `${formName}.federalNewWorkPlacesPlan`,
                })}
              >
                <ControlledAmountInput
                  {...getAmountInputProps({
                    name: `${formName}.federalNewWorkPlacesPlan`,
                    inputProps: {
                      disabled: true,
                      integersOnly: true,
                      suffix: '',
                      leftAddons: (
                        <FormIconWithTooltip tooltipContent={federalNewWorkPlacesPlanDeviation} />
                      ),
                    },
                  })}
                />
              </SubscribableControl>
            </RowWithBorder>
          </Stack>
        </Group>
      </Stack>
    </SummaryReportOfMonitoringResultsRegionReportInfoCollapse>
  )
}

export default SummaryReportOfMonitoringResultsRegionReportInfoFederalNewWorkPlacesItem
