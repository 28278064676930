import { FormValuesValidationSection } from '@components/DocumentFormComponents/types'
import { AAgreementAccessionMunicipalFormValues } from '@components/Forms/AAgreementAccessionMunicipalForm/types'
import { defaultRHFValidation, lengthValidate } from '@constants/validations'
import { isNotEmptyString, isString } from '@helpers/checkTypes'

const sixthSectionAAgreementAccessionMunicipalValidationMap: FormValuesValidationSection<
  keyof AAgreementAccessionMunicipalFormValues['6'],
  AAgreementAccessionMunicipalFormValues
> = {
  ruleLinkMunicipalNpa: {
    required: defaultRHFValidation.required,
    validate: (value) => isString(value) && lengthValidate(value, 100),
  },
  extraMunicipalAccessionLinkStabilization: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 100) : undefined),
  },
  extraMunicipalAccessionLinkCompensation: {
    validate: (value) => (isNotEmptyString(value) ? lengthValidate(value, 100) : undefined),
  },
}

export { sixthSectionAAgreementAccessionMunicipalValidationMap }
