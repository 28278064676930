import React from 'react'
import { useFormContext } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import ActionButton from '@components/DocumentFormComponents/FieldView/ActionButton'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import { useDataOnFulfillmentOfTermsSZPKManager } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/Manager'
import { DataOnFulfillmentOfTermsSZPKFormValues } from '@components/Forms/DataOnFulfillmentOfTermsSZPK/types'
import {
  DataOnFulfillmentOfTermsSZPKFieldArrayControlUpdateWatcher,
  DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher,
} from '@components/Forms/DataOnFulfillmentOfTermsSZPK/watcher'
import Container from '@components/ReactBootstrap/Container'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

import { dataOnFulfillmentOfTermsSZPKBlockValues } from '../../const'

import EconomicSphereCard from './AdditionalForms/EconomicSphereCard'
import styles from './Fourth.module.scss'
import { EconomicSpheresRegionalArrayPathName } from './types'

const Fourth = () => {
  const formInstance = useFormContext<DataOnFulfillmentOfTermsSZPKFormValues>()

  const economicSpheresRegionalReportFormName =
    dataOnFulfillmentOfTermsSZPKBlockValues['9'].economicSpheresRegionalReport

  const {
    state: { blockViewIsValidating, editMode },
    handlers: { handleRemoveItemFromList, handleAddItemToListWithOutValue },
    preparedProps: { subscribableControl },
  } = useDataOnFulfillmentOfTermsSZPKManager()

  const { getSubscribableControlProps } = useFormComponentPresets({
    formInstance,
    editMode,
    blockViewIsValidating,
    subscribableControl,
    watcher: DataOnFulfillmentOfTermsSZPKFieldsControlUpdateWatcher,
  })

  const { fields: economicSpheresRegionalReportCards } = useFieldArraySubscribableControl<
    DataOnFulfillmentOfTermsSZPKFormValues,
    EconomicSpheresRegionalArrayPathName,
    'keyNameId'
  >({
    name: economicSpheresRegionalReportFormName,
    control: formInstance?.control,
    keyName: 'keyNameId',
    watcher: DataOnFulfillmentOfTermsSZPKFieldArrayControlUpdateWatcher,
  })


  const handleAddSphere = async () => {
    await handleAddItemToListWithOutValue?.(economicSpheresRegionalReportFormName)

    if (!economicSpheresRegionalReportCards.length) {
      formInstance.clearErrors(economicSpheresRegionalReportFormName)
    }
  }

  const getRemoveSphereHandler = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${economicSpheresRegionalReportFormName}.${index}`,
      economicSpheresRegionalReportFormName,
    )
  }

  if (!formInstance) return null

  return (
    <Container className={'p-1'}>
      <Stack direction={'vertical'} gap={3}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: economicSpheresRegionalReportFormName,
          })}
        >
          <FlipperList list={economicSpheresRegionalReportCards}>
            {economicSpheresRegionalReportCards.map((economicSphere, index) => {
              const formName = `${economicSpheresRegionalReportFormName}.${index}` as const

              return (
                <EconomicSphereCard
                  key={economicSphere.id}
                  formName={formName}
                  parentAnchorId={'4'}
                  objectId={economicSphere.id}
                  indexOfObject={index}
                  onDeleteObject={getRemoveSphereHandler(index)}
                />
              )
            })}
          </FlipperList>
        </SubscribableControl>
        {editMode && (
          <Row>
            <AsyncWrapper promise={handleAddSphere}>
              {({ isLoading, wrappedPromise }) => (
                <ActionButton
                  disabled={isLoading}
                  wrapperClassName={styles['action-button-wrapper']}
                  loaderProps={{
                    loading: isLoading,
                    placement: 'trailing',
                    variant: 'lite',
                  }}
                  onClick={wrappedPromise}
                >
                  Добавить сферу реализации проекта
                </ActionButton>
              )}
            </AsyncWrapper>
          </Row>
        )}
      </Stack>
    </Container>
  )
}

export default Fourth
