import { FieldStateHelpers } from '@components/DocumentFormComponents/FieldState/FieldState.helpers'
import { AAgreementAccessionMunicipalFormValues } from '@components/Forms/AAgreementAccessionMunicipalForm/types'

const {
  getDefaultRfFieldState,
  getDefaultSubjectsFieldState,
  getDefaultNewMunicipalityFieldState,
  getDefaultInvestorFieldState,
} = FieldStateHelpers

const mapOfAAgreementAccessionMunicipalMenu = [
  { id: '1', title: 'Уполномоченный федеральный орган исполнительной власти' },
  { id: '2', title: 'Уполномоченный орган субъекта Российской Федерации' },
  { id: '3', title: 'Муниципальное образование' },
  { id: '4', title: 'Присоединяемое муниципальное образование' },
  { id: '5', title: 'Организация, реализующая проект' },
  { id: '6', title: 'Присоединение муниципального образования' },
]

const defaultAAgreementAccessionMunicipalFormValues: AAgreementAccessionMunicipalFormValues = {
  '1': getDefaultRfFieldState(),
  '2': {
    subjects: getDefaultSubjectsFieldState(),
  },
  '3': {
    municipalities: [
      {
        id: '1',
        municipalityFullName: '',
        municipalityFullNameGenitive: '',
        municipalityHeaderName: null,
        municipalityHeaderNameGenitive: '',
        municipalityHeaderPosition: '',
        municipalityHeaderPositionGenitive: '',
        municipalityHeaderReasonGenitive: '',
      },
    ],
  },
  '4': {
    ...getDefaultNewMunicipalityFieldState(),
    municipalityFullNameInstrumental: '',
  },
  '5': getDefaultInvestorFieldState(),
  '6': {
    ruleLinkMunicipalNpa: '',
    extraMunicipalAccessionLinkStabilization: '',
    extraMunicipalAccessionLinkCompensation: '',
    isMunicipalityReadyToCompensate: false,
  },
  additionalFields: {
    szpkNumber: '',
    initialMunicipalities: [],
  },
}

const aAgreementAccessionMunicipalBlockValues = {
  '1': {
    rfFullName: '1.rfFullName',
    rfHeaderName: '1.rfHeaderName',
    rfHeaderNameGenitive: '1.rfHeaderNameGenitive',
    rfHeaderPosition: '1.rfHeaderPosition',
    rfHeaderPositionGenitive: '1.rfHeaderPositionGenitive',
    rfHeaderReasonGenitive: '1.rfHeaderReasonGenitive',
  },
  '2': {
    subjects: '2.subjects',
  },
  '3': {
    municipalities: '3.municipalities',
  },
  '4': {
    municipalityFullName: '4.municipalityFullName',
    municipalityFullNameGenitive: '4.municipalityFullNameGenitive',
    municipalityFullNameInstrumental: '4.municipalityFullNameInstrumental',
    municipalityHeaderName: '4.municipalityHeaderName',
    municipalityHeaderNameGenitive: '4.municipalityHeaderNameGenitive',
    municipalityHeaderPosition: '4.municipalityHeaderPosition',
    municipalityHeaderPositionGenitive: '4.municipalityHeaderPositionGenitive',
    municipalityHeaderReasonGenitive: '4.municipalityHeaderReasonGenitive',
  },
  '5': {
    investorFullName: '5.investorFullName',
    investorHeaderName: '5.investorHeaderName',
    investorHeaderNameGenitive: '5.investorHeaderNameGenitive',
    investorHeaderPosition: '5.investorHeaderPosition',
    investorHeaderPositionGenitive: '5.investorHeaderPositionGenitive',
    investorHeaderReasonGenitive: '5.investorHeaderReasonGenitive',
  },
  '6': {
    ruleLinkMunicipalNpa: '6.ruleLinkMunicipalNpa',
    extraMunicipalAccessionLinkStabilization: '6.extraMunicipalAccessionLinkStabilization',
    extraMunicipalAccessionLinkCompensation: '6.extraMunicipalAccessionLinkCompensation',
    isMunicipalityReadyToCompensate: '6.isMunicipalityReadyToCompensate',
  },
  additionalFields: {
    szpkNumber: 'additionalFields.szpkNumber',
    initialMunicipalities: 'additionalFields.initialMunicipalities',
  },
} as const

export {
  aAgreementAccessionMunicipalBlockValues,
  defaultAAgreementAccessionMunicipalFormValues,
  mapOfAAgreementAccessionMunicipalMenu,
}
