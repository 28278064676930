import { FieldErrors, UnpackNestedValue } from 'react-hook-form'

import { NestedMenuHashMap } from '@components/DocumentFormComponents/NestedMenu/Manager/types'
import { NestedMapOfMenu } from '@components/DocumentFormComponents/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStage,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStepAnotherStage,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import { compact } from '@helpers/array/compact'
import { isNotEmptyString, isUndefined } from '@helpers/checkTypes'

const generateMenuListForAAgreementOnNonConclusionOrNonPerformanceOfConcessionA = (
  formValues:
    | AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues
    | UnpackNestedValue<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>,
  initialHashMap: NestedMenuHashMap,
  formErrors: FieldErrors<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>,
): NestedMapOfMenu[] => {
  const getMenuSectionWithError = (numberSectionOfMenu: keyof NestedMenuHashMap) => {
    if (isUndefined(initialHashMap[numberSectionOfMenu])) return

    const preparedChildrenSections =
      initialHashMap[numberSectionOfMenu]?.children?.map((item) => ({
        ...item,
        hasError: !!formErrors?.[item.id],
      })) || []

    return {
      ...initialHashMap[numberSectionOfMenu],
      hasError:
        !!formErrors?.[numberSectionOfMenu] ||
        preparedChildrenSections.some((item) => item.hasError),
      children: preparedChildrenSections,
    }
  }

  const getPreparedStagesMenu = (
    stage: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStage,
    indexOfStage: number,
  ): NestedMapOfMenu => {
    const stageId = `${initialHashMap['7'].id}.stages.${indexOfStage}`
    const stepError = formErrors?.['7']?.stages?.[indexOfStage]

    const preparedConstructionStageMenu = ((): NestedMapOfMenu[] => {
      if (!stage.isConstruction && !stage.isReconstruction && !stage.isModernization) return []

      const constructionStageId = `${stageId}.constructionStage`
      const constructionStageError = stepError?.constructionStage

      const constructionObjectsId = `${constructionStageId}.constructionObjects`
      const permissionsObjectsId = `${constructionStageId}.permissionsObjects`
      const exploitationObjectsId = `${constructionStageId}.exploitation.exploitationObjects`
      const registrationObjectsId = `${constructionStageId}.registration.registrationObjects`

      const preparedConstructionObjects = ((): NestedMapOfMenu[] => {
        if (
          !stage.constructionStage.constructionObjects ||
          !stage.constructionStage.constructionObjects.length
        )
          return []

        return stage.constructionStage.constructionObjects.map((object, indexOfObject) => ({
          path: `${constructionObjectsId}.${indexOfObject}`,
          id: `${stage.anchorId}.1.${indexOfObject + 1}`,
          title: isNotEmptyString(object.objectName)
            ? object.objectName
            : `Основной объект №${indexOfObject + 1}`,
          isCollapsed: false,
          hasError: !!constructionStageError?.constructionObjects?.[indexOfObject],
          parentBaseId: `${stage.anchorId}.1`,
          children: [],
        }))
      })()

      const preparedPermissionObjects = ((): NestedMapOfMenu[] => {
        if (
          !stage.constructionStage.permissionsObjects ||
          !stage.constructionStage.permissionsObjects.length
        )
          return []

        return stage.constructionStage.permissionsObjects.map((object, indexOfObject) => ({
          path: `${permissionsObjectsId}.${indexOfObject}`,
          id: `${stage.anchorId}.2.${indexOfObject + 1}`,
          parentBaseId: `${stage.anchorId}.2`,
          isCollapsed: false,
          hasError: !!constructionStageError?.permissionsObjects?.[indexOfObject],
          title: isNotEmptyString(object.permissionKind)
            ? object.permissionKind
            : `Разрешение №${indexOfObject + 1}`,
          children: [],
        }))
      })()

      const preparedExploitationObjects = ((): NestedMapOfMenu[] => {
        if (
          !stage.constructionStage.exploitation.exploitationObjects ||
          !stage.constructionStage.exploitation.exploitationObjects.length
        )
          return []

        return stage.constructionStage?.exploitation?.exploitationObjects.map(
          (object, indexOfObject) => ({
            path: `${exploitationObjectsId}.${indexOfObject}`,
            id: `${stage.anchorId}.3.${indexOfObject + 1}`,
            parentBaseId: `${stage.anchorId}.3`,
            isCollapsed: false,
            hasError: !!constructionStageError?.exploitation?.exploitationObjects?.[indexOfObject],
            title: isNotEmptyString(object.objectName)
              ? object.objectName
              : `Эксплуатация №${indexOfObject + 1}`,
            children: [],
          }),
        )
      })()

      const preparedRegistrationObjects = ((): NestedMapOfMenu[] => {
        if (
          !stage.constructionStage.registration.registrationObjects ||
          !stage.constructionStage.registration.registrationObjects.length
        )
          return []

        return stage.constructionStage.registration.registrationObjects.map(
          (object, indexOfObject) => ({
            path: `${registrationObjectsId}.${indexOfObject}`,
            id: `${stage.anchorId}.4.${indexOfObject + 1}`,
            parentBaseId: `${stage.anchorId}.4`,
            isCollapsed: false,
            hasError: !!constructionStageError?.registration?.registrationObjects?.[indexOfObject],
            title: isNotEmptyString(object.objectName)
              ? object.objectName
              : `Регистрация №${indexOfObject + 1}`,
            children: [],
          }),
        )
      })()

      return [
        {
          path: constructionObjectsId,
          id: `${stage.anchorId}.1`,
          title: 'Объекты недвижимости',
          parentBaseId: stage.anchorId,
          isCollapsed: false,
          hasError: !!constructionStageError?.constructionObjects?.length,
          children: preparedConstructionObjects,
        },
        {
          path: permissionsObjectsId,
          id: `${stage.anchorId}.2`,
          title: 'Разрешения на объекты',
          parentBaseId: stage.anchorId,
          isCollapsed: false,
          hasError: !!constructionStageError?.permissionsObjects?.length,
          children: preparedPermissionObjects,
        },
        {
          path: exploitationObjectsId,
          id: `${stage.anchorId}.3`,
          title: 'Эксплуатация объектов',
          isCollapsed: false,
          hasError: !!constructionStageError?.exploitation,
          parentBaseId: stage.anchorId,
          children: preparedExploitationObjects,
        },
        {
          path: registrationObjectsId,
          id: `${stage.anchorId}.4`,
          title: 'Регистрация объектов',
          isCollapsed: false,
          hasError: !!constructionStageError?.registration,
          parentBaseId: stage.anchorId,
          children: preparedRegistrationObjects,
        },
      ]
    })()

    const preparedRidStageMenu = ((): NestedMapOfMenu[] => {
      if (!stage.isCreateRID) return []

      const ridStageId = `${stageId}.ridStage`
      const ridError = stepError?.ridStage

      const ridObjectsId = `${ridStageId}.ridObjects`
      const ridExploitationObjectsId = `${ridStageId}.exploitation.ridExploitationObjects`
      const ridRegistrationObjectsId = `${ridStageId}.registration.ridRegistrationsObjects`

      const preparedRidObjects = ((): NestedMapOfMenu[] => {
        if (!stage.ridStage?.ridObjects || !stage.ridStage?.ridObjects?.length) return []

        return stage.ridStage?.ridObjects?.map((object, indexOfRid) => ({
          id: `${stage.anchorId}.5.${indexOfRid + 1}`,
          path: `${ridObjectsId}.${indexOfRid}`,
          title: isNotEmptyString(object.ridName) ? object.ridName : `РИД ${indexOfRid + 1}`,
          isCollapsed: false,
          hasError: !!ridError?.ridObjects?.[indexOfRid],
          parentBaseId: `${stage.anchorId}.5`,
          children: [],
        }))
      })()

      const preparedRidExploitationObjects = ((): NestedMapOfMenu[] => {
        if (
          !stage.ridStage?.exploitation?.ridExploitationObjects ||
          !stage.ridStage?.exploitation?.ridExploitationObjects.length
        )
          return []

        return stage.ridStage?.exploitation?.ridExploitationObjects.map((object, indexOfRid) => ({
          id: `${stage.anchorId}.6.${indexOfRid + 1}`,
          path: `${ridExploitationObjectsId}.${indexOfRid}`,
          title: isNotEmptyString(object.ridName)
            ? object.ridName
            : `Эксплуатация РИД ${indexOfRid + 1}`,
          isCollapsed: false,
          hasError: !!ridError?.exploitation?.ridExploitationObjects?.[indexOfRid],
          parentBaseId: `${stage.anchorId}.6`,
          children: [],
        }))
      })()

      const preparedRidRegistrationObjects = ((): NestedMapOfMenu[] => {
        if (
          !stage.ridStage?.registration?.ridRegistrationsObjects ||
          !stage.ridStage?.registration?.ridRegistrationsObjects.length
        )
          return []

        return stage.ridStage?.registration?.ridRegistrationsObjects.map((object, indexOfRid) => ({
          id: `${stage.anchorId}.7.${indexOfRid + 1}`,
          path: `${ridRegistrationObjectsId}.${indexOfRid}`,
          title: isNotEmptyString(object.ridName)
            ? object.ridName
            : `Регистрация РИД ${indexOfRid + 1}`,
          isCollapsed: false,
          hasError: !!ridError?.registration?.ridRegistrationsObjects?.[indexOfRid],
          parentBaseId: `${stage.anchorId}.7`,
          children: [],
        }))
      })()

      return [
        {
          id: `${stage.anchorId}.5`,
          path: ridObjectsId,
          title: 'Результаты интеллектуальной деятельности (РИД)',
          isCollapsed: false,
          hasError: !!ridError?.ridObjects?.length,
          parentBaseId: stage.anchorId,
          children: preparedRidObjects,
        },
        {
          id: `${stage.anchorId}.6`,
          path: ridExploitationObjectsId,
          title: 'Эксплуатация РИД',
          isCollapsed: false,
          hasError: !!ridError?.exploitation,
          parentBaseId: stage.anchorId,
          children: preparedRidExploitationObjects,
        },
        {
          id: `${stage.anchorId}.7`,
          path: ridRegistrationObjectsId,
          title: 'Регистрация РИД',
          isCollapsed: false,
          hasError: !!ridError?.registration,
          parentBaseId: stage.anchorId,
          children: preparedRidRegistrationObjects,
        },
      ]
    })()

    return {
      path: stageId,
      id: stage.anchorId,
      title: isNotEmptyString(stage.stepDescription)
        ? stage.stepDescription
        : `Мероприятие ${indexOfStage + 1}`,
      isCollapsed: false,
      parentBaseId: initialHashMap['7'].id,
      hasError: !!stepError,
      children: [...preparedConstructionStageMenu, ...preparedRidStageMenu],
    }
  }

  const getPreparedStepActivityOthersMenu = (
    item: AAgreementOnNonConclusionOrNonPerformanceOfConcessionAStepAnotherStage,
    indexOfStep: number,
  ): NestedMapOfMenu[] => {
    if (!item.activityOthers || !item.activityOthers.length) return []

    return item.activityOthers?.map((activity, indexOfActivity): NestedMapOfMenu => {
      const stepAnchorId = `${initialHashMap['8'].id}.stepActivityOthers.${indexOfStep}`

      const activityAnchorId = `${stepAnchorId}.activityOthers.${indexOfActivity + 1}`

      const activityId = `${initialHashMap['8'].id}.${indexOfStep + 1}.${indexOfActivity + 1}`

      const activityError =
        formErrors?.['8']?.stepActivityOthers?.[indexOfStep]?.activityOthers?.[indexOfActivity]

      const preparedMeasureObjectsMenu = ((): NestedMapOfMenu[] => {
        if (!activity.measureObjects || !activity.measureObjects.length) return []

        return activity.measureObjects.map((object, indexOfObject) => ({
          path: `${activityAnchorId}.${indexOfObject}`,
          id: `${activityId}.${indexOfObject + 1}`,
          parentBaseId: activityAnchorId,
          isCollapsed: false,
          hasError: !!activityError?.measureObjects?.length,
          title: isNotEmptyString(object.objectName)
            ? object.objectName
            : `Объект №${indexOfObject + 1}`,
          children: [],
        }))
      })()

      return {
        path: activityAnchorId,
        id: activityId,
        parentBaseId: stepAnchorId,
        title: isNotEmptyString(activity.otherActivityName)
          ? activity.otherActivityName
          : `Мероприятие ${indexOfActivity + 1}`,
        isCollapsed: false,
        hasError: !!activityError,
        children: preparedMeasureObjectsMenu,
      }
    })
  }

  const preparedStages = formValues['7'].stages?.map(getPreparedStagesMenu)

  const preparedStepActivityOtherMenu = formValues['8']?.stepActivityOthers?.flatMap(
    getPreparedStepActivityOthersMenu,
  )

  const preparedHashMap: Record<string, NestedMapOfMenu | undefined> = {
    '1': getMenuSectionWithError('1'),
    '2': getMenuSectionWithError('2'),
    '3': getMenuSectionWithError('3'),
    '4': getMenuSectionWithError('4'),
    '5': getMenuSectionWithError('5'),
    '10': getMenuSectionWithError('10'),
    '11': getMenuSectionWithError('11'),
    '12': getMenuSectionWithError('12'),
    '6': getMenuSectionWithError('6'),
    '9': getMenuSectionWithError('9'),
    '7': {
      ...initialHashMap['7'],
      hasError: preparedStages.some(({ hasError }) => hasError),
      children: preparedStages,
    },
    '8': {
      ...initialHashMap['8'],
      hasError: preparedStepActivityOtherMenu.some(({ hasError }) => hasError),
      children: preparedStepActivityOtherMenu,
    },
  }

  return compact([
    preparedHashMap['1'],
    preparedHashMap['2'],
    preparedHashMap['3'],
    preparedHashMap['4'],
    preparedHashMap['5'],
    preparedHashMap['6'],
    preparedHashMap['7'],
    preparedHashMap['8'],
    preparedHashMap['9'],
    preparedHashMap['10'],
    preparedHashMap['11'],
    preparedHashMap['12'],
  ]) as NestedMapOfMenu[]
}

export { generateMenuListForAAgreementOnNonConclusionOrNonPerformanceOfConcessionA }
