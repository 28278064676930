import React, { FC, ReactNode } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import FieldArraySwapper from '@components/DocumentFormComponents/FieldArraySwapper'
import Border from '@components/DocumentFormComponents/FieldView/Border'
import styles from '@components/DocumentFormComponents/FormStyles/AdditionalFormItem.module.scss'
import ObjectStepWrapper from '@components/DocumentFormComponents/ObjectStepWrapper'
import RegistrationFormItem from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/AdditionalForms/MainTemplate/ConstructionObject/Forms/CRegistrationForm/RegistrationFormItem'
import { SeventhRegistrationObjectsArrayPathName } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface RegistrationItemProps {
  id: string
  anchorId: string
  formName: SeventhRegistrationObjectsArrayPathName
  blockViewIsValidating: boolean
  editMode: boolean
  formInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>
  formModifierInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>
  indexOfRegistration: number
  lastIndexOfRegistration: number
  onDeleteRegistration: VoidFunction
  positiveStepEndDate: (text: string) => (value: unknown) => string | undefined

  onMoveTop: () => Promise<void>
  onMoveBottom: () => Promise<void>
  leftAddons?: ReactNode
}

const RegistrationItem: FC<RegistrationItemProps> = ({
  id,
  anchorId,
  blockViewIsValidating,
  editMode,
  formInstance,
  formModifierInstance,
  formName,
  indexOfRegistration,
  lastIndexOfRegistration,
  onDeleteRegistration,
  positiveStepEndDate,
  onMoveTop,
  onMoveBottom,
  leftAddons,
}) => {
  const title = useWatch({
    name: `${formName}.title`,
    control: formInstance.control,
  })

  return (
    <ObjectStepWrapper
      disableBorder
      stepId={id}
      id={anchorId}
      leftAddons={leftAddons}
      headerNode={<Typography.Body variant={'bodyMSemibold'}>{title}</Typography.Body>}
      additionalHeaderNode={
        editMode && (
          <FieldArraySwapper
            index={indexOfRegistration}
            lastIndex={lastIndexOfRegistration}
            onMoveTop={onMoveTop}
            onMoveBottom={onMoveBottom}
          />
        )
      }
      onRemoveObjectStep={editMode ? onDeleteRegistration : undefined}
    >
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <RegistrationFormItem
            formName={formName}
            blockViewIsValidating={blockViewIsValidating}
            formInstance={formInstance}
            formModifierInstance={formModifierInstance}
            positiveStepEndDate={positiveStepEndDate}
          />
        </Row>
        <Row>
          <Col xs={12}>
            <Border className={styles.additionalFormItem__border} />
          </Col>
        </Row>
      </Stack>
    </ObjectStepWrapper>
  )
}

export default RegistrationItem
