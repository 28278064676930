import { RHFBasicAdapters } from '@components/DocumentFormComponents/adapters/RHF.adapter'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import {
  IEconomicSphereFederalReport,
  IEconomicSphereOkved,
  IEconomicSphereOkvedFederalReport,
  IRegionFederalReport,
  IRegionReportInfo,
  ISzpkChangeTerminateFederalReport,
  SummaryReportOfMonitoringResultsFormValues,
} from '@components/Forms/SummaryReportOfMonitoringResultsForm/types'
import { isArray, isNullOrUndefined } from '@helpers/checkTypes'
import { convertStringToDateFormat } from '@helpers/date/convertStringToDateFormat'
import plural from 'plural-ru'

const { getDeviationText, formatBackendMoneyToString } = DocumentFormHelpers

const generateRHFObjectForSummaryReportOfMonitoringResultsForm = (
  objectFromPropertyState: Record<string, any>,
): SummaryReportOfMonitoringResultsFormValues => {
  const preparedRegionReportInfo: IRegionReportInfo[] = (() => {
    if (
      !isArray(objectFromPropertyState.regionsReportInfo?.value) ||
      !objectFromPropertyState.regionsReportInfo?.value?.length
    )
      return []

    return objectFromPropertyState.regionsReportInfo?.value.map(
      (regionReportInfo): IRegionReportInfo => {
        return {
          id: regionReportInfo.propertyId,
          regionName: regionReportInfo.value?.regionName?.value,
          federalSzpkCountPlan: regionReportInfo.value?.federalSzpkCountPlan?.value,
          federalSzpkCountFact: regionReportInfo.value?.federalSzpkCountFact?.value,
          regionalSzpkCountPlan: regionReportInfo.value?.regionalSzpkCountPlan?.value,
          regionalSzpkCountFact: regionReportInfo.value?.regionalSzpkCountFact?.value,
          federalSzpkPeriodCountPlan: regionReportInfo.value?.federalSzpkPeriodCountPlan?.value,
          federalSzpkPeriodCountFact: regionReportInfo.value?.federalSzpkPeriodCountFact?.value,
          federalTotalCapitalFactChecker:
            regionReportInfo.value?.federalTotalCapitalFactChecker?.value,
          federalTotalCapitalFact: regionReportInfo.value?.federalTotalCapitalFact?.value,
          federalTotalCapitalFactDeviation: getDeviationText(
            regionReportInfo.value?.federalTotalCapitalFact?.value,
            regionReportInfo.value?.federalTotalCapitalFactChecker?.value,
          ),
          federalTotalCapitalPlanChecker:
            regionReportInfo.value?.federalTotalCapitalPlanChecker?.value,
          federalTotalCapitalPlan: regionReportInfo.value?.federalTotalCapitalPlan?.value,
          federalTotalCapitalPlanDeviation: getDeviationText(
            regionReportInfo.value?.federalTotalCapitalPlan?.value,
            regionReportInfo.value?.federalTotalCapitalPlanChecker?.value,
          ),
          regionalTotalCapitalFactChecker:
            regionReportInfo.value?.regionalTotalCapitalFactChecker?.value,
          regionalTotalCapitalFact: regionReportInfo.value?.regionalTotalCapitalFact?.value,
          regionalTotalCapitalFactDeviation: getDeviationText(
            regionReportInfo.value?.regionalTotalCapitalFact?.value,
            regionReportInfo.value?.regionalTotalCapitalFactChecker?.value,
          ),
          regionalTotalCapitalPlanChecker:
            regionReportInfo.value?.regionalTotalCapitalPlanChecker?.value,
          regionalTotalCapitalPlan: regionReportInfo.value?.regionalTotalCapitalPlan?.value,
          regionalTotalCapitalPlanDeviation: getDeviationText(
            regionReportInfo.value?.regionalTotalCapitalPlan?.value,
            regionReportInfo.value?.regionalTotalCapitalPlanChecker?.value,
          ),
          federalInvestorCapitalFactChecker:
            regionReportInfo.value?.federalInvestorCapitalFactChecker?.value,
          federalInvestorCapitalFact: regionReportInfo.value?.federalInvestorCapitalFact?.value,
          federalInvestorCapitalFactDeviation: getDeviationText(
            regionReportInfo.value?.federalInvestorCapitalFact?.value,
            regionReportInfo.value?.federalInvestorCapitalFactChecker?.value,
          ),
          federalInvestorCapitalPlanChecker:
            regionReportInfo.value?.federalInvestorCapitalPlanChecker?.value,
          federalInvestorCapitalPlan: regionReportInfo.value?.federalInvestorCapitalPlan?.value,
          federalInvestorCapitalPlanDeviation: getDeviationText(
            regionReportInfo.value?.federalInvestorCapitalPlan?.value,
            regionReportInfo.value?.federalInvestorCapitalPlanChecker?.value,
          ),
          regionalInvestorCapitalFactChecker:
            regionReportInfo.value?.regionalInvestorCapitalFactChecker?.value,
          regionalInvestorCapitalFact: regionReportInfo.value?.regionalInvestorCapitalFact?.value,
          regionalInvestorCapitalFactDeviation: getDeviationText(
            regionReportInfo.value?.regionalInvestorCapitalFact?.value,
            regionReportInfo.value?.regionalInvestorCapitalFactChecker?.value,
          ),
          regionalInvestorCapitalPlanChecker:
            regionReportInfo.value?.regionalInvestorCapitalPlanChecker?.value,
          regionalInvestorCapitalPlan: regionReportInfo.value?.regionalInvestorCapitalPlan?.value,
          regionalInvestorCapitalPlanDeviation: getDeviationText(
            regionReportInfo.value?.regionalInvestorCapitalPlan?.value,
            regionReportInfo.value?.regionalInvestorCapitalPlanChecker?.value,
          ),
          federalNewWorkPlacesFactChecker:
            regionReportInfo.value?.federalNewWorkPlacesFactChecker?.value,
          federalNewWorkPlacesFact: regionReportInfo.value?.federalNewWorkPlacesFact?.value,
          federalNewWorkPlacesFactDeviation: getDeviationText(
            regionReportInfo.value?.federalNewWorkPlacesFact?.value,
            regionReportInfo.value?.federalNewWorkPlacesFactChecker?.value,
          ),
          federalNewWorkPlacesPlanChecker:
            regionReportInfo.value?.federalNewWorkPlacesPlanChecker?.value,
          federalNewWorkPlacesPlan: regionReportInfo.value?.federalNewWorkPlacesPlan?.value,
          federalNewWorkPlacesPlanDeviation: getDeviationText(
            regionReportInfo.value?.federalNewWorkPlacesPlan?.value,
            regionReportInfo.value?.federalNewWorkPlacesPlanChecker?.value,
          ),
          regionalNewWorkPlacesFactChecker:
            regionReportInfo.value?.regionalNewWorkPlacesFactChecker?.value,
          regionalNewWorkPlacesFact: regionReportInfo.value?.regionalNewWorkPlacesFact?.value,
          regionalNewWorkPlacesFactDeviation: getDeviationText(
            regionReportInfo.value?.regionalNewWorkPlacesFact?.value,
            regionReportInfo.value?.regionalNewWorkPlacesFactChecker?.value,
          ),
          regionalNewWorkPlacesPlanChecker:
            regionReportInfo.value?.regionalNewWorkPlacesPlanChecker?.value,
          regionalNewWorkPlacesPlan: regionReportInfo.value?.regionalNewWorkPlacesPlan?.value,
          regionalNewWorkPlacesPlanDeviation: getDeviationText(
            regionReportInfo.value?.regionalNewWorkPlacesPlan?.value,
            regionReportInfo.value?.regionalNewWorkPlacesPlanChecker?.value,
          ),
          federalCompensationValueFactChecker:
            regionReportInfo.value?.federalCompensationValueFactChecker?.value,
          federalCompensationValueFact: regionReportInfo.value?.federalCompensationValueFact?.value,
          federalCompensationValueFactDeviation: getDeviationText(
            regionReportInfo.value?.federalCompensationValueFact?.value,
            regionReportInfo.value?.federalCompensationValueFactChecker?.value,
          ),
          federalCompensationValuePlanChecker:
            regionReportInfo.value?.federalCompensationValuePlanChecker?.value,
          federalCompensationValuePlan: regionReportInfo.value?.federalCompensationValuePlan?.value,
          federalCompensationValuePlanDeviation: getDeviationText(
            regionReportInfo.value?.federalCompensationValuePlan?.value,
            regionReportInfo.value?.federalCompensationValuePlanChecker?.value,
          ),
          regionalCompensationValueFactChecker:
            regionReportInfo.value?.regionalCompensationValueFactChecker?.value,
          regionalCompensationValueFact:
            regionReportInfo.value?.regionalCompensationValueFact?.value,
          regionalCompensationValueFactDeviation: getDeviationText(
            regionReportInfo.value?.regionalCompensationValueFact?.value,
            regionReportInfo.value?.regionalCompensationValueFactChecker?.value,
          ),
          regionalCompensationValuePlanChecker:
            regionReportInfo.value?.regionalCompensationValuePlanChecker?.value,
          regionalCompensationValuePlan:
            regionReportInfo.value?.regionalCompensationValuePlan?.value,
          regionalCompensationValuePlanDeviation: getDeviationText(
            regionReportInfo.value?.regionalCompensationValuePlan?.value,
            regionReportInfo.value?.regionalCompensationValuePlanChecker?.value,
          ),

          reportSignDate: regionReportInfo.value?.reportSignDate?.value,
        }
      },
    )
  })()

  const preparedRegionsFederalReport: IRegionFederalReport[] = (() => {
    if (
      !isArray(objectFromPropertyState.regionsFederalReport?.value) ||
      !objectFromPropertyState.regionsFederalReport?.value?.length
    )
      return []

    return objectFromPropertyState.regionsFederalReport?.value.map(
      (regionFederalReport): IRegionFederalReport => {
        return {
          id: regionFederalReport.propertyId,
          regionName: regionFederalReport.value?.regionName?.value,
          countRegionFederalReport: regionFederalReport.value?.countRegionFederalReport?.value,
          countRegionFederalReportCorrect:
            regionFederalReport.value?.countRegionFederalReportCorrect?.value,
          totalCapitalRegionFederalReportChecker:
            regionFederalReport.value?.totalCapitalRegionFederalReportChecker?.value,
          totalCapitalRegionFederalReportRegion:
            regionFederalReport.value?.totalCapitalRegionFederalReportRegion?.value,
          totalCapitalRegionFederalReportCorrect:
            regionFederalReport.value?.totalCapitalRegionFederalReportCorrect?.value,
          investorCapitalRegionFederalReportChecker:
            regionFederalReport.value?.investorCapitalRegionFederalReportChecker?.value,
          investorCapitalRegionFederalReportRegion:
            regionFederalReport.value?.investorCapitalRegionFederalReportRegion?.value,
          investorCapitalRegionFederalReportCorrect:
            regionFederalReport.value?.investorCapitalRegionFederalReportCorrect?.value,
          newWorkPlacesRegionFederalReportChecker:
            regionFederalReport.value?.newWorkPlacesRegionFederalReportChecker?.value,
          newWorkPlacesRegionFederalReportRegion:
            regionFederalReport.value?.newWorkPlacesRegionFederalReportRegion?.value,
          newWorkPlacesRegionFederalReportCorrect:
            regionFederalReport.value?.newWorkPlacesRegionFederalReportCorrect?.value,
        }
      },
    )
  })()

  const preparedSzpksChangeTerminateFederalReport: ISzpkChangeTerminateFederalReport[] = (() => {
    if (
      !isArray(objectFromPropertyState.szpksChangeTerminateFederalReport?.value) ||
      !objectFromPropertyState.szpksChangeTerminateFederalReport?.value?.length
    )
      return []

    return objectFromPropertyState.szpksChangeTerminateFederalReport?.value.map(
      (szpkChangeTerminateFederalReport): ISzpkChangeTerminateFederalReport => {
        const preparedChangeReasonsChecker = (() => {
          if (
            !isArray(
              szpkChangeTerminateFederalReport.value?.szpkReportId?.value?.changeReasonsChecker,
            ) ||
            !szpkChangeTerminateFederalReport.value?.szpkReportId?.value?.changeReasonsChecker
              ?.length
          )
            return ''

          return szpkChangeTerminateFederalReport.value?.szpkReportId?.value?.changeReasonsChecker.join(
            ', ',
          )
        })()

        const preparedChangeReasons = (() => {
          if (
            !isArray(szpkChangeTerminateFederalReport.value?.szpkReportId?.value?.changeReasons) ||
            !szpkChangeTerminateFederalReport.value?.szpkReportId?.value?.changeReasons?.length
          )
            return ''

          return szpkChangeTerminateFederalReport.value?.szpkReportId?.value?.changeReasons.join(
            ', ',
          )
        })()

        return {
          id: szpkChangeTerminateFederalReport.propertyId,
          projectName: szpkChangeTerminateFederalReport.value?.szpkReportId?.value?.projectName,
          isChange: szpkChangeTerminateFederalReport.value?.isChanges?.value,
          isTerminate: szpkChangeTerminateFederalReport.value?.isTerminate?.value,
          registeredDate: convertStringToDateFormat(
            szpkChangeTerminateFederalReport.value?.szpkReportId?.value?.szpkRegistrationDate,
          ),
          registeredNumber: szpkChangeTerminateFederalReport.value?.szpkReportId?.value?.szpkNumber,
          totalCapitalFactChecker: formatBackendMoneyToString(
            szpkChangeTerminateFederalReport.value?.szpkReportId?.value?.totalCapitalFactChecker,
          ),
          totalCapitalFact: formatBackendMoneyToString(
            szpkChangeTerminateFederalReport.value?.szpkReportId?.value?.totalCapitalFact,
          ),
          totalCapitalCorrect: szpkChangeTerminateFederalReport.value?.totalCapitalCorrect?.value,
          investorCapitalFactChecker: formatBackendMoneyToString(
            szpkChangeTerminateFederalReport.value?.szpkReportId?.value?.investorCapitalFactChecker,
          ),
          investorCapitalFact: formatBackendMoneyToString(
            szpkChangeTerminateFederalReport.value?.szpkReportId?.value?.investorCapitalFact,
          ),
          investorCapitalCorrect:
            szpkChangeTerminateFederalReport.value?.investorCapitalCorrect?.value,
          reasonChecker: preparedChangeReasonsChecker,
          reasonRegion: preparedChangeReasons,
          changeTerminateReason:
            szpkChangeTerminateFederalReport.value?.changeTerminateReason?.value,
        }
      },
    )
  })()

  const preparedEconomicSpheresFederalReport = (() => {
    if (
      !isArray(objectFromPropertyState.economicSpheresFederalReport?.value) ||
      !objectFromPropertyState.economicSpheresFederalReport?.value?.length
    )
      return []

    return objectFromPropertyState.economicSpheresFederalReport?.value.map(
      (economicSphereFederalReport): IEconomicSphereFederalReport => {
        const preparedEconomicSphereOkvedsFederalReport = (() => {
          if (
            !isArray(economicSphereFederalReport.value?.economicSphereOkvedsFederalReport?.value) ||
            !economicSphereFederalReport.value?.economicSphereOkvedsFederalReport?.value?.length
          )
            return []

          {
            return economicSphereFederalReport.value?.economicSphereOkvedsFederalReport?.value.map(
              (economicSphereOkvedFederalReport): IEconomicSphereOkvedFederalReport => {
                const preparedRegionsOkvedReportInfo: IEconomicSphereOkved[] = (() => {
                  const regionsReportInfo = objectFromPropertyState.regionsReportInfo?.value
                  const regionsOkvedReportInfo: IEconomicSphereOkved[] = []
                  if (!isArray(regionsReportInfo) || !regionsReportInfo.length) return []

                  {
                    regionsReportInfo.filter((regionReportInfo) => {
                      const economicSpheres = regionReportInfo.value?.economicSpheres?.value
                      if (!isArray(economicSpheres) || !economicSpheres.length) return

                      {
                        return !!economicSpheres.filter((economicSphere) => {
                          const economicSphereOkveds =
                            economicSphere.value?.economicSphereOkveds?.value
                          if (!isArray(economicSphereOkveds) || !economicSphereOkveds.length) return

                          {
                            return !!economicSphereOkveds.filter((economicSphereOkved) => {
                              if (
                                !isNullOrUndefined(
                                  economicSphereOkved.value?.economicSphereOkvedCode?.value?.id,
                                ) &&
                                !isNullOrUndefined(
                                  economicSphereOkvedFederalReport.value
                                    ?.economicSphereOkvedCodeFederalReport?.value?.id,
                                ) &&
                                economicSphereOkved.value?.economicSphereOkvedCode?.value?.id ===
                                  economicSphereOkvedFederalReport.value
                                    ?.economicSphereOkvedCodeFederalReport?.value?.id
                              ) {
                                regionsOkvedReportInfo.push({
                                  economicSphereOkvedCode:
                                    economicSphereOkved.value?.economicSphereOkvedCode?.value,
                                  id: economicSphereOkved.propertyId,
                                  investorCapital:
                                    economicSphereOkved.value?.investorCapital?.value,
                                  investorCapitalChecker:
                                    economicSphereOkved.value?.investorCapitalChecker?.value,
                                  regionName: regionReportInfo.value?.regionName?.value,
                                  totalCapital: economicSphereOkved.value?.totalCapital?.value,
                                  totalCapitalChecker:
                                    economicSphereOkved.value?.totalCapitalChecker?.value,
                                  workPlacesCapital:
                                    economicSphereOkved.value?.workPlacesCapital?.value,
                                  workPlacesChecker:
                                    economicSphereOkved.value?.workPlacesChecker?.value,
                                })
                                return true
                              }
                            }).length
                          }
                        }).length
                      }
                    })
                  }
                  return regionsOkvedReportInfo
                })()

                return {
                  id: economicSphereOkvedFederalReport.propertyId,
                  economicSphereOkvedCodeFederalReport:
                    economicSphereOkvedFederalReport.value?.economicSphereOkvedCodeFederalReport
                      ?.value,
                  countEconomicSphereOkvedFederalReport:
                    economicSphereOkvedFederalReport.value?.countEconomicSphereOkvedFederalReport
                      ?.value,
                  countEconomicSphereOkvedFederalReportCorrect:
                    economicSphereOkvedFederalReport.value
                      ?.countEconomicSphereOkvedFederalReportCorrect?.value,
                  totalCapitalEconomicSphereOkvedFederalReportChecker:
                    economicSphereOkvedFederalReport.value
                      ?.totalCapitalEconomicSphereOkvedFederalReportChecker?.value,
                  totalCapitalEconomicSphereOkvedFederalReportRegion:
                    economicSphereOkvedFederalReport.value
                      ?.totalCapitalEconomicSphereOkvedFederalReportRegion?.value,
                  totalCapitalEconomicSphereOkvedFederalReportCorrect:
                    economicSphereOkvedFederalReport.value
                      ?.totalCapitalEconomicSphereOkvedFederalReportCorrect?.value,
                  investorCapitalEconomicSphereOkvedFederalReportChecker:
                    economicSphereOkvedFederalReport.value
                      ?.investorCapitalEconomicSphereOkvedFederalReportChecker?.value,
                  investorCapitalEconomicSphereOkvedFederalReportRegion:
                    economicSphereOkvedFederalReport.value
                      ?.investorCapitalEconomicSphereOkvedFederalReportRegion?.value,
                  investorCapitalEconomicSphereOkvedFederalReportCorrect:
                    economicSphereOkvedFederalReport.value
                      ?.investorCapitalEconomicSphereOkvedFederalReportCorrect?.value,
                  newWorkPlacesEconomicSphereOkvedFederalReportChecker:
                    economicSphereOkvedFederalReport.value
                      ?.newWorkPlacesEconomicSphereOkvedFederalReportChecker?.value,
                  newWorkPlacesEconomicSphereOkvedFederalReportRegion:
                    economicSphereOkvedFederalReport.value
                      ?.newWorkPlacesEconomicSphereOkvedFederalReportRegion?.value,
                  newWorkPlacesEconomicSphereOkvedFederalReportCorrect:
                    economicSphereOkvedFederalReport.value
                      ?.newWorkPlacesEconomicSphereOkvedFederalReportCorrect?.value,
                  regionsOkvedReportInfo: preparedRegionsOkvedReportInfo,
                }
              },
            )
          }
        })()
        return {
          id: economicSphereFederalReport.propertyId,
          economicSphereNameFederalReport:
            economicSphereFederalReport.value?.economicSphereNameFederalReport?.value,
          economicSphereOkvedsFederalReport: preparedEconomicSphereOkvedsFederalReport,
        }
      },
    )
  })()

  const preparedRegionReportInfoSignSubtitle = (() => {
    const allRegionReportsInfoCount = preparedRegionReportInfo.length
    const signedRegionReportsInfoCount = preparedRegionReportInfo.filter(
      (regionReportInfo) => !!regionReportInfo.reportSignDate,
    ).length

    return `Подписано ${signedRegionReportsInfoCount} ${plural(
      signedRegionReportsInfoCount,
      'отчет',
      'отчета',
      'отчетов',
    )} об исполнении из ${allRegionReportsInfoCount}`
  })()

  return {
    '1': {
      ...RHFBasicAdapters.getInfoAboutDocumentAdapter(objectFromPropertyState),
      documentReceivers: objectFromPropertyState?.documentReceivers?.value || '',
    },
    '2': {
      ...RHFBasicAdapters.getInitiatorAdapter(objectFromPropertyState),
    },
    '3': {
      signedRegionReportInfoSignSubtitle: preparedRegionReportInfoSignSubtitle,

      regionsReportInfo: preparedRegionReportInfo,

      szpkCountFederalReportPlan: objectFromPropertyState?.szpkCountFederalReportPlan?.value,
      szpkCountFederalReportFact: objectFromPropertyState?.szpkCountFederalReportFact?.value,
      federalSzpkCountFederalReportPlan:
        objectFromPropertyState?.federalSzpkCountFederalReportPlan?.value,
      federalSzpkCountFederalReportPlanCorrect:
        objectFromPropertyState?.federalSzpkCountFederalReportPlanCorrect?.value,
      federalSzpkCountFederalReportFact:
        objectFromPropertyState?.federalSzpkCountFederalReportFact?.value,
      federalSzpkCountFederalReportFactCorrect:
        objectFromPropertyState?.federalSzpkCountFederalReportFactCorrect?.value,

      regionalSzpkCountFederalReportPlan:
        objectFromPropertyState?.regionalSzpkCountFederalReportPlan?.value,
      regionalSzpkCountFederalReportPlanCorrect:
        objectFromPropertyState?.regionalSzpkCountFederalReportPlanCorrect?.value,
      regionalSzpkCountFederalReportFact:
        objectFromPropertyState?.regionalSzpkCountFederalReportFact?.value,
      regionalSzpkCountFederalReportFactCorrect:
        objectFromPropertyState?.regionalSzpkCountFederalReportFactCorrect?.value,

      szpkPeriodCountFederalReportPlan:
        objectFromPropertyState?.szpkPeriodCountFederalReportPlan?.value,
      szpkPeriodCountFederalReportFact:
        objectFromPropertyState?.szpkPeriodCountFederalReportFact?.value,
      federalSzpkPeriodCountFederalReportPlan:
        objectFromPropertyState?.federalSzpkPeriodCountFederalReportPlan?.value,
      federalSzpkPeriodCountFederalReportPlanCorrect:
        objectFromPropertyState?.federalSzpkPeriodCountFederalReportPlanCorrect?.value,
      federalSzpkPeriodCountFederalReportFact:
        objectFromPropertyState?.federalSzpkPeriodCountFederalReportFact?.value,
      federalSzpkPeriodCountFederalReportFactCorrect:
        objectFromPropertyState?.federalSzpkPeriodCountFederalReportFactCorrect?.value,

      regionalSzpkPeriodCountFederalReportPlan:
        objectFromPropertyState?.regionalSzpkPeriodCountFederalReportPlan?.value,
      regionalSzpkPeriodCountFederalReportPlanCorrect:
        objectFromPropertyState?.regionalSzpkPeriodCountFederalReportPlanCorrect?.value,
      regionalSzpkPeriodCountFederalReportFact:
        objectFromPropertyState?.regionalSzpkPeriodCountFederalReportFact?.value,
      regionalSzpkPeriodCountFederalReportFactCorrect:
        objectFromPropertyState?.regionalSzpkPeriodCountFederalReportFactCorrect?.value,

      totalCapitalFederalReportPlan: objectFromPropertyState?.totalCapitalFederalReportPlan?.value,
      totalCapitalFederalReportFact: objectFromPropertyState?.totalCapitalFederalReportFact?.value,

      federalTotalCapitalFederalReportPlanChecker:
        objectFromPropertyState?.federalTotalCapitalFederalReportPlanChecker?.value,
      federalTotalCapitalFederalReportPlanRegion:
        objectFromPropertyState?.federalTotalCapitalFederalReportPlanRegion?.value,
      federalTotalCapitalFederalReportPlanRegionDeviation: getDeviationText(
        objectFromPropertyState.federalTotalCapitalFederalReportPlanRegion?.value,
        objectFromPropertyState.federalTotalCapitalFederalReportPlanChecker?.value,
      ),
      federalTotalCapitalFederalReportPlanCorrect:
        objectFromPropertyState?.federalTotalCapitalFederalReportPlanCorrect?.value,
      federalTotalCapitalFederalReportFactChecker:
        objectFromPropertyState?.federalTotalCapitalFederalReportFactChecker?.value,
      federalTotalCapitalFederalReportFactRegion:
        objectFromPropertyState?.federalTotalCapitalFederalReportFactRegion?.value,
      federalTotalCapitalFederalReportFactRegionDeviation: getDeviationText(
        objectFromPropertyState.federalTotalCapitalFederalReportFactRegion?.value,
        objectFromPropertyState.federalTotalCapitalFederalReportFactChecker?.value,
      ),
      federalTotalCapitalFederalReportFactCorrect:
        objectFromPropertyState?.federalTotalCapitalFederalReportFactCorrect?.value,

      regionalTotalCapitalFederalReportPlanChecker:
        objectFromPropertyState?.regionalTotalCapitalFederalReportPlanChecker?.value,
      regionalTotalCapitalFederalReportPlanRegion:
        objectFromPropertyState?.regionalTotalCapitalFederalReportPlanRegion?.value,
      regionalTotalCapitalFederalReportPlanRegionDeviation: getDeviationText(
        objectFromPropertyState?.regionalTotalCapitalFederalReportPlanRegion?.value,
        objectFromPropertyState?.regionalTotalCapitalFederalReportPlanChecker?.value,
      ),
      regionalTotalCapitalFederalReportPlanCorrect:
        objectFromPropertyState?.regionalTotalCapitalFederalReportPlanCorrect?.value,
      regionalTotalCapitalFederalReportFactChecker:
        objectFromPropertyState?.regionalTotalCapitalFederalReportFactChecker?.value,
      regionalTotalCapitalFederalReportFactRegion:
        objectFromPropertyState?.regionalTotalCapitalFederalReportFactRegion?.value,
      regionalTotalCapitalFederalReportFactRegionDeviation: getDeviationText(
        objectFromPropertyState?.regionalTotalCapitalFederalReportFactRegion?.value,
        objectFromPropertyState?.regionalTotalCapitalFederalReportFactChecker?.value,
      ),
      regionalTotalCapitalFederalReportFactCorrect:
        objectFromPropertyState?.regionalTotalCapitalFederalReportFactCorrect?.value,

      investorCapitalFederalReportPlan:
        objectFromPropertyState?.investorCapitalFederalReportPlan?.value,
      investorCapitalFederalReportFact:
        objectFromPropertyState?.investorCapitalFederalReportFact?.value,

      federalInvestorCapitalFederalReportPlanChecker:
        objectFromPropertyState?.federalInvestorCapitalFederalReportPlanChecker?.value,
      federalInvestorCapitalFederalReportPlanRegion:
        objectFromPropertyState?.federalInvestorCapitalFederalReportPlanRegion?.value,
      federalInvestorCapitalFederalReportPlanRegionDeviation: getDeviationText(
        objectFromPropertyState?.federalInvestorCapitalFederalReportPlanRegion?.value,
        objectFromPropertyState?.federalInvestorCapitalFederalReportPlanChecker?.value,
      ),
      federalInvestorCapitalFederalReportPlanCorrect:
        objectFromPropertyState?.federalInvestorCapitalFederalReportPlanCorrect?.value,
      federalInvestorCapitalFederalReportFactChecker:
        objectFromPropertyState?.federalInvestorCapitalFederalReportFactChecker?.value,
      federalInvestorCapitalFederalReportFactRegion:
        objectFromPropertyState?.federalInvestorCapitalFederalReportFactRegion?.value,
      federalInvestorCapitalFederalReportFactRegionDeviation: getDeviationText(
        objectFromPropertyState?.federalInvestorCapitalFederalReportFactRegion?.value,
        objectFromPropertyState?.federalInvestorCapitalFederalReportFactChecker?.value,
      ),
      federalInvestorCapitalFederalReportFactCorrect:
        objectFromPropertyState?.federalInvestorCapitalFederalReportFactCorrect?.value,

      regionalInvestorCapitalFederalReportPlanChecker:
        objectFromPropertyState?.regionalInvestorCapitalFederalReportPlanChecker?.value,
      regionalInvestorCapitalFederalReportPlanRegion:
        objectFromPropertyState?.regionalInvestorCapitalFederalReportPlanRegion?.value,
      regionalInvestorCapitalFederalReportPlanRegionDeviation: getDeviationText(
        objectFromPropertyState?.regionalInvestorCapitalFederalReportPlanRegion?.value,
        objectFromPropertyState?.regionalInvestorCapitalFederalReportPlanChecker?.value,
      ),
      regionalInvestorCapitalFederalReportPlanCorrect:
        objectFromPropertyState?.regionalInvestorCapitalFederalReportPlanCorrect?.value,
      regionalInvestorCapitalFederalReportFactChecker:
        objectFromPropertyState?.regionalInvestorCapitalFederalReportFactChecker?.value,
      regionalInvestorCapitalFederalReportFactRegion:
        objectFromPropertyState?.regionalInvestorCapitalFederalReportFactRegion?.value,
      regionalInvestorCapitalFederalReportFactCorrect:
        objectFromPropertyState?.regionalInvestorCapitalFederalReportFactCorrect?.value,

      newWorkPlacesFederalReportPlan:
        objectFromPropertyState?.newWorkPlacesFederalReportPlan?.value,
      newWorkPlacesFederalReportFact:
        objectFromPropertyState?.newWorkPlacesFederalReportFact?.value,

      federalNewWorkPlacesFederalReportPlanChecker:
        objectFromPropertyState?.federalNewWorkPlacesFederalReportPlanChecker?.value,
      federalNewWorkPlacesFederalReportPlanRegion:
        objectFromPropertyState?.federalNewWorkPlacesFederalReportPlanRegion?.value,
      federalNewWorkPlacesFederalReportPlanRegionDeviation: getDeviationText(
        objectFromPropertyState?.federalNewWorkPlacesFederalReportPlanRegion?.value,
        objectFromPropertyState?.federalNewWorkPlacesFederalReportPlanChecker?.value,
      ),
      federalNewWorkPlacesFederalReportPlanCorrect:
        objectFromPropertyState?.federalNewWorkPlacesFederalReportPlanCorrect?.value,
      federalNewWorkPlacesFederalReportFactChecker:
        objectFromPropertyState?.federalNewWorkPlacesFederalReportFactChecker?.value,
      federalNewWorkPlacesFederalReportFactRegion:
        objectFromPropertyState?.federalNewWorkPlacesFederalReportFactRegion?.value,
      federalNewWorkPlacesFederalReportFactRegionDeviation: getDeviationText(
        objectFromPropertyState?.federalNewWorkPlacesFederalReportFactRegion?.value,
        objectFromPropertyState?.federalNewWorkPlacesFederalReportFactChecker?.value,
      ),
      federalNewWorkPlacesFederalReportFactCorrect:
        objectFromPropertyState?.federalNewWorkPlacesFederalReportFactCorrect?.value,

      regionalNewWorkPlacesFederalReportPlanRegion:
        objectFromPropertyState?.regionalNewWorkPlacesFederalReportPlanRegion?.value,
      regionalNewWorkPlacesFederalReportPlanRegionDeviation: getDeviationText(
        objectFromPropertyState?.regionalNewWorkPlacesFederalReportPlanRegion?.value,
        objectFromPropertyState?.regionalNewWorkPlacesFederalReportPlanChecker?.value,
      ),
      regionalNewWorkPlacesFederalReportPlanChecker:
        objectFromPropertyState?.regionalNewWorkPlacesFederalReportPlanChecker?.value,
      regionalNewWorkPlacesFederalReportPlanCorrect:
        objectFromPropertyState?.regionalNewWorkPlacesFederalReportPlanCorrect?.value,
      regionalNewWorkPlacesFederalReportFactChecker:
        objectFromPropertyState?.regionalNewWorkPlacesFederalReportFactChecker?.value,
      regionalNewWorkPlacesFederalReportFactRegion:
        objectFromPropertyState?.regionalNewWorkPlacesFederalReportFactRegion?.value,
      regionalNewWorkPlacesFederalReportFactRegionDeviation: getDeviationText(
        objectFromPropertyState?.regionalNewWorkPlacesFederalReportFactRegion?.value,
        objectFromPropertyState?.regionalNewWorkPlacesFederalReportFactChecker?.value,
      ),
      regionalNewWorkPlacesFederalReportFactCorrect:
        objectFromPropertyState?.regionalNewWorkPlacesFederalReportFactCorrect?.value,

      compensationValueFederalReportPlan:
        objectFromPropertyState?.compensationValueFederalReportPlan?.value,
      compensationValueFederalReportFact:
        objectFromPropertyState?.compensationValueFederalReportFact?.value,

      federalCompensationValueFederalReportPlanChecker:
        objectFromPropertyState?.federalCompensationValueFederalReportPlanChecker?.value,
      federalCompensationValueFederalReportPlanRegion:
        objectFromPropertyState?.federalCompensationValueFederalReportPlanRegion?.value,
      federalCompensationValueFederalReportPlanRegionDeviation: getDeviationText(
        objectFromPropertyState?.federalCompensationValueFederalReportPlanRegion?.value,
        objectFromPropertyState?.federalCompensationValueFederalReportPlanChecker?.value,
      ),
      federalCompensationValueFederalReportPlanCorrect:
        objectFromPropertyState?.federalCompensationValueFederalReportPlanCorrect?.value,
      federalCompensationValueFederalReportFactChecker:
        objectFromPropertyState?.federalCompensationValueFederalReportFactChecker?.value,
      federalCompensationValueFederalReportFactRegion:
        objectFromPropertyState?.federalCompensationValueFederalReportFactRegion?.value,
      federalCompensationValueFederalReportFactRegionDeviation: getDeviationText(
        objectFromPropertyState?.federalCompensationValueFederalReportFactRegion?.value,
        objectFromPropertyState?.federalCompensationValueFederalReportFactChecker?.value,
      ),
      federalCompensationValueFederalReportFactCorrect:
        objectFromPropertyState?.federalCompensationValueFederalReportFactCorrect?.value,

      regionalCompensationValueFederalReportPlanChecker:
        objectFromPropertyState?.regionalCompensationValueFederalReportPlanChecker?.value,
      regionalCompensationValueFederalReportPlanRegion:
        objectFromPropertyState?.regionalCompensationValueFederalReportPlanRegion?.value,
      regionalCompensationValueFederalReportPlanRegionDeviation: getDeviationText(
        objectFromPropertyState?.regionalCompensationValueFederalReportPlanRegion?.value,
        objectFromPropertyState?.regionalCompensationValueFederalReportPlanChecker?.value,
      ),
      regionalCompensationValueFederalReportPlanCorrect:
        objectFromPropertyState?.regionalCompensationValueFederalReportPlanCorrect?.value,
      regionalCompensationValueFederalReportFactChecker:
        objectFromPropertyState?.regionalCompensationValueFederalReportFactChecker?.value,
      regionalCompensationValueFederalReportFactRegion:
        objectFromPropertyState?.regionalCompensationValueFederalReportFactRegion?.value,
      regionalInvestorCapitalFederalReportFactRegionDeviation: getDeviationText(
        objectFromPropertyState?.regionalCompensationValueFederalReportFactRegion?.value,
        objectFromPropertyState?.regionalCompensationValueFederalReportFactChecker?.value,
      ),
      regionalCompensationValueFederalReportFactCorrect:
        objectFromPropertyState?.regionalCompensationValueFederalReportFactCorrect?.value,
      regionalCompensationValueFederalReportFactRegionDeviation: getDeviationText(
        objectFromPropertyState?.regionalCompensationValueFederalReportFactRegion?.value,
        objectFromPropertyState?.regionalCompensationValueFederalReportFactChecker?.value,
      ),
    },
    '4': {
      economicSpheresFederalReport: preparedEconomicSpheresFederalReport,
    },
    '5': {
      regionsFederalReport: preparedRegionsFederalReport,
    },
    '6': {
      szpksChangeTerminateFederalReport: preparedSzpksChangeTerminateFederalReport,
      federalChangeTerminationSzpkCountFederalReport:
        objectFromPropertyState?.federalChangeTerminationSzpkCountFederalReport?.value,
      federalChangeTerminationSzpkTotalCapitalFederalReport:
        objectFromPropertyState?.federalChangeTerminationSzpkTotalCapitalFederalReport?.value,
      federalChangeTerminationSzpkInvestorCapitalFederalReport:
        objectFromPropertyState?.federalChangeTerminationSzpkInvestorCapitalFederalReport?.value,

      federalChangeSzpkCountFederalReport:
        objectFromPropertyState?.federalChangeSzpkCountFederalReport?.value,
      federalChangeSzpkTotalCapitalFederalReport:
        objectFromPropertyState?.federalChangeSzpkTotalCapitalFederalReport?.value,
      federalChangeSzpkInvestorCapitalFederalReport:
        objectFromPropertyState?.federalChangeSzpkInvestorCapitalFederalReport?.value,

      federalTerminationSzpkCountFederalReport:
        objectFromPropertyState?.federalTerminationSzpkCountFederalReport?.value,
      federalTerminationSzpkTotalCapitalFederalReport:
        objectFromPropertyState?.federalTerminationSzpkTotalCapitalFederalReport?.value,
      federalTerminationSzpkInvestorCapitalFederalReport:
        objectFromPropertyState?.federalTerminationSzpkInvestorCapitalFederalReport?.value,

      regionalChangeTerminationSzpkCountFederalReport:
        objectFromPropertyState?.regionalChangeTerminationSzpkCountFederalReport?.value,
      regionalChangeTerminationSzpkTotalCapitalFederalReport:
        objectFromPropertyState?.regionalChangeTerminationSzpkTotalCapitalFederalReport?.value,
      regionalChangeTerminationSzpkInvestorCapitalFederalReport:
        objectFromPropertyState?.regionalChangeTerminationSzpkInvestorCapitalFederalReport?.value,

      regionalChangeSzpkCountFederalReport:
        objectFromPropertyState?.regionalChangeSzpkCountFederalReport?.value,
      regionalChangeSzpkTotalCapitalFederalReport:
        objectFromPropertyState?.regionalChangeSzpkTotalCapitalFederalReport?.value,
      regionalChangeSzpkInvestorCapitalFederalReport:
        objectFromPropertyState?.regionalChangeSzpkInvestorCapitalFederalReport?.value,

      regionalTerminationSzpkCountFederalReport:
        objectFromPropertyState?.regionalTerminationSzpkCountFederalReport?.value,
      regionalTerminationSzpkTotalCapitalFederalReport:
        objectFromPropertyState?.regionalTerminationSzpkTotalCapitalFederalReport?.value,
      regionalTerminationSzpkInvestorCapitalFederalReport:
        objectFromPropertyState?.regionalTerminationSzpkInvestorCapitalFederalReport?.value,
    },
  }
}

export { generateRHFObjectForSummaryReportOfMonitoringResultsForm }
