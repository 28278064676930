import React, { FC, ReactNode } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'

import FieldArraySwapper from '@components/DocumentFormComponents/FieldArraySwapper'
import Border from '@components/DocumentFormComponents/FieldView/Border'
import styles from '@components/DocumentFormComponents/FormStyles/AdditionalFormItem.module.scss'
import ObjectStepWrapper from '@components/DocumentFormComponents/ObjectStepWrapper'
import RExploitationFormItem from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/AdditionalForms/MainTemplate/RidObject/Forms/RExploitationForm/RExploitationFormItem'
import { SeventhRidExploitationObjectsArrayPathName } from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/Forms/7/types'
import {
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues,
  AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues,
} from '@components/Forms/AAgreementOnNonConclusionOrNonPerformanceOfConcessionAForm/types'
import Typography from '@components/NewDesign/Typography'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'

interface RExploitationItemProps {
  id: string
  anchorId: string
  formName: SeventhRidExploitationObjectsArrayPathName
  blockViewIsValidating: boolean
  editMode: boolean
  formInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormValues>
  formModifierInstance: UseFormReturn<AAgreementOnNonConclusionOrNonPerformanceOfConcessionAFormModifierValues>
  indexOfBlock: number
  indexOfExploitation: number
  lastIndexOfExploitation: number
  onDeleteExploitation: VoidFunction
  onMoveTop: () => Promise<void>
  onMoveBottom: () => Promise<void>
  leftAddons?: ReactNode
}

const RExploitationItem: FC<RExploitationItemProps> = ({
  id,
  anchorId,
  blockViewIsValidating,
  editMode,
  formInstance,
  formModifierInstance,
  indexOfBlock,
  lastIndexOfExploitation,
  formName,
  indexOfExploitation,
  onDeleteExploitation,
  onMoveTop,
  onMoveBottom,
  leftAddons,
}) => {
  const title = useWatch({
    name: `${formName}.title`,
    control: formInstance.control,
  })

  return (
    <ObjectStepWrapper
      disableBorder
      stepId={id}
      id={anchorId}
      leftAddons={leftAddons}
      headerNode={<Typography.Body variant={'bodyMSemibold'}>{title}</Typography.Body>}
      additionalHeaderNode={
        editMode && (
          <FieldArraySwapper
            index={indexOfExploitation}
            lastIndex={lastIndexOfExploitation}
            onMoveTop={onMoveTop}
            onMoveBottom={onMoveBottom}
          />
        )
      }
      onRemoveObjectStep={editMode ? onDeleteExploitation : undefined}
    >
      <Stack direction={'vertical'} gap={3}>
        <Row>
          <RExploitationFormItem
            formName={formName}
            blockViewIsValidating={blockViewIsValidating}
            formInstance={formInstance}
            formModifierInstance={formModifierInstance}
            indexOfBlock={indexOfBlock}
          />
        </Row>
        <Row>
          <Col xs={12}>
            <Border className={styles.additionalFormItem__border} />
          </Col>
        </Row>
      </Stack>
    </ObjectStepWrapper>
  )
}

export default RExploitationItem
