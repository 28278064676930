import { FormModifierAdapters } from '@components/DocumentFormComponents/adapters/Modifier.adapter'
import { FormFieldStates } from '@components/DocumentFormComponents/types'
import {
  RegistrationCertificateFormModifierValues,
  RegistrationCertificateGeneralStepRegisteredDateValueModifier,
} from '@components/Forms/RegistrationCertificateForm/types'
import { isArray } from '@helpers/checkTypes'

const generateFormModifierValuesForRegistrationCertificateForm = (
  objectFromPropertyState: Record<string, any>,
): RegistrationCertificateFormModifierValues => {
  const preparedExtraRegisteredDocumentValues: FormFieldStates[] = (() => {
    if (
      !isArray(objectFromPropertyState.extraRegisteredDocumentValues?.value) ||
      !objectFromPropertyState.extraRegisteredDocumentValues?.value?.length
    )
      return []

    return objectFromPropertyState.extraRegisteredDocumentValues?.value.map(
      (extraRegisteredDocument): FormFieldStates => {
        const isPublic = [
          extraRegisteredDocument?.value?.extraTypeName?.isPublic,
          extraRegisteredDocument?.value?.extraRegistrationDate?.isPublic,
          extraRegisteredDocument?.value?.extraDescription?.isPublic,
          extraRegisteredDocument?.value?.extraLastSignDate?.isPublic,
        ].every(Boolean)

        return {
          id: extraRegisteredDocument?.propertyId,
          isPublic,
        }
      },
    )
  })()

  const preparedRelatedContracts: FormFieldStates[] = (() => {
    if (
      !isArray(objectFromPropertyState.relatedContracts?.value) ||
      !objectFromPropertyState.relatedContracts?.value?.length
    )
      return []

    return objectFromPropertyState.relatedContracts?.value.map(
      (relatedContract): FormFieldStates => {
        const isPublic = [
          relatedContract?.value?.relatedContractNumber?.isPublic,
          relatedContract?.value?.relatedContractDate?.isPublic,
          relatedContract?.value?.relatedContractPublicLawMember?.isPublic,
          relatedContract?.value?.relatedContractKind?.isPublic,
          relatedContract?.value?.relatedContractTerms?.isPublic,
        ].every(Boolean)

        return {
          id: relatedContract?.propertyId,
          isPublic,
        }
      },
    )
  })()

  const preparedRelatedContractListRegisteredDocumentValues: FormFieldStates[] = (() => {
    if (
      !isArray(objectFromPropertyState?.relatedContractListRegisteredDocumentValues?.value) ||
      !objectFromPropertyState?.relatedContractListRegisteredDocumentValues?.value?.length
    )
      return []

    return objectFromPropertyState?.relatedContractListRegisteredDocumentValues?.value.map(
      (documentValue): FormFieldStates => {
        const isPublic = [
          documentValue?.value?.relatedContractNumberRegisteredDocument?.isPublic,
          documentValue?.value?.relatedContractDateRegisteredDocument?.isPublic,
          documentValue?.value?.relatedContractDescriptionRegisteredDocument?.isPublic,
          documentValue?.value?.relatedContractOrganizationRegisteredDocument?.isPublic,
        ].every(Boolean)

        return {
          id: documentValue?.propertyId,
          isPublic,
        }
      },
    )
  })()

  const preparedGeneralStepRegisteredDateValues: RegistrationCertificateGeneralStepRegisteredDateValueModifier[] =
    (() => {
      if (
        !isArray(objectFromPropertyState.stepRegisteredDateValues?.value) ||
        !objectFromPropertyState.stepRegisteredDateValues?.value?.length
      )
        return []

      return objectFromPropertyState.stepRegisteredDateValues?.value.map(
        (step): RegistrationCertificateGeneralStepRegisteredDateValueModifier => {
          const currentStep = objectFromPropertyState.steps?.value?.find(
            (item) => item.permanentId === step?.value?.stepId?.value?.id,
          )

          const isStepDateGroupPublic = [
            currentStep?.value?.stepStartDate?.isPublic,
            currentStep?.value?.stepEndDate?.isPublic,
          ].every(Boolean)

          const isCapitalGroupPublic = [
            step.value?.factStepInvestorCapital?.isPublic,
            step.value?.stepRemainsCapital?.isPublic,
            currentStep?.value?.stepInvestorCapitalValue?.isPublic,
          ].every(Boolean)

          return {
            id: step.propertyId,
            stepDateGroup: {
              isPublic: isStepDateGroupPublic,
            },
            capitalGroup: {
              isPublic: isCapitalGroupPublic,
            },
          }
        },
      )
    })()

  return {
    '3': {
      ...FormModifierAdapters.getInvestorModifierAdapter(objectFromPropertyState),
      isProjectCompany: FormModifierAdapters.getModifierStatesFormPropertyState(
        objectFromPropertyState.investor?.value.isProjectCompany,
      ),
    },
    '4': {
      rfFullName: FormModifierAdapters.getRFModifierAdapter(objectFromPropertyState).rfFullName,
      subjects: FormModifierAdapters.getSubjectsModifierAdapter(objectFromPropertyState),
      municipalities:
        FormModifierAdapters.getMunicipalitiesModifierAdapter(objectFromPropertyState),
    },
    '5': {
      projectName: FormModifierAdapters.getModifierStatesFormPropertyState(
        objectFromPropertyState.projectName,
      ),
      szpkSignDate: FormModifierAdapters.getModifierStatesFormPropertyState(
        objectFromPropertyState.szpkSignDate,
      ),
      investorCapital: FormModifierAdapters.getModifierStatesFormPropertyState(
        objectFromPropertyState.investorCapital,
      ),
      factMadeInvestorCapital: !!objectFromPropertyState.factMadeInvestorCapital?.value
        ? FormModifierAdapters.getModifierStatesFormPropertyState(
            objectFromPropertyState.factMadeInvestorCapital,
          )
        : FormModifierAdapters.getModifierStatesFormPropertyState(
            objectFromPropertyState.madeInvestorCapital,
          ),
      factRemainsInvestorCapital: !!objectFromPropertyState.factMadeInvestorCapital?.value
        ? FormModifierAdapters.getModifierStatesFormPropertyState(
            objectFromPropertyState.factRemainsInvestorCapital,
          )
        : FormModifierAdapters.getModifierStatesFormPropertyState(
            objectFromPropertyState.remainsInvestorCapital,
          ),
      stabilizationPeriod: FormModifierAdapters.getModifierStatesFormPropertyState(
        objectFromPropertyState.stabilizationPeriod,
      ),
      plannedTax: {
        id: objectFromPropertyState.plannedTax?.propertyId,
        isPublic: objectFromPropertyState.plannedTax?.isPublic,
      },
      stepRegisteredDateValues: preparedGeneralStepRegisteredDateValues,
    },
    '6': {
      extraRegisteredDocuments: preparedExtraRegisteredDocumentValues,
    },
    '7': {
      relatedContractListRegisteredDocumentValues:
        preparedRelatedContractListRegisteredDocumentValues,
    },
    '8': {
      relatedContracts: preparedRelatedContracts,
    },
    '9': {
      infoMonitoringReport: FormModifierAdapters.getModifierStatesFormPropertyState(
        objectFromPropertyState.infoMonitoringReport,
      ),
      infoMonitoringResult: FormModifierAdapters.getModifierStatesFormPropertyState(
        objectFromPropertyState.infoMonitoringResult,
      ),
    },
    '10': {
      infoBudgetRF: FormModifierAdapters.getModifierStatesFormPropertyState(
        objectFromPropertyState.infoBudgetRF,
      ),
      infoDamageFormatCompensation: FormModifierAdapters.getModifierStatesFormPropertyState(
        objectFromPropertyState.infoDamageFormatCompensation,
      ),
      infoViolationStabilizationPeriod: FormModifierAdapters.getModifierStatesFormPropertyState(
        objectFromPropertyState.infoViolationStabilizationPeriod,
      ),
      infoPartner: FormModifierAdapters.getModifierStatesFormPropertyState(
        objectFromPropertyState.infoPartner,
      ),
      infoDeposit: FormModifierAdapters.getModifierStatesFormPropertyState(
        objectFromPropertyState.infoDeposit,
      ),
      infoCession: FormModifierAdapters.getModifierStatesFormPropertyState(
        objectFromPropertyState.infoCession,
      ),
    },
    '11': {
      agreementTerminationReasonDescription:
        FormModifierAdapters.getModifierStatesFormPropertyState(
          objectFromPropertyState.agreementTerminationReasonDescription,
        ),
      agreementTerminationReasonInvalidPart:
        FormModifierAdapters.getModifierStatesFormPropertyState(
          objectFromPropertyState.agreementTerminationReasonInvalidPart,
        ),
    },
  }
}

export { generateFormModifierValuesForRegistrationCertificateForm }
