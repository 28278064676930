import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'

import AsyncWrapper from '@components/AsyncWrapper'
import { DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST } from '@components/DocumentFormComponents/const'
import FieldView from '@components/DocumentFormComponents/FieldView'
import FlipperList from '@components/DocumentFormComponents/FlipperList'
import FormError from '@components/DocumentFormComponents/FormError'
import ControlledFormSingleSelect from '@components/DocumentFormComponents/FormSelect/Single/Controlled'
import Group from '@components/DocumentFormComponents/Group'
import { DocumentFormHelpers } from '@components/DocumentFormComponents/helpers'
import { useFieldArraySubscribableControl } from '@components/DocumentFormComponents/hooks/useFieldArraySubscribableControl'
import { useFormComponentPresets } from '@components/DocumentFormComponents/hooks/useFormComponentPresets'
import { isFetcherProps } from '@components/DocumentFormComponents/hooks/useOverrideFormProps'
import SubscribableControl from '@components/DocumentFormComponents/SubscribableControl'
import {
  ConstructionObjectRegionsPathName,
  ConstructionObjectsArrayPathName,
  ConstructionSubObjectsArrayPathName,
} from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Forms/5/types'
import { useAAgreementOnCreationOtherEstateObjectsManager } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/Manager'
import { AAgreementOnCreationOtherEstateObjectsFormValues } from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/types'
import {
  AAgreementOnCreationOtherEstateObjectsFieldArrayControlUpdateWatcher,
  AAgreementOnCreationOtherEstateObjectsFieldsControlUpdateWatcher,
} from '@components/Forms/AAgreementOnCreationOtherEstateObjectsForm/watcher'
import Button from '@components/NewDesign/Button'
import Col from '@components/ReactBootstrap/Col'
import Row from '@components/ReactBootstrap/Row'
import Stack from '@components/ReactBootstrap/Stack'
import { isArray, isObject } from '@helpers/checkTypes'
import CircleAddIcon from '@icons/CircleAddIcon.svg'
import { IRegionsSetupPropsObject } from '@services/Properties/Properties.entity'

import { objectRegionsValidationMap } from './validation'

const { isFormFieldError } = DocumentFormHelpers

interface ObjectRegionsListProps {
  editMode: boolean
  blockViewIsValidating: boolean
  formInstance: UseFormReturn<AAgreementOnCreationOtherEstateObjectsFormValues>
  formName: ConstructionObjectsArrayPathName | ConstructionSubObjectsArrayPathName
}

const ObjectRegionsList: FC<ObjectRegionsListProps> = ({
  editMode,
  blockViewIsValidating,
  formInstance,
  formName,
}) => {
  const {
    handlers: { handleAddItemToListWithOutValue, handleRemoveItemFromList, handleChangeValue },
    preparedProps: { subscribableControl },
  } = useAAgreementOnCreationOtherEstateObjectsManager()

  const { getSubscribableControlProps, getSingleSelectProps } = useFormComponentPresets({
    editMode,
    blockViewIsValidating,
    formInstance,
    subscribableControl,
    watcher: AAgreementOnCreationOtherEstateObjectsFieldsControlUpdateWatcher,
  })

  const { fields: objectRegions } = useFieldArraySubscribableControl<
    AAgreementOnCreationOtherEstateObjectsFormValues,
    ConstructionObjectRegionsPathName,
    'keyNameId'
  >({
    control: formInstance.control,
    name: `${formName}.objectRegions`,
    keyName: 'keyNameId',
    watcher: AAgreementOnCreationOtherEstateObjectsFieldArrayControlUpdateWatcher,
  })

  const handleAddNewObjectNamePermission = async () => {
    await handleAddItemToListWithOutValue?.(`${formName}.objectRegions`)

    if (!objectRegions.length) {
      formInstance.clearErrors(`${formName}.objectRegions`)
    }
  }

  const handleRemoveObjectNamePermission = (index: number) => async () => {
    await handleRemoveItemFromList?.(
      `${formName}.objectRegions.${index}`,
      `${formName}.objectRegions`,
    )
  }

  const objectRegionsError = formInstance.getFieldState(`${formName}.objectRegions`)?.error

  return (
    <Group disableBottomBorder title="Местоположение объекта">
      <Stack direction={'vertical'} gap={!!objectRegions.length ? 3 : 0}>
        <SubscribableControl
          {...getSubscribableControlProps({
            path: `${formName}.objectRegions`,
          })}
        >
          <FlipperList list={objectRegions}>
            {objectRegions.map((objectRegion, index) => {
              const objectRegionFormName = `${formName}.objectRegions.${index}` as const

              return (
                <FieldView.RowWithRemoveButton
                  key={objectRegion.id}
                  id={objectRegion.id}
                  onRemove={editMode ? handleRemoveObjectNamePermission(index) : undefined}
                >
                  <SubscribableControl
                    {...getSubscribableControlProps({
                      path: `${objectRegionFormName}.objectRegion`,
                    })}
                  >
                    {({ overrideProps }) => {
                      const fetcherProps = isFetcherProps(overrideProps.fetcherOptions)
                        ? overrideProps.fetcherOptions
                        : undefined

                      return (
                        <ControlledFormSingleSelect
                          {...getSingleSelectProps({
                            fetcherProps,
                            transformOptionsData: (data) =>
                              isObject<IRegionsSetupPropsObject>(data) &&
                              isObject(data.filteredRegions)
                                ? Object.entries(data.filteredRegions)
                                : data,
                            optionsAdapter: (item) => {
                              if (!isArray(item)) return null

                              const [itemKey, itemValue] = item

                              return {
                                displayValue: itemValue || '',
                                value: itemKey,
                              }
                            },
                            controllerProps: {
                              name: `${objectRegionFormName}.objectRegion`,
                              rules: objectRegionsValidationMap.objectRegion,
                            },
                            selectProps: {
                              withContextSearch: true,
                              inputProps: {
                                label: 'Адрес (местоположение) объекта',
                              },
                              onChangeFormValue: () =>
                                setTimeout(
                                  () => handleChangeValue?.(`${objectRegionFormName}.objectRegion`),
                                  0,
                                ),
                            },
                          })}
                        />
                      )
                    }}
                  </SubscribableControl>
                </FieldView.RowWithRemoveButton>
              )
            })}
          </FlipperList>
        </SubscribableControl>
        {isFormFieldError(objectRegionsError) && !objectRegions.length && (
          <FormError>{DEFAULT_VALIDATION_ERROR_TEXT_FOR_LIST}</FormError>
        )}
        {editMode && (
          <Row className={'px-1'}>
            <Col xs={12}>
              <AsyncWrapper promise={handleAddNewObjectNamePermission}>
                {({ isLoading, wrappedPromise }) => {
                  return (
                    <Button
                      disabled={isLoading}
                      leadingIcon={{ src: CircleAddIcon }}
                      variant={'buttonSMedium'}
                      size={'2xs'}
                      view={'plain'}
                      loaderProps={{
                        loading: isLoading,
                        placement: 'trailing',
                        variant: 'lite',
                      }}
                      onClick={wrappedPromise}
                    >
                      Добавить местоположение
                    </Button>
                  )
                }}
              </AsyncWrapper>
            </Col>
          </Row>
        )}
      </Stack>
    </Group>
  )
}

export default ObjectRegionsList
